<app-page>
    <app-title>{{ 'device_orders' | translate }}</app-title>
    <div appFieldContainer>
        <mat-form-field appField>
            <mat-label>{{ 'device_id' | translate }}</mat-label>
            <input matInput [formControl]="deviceIdFormControl" required>
            <mat-error *ngIf="deviceIdFormControl.invalid">{{formService.getFieldErrorMessage(deviceIdFormControl)}}</mat-error>
        </mat-form-field>
        <br>
        <app-action [text]="'submit' | translate" [disabled]="deviceIdFormControl.invalid" (onExecute)="fetchDeviceOrders(deviceIdFormControl.value)">
        </app-action>
    </div>
    <br>
    <app-orders [ordersModel]="deviceOrdersModel"></app-orders>
</app-page>
