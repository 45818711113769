import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageCenteredContentComponent } from '../../components/page-centered-content/page-centered-content.component';
import { SignInComponent } from '../../components/sign-in/sign-in.component';

@Component({
  selector: 'app-sign-in-page',
  standalone: true,
  imports: [CommonModule, PageCenteredContentComponent, SignInComponent],
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.css']
})
export class SignInPageComponent {

}
