import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-right-aligned-compact-content>
//   <table class="toolbar-table">
//       <tr>
//           <td>
//               <button mat-fab [matTooltip]="'add' | translate" (click)="addMenuEntry()">
//                   <mat-icon>add</mat-icon>
//               </button>
//           </td>
//       </tr>
//   </table>
// </app-right-aligned-compact-content>

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-right-aligned-compact-content',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './right-aligned-compact-content.component.html',
  styleUrls: ['./right-aligned-compact-content.component.css']
})
export class RightAlignedCompactContentComponent {

}
