import * as proto from 'src/proto/compiled-protos';
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { ActivatedRoute } from '@angular/router';
import { ActionObserver } from 'src/app/general/components/action/action';
import { DeviceService } from 'src/app/general/services/device.service';
import { FormService } from 'src/app/general/services/form.service';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { SuccessResult, ErrorResult } from 'src/app/general/util/result';
import { ProtoUtil } from '../../util/proto-util';

@Component({
  selector: 'app-add-business-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent
  ],
  templateUrl: './add-business-page.component.html',
  styleUrls: ['./add-business-page.component.css']
})
export class AddBusinessPageComponent implements OnInit {

  userId: string;

  businessNameField: FormControl = new FormControl('', [Validators.required]);
  descriptionEnField: FormControl = new FormControl('', [Validators.required]);
  descriptionEsField: FormControl = new FormControl('', [Validators.required]);

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.userId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.userId = Util.safeString(routeParams.get('userId'));
  }

  public addBusiness(actionObserver: ActionObserver): void {
    const business = new proto.waiternow.common.BusinessProto();
    business.businessName = this.businessNameField.value;
    if (this.descriptionEnField.value || this.descriptionEsField.value) {
      business.description =  ProtoUtil.createTextProto(this.descriptionEnField.value, this.descriptionEsField.value);
    }

    this.backendService.createBusiness(
      business,
      this.userId,
      /* onSuccess= */ addedBusiness => {
        actionObserver.onSuccess(SuccessResult.empty());
        this.navigationService.goToBusinessPage(Util.safeString(addedBusiness?.id));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_adding_business'));
      }
    );
  }
}
