import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from '../../components/user/user.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { PageComponent } from '../../components/page/page.component';
import { Util } from 'src/app/general/util/util';

@Component({
  selector: 'app-user-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    UserComponent
  ],
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})
export class UserPageComponent {
  userId: string;
  userEmail: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute) {
        this.userId = '';
        this.userEmail = '';
      }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    const userIdOrEmail = Util.safeString(pathParams.get('userIdOrEmail'));
    if (userIdOrEmail.indexOf('@') > 0) {
      this.userEmail = userIdOrEmail;
    } else {
      this.userId = userIdOrEmail;
    }
  }
}
