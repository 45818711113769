<app-page>
    <app-business-search (onBusinessSelectionChange)="onBusinessSelectionChange($event)"></app-business-search>
    <div *ngIf="selectedBusiness">
        <br>
        <mat-divider></mat-divider>
        <br>
        <div align="right">
            <app-action icon="open_in_new" tooltip="{{ 'open_in_new_window' | translate }}"
                    (onExecute)="openSelectedBusinessInNewTab()">
            </app-action>
        </div>
        <app-business [businessInput]="selectedBusiness"></app-business>
    </div>
</app-page>
