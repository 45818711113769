<app-loadable-content [loadingController]="locationPointsOfServiceLoadingController" (onRetry)="loadPointsOfService()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'points_of_service' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-data-table
                [data]="pointsOfService"
                [columns]="columns"
                [toolbarActions]="pointOfServiceToolbarModel.actions"
                [compactToolbarActions]="pointOfServiceToolbarModel.compactActions">
            </app-data-table>
        </mat-card-content>
    </mat-card>
</app-loadable-content>
