import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { PageComponent } from '../../components/page/page.component';
import { CampaignsComponent } from '../../components/campaigns/campaigns.component';
import { LocationHeaderComponent } from '../../components/location-header/location-header.component';
import { BusinessHeaderComponent } from '../../components/business-header/business-header.component';
import { NavigationService } from '../../services/navigation.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-campaigns-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    CampaignsComponent,
    BusinessHeaderComponent,
    LocationHeaderComponent,
    ActionComponent,
    TranslateModule
  ],
  templateUrl: './campaigns-page.component.html',
  styleUrls: ['./campaigns-page.component.css']
})
export class CampaignsPageComponent {

  businessId: string;
  locationId: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute,
      public navigationService: NavigationService) {
    this.businessId = '';
    this.locationId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.businessId = Util.safeString(pathParams.get('businessId'));
    this.locationId = Util.safeString(pathParams.get('locationId'));
  }

  public goToAddCampaignPage(): void {
    if (this.businessId) {
      this.navigationService.goToAddBusinessCampaignPage(this.businessId)
    } else if (this.locationId) {
      this.navigationService.goToAddLocationCampaignPage(this.locationId)
    }
  }
}
