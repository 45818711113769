import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  country: Country;

  constructor(
      private translateService: TranslateService) {
    this.country = Country.Us;
  }

  /**
   * @returns true of the current langauge is english
   */
  public isEnglish(): boolean {
    return this.getCurrentLanguage() !== 'es';
  }

  /**
   * @returns true of the current langauge is english
   */
  public isSpanish(): boolean {
    return this.getCurrentLanguage() === 'es';
  }

  /**
   * Sets the current language to be English.
   */
  public setEnglish(): void {
    this.translateService.use('en');
  }

  /**
   * Sets the current language to be Spanish.
   */
  public setSpanish(): void {
    this.translateService.use('es');
  }

  private getCurrentLanguage(): string {
    if (!this.translateService.currentLang) {
      return this.translateService.getDefaultLang();
    }
    return this.translateService.currentLang;
  }

  /**
   * @returns true of the current country is USA.
   */
  public isUs() {
    return this.country == Country.Us;
  }

  /**
   * @returns true of the current country is Mexico.
   */
  public isMexico() {
    return this.country == Country.Mexico;
  }

  /**
   * Sets the current country to USA.
   */
  public setUs(): void {
    this.country = Country.Us;
  }

  /**
   * Sets the current country to Mexico.
   */
  public setMexico(): void {
    this.country = Country.Mexico;
  }
}

export enum Country  {
  Us,
  Mexico
}