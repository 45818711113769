import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignComponent } from '../../components/campaign/campaign.component';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';

@Component({
  selector: 'app-campaign-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    CampaignComponent
  ],
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.css']
})
export class CampaignPageComponent {
  campaignId: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute) {
    this.campaignId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.campaignId = Util.safeString(pathParams.get('campaignId'));
  }
}
