import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { FormService } from 'src/app/general/services/form.service';
import { SessionService } from '../../services/session.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { OrdersModel } from '../../components/orders/orders-model';
import { DeviceOrdersModel } from '../../components/orders/device-orders-model';
import { BackendService } from '../../services/backend.service';
import { OrdersComponent } from '../../components/orders/orders.component';

@Component({
  selector: 'app-device-orders-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TitleComponent,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    OrdersComponent
  ],
  templateUrl: './device-orders-page.component.html',
  styleUrls: ['./device-orders-page.component.css']
})
export class DeviceOrdersPageComponent implements OnInit {

  deviceIdFormControl: FormControl = new FormControl('', [Validators.required]);
  deviceOrdersModel: OrdersModel;

  constructor(
      public formService: FormService,
      public sessionService: SessionService,
      private backendService: BackendService) {
    this.deviceOrdersModel = new DeviceOrdersModel('', this.backendService);
  }

  ngOnInit(): void {
    this.sessionService.enforceAuthentication();
  }

  public fetchDeviceOrders(deviceId: string): void {
    this.deviceOrdersModel = new DeviceOrdersModel(deviceId, this.backendService);
  }
}
