import * as proto from 'src/proto/compiled-protos';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DeviceService } from '../../../general/services/device.service';
import { FormService } from '../../../general/services/form.service';
import { BackendService } from '../../services/backend.service';
import { Consumer, Runnable } from 'src/app/general/interfaces/functions';
import { ProtoUtil } from '../../util/proto-util';
import { MatRadioModule } from '@angular/material/radio';
import { MoneyPickerComponent, Money } from 'src/app/general/components/money-picker/money-picker.component';
import { QuantityPickerComponent, Quantity } from 'src/app/general/components/quantity-picker/quantity-picker.component';
import { HeadingComponent } from 'src/app/general/components/heading/heading.component';
import { CURRENCIES, DISTANCE_UNITS_NAMES } from '../../util/constants';
import { Formatter } from '../../util/formatter';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { AppError } from 'src/app/general/util/error';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, SuccessResult } from "src/app/general/util/result";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <div>
// <table>
//   <tr>
//     <td>
//         <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll(); editLocationSettingsComponentAccordionOpenAll()"></app-action>
//     </td>
//     <td>
//         <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll(); editLocationSettingsComponentAccordionCloseAll()"></app-action>
//     </td>
//   </tr>
// </table>

//  <mat-accordion multi>
//      ...
//  </mat-accordion multi>
// <!-- app-edit-location-settings also defines an accordion -->
// <app-edit-location-settings
//     [locationId]="locationId"
//     [locationSettings]="locationSettings">
// </app-edit-location-settings>

// <!-- app-edit-location-settings also defines an accordion -->
// <app-edit-location-settings
//     [businessId]="businessId"
//     [locationSettings]="locationSettings">
// </app-edit-location-settings>

// TS:

// @ViewChild(MatAccordion) accordion!: MatAccordion;
// @ViewChild(EditLocationSettingsComponent) editLocationSettingsComponent!: EditLocationSettingsComponent;

// public editLocationSettingsComponentAccordionOpenAll(): void {
//   this.editLocationSettingsComponent.accordionOpenAll();
// }

// public editLocationSettingsComponentAccordionCloseAll(): void {
//   this.editLocationSettingsComponent.accordionCloseAll();
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-edit-location-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MoneyPickerComponent,
    QuantityPickerComponent,
    HeadingComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    MatCheckboxModule,
    MatSelectModule
  ],
  templateUrl: './edit-location-settings.component.html',
  styleUrls: ['./edit-location-settings.component.css']
})
export class EditLocationSettingsComponent implements OnInit, OnChanges {
  readonly CURRENCIES: Array<string> = CURRENCIES;
  readonly DISTANCE_UNITS_NAMES: Array<string> = DISTANCE_UNITS_NAMES;
  readonly NO_DELIVERY: string = "none";
  readonly SELF_DELIVERY: string = "self";
  readonly DOORDASH_DELIVERY: string = "doordash";
  readonly TAX_TYPE_UNALTERABLE: string = "unalterable";
  readonly TAX_TYPE_FALLBACK: string = "fallback";
  readonly LANGUAGE_NONE: string = "none";
  readonly LANGUAGE_ENGLISH: string = "english";
  readonly LANGUAGE_SPANISH: string = "spanish";
  readonly THEME_NONE: string = "none";
  readonly THEME_DARK: string = "dark";
  readonly THEME_LIGHT: string = "light";

  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @Input() businessId?: string;
  @Input() locationId?: string;
  @Input() locationSettings?: proto.waiternow.common.LocationProto.ISettingsProto | null | undefined;

  alertConfigFields = new (class {
    email: FormControl = new FormControl('', [Validators.email]);
    phoneNumber: FormControl = new FormControl('');
  })();

  enabledFeaturesFields = new (class {
    pokeWaiter: FormControl = new FormControl(true);
    structuredMenu: FormControl = new FormControl(true);
    imageMenu: FormControl = new FormControl(true);
    messageWaiter: FormControl = new FormControl(true);
    askForCheck: FormControl = new FormControl(true);
    specialInstructionsForMenuItem: FormControl = new FormControl(true);
    orderForLater: FormControl = new FormControl(true);
    orderForLaterDay: FormControl = new FormControl(true);
    onlineTips: FormControl = new FormControl(true);
    onsiteTips: FormControl = new FormControl(true);
    delivery: FormControl = new FormControl(true);
    chargeCreditCardFeeToConsumer: FormControl = new FormControl(false);
    alcoholForOnlinePickup: FormControl = new FormControl(false);
    alcoholForDelivery: FormControl = new FormControl(false);
    printOrderAndReceipt: FormControl = new FormControl(false);
    isSmsOnNewOrdersForBusinessWebEnabled: FormControl = new FormControl(false);
    expandQuantityForPrintedOrders: FormControl = new FormControl(false);
  })();

  viewFields = new (class {
    theme: FormControl = new FormControl('');
  })();

  localeFields = new (class {
    language: FormControl = new FormControl('');
  })();

  businessHoursFields = new (class {
    sundayFrom: FormControl = new FormControl('');
    sundayTo: FormControl = new FormControl('');
    mondayFrom: FormControl = new FormControl('');
    mondayTo: FormControl = new FormControl('');
    tuesdayFrom: FormControl = new FormControl('');
    tuesdayTo: FormControl = new FormControl('');
    wednesdayFrom: FormControl = new FormControl('');
    wednesdayTo: FormControl = new FormControl('');
    thursdayFrom: FormControl = new FormControl('');
    thursdayTo: FormControl = new FormControl('');
    fridayFrom: FormControl = new FormControl('');
    fridayTo: FormControl = new FormControl('');
    saturdayFrom: FormControl = new FormControl('');
    saturdayTo: FormControl = new FormControl('');
    isBusinessHoursValid: boolean = true;

    isSundayClosed = new FormControl(false);
    isMondayClosed = new FormControl(false);
    isTuesdayClosed = new FormControl(false);
    isWednesdayClosed = new FormControl(false);
    isThursdayClosed = new FormControl(false);
    isFridayClosed = new FormControl(false);
    isSaturdayClosed = new FormControl(false);
  })();

  taxesConfigFields = new (class {
    salesTax: FormControl = new FormControl();
    isSalesTaxValid: boolean = true;
    taxesType: FormControl = new FormControl('');
  })();

  orderingFields = new (class {
    orderPreparationDurationMinutes: FormControl = new FormControl();
  })();

  deliveryConfigFields = new (class {
    deliveryType: FormControl = new FormControl("");
    isSelfConfigurationValid: boolean = false;
    isSelfConfigurationDisabled: boolean = true;
    baseFeeInitialValue: Money | null | undefined;
    baseDistanceInitialValue: Quantity | null | undefined;
    feePerDistanceUnitInitialValue: Money | null | undefined;
    maxDistanceInitialValue: Quantity | null | undefined;
    baseFeeCurrentValue: Money | null | undefined;
    baseDistanceCurrentValue: Quantity | null | undefined;
    feePerDistanceUnitCurrentValue: Money | null | undefined;
    maxDistanceCurrentValue: Quantity | null | undefined;
  })();

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      private backendService: BackendService) {
  }

  public accordionOpenAll(): void {
    this.accordion.openAll();
  }

  public accordionCloseAll(): void {
    this.accordion.closeAll();
  }

  ngOnInit(): void {
    this.initFormControls();
    this.deliveryConfigFields.deliveryType.valueChanges.subscribe(() => {
      this.updateSelfConfigurationEnablement();
      this.updateIsSelfConfigurationValid();
    });
    this.businessHoursFields.sundayFrom.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.sundayTo.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.mondayFrom.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.mondayTo.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.tuesdayFrom.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.tuesdayTo.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.wednesdayFrom.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.wednesdayTo.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.thursdayFrom.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.thursdayTo.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.fridayFrom.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.fridayTo.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.saturdayFrom.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.businessHoursFields.saturdayTo.valueChanges.subscribe(() => {
      this.updateIsBusinessHoursValid();
    });
    this.taxesConfigFields.salesTax.valueChanges.subscribe(() => {
      this.updateIsSalesTaxValid();
    });

    this.businessHoursFields.isSundayClosed.valueChanges.subscribe(() => {
      if (this.businessHoursFields.isSundayClosed.value) {
        this.businessHoursFields.sundayFrom.setValue('');
        this.businessHoursFields.sundayTo.setValue('');
      }
    });
    this.businessHoursFields.isMondayClosed.valueChanges.subscribe(() => {
      if (this.businessHoursFields.isMondayClosed.value) {
        this.businessHoursFields.mondayFrom.setValue('');
        this.businessHoursFields.mondayTo.setValue('');
      }
    });
    this.businessHoursFields.isTuesdayClosed.valueChanges.subscribe(() => {
      if (this.businessHoursFields.isTuesdayClosed.value) {
        this.businessHoursFields.tuesdayFrom.setValue('');
        this.businessHoursFields.tuesdayTo.setValue('');
      }
    });
    this.businessHoursFields.isWednesdayClosed.valueChanges.subscribe(() => {
      if (this.businessHoursFields.isWednesdayClosed.value) {
        this.businessHoursFields.wednesdayFrom.setValue('');
        this.businessHoursFields.wednesdayTo.setValue('');
      }
    });
    this.businessHoursFields.isThursdayClosed.valueChanges.subscribe(() => {
      if (this.businessHoursFields.isThursdayClosed.value) {
        this.businessHoursFields.thursdayFrom.setValue('');
        this.businessHoursFields.thursdayTo.setValue('');
      }
    });
    this.businessHoursFields.isFridayClosed.valueChanges.subscribe(() => {
      if (this.businessHoursFields.isFridayClosed.value) {
        this.businessHoursFields.fridayFrom.setValue('');
        this.businessHoursFields.fridayTo.setValue('');
      }
    });
    this.businessHoursFields.isSaturdayClosed.valueChanges.subscribe(() => {
      if (this.businessHoursFields.isSaturdayClosed.value) {
        this.businessHoursFields.saturdayFrom.setValue('');
        this.businessHoursFields.saturdayTo.setValue('');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('locationSettings', changes)) {
      this.initFormControls();
    }
  }

  private initFormControls(): void {
    if (!this.locationSettings) {
      return;
    }

    const viewSetings = this.locationSettings?.viewSettings;
    const localeSetings = this.locationSettings?.localeSettings;
    const operationSetings = this.locationSettings?.operationSettings;

    // Alerts

    if (operationSetings && operationSetings.alertConfig) {
      const alertConfig = operationSetings.alertConfig;
      this.alertConfigFields.email.setValue(alertConfig.email);
      this.alertConfigFields.phoneNumber.setValue(Formatter.formatPhoneNumber(alertConfig.phoneNumber));
    }

    // Enabled Features

    let enabledFeatures: proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto;
    if (operationSetings && operationSetings.enabledFeatures) {
      enabledFeatures = operationSetings.enabledFeatures;
    } else {
      enabledFeatures = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto();
    }
    this.enabledFeaturesFields.pokeWaiter.setValue(!enabledFeatures.isPokeWaiterDisabled);
    this.enabledFeaturesFields.structuredMenu.setValue(!enabledFeatures.isStructuredMenuDisabled);
    this.enabledFeaturesFields.imageMenu.setValue(!enabledFeatures.isImageMenuDisabled);
    this.enabledFeaturesFields.messageWaiter.setValue(!enabledFeatures.isMessageWaiterDisabled);
    this.enabledFeaturesFields.askForCheck.setValue(!enabledFeatures.isAskForCheckDisabled);
    this.enabledFeaturesFields.specialInstructionsForMenuItem.setValue(!enabledFeatures.isSpecialInstructionsDisabled);
    this.enabledFeaturesFields.orderForLater.setValue(!enabledFeatures.isOrderForLaterDisabled);
    this.enabledFeaturesFields.orderForLaterDay.setValue(!enabledFeatures.isOrderForLaterDayDisabled);
    this.enabledFeaturesFields.onlineTips.setValue(!enabledFeatures.isOnlineTipsDisabled);
    this.enabledFeaturesFields.onsiteTips.setValue(!enabledFeatures.isOnsiteTipsDisabled);
    this.enabledFeaturesFields.delivery.setValue(!enabledFeatures.isDeliveryDisabled);
    this.enabledFeaturesFields.chargeCreditCardFeeToConsumer.setValue(enabledFeatures.chargeCreditCardFeeToConsumer);
    this.enabledFeaturesFields.alcoholForOnlinePickup.setValue(enabledFeatures.isAlcoholForOnlinePickupAllowed);
    this.enabledFeaturesFields.alcoholForDelivery.setValue(enabledFeatures.isAlcoholForDeliveryAllowed);
    this.enabledFeaturesFields.printOrderAndReceipt.setValue(enabledFeatures.printOrderAndReceipt);
    this.enabledFeaturesFields.isSmsOnNewOrdersForBusinessWebEnabled.setValue(enabledFeatures.isSmsOnNewOrdersForBusinessWebEnabled);
    this.enabledFeaturesFields.expandQuantityForPrintedOrders.setValue(enabledFeatures.expandQuantityForPrintedOrders);

    // View

    if (viewSetings) {
      if (viewSetings.theme == proto.waiternow.common.Theme.DARK) {
        this.viewFields.theme.setValue(this.THEME_DARK);
      } else if(viewSetings.theme == proto.waiternow.common.Theme.LIGHT) {
        this.viewFields.theme.setValue(this.THEME_LIGHT);
      } else {
        this.viewFields.theme.setValue(this.THEME_NONE);
      }
    } else {
      this.viewFields.theme.setValue(this.THEME_NONE);
    }

    // Locale

    if (localeSetings) {
      if (localeSetings.language == proto.waiternow.common.Language.ENGLISH) {
        this.localeFields.language.setValue(this.LANGUAGE_ENGLISH);
      } else if(localeSetings.language == proto.waiternow.common.Language.SPANISH) {
        this.localeFields.language.setValue(this.LANGUAGE_SPANISH);
      } else {
        this.localeFields.language.setValue(this.LANGUAGE_NONE);
      }
    } else {
      this.localeFields.language.setValue(this.LANGUAGE_NONE);
    }

    // Business Hours

    if (operationSetings && operationSetings.businessHours) {
      const businessHours = operationSetings.businessHours;
      if (businessHours.sundayHours && businessHours.sundayHours.from && businessHours.sundayHours.to) {
        this.businessHoursFields.sundayFrom.setValue(Formatter.formatTimeToMinutes(businessHours.sundayHours.from))
        this.businessHoursFields.sundayTo.setValue(Formatter.formatTimeToMinutes(businessHours.sundayHours.to))
      }
      if (businessHours.mondayHours && businessHours.mondayHours.from && businessHours.mondayHours.to) {
        this.businessHoursFields.mondayFrom.setValue(Formatter.formatTimeToMinutes(businessHours.mondayHours.from))
        this.businessHoursFields.mondayTo.setValue(Formatter.formatTimeToMinutes(businessHours.mondayHours.to))
      }
      if (businessHours.tuesdayHours && businessHours.tuesdayHours.from && businessHours.tuesdayHours.to) {
        this.businessHoursFields.tuesdayFrom.setValue(Formatter.formatTimeToMinutes(businessHours.tuesdayHours.from))
        this.businessHoursFields.tuesdayTo.setValue(Formatter.formatTimeToMinutes(businessHours.tuesdayHours.to))
      }
      if (businessHours.wednesdayHours && businessHours.wednesdayHours.from && businessHours.wednesdayHours.to) {
        this.businessHoursFields.wednesdayFrom.setValue(Formatter.formatTimeToMinutes(businessHours.wednesdayHours.from))
        this.businessHoursFields.wednesdayTo.setValue(Formatter.formatTimeToMinutes(businessHours.wednesdayHours.to))
      }
      if (businessHours.thursdayHours && businessHours.thursdayHours.from && businessHours.thursdayHours.to) {
        this.businessHoursFields.thursdayFrom.setValue(Formatter.formatTimeToMinutes(businessHours.thursdayHours.from))
        this.businessHoursFields.thursdayTo.setValue(Formatter.formatTimeToMinutes(businessHours.thursdayHours.to))
      }
      if (businessHours.fridayHours && businessHours.fridayHours.from && businessHours.fridayHours.to) {
        this.businessHoursFields.fridayFrom.setValue(Formatter.formatTimeToMinutes(businessHours.fridayHours.from))
        this.businessHoursFields.fridayTo.setValue(Formatter.formatTimeToMinutes(businessHours.fridayHours.to))
      }
      if (businessHours.saturdayHours && businessHours.saturdayHours.from && businessHours.saturdayHours.to) {
        this.businessHoursFields.saturdayFrom.setValue(Formatter.formatTimeToMinutes(businessHours.saturdayHours.from))
        this.businessHoursFields.saturdayTo.setValue(Formatter.formatTimeToMinutes(businessHours.saturdayHours.to))
      }
      this.businessHoursFields.isSundayClosed.setValue(Boolean(businessHours.isSundayClosed));
      this.businessHoursFields.isMondayClosed.setValue(Boolean(businessHours.isMondayClosed));
      this.businessHoursFields.isTuesdayClosed.setValue(Boolean(businessHours.isTuesdayClosed));
      this.businessHoursFields.isWednesdayClosed.setValue(Boolean(businessHours.isWednesdayClosed));
      this.businessHoursFields.isThursdayClosed.setValue(Boolean(businessHours.isThursdayClosed));
      this.businessHoursFields.isFridayClosed.setValue(Boolean(businessHours.isFridayClosed));
      this.businessHoursFields.isSaturdayClosed.setValue(Boolean(businessHours.isSaturdayClosed));
    }

    this.updateIsBusinessHoursValid();

    // Taxes

    // This taxes configuration is treated as the source of truth so they are used in payments.
    // This is the default value if type is not set and taxes is not empty.
    // UNALTERABLE = 1;
    // Taxes are fetched from government APIs and this taxes configuration is only used in the event of failures.
    // This is the default value if taxes is empty.
    // FALLBACK = 2;
    if (operationSetings && operationSetings.taxes) {
      const taxes = operationSetings.taxes;
      if (taxes.type == proto.waiternow.common.TaxesProto.Type.FALLBACK) {
        this.taxesConfigFields.taxesType.setValue(this.TAX_TYPE_FALLBACK);
      } else {
        this.taxesConfigFields.taxesType.setValue(this.TAX_TYPE_UNALTERABLE);
      }
      if (taxes.salesTax) {
        this.taxesConfigFields.salesTax.setValue(taxes.salesTax.value);
      } else if (taxes.saleTaxes && taxes.saleTaxes?.length > 0) {
        this.taxesConfigFields.salesTax.setValue(taxes.saleTaxes[0].percentage?.value);
      }
    } else {
      this.taxesConfigFields.taxesType.setValue(this.TAX_TYPE_FALLBACK);
    }
    this.updateIsSalesTaxValid();

    // Ordering
    if (this.locationSettings.operationSettings?.orderPreparationDuration) {
      this.orderingFields.orderPreparationDurationMinutes.setValue(ProtoUtil.durationProtoToMillis(this.locationSettings.operationSettings?.orderPreparationDuration) / (1000 * 60));
    }

    // Delivery

    if (operationSetings && operationSetings.deliveryConfig) {
      const deliveryConfig = operationSetings.deliveryConfig;
      if (deliveryConfig.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.SELF) {
        this.deliveryConfigFields.deliveryType.setValue(this.SELF_DELIVERY);
      } else if(deliveryConfig.deliveryCarrier == proto.waiternow.common.DeliveryCarrier.DOORDASH) {
        this.deliveryConfigFields.deliveryType.setValue(this.DOORDASH_DELIVERY);
      } else {
        this.deliveryConfigFields.deliveryType.setValue(this.NO_DELIVERY);
      }
      if (deliveryConfig.selfDeliveryConfig) {
        const selfDeliveryConfig = deliveryConfig.selfDeliveryConfig;
        if (selfDeliveryConfig.baseFee) {
          this.deliveryConfigFields.baseFeeInitialValue
              = ProtoUtil.moneyProtoToPickerMoney(selfDeliveryConfig.baseFee);
          this.deliveryConfigFields.baseFeeCurrentValue = this.deliveryConfigFields.baseFeeInitialValue;
        }
        if (selfDeliveryConfig.baseDistance) {
          this.deliveryConfigFields.baseDistanceInitialValue
              = ProtoUtil.distanceQuantityProtoToPickerQuantity(selfDeliveryConfig.baseDistance);
          this.deliveryConfigFields.baseDistanceCurrentValue = this.deliveryConfigFields.baseDistanceInitialValue;
        }
        if (selfDeliveryConfig.feePerDistanceUnit) {
          this.deliveryConfigFields.feePerDistanceUnitInitialValue
              = ProtoUtil.moneyProtoToPickerMoney(selfDeliveryConfig.feePerDistanceUnit);
          this.deliveryConfigFields.feePerDistanceUnitCurrentValue = this.deliveryConfigFields.feePerDistanceUnitInitialValue;
        }
        if (selfDeliveryConfig.maxDistance) {
          this.deliveryConfigFields.maxDistanceInitialValue
              = ProtoUtil.distanceQuantityProtoToPickerQuantity(selfDeliveryConfig.maxDistance);
          this.deliveryConfigFields.maxDistanceCurrentValue = this.deliveryConfigFields.maxDistanceInitialValue;
        }
      }
    } else {
      this.deliveryConfigFields.deliveryType.setValue(this.NO_DELIVERY);
    }
    this.updateSelfConfigurationEnablement();
    this.updateIsSelfConfigurationValid();
  }

  private updateSelfConfigurationEnablement() {
    this.deliveryConfigFields.isSelfConfigurationDisabled
        = this.deliveryConfigFields.deliveryType.value != this.SELF_DELIVERY;
  }

  private createBusiness(): proto.waiternow.common.BusinessProto {
    const business = new proto.waiternow.common.BusinessProto();
    if (this.businessId) {
      business.id = this.businessId;
    }
    return business;
  }

  private createLocation(): proto.waiternow.common.LocationProto {
    const location = new proto.waiternow.common.LocationProto();
    if (this.locationId) {
      location.id = this.locationId;
    }
    return location;
  }

  private createLocationSettings(): proto.waiternow.common.LocationProto.SettingsProto {
    const locationSettings = new proto.waiternow.common.LocationProto.SettingsProto();
    return locationSettings;
  }

  private saveSettings(
      locationSettings: proto.waiternow.common.LocationProto.SettingsProto,
      settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    if (this.businessId) {
      const business = this.createBusiness();
      business.locationSettings = locationSettings;
      this.backendService.updateBusiness(
        business,
        /* fieldsToRemove= */ [],
        settingsFieldsToRemove,
        /* onSuccess= */ () => onSuccess(),
        /* onError= */ error => onError(error)
      );
    } else if (this.locationId) {
      const location = this.createLocation();;
      location.settings = locationSettings;
      this.backendService.updateLocation(
        location,
        /* fieldsToRemove= */ [],
        settingsFieldsToRemove,
        /* onSuccess= */ () => onSuccess(),
        /* onError= */ error => onError(error)
      );
    }
  }

  public saveAlerts(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    if (!this.alertConfigFields.email.value && !this.alertConfigFields.phoneNumber.value) {
      settingsFieldsToRemove.push(
          proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ALERT_CONFIG);
    } else {
      locationSettings.operationSettings = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
      locationSettings.operationSettings.alertConfig
          = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto();
      if (this.alertConfigFields.email.value) {
        locationSettings.operationSettings.alertConfig.email = this.alertConfigFields.email.value;
      }
      if (this.alertConfigFields.phoneNumber.value) {
        locationSettings.operationSettings.alertConfig.phoneNumber
            = ProtoUtil.createSimplePhoneNumberProto(this.alertConfigFields.phoneNumber.value);
      }
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_alert_configuration'));
      }
    );
  }

  public saveEnabledFeatures(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    locationSettings.operationSettings = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
    locationSettings.operationSettings.enabledFeatures
        = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto();

    if (!this.enabledFeaturesFields.pokeWaiter.value) {
      locationSettings.operationSettings.enabledFeatures.isPokeWaiterDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_POKE_WAITER_DISABLED);
    }
    if (!this.enabledFeaturesFields.structuredMenu.value) {
      locationSettings.operationSettings.enabledFeatures.isStructuredMenuDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_STRUCTURED_MENU_DISABLED);
    }
    if (!this.enabledFeaturesFields.imageMenu.value) {
      locationSettings.operationSettings.enabledFeatures.isImageMenuDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IMAGE_MENU_DISABLED);
    }
    if (!this.enabledFeaturesFields.messageWaiter.value) {
      locationSettings.operationSettings.enabledFeatures.isMessageWaiterDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_MESSAGE_WAITER_DISABLED);
    }
    if (!this.enabledFeaturesFields.askForCheck.value) {
      locationSettings.operationSettings.enabledFeatures.isAskForCheckDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_ASK_FOR_CHECK_DISABLED);
    }
    if (!this.enabledFeaturesFields.specialInstructionsForMenuItem.value) {
      locationSettings.operationSettings.enabledFeatures.isSpecialInstructionsDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_SPECIAL_INSTRUCTIONS_DISABLED);
    }
    if (!this.enabledFeaturesFields.orderForLater.value) {
      locationSettings.operationSettings.enabledFeatures.isOrderForLaterDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_ORDER_FOR_LATER_DISABLED);
    }
    if (!this.enabledFeaturesFields.orderForLaterDay.value) {
      locationSettings.operationSettings.enabledFeatures.isOrderForLaterDayDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_ORDER_FOR_LATER_DAY_DISABLED);
    }
    if (!this.enabledFeaturesFields.onlineTips.value) {
      locationSettings.operationSettings.enabledFeatures.isOnlineTipsDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_ONLINE_TIPS_DISABLED);
    }
    if (!this.enabledFeaturesFields.onsiteTips.value) {
      locationSettings.operationSettings.enabledFeatures.isOnsiteTipsDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_ONSITE_TIPS_DISABLED);
    }
    if (!this.enabledFeaturesFields.delivery.value) {
      locationSettings.operationSettings.enabledFeatures.isDeliveryDisabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_DELIVERY_DISABLED);
    }
    if (this.enabledFeaturesFields.chargeCreditCardFeeToConsumer.value) {
      locationSettings.operationSettings.enabledFeatures.chargeCreditCardFeeToConsumer = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_CHARGE_CREDIT_CARD_FEE_TO_CONSUMER);
    }
    if (this.enabledFeaturesFields.alcoholForOnlinePickup.value) {
      locationSettings.operationSettings.enabledFeatures.isAlcoholForOnlinePickupAllowed = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_ALCOHOL_FOR_PICKUP_ALLOWED);
    }
    if (this.enabledFeaturesFields.alcoholForDelivery.value) {
      locationSettings.operationSettings.enabledFeatures.isAlcoholForDeliveryAllowed = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_ALCOHOL_FOR_DELIVERY_ALLOWED);
    }
    if (this.enabledFeaturesFields.printOrderAndReceipt.value) {
      locationSettings.operationSettings.enabledFeatures.printOrderAndReceipt = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_PRINT_ORDER_AND_RECEIPT);
    }
    if (this.enabledFeaturesFields.isSmsOnNewOrdersForBusinessWebEnabled.value) {
      locationSettings.operationSettings.enabledFeatures.isSmsOnNewOrdersForBusinessWebEnabled = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_IS_SMS_ON_NEW_ORDERS_FOR_BUSINESS_WEB_ENABLED);
    }
    if (this.enabledFeaturesFields.expandQuantityForPrintedOrders.value) {
      locationSettings.operationSettings.enabledFeatures.expandQuantityForPrintedOrders = true;
    } else {
      settingsFieldsToRemove.push(
        proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ENABLED_FEATURES_EXPAND_QUANTITY_FOR_PRINTED_ORDERS);
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_enabled_features'));
      }
    );
  }

  public saveView(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    if (this.viewFields.theme.value === this.THEME_NONE) {
      settingsFieldsToRemove.push(proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.VIEW_THEME);
    } else {
      locationSettings.viewSettings = new proto.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto();
      if (this.viewFields.theme.value === this.THEME_DARK) {
        locationSettings.viewSettings.theme = proto.waiternow.common.Theme.DARK;
      } else {
        locationSettings.viewSettings.theme = proto.waiternow.common.Theme.LIGHT;
      }
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_view_configuration'));
      }
    );
  }

  public saveLocale(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    if (this.localeFields.language.value === this.LANGUAGE_NONE) {
      settingsFieldsToRemove.push(proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.LOCALE_LANGUAGE);
    } else {
      locationSettings.localeSettings = new proto.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto();
      if (this.localeFields.language.value === this.LANGUAGE_ENGLISH) {
        locationSettings.localeSettings.language = proto.waiternow.common.Language.ENGLISH;
      } else {
        locationSettings.localeSettings.language = proto.waiternow.common.Language.SPANISH;
      }
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_locale'));
      }
    );
  }

  public saveBusinessHours(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    if (
      !this.businessHoursFields.sundayFrom.value && !this.businessHoursFields.sundayTo.value
      && !this.businessHoursFields.mondayFrom.value && !this.businessHoursFields.mondayTo.value
      && !this.businessHoursFields.tuesdayFrom.value && !this.businessHoursFields.tuesdayTo.value
      && !this.businessHoursFields.wednesdayFrom.value && !this.businessHoursFields.wednesdayTo.value
      && !this.businessHoursFields.thursdayFrom.value && !this.businessHoursFields.thursdayTo.value
      && !this.businessHoursFields.fridayFrom.value && !this.businessHoursFields.fridayTo.value
      && !this.businessHoursFields.saturdayFrom.value && !this.businessHoursFields.saturdayTo.value
      && !this.businessHoursFields.isSundayClosed.value
      && !this.businessHoursFields.isMondayClosed.value
      && !this.businessHoursFields.isTuesdayClosed.value
      && !this.businessHoursFields.isWednesdayClosed.value
      && !this.businessHoursFields.isThursdayClosed.value
      && !this.businessHoursFields.isFridayClosed.value
      && !this.businessHoursFields.isSaturdayClosed.value
    ) {
      settingsFieldsToRemove.push(proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.BUSINESS_HOURS);
    } else {
      locationSettings.operationSettings = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
      const businessHours = new proto.waiternow.common.BusinessHoursProto();
      locationSettings.operationSettings.businessHours = businessHours;

      if (this.businessHoursFields.sundayFrom.value && this.businessHoursFields.sundayTo.value) {
        businessHours.sundayHours = this.createTimeRange(
          this.businessHoursFields.sundayFrom.value, this.businessHoursFields.sundayTo.value);
      }
      if (this.businessHoursFields.mondayFrom.value && this.businessHoursFields.mondayTo.value) {
        businessHours.mondayHours = this.createTimeRange(
          this.businessHoursFields.mondayFrom.value, this.businessHoursFields.mondayTo.value);
      }
      if (this.businessHoursFields.tuesdayFrom.value && this.businessHoursFields.tuesdayTo.value) {
        businessHours.tuesdayHours = this.createTimeRange(
          this.businessHoursFields.tuesdayFrom.value, this.businessHoursFields.tuesdayTo.value);
      }
      if (this.businessHoursFields.wednesdayFrom.value && this.businessHoursFields.wednesdayTo.value) {
        businessHours.wednesdayHours = this.createTimeRange(
          this.businessHoursFields.wednesdayFrom.value, this.businessHoursFields.wednesdayTo.value);
      }
      if (this.businessHoursFields.thursdayFrom.value && this.businessHoursFields.thursdayTo.value) {
        businessHours.thursdayHours = this.createTimeRange(
          this.businessHoursFields.thursdayFrom.value, this.businessHoursFields.thursdayTo.value);
      }
      if (this.businessHoursFields.fridayFrom.value && this.businessHoursFields.fridayTo.value) {
        businessHours.fridayHours = this.createTimeRange(
          this.businessHoursFields.fridayFrom.value, this.businessHoursFields.fridayTo.value);
      }
      if (this.businessHoursFields.saturdayFrom.value && this.businessHoursFields.saturdayTo.value) {
        businessHours.saturdayHours = this.createTimeRange(
          this.businessHoursFields.saturdayFrom.value, this.businessHoursFields.saturdayTo.value);
      }
      businessHours.isSundayClosed = Boolean(this.businessHoursFields.isSundayClosed.value);
      businessHours.isMondayClosed = Boolean(this.businessHoursFields.isMondayClosed.value);
      businessHours.isTuesdayClosed = Boolean(this.businessHoursFields.isTuesdayClosed.value);
      businessHours.isWednesdayClosed = Boolean(this.businessHoursFields.isWednesdayClosed.value);
      businessHours.isThursdayClosed = Boolean(this.businessHoursFields.isThursdayClosed.value);
      businessHours.isFridayClosed = Boolean(this.businessHoursFields.isFridayClosed.value);
      businessHours.isSaturdayClosed = Boolean(this.businessHoursFields.isSaturdayClosed.value);
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_locale'));
      }
    );
  }

  private createTimeRange(timeTextFrom: string, timeTextTo: string): proto.waiternow.common.TimeRangeProto {
    const timeRange = new proto.waiternow.common.TimeRangeProto();
    timeRange.from = ProtoUtil.timeTextToTimeProto(timeTextFrom);
    timeRange.to = ProtoUtil.timeTextToTimeProto(timeTextTo);
    return timeRange;
  }

  private updateIsBusinessHoursValid(): void {
    if (
      !this.isTimeTextRangeValid(this.businessHoursFields.sundayFrom.value, this.businessHoursFields.sundayTo.value)
      || !this.isTimeTextRangeValid(this.businessHoursFields.mondayFrom.value, this.businessHoursFields.mondayTo.value)
      || !this.isTimeTextRangeValid(this.businessHoursFields.tuesdayFrom.value, this.businessHoursFields.tuesdayTo.value)
      || !this.isTimeTextRangeValid(this.businessHoursFields.wednesdayFrom.value, this.businessHoursFields.wednesdayTo.value)
      || !this.isTimeTextRangeValid(this.businessHoursFields.thursdayFrom.value, this.businessHoursFields.thursdayTo.value)
      || !this.isTimeTextRangeValid(this.businessHoursFields.fridayFrom.value, this.businessHoursFields.fridayTo.value)
      || !this.isTimeTextRangeValid(this.businessHoursFields.saturdayFrom.value, this.businessHoursFields.saturdayTo.value)
    ) {
      this.businessHoursFields.isBusinessHoursValid = false;
    } else {
      this.businessHoursFields.isBusinessHoursValid = true;
    }
  }

  private isTimeTextRangeValid(timeTextFrom: string, timeTextTo: string): boolean {
    if (timeTextFrom && !timeTextTo) {
      return false;
    }
    if (!timeTextFrom && timeTextTo) {
      return false;
    }
    if (timeTextFrom) {
      if (!this.isTextTimeValid(timeTextFrom)) {
        return false;
      }
    }
    if (timeTextTo) {
      if (!this.isTextTimeValid(timeTextTo)) {
        return false;
      }
    }
    return true;
  }

  private isTextTimeValid(timeText: string): boolean {
    if (timeText) {
      const timeComponents: Array<string> = timeText.split(':');
      if (timeComponents.length != 2) {
        return false;
      }
      if (isNaN(Number(timeComponents[0])) || isNaN(Number(timeComponents[1]))) {
        return false;
      }
      const hour = parseInt(timeComponents[0]);
      const minute = parseInt(timeComponents[1]);
      if (hour < 0 || hour > 23) {
        return false;
      }
      if (minute < 0 || minute > 59) {
        return false;
      }
    }
    return true;
  }

  private updateIsSalesTaxValid(): void {
    // 0 is a valid value for taxes and empty is used to delete taxes
    if (!this.taxesConfigFields.salesTax.value) {
      this.taxesConfigFields.isSalesTaxValid = true;
      return;
    }
    const salesTaxNumber = Number(this.taxesConfigFields.salesTax.value);
    this.taxesConfigFields.isSalesTaxValid = salesTaxNumber >= 0 && salesTaxNumber < 1;
  }

  public saveTaxes(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    // 0 is a valid value for taxes
    if (!this.taxesConfigFields.salesTax.value && this.taxesConfigFields.salesTax.value != 0) {
      settingsFieldsToRemove.push(proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.TAXES);
    } else {
      locationSettings.operationSettings = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
      locationSettings.operationSettings.taxes = new proto.waiternow.common.TaxesProto();
      locationSettings.operationSettings.taxes.salesTax = new proto.waiternow.common.DecimalProto();
      locationSettings.operationSettings.taxes.salesTax.value = this.taxesConfigFields.salesTax.value.toString();
      if (this.taxesConfigFields.taxesType.value == this.TAX_TYPE_UNALTERABLE) {
        locationSettings.operationSettings.taxes.type = proto.waiternow.common.TaxesProto.Type.UNALTERABLE;
      } else {
        locationSettings.operationSettings.taxes.type = proto.waiternow.common.TaxesProto.Type.FALLBACK;
      }
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_taxes'));
      }
    );
  }

  public saveOrdering(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    if (!this.orderingFields.orderPreparationDurationMinutes.value) {
      settingsFieldsToRemove.push(
          proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.ORDER_PREPARATION_DURATION);
    } else {
      locationSettings.operationSettings = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
      locationSettings.operationSettings.orderPreparationDuration = ProtoUtil.createDurationProtoFromMillis(this.orderingFields.orderPreparationDurationMinutes.value * 1000 * 60)
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_ordering_configuration'));
      }
    );
  }

  public onBaseFeeChange(money: Money | null | undefined) {
    this.deliveryConfigFields.baseFeeCurrentValue = money;
    this.updateIsSelfConfigurationValid();
  }

  public onBaseDistanceChange(quantity: Quantity | null | undefined) {
    this.deliveryConfigFields.baseDistanceCurrentValue = quantity;
    this.updateIsSelfConfigurationValid();
  }

  public onFeePerDistanceUnitChange(money: Money | null | undefined) {
    this.deliveryConfigFields.feePerDistanceUnitCurrentValue = money;
    this.updateIsSelfConfigurationValid();
  }

  public onMaxDistanceChange(quantity: Quantity | null | undefined) {
    this.deliveryConfigFields.maxDistanceCurrentValue = quantity;
    this.updateIsSelfConfigurationValid();
  }

  public updateIsSelfConfigurationValid(): void {
    if (this.deliveryConfigFields.deliveryType.value != this.SELF_DELIVERY) {
      this.deliveryConfigFields.isSelfConfigurationValid = true;
    } else if (!this.deliveryConfigFields.baseFeeCurrentValue
        || !this.deliveryConfigFields.baseDistanceCurrentValue
        || !this.deliveryConfigFields.feePerDistanceUnitCurrentValue
        || !this.deliveryConfigFields.maxDistanceCurrentValue) {
      this.deliveryConfigFields.isSelfConfigurationValid = false;
    } else {
      this.deliveryConfigFields.isSelfConfigurationValid = true;
    }
  }

  public saveDelivery(actionObserver: ActionObserver): void {
    const locationSettings = this.createLocationSettings();
    const settingsFieldsToRemove: Array<proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField> = [];

    if (this.deliveryConfigFields.deliveryType.value === this.NO_DELIVERY) {
      settingsFieldsToRemove.push(proto.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField.DELIVERY_CONFIG);
    } else {
      locationSettings.operationSettings = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
      locationSettings.operationSettings.deliveryConfig
          = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto();
      if (this.deliveryConfigFields.deliveryType.value === this.DOORDASH_DELIVERY) {
        locationSettings.operationSettings.deliveryConfig.deliveryCarrier = proto.waiternow.common.DeliveryCarrier.DOORDASH;
      } else {
        locationSettings.operationSettings.deliveryConfig.deliveryCarrier = proto.waiternow.common.DeliveryCarrier.SELF;
        locationSettings.operationSettings.deliveryConfig.selfDeliveryConfig
            = new proto.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto();
        if (this.deliveryConfigFields.baseFeeCurrentValue) {
          locationSettings.operationSettings.deliveryConfig.selfDeliveryConfig.baseFee
              = ProtoUtil.pickerMoneyToMoneyProto(this.deliveryConfigFields.baseFeeCurrentValue);
        }
        if (this.deliveryConfigFields.baseDistanceCurrentValue) {
          locationSettings.operationSettings.deliveryConfig.selfDeliveryConfig.baseDistance
              = ProtoUtil.pickerQuantityToDistanceQuantityProto(this.deliveryConfigFields.baseDistanceCurrentValue);
        }
        if (this.deliveryConfigFields.feePerDistanceUnitCurrentValue) {
          locationSettings.operationSettings.deliveryConfig.selfDeliveryConfig.feePerDistanceUnit
              = ProtoUtil.pickerMoneyToMoneyProto(this.deliveryConfigFields.feePerDistanceUnitCurrentValue);
        }
        if (this.deliveryConfigFields.maxDistanceCurrentValue) {
          locationSettings.operationSettings.deliveryConfig.selfDeliveryConfig.maxDistance
              = ProtoUtil.pickerQuantityToDistanceQuantityProto(this.deliveryConfigFields.maxDistanceCurrentValue);
        }
      }
    }

    this.saveSettings(
      locationSettings,
      settingsFieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_delivery_configuration'));
      }
    );
  }
}
