import { Component, Input, OnChanges, SimpleChanges, ContentChildren, ElementRef, QueryList, ViewChildren, AfterViewInit, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import { ComponentUtil } from '../../util/component-util';

// ----------------------------------------------------------------------------
// Usage Example

// Layouts content in a table where cells will occupy 100% of the available space and the height
// is determined by the content. This is sueful to adjust the layout for desktop vs mobile versions.
// Columns will have the same width.

// Template:

// <app-table-layout [columns]="3" [items]="5">
//     <div #layout>Item 1</div>
//     <span #layout>Item 2</span>
//     <table #layout align="center"><tr><td>Item 3 a</td><td>Item 3 b</td></tr><tr><td>Item 3 c</td><td>Item 3 d</td></tr></table>
//     <input #layout type="button" value="Item 4"/>
//     <!-- NOTE: Wrap custom angular components into regular HTML tags -->
//     <div #layout><my-angular-component>Item 5</my-angular-component><div>
// </app-table-layout>

// Items to layout must be marked as #layout, and [items] must specify the correct number of items to layout.
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-table-layout',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.css']
})
export class TableLayoutComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() columns!: number;
  @Input() items!: number;

  // @ContentChild and @ContentChildren queries will return directives existing inside the <ng-content></ng-content>
  // element of your view, whereas @ViewChild and @ViewChildren only look at elements that are on your view template directly.
  @ContentChildren('layout') originalItemElements!: QueryList<ElementRef>;
  @ViewChildren('internal_item_container') itemContainers!: QueryList<ElementRef>;

  columnsSequence: Array<number>;
  rowsSequence: Array<number>;
  columnWidth: string;

  constructor(
      private changeDetectorRef: ChangeDetectorRef) {
    this.columnsSequence = new Array();
    this.rowsSequence = new Array();
    this.columnWidth = '100%';
  }

  ngOnInit(): void {
    this.columnsSequence = Array(this.columns);
    this.rowsSequence = Array(Math.ceil(this.items / this.columns));
    this.columnWidth = Math.round(100 / this.columns) + "%";
  }

  ngAfterViewInit(): void {
    for (let i = 0; i < this.originalItemElements.length && i < this.itemContainers.length; i++) {
      this.itemContainers.get(i)?.nativeElement.appendChild(this.originalItemElements.get(i)?.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('columns', changes)
        || ComponentUtil.bindingChanged('items', changes)) {
      this.originalItemElements.setDirty();
      this.itemContainers.setDirty();
      this.ngOnInit();
      this.changeDetectorRef.detectChanges();
      this.ngAfterViewInit();
    }
  }

  public getItemIndex(row: number, column: number): number {
    return (row * this.columns) + column;
  }

  public isValidItem(row: number, column: number): boolean {
    return this.getItemIndex(row, column) < this.items;
  }
}
