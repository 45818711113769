import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-progress *ngIf="isInProgress">
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-progress',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent {

  // This component is a wraper of MatProgressSpinnerModule that defined the size of the spinner
}
