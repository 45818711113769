import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { BusinessHeaderComponent } from '../../components/business-header/business-header.component';
import { BusinessUsersComponent } from '../../components/business-users/business-users.component';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';

@Component({
  selector: 'app-business-users-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    ActionComponent,
    TranslateModule,
    BusinessHeaderComponent,
    BusinessUsersComponent
  ],
  templateUrl: './business-users-page.component.html',
  styleUrls: ['./business-users-page.component.css']
})
export class BusinessUsersPageComponent implements OnInit {

  businessId: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute,
      private navigationService: NavigationService) {
    this.businessId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.businessId = Util.safeString(pathParams.get('businessId'));
  }

  public goToBusinessPage(): void {
    this.navigationService.goToBusinessPage(this.businessId)
  }
}
