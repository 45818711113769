import { Observable, map, of } from "rxjs";
import { IndeterminatePaginatorData } from "src/app/general/components/indeterminate-paginator/indeterminate-paginator-model";
import { OrdersModel } from "./orders-model";
import { BackendService } from "../../services/backend.service";
import { DataTableColumn, StringColumn } from "src/app/general/components/data-table/data-table-column";
import { Formatter } from "../../util/formatter";
import { ProtoUtil } from "../../util/proto-util";
import { TimeUtil } from "src/app/general/util/time-util";
import * as proto from 'src/proto/compiled-protos';

export class UnackedAndPaidOrdersModel implements OrdersModel {
  backendService: BackendService;

  constructor(
      backendService: BackendService) {
    this.backendService = backendService;
  }

  fetchData(continuationToken: string | null | undefined): Observable<IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>> {
    return this.backendService.findUnackedAndPaidOrdersReturnObservable(continuationToken)
      .pipe(
        map(locationOrders => {
          const paginatorData: IndeterminatePaginatorData<proto.waiternow.common.IOrderProto> = {data: [], continuationToken: continuationToken};
          if (locationOrders) {
            paginatorData.data = locationOrders.orders;
            paginatorData.continuationToken = locationOrders.continuationToken;
          }
          return paginatorData;
        })
      );
  }

  getAdditionalColumns(): Array<DataTableColumn<proto.waiternow.common.IOrderProto>> {
    return [
      new StringColumn(
        /* name= */ 'durationSincePaid',
        /* translateId= */ 'duration_since_paid',
        /* valueExtractor= */ order =>
            Formatter.formatElapsedTime(
              TimeUtil.createDateFromMillis(
                ProtoUtil.timestampProtoToMillis(order.status?.payment?.paymentTime))),
        /* hideInCompactMode= */ true
      ),
      new StringColumn(
        /* name= */ 'location',
        /* translateId= */ 'location',
        /* valueExtractor= */ order => order.redundantData?.businessName + ' ' + order.redundantData?.locationName,
        /* hideInCompactMode= */ true
      )
    ];
  }
}
