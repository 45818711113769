<app-page>
    <app-business-header [businessId]="businessId" *ngIf="businessId"></app-business-header>
    <app-location-header [locationId]="locationId" *ngIf="locationId"></app-location-header>
    <div>
        <table>
            <tr>
                <td>
                    <app-action [text]="'add' | translate" [defaultStyle]=true (onExecute)="goToAddCampaignPage()"></app-action>
                </td>
                <td>
                    <app-action [text]="'go_to_business' | translate" [defaultStyle]=true (onExecute)="navigationService.goToBusinessPage(businessId)" *ngIf="businessId"></app-action>
                    <app-action [text]="'go_to_location' | translate" [defaultStyle]=true (onExecute)="navigationService.goToLocationPage(locationId)" *ngIf="locationId"></app-action>
                </td>
            </tr>
        </table>
    </div>
    <app-campaigns [businessId]="businessId" *ngIf="businessId"></app-campaigns>
    <app-campaigns [locationId]="locationId" *ngIf="locationId"></app-campaigns>
</app-page>
