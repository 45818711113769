import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { ItemReusedWarningDialogComponent } from './item-reused-warning-dialog.component';
import { ItemReusedWarningDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public showWarning(): void {
//   const inputDialogData: ItemReusedWarningDialogData = {
//     ...
//   }
//   this.itemReusedWarningDialogService.openDialog(inputDialogData);
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class ItemReusedWarningDialogService {

  constructor(private dialog: MatDialog) { }

  public openDialog(inputDialogData: ItemReusedWarningDialogData) : CloseDialog {
    const dialogRef = this.dialog.open(ItemReusedWarningDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    return () => dialogRef.close();
  }
}
