import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { EnvironmentUtil } from "./environment-util";
import { ProtoType } from "./constants";
import { SessionService } from "../services/session.service";
import { Consumer, Function } from "src/app/general/interfaces/functions";

export class ToolbarUtil {

  static createToolbarActionOpenInNewWindow<T>(onExecute: Consumer<T>): ToolbarAction<T> {
    return {
      icon: 'open_in_new',
      tooltipTranslateId: 'open_in_new_window',
      onExecute: (subject: T | undefined) => {
        if (subject) {
          onExecute(subject);
        }
      }
    }
  }

  static createToolbarActionEdit<T>(onExecute: Consumer<T>): ToolbarAction<T> {
    return {
      icon: 'edit',
      tooltipTranslateId: 'edit',
      onExecute: (subject: T | undefined) => {
        if (subject) {
          onExecute(subject);
        }
      }
    }
  }

  static createToolbarActionDelete<T>(onExecute: Consumer<T>): ToolbarAction<T> {
    return {
      icon: 'delete',
      tooltipTranslateId: 'delete',
      onExecute: (subject: T | undefined) => {
        if (subject) {
          onExecute(subject);
        }
      }
    }
  }

  static createToolbarActionViewProto<T>(
      protoType: ProtoType,
      protoIdProvider: Function<T, string>,
      sessionService: SessionService): ToolbarAction<T> {
    return ToolbarUtil.createToolbarActionViewProtoWithTooltipAndIcon('plagiarism', 'view_proto', protoType, protoIdProvider, sessionService);
  }

  static createToolbarActionViewProtoWithTooltipAndIcon<T>(
      icon: string,
      tooltipTranslateId: string,
      protoType: ProtoType,
      protoIdProvider: Function<T, string>,
      sessionService: SessionService): ToolbarAction<T> {
    return {
      icon: icon,
      tooltipTranslateId: tooltipTranslateId,
      formPostActionToOpenInNewWindowProvider: (subject: T | undefined) => {
        return {
          url: EnvironmentUtil.resolveBackendUrl('/service/debug/proto/read/' + protoType),
          formParams: [
            sessionService.getAuthTokenFormParam(),
            { name: 'ProtoId', value: subject ? protoIdProvider(subject) : '' }
          ]
        };
      }
    }
  }
}
