import { UnaryFunction } from 'rxjs';

/**
 * Utility class to assist on storing fields in Local Storage.
 */
export class FieldLocalStore<T> {
    fieldKey: string;
    encodeValue: UnaryFunction<T, string>;
    decodeValue: UnaryFunction<string, T>;

    constructor(fieldKey: string, encodeValue: UnaryFunction<T, string>, decodeValue: UnaryFunction<string, T>) {
        this.fieldKey = fieldKey;
        this.encodeValue = encodeValue;
        this.decodeValue = decodeValue;
    }
  
    public set(value: T): void {
      localStorage.setItem(this.fieldKey, this.encodeValue(value));
    }
  
    public get(): T | null | undefined {
      const stringValue = localStorage.getItem(this.fieldKey);
      if (!stringValue) {
        return null;
      }
      return this.decodeValue(stringValue);
    }
  
    public clear(): void {
      return localStorage.removeItem(this.fieldKey);
    }
}
  
/**
 * Utility class to assist on storing string fields in Local Storage.
 */
export class StringFieldLocalStore extends FieldLocalStore<string> {

    constructor(fieldKey: string) {
        super(
            fieldKey,
            value => value,
            encodedValue => encodedValue
        );
    }
}

/**
 * Utility class to assist on storing boolean fields in Local Storage.
 */
export class BooleanFieldLocalStore extends FieldLocalStore<boolean> {

    constructor(fieldKey: string) {
        super(
            fieldKey,
            value => value.toString(),
            encodedValue => encodedValue == 'true'
        );
    }
}

/**
 * Utility class to assist on storing number fields in Local Storage.
 */
export class NumberFieldLocalStore extends FieldLocalStore<number> {

    constructor(fieldKey: string) {
        super(
            fieldKey,
            value => value.toString(),
            encodedValue => Number(encodedValue)
        );
    }
}

/**
 * Utility class to assist on storing number fields in Local Storage.
 */
export class DateFieldLocalStore extends FieldLocalStore<Date> {

    constructor(fieldKey: string) {
        super(
            fieldKey,
            value => value.getTime().toString(),
            encodedValue => new Date(Number(encodedValue))
        );
    }
}
