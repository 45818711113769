import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const TOAST_TIME_OUT: number = 5000;

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
      private toastrService: ToastrService) { }

  public success(message: string) {
    this.toastrService.success(message, /* toast title */ '', {
      // https://www.npmjs.com/package/ngx-toastr
      closeButton: true,
      tapToDismiss: true,
      timeOut: TOAST_TIME_OUT,
      positionClass: 'toast-center-center', // 'toast-top-left', 'toast-top-center', 'toast-top-right', toast-bottom-center
      progressBar: true, // Progress bar indicating the time when the toast will be closed
      progressAnimation: 'increasing', // decreasing,
      enableHtml: true, // Enable html in message
    });
  }

  public error(message: string) {
    this.toastrService.error(message, /* toast title */ '', {
      // https://www.npmjs.com/package/ngx-toastr
      closeButton: true,
      tapToDismiss: true,
      timeOut: TOAST_TIME_OUT,
      positionClass: 'toast-center-center', // 'toast-top-left', 'toast-top-center', 'toast-top-right', toast-bottom-center
      progressBar: true, // Progress bar indicating the time when the toast will be closed
      progressAnimation: 'increasing', // decreasing,
      enableHtml: true, // Enable html in message
    });
  }

  public info(message: string) {
    this.toastrService.info(message, /* toast title */ '', {
      // https://www.npmjs.com/package/ngx-toastr
      closeButton: true,
      tapToDismiss: true,
      timeOut: TOAST_TIME_OUT,
      positionClass: 'toast-center-center', // 'toast-top-left', 'toast-top-center', 'toast-top-right', toast-bottom-center
      progressBar: true, // Progress bar indicating the time when the toast will be closed
      progressAnimation: 'increasing', // decreasing,
      enableHtml: true, // Enable html in message
    });
  }
}
