import { AppError } from "./error";

export class LoadingMessage {
  message?: string;
  messageTranslateId?: string;

  constructor(message?: string, messageTranslateId?: string) {
    this.message = message;
    this.messageTranslateId = messageTranslateId;
  }

  static empty(): LoadingMessage {
    return new LoadingMessage();
  }

  static withMessage(message: string): LoadingMessage {
    return new LoadingMessage(message);
  }

  static withMessageTranslateId(messageTranslateId: string): LoadingMessage {
    return new LoadingMessage(undefined, messageTranslateId);
  }
}

export class SuccessResult {
  successMessage?: string;
  successMessageTranslateId?: string;

  constructor(successMessage?: string, successMessageTranslateId?: string) {
    this.successMessage = successMessage;
    this.successMessageTranslateId = successMessageTranslateId;
  }

  static empty(): SuccessResult {
    return new SuccessResult();
  }

  static withMessage(message: string): SuccessResult {
    return new SuccessResult(message);
  }

  static withMessageTranslateId(messageTranslateId: string): SuccessResult {
    return new SuccessResult(undefined, messageTranslateId);
  }
}

export class ErrorResult {
  error?: AppError;
  errorMessage?: string;
  errorMessageTranslateId?: string;

  constructor(error?: AppError, errorMessage?: string, errorMessageTranslateId?: string) {
    this.error = error;
    this.errorMessage = errorMessage;
    this.errorMessageTranslateId = errorMessageTranslateId;
  }

  static empty(): ErrorResult {
    return new ErrorResult();
  }

  static withError(error: AppError): ErrorResult {
    return new ErrorResult(error);
  }

  static withErrorAndMessage(error: AppError, message: string): ErrorResult {
    return new ErrorResult(error, message);
  }

  static withErrorAndMessageTranslateId(error: AppError, messageTranslateId: string): ErrorResult {
    return new ErrorResult(error, undefined, messageTranslateId);
  }

  static withMessage(message: string): ErrorResult {
    return new ErrorResult(undefined, message);
  }

  static withMessageTranslateId(messageTranslateId: string): ErrorResult {
    return new ErrorResult(undefined, undefined, messageTranslateId);
  }
}
