<app-loadable-content [loadingController]="locationDevicesLoadingController" (onRetry)="loadDevices()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'devices' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-data-table
                [data]="devices"
                [columns]="columns"
                [toolbarActions]="deviceToolbarModel.actions"
                [compactToolbarActions]="deviceToolbarModel.compactActions">
            </app-data-table>
        </mat-card-content>
    </mat-card>
</app-loadable-content>
