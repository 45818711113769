import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from 'src/app/general/services/device.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormService } from 'src/app/general/services/form.service';
import { MatInputModule } from '@angular/material/input';
import { BackendService } from '../../services/backend.service';
import { ProgressComponent } from 'src/app/general/components/progress/progress.component';
import { IndeterminatePaginatorComponent } from 'src/app/general/components/indeterminate-paginator/indeterminate-paginator.component';
import { IndeterminatePaginatorData, IndeterminatePaginatorModel } from 'src/app/general/components/indeterminate-paginator/indeterminate-paginator-model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from '../../services/session.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { ErrorComponent } from 'src/app/general/components/error/error.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { TableLayoutComponent } from 'src/app/general/components/table-layout/table-layout.component';
import { Util } from 'src/app/general/util/util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { ToolbarAction } from 'src/app/general/components/toolbar/toolbar-action';
import { AppError } from 'src/app/general/util/error';
import { UserComponent } from '../user/user.component';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-business-search (onBusinessSelectionChange)="onBusinessSelectionChange($event)"></app-business-search>

// TS:

// public onBusinessSelectionChange(business: protos.waiternow.common.IBusinessProto): void {
//   this.selectedBusiness = business;
// }
// ----------------------------------------------------------------------------

interface BusinessesQuery {
  email?: string;
  businessName?: string;
}

@Component({
  selector: 'app-business-search',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ProgressComponent,
    IndeterminatePaginatorComponent,
    TitleComponent,
    ErrorComponent,
    FieldContainerDirective,
    FieldDirective,
    TableLayoutComponent,
    ActionComponent,
    DataTableComponent,
    UserComponent
  ],
  templateUrl: './business-search.component.html',
  styleUrls: ['./business-search.component.css']
})
export class BusinessSearchComponent implements IndeterminatePaginatorModel<proto.waiternow.common.IBusinessProto> {
  @Output() onBusinessSelectionChange: EventEmitter<proto.waiternow.common.IBusinessProto> = new EventEmitter<proto.waiternow.common.IBusinessProto>();

  isError: boolean;
  error?:AppError;
  isInProgress: boolean;

  emailFormControl: FormControl;
  businessNameFormControl: FormControl;

  businesses: Array<proto.waiternow.common.IBusinessProto>;
  initialPaginatorData: IndeterminatePaginatorData<proto.waiternow.common.IBusinessProto>;
  paginatorModel: IndeterminatePaginatorModel<proto.waiternow.common.IBusinessProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.IBusinessProto>>;

  actions: Array<ToolbarAction<proto.waiternow.common.IBusinessProto>>;

  businessesQuery: BusinessesQuery;

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      private backendService: BackendService,
      private sessionService: SessionService) {
    this.isError = false;
    this.isInProgress = false;

    this.emailFormControl = new FormControl('', [Validators.email]);
    this.businessNameFormControl = new FormControl('', []);

    this.businesses = new Array();
    this.initialPaginatorData = { data: new Array(), continuationToken: ''};
    this.paginatorModel = this;
    this.columns = [
      new StringColumn(
        /* name= */ 'businessName',
        /* translateId= */ 'business_name',
        /* valueExtractor= */  business => business.businessName)
      ];

    this.businessesQuery = {};

    this.actions =
    [
      {
        icon: 'visibility',
        tooltipTranslateId: 'select',
        onExecute: business => this.onBusinessSelectionChange.emit(business)
      },
    ];
  }

  updatePage(page: proto.waiternow.common.IBusinessProto[]): void {
    this.businesses = page;
  }

  fetchData(continuationToken: string | null | undefined): Observable<IndeterminatePaginatorData<proto.waiternow.common.IBusinessProto>> {
    if (this.businessesQuery.email) {
      // Businesses by user do not support pagination
      return of();
    }
    if (this.businessesQuery.businessName) {
      return this.backendService.findBusinessesByNameReturnObservable(this.businessesQuery.businessName, continuationToken)
      .pipe(
        map(businesses => {
          const paginatorData: IndeterminatePaginatorData<proto.waiternow.common.IBusinessProto> = {data: new Array(), continuationToken: continuationToken};
          if (businesses) {
            paginatorData.data = businesses.businesses;
            paginatorData.continuationToken = businesses.continuationToken;
          }
          return paginatorData;
        })
      );
    }
    return of();
  }

  public findBusinesses(): void {
    this.isInProgress = true;
    this.isError = false;

    if (this.emailFormControl.value) {
      this.businessesQuery = { email: this.emailFormControl.value };
    } else {
      this.businessesQuery = { businessName: this.businessNameFormControl.value };
    }

    if (this.businessesQuery.email) {
      this.backendService.findBusinessesByUserEmail(
        this.businessesQuery.email,
        /* onSuccess= */ userBusinesses => {
          this.isInProgress = false;
          if (userBusinesses) {
            this.initialPaginatorData = {
              data: Util.convertArray(
                userBusinesses.businesses,
                managedBusiness => {
                  if (managedBusiness.business) {
                    return managedBusiness.business;
                  }
                  return null;
                }
              ),
              continuationToken: ''
            };
          }
        },
        /* onError= */ error => {
          if (this.sessionService.handleAuthenticationError(error)) {
            return;
          }
          this.error = error;
          this.isError = true;
          this.isInProgress = false;
        });
    } else if (this.businessesQuery.businessName) {
      this.backendService.findBusinessesByName(
        this.businessesQuery.businessName,
        /* continuationToken= */ null,
        /* onSuccess= */ businesses => {
          this.isInProgress = false;
          if (businesses) {
            this.initialPaginatorData = {
              data: businesses.businesses,
              continuationToken: businesses.continuationToken
            };
          } else {
            this.isError = true;
            this.error = undefined;
          }
        },
        /* onError= */ error => {
          if (this.sessionService.handleAuthenticationError(error)) {
            return;
          }
          this.error = error;
          this.isError = true;
          this.isInProgress = false;
        });
    }
  }

  public clearBusinesses(): void {
    this.businesses = [];
  }
}
