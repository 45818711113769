import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Adds a user header. If the user id is provided the user is loaded.
// If the user proto is provided the data is taken from such user.

// Usage Example

// Template:

// <app-user-header [userId]="userId"></app-user-header>
// <app-user-header [user]="user"></app-user-header>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-user-header',
  standalone: true,
  imports: [
    CommonModule,
    TitleComponent,
    TranslateModule,
    LoadableContentComponent
  ],
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css']
})
export class UserHeaderComponent {
  @Input() userId?: string;
  @Input() user?: proto.waiternow.common.IUserProto;

  userLoadingController: LoadingController;

  headerText: string;

  constructor(
      private sessionService: SessionService,
      private backendService: BackendService) {
    this.userId = '';
    this.headerText = '';
    this.userLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.userId && !this.user) {
      if (this.sessionService.enforceAuthentication()
          == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
        return;
      }
      this.loadUser();
    }
    if (this.user) {
      this.updateHeaderText(this.user);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('userId', changes)
       || ComponentUtil.bindingChanged('user', changes)) {
      this.ngOnInit();
    }
  }

  private updateHeaderText(userProto: proto.waiternow.common.IUserProto): void {
    this.headerText = Util.safeString(userProto.name + ' ' + userProto.lastName);
  }

  public loadUser(): void {
    this.userLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_user'));
    this.backendService.getUser(
      Util.safeString(this.userId),
      /* onSuccess= */ user => {
        if (user) {
          this.updateHeaderText(user);
        }
        this.userLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.userLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_user'));
      }
    );
  }
}
