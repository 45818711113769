<table width="100%" *ngIf="loadingController.isLoading || loadingController.isError">
    <tr>
        <td class="centered-content">
            <table>
                <tr *ngIf="loadingController.isLoading">
                    <td align="center">
                        <app-progress></app-progress>
                    </td>
                </tr>

                <tr *ngIf="loadingController.isLoading && loadingController.loadingMessage">
                    <td align="center">
                        {{loadingController.loadingMessage}}
                    </td>
                </tr>
                <tr *ngIf="loadingController.isLoading && loadingController.loadingMessageTranslateId">
                    <td align="center">
                        {{loadingController.loadingMessageTranslateId | translate}}
                    </td>
                </tr>

                <tr *ngIf="!loadingController.isLoading && loadingController.isError && loadingController.errorMessage">
                    <td><app-error [message]="loadingController.errorMessage" [error]="loadingController.error"></app-error></td>
                </tr>
                <tr *ngIf="!loadingController.isLoading && loadingController.isError && loadingController.errorMessageTranslateId">
                    <td><app-error [message]="loadingController.errorMessageTranslateId | translate" [error]="loadingController.error"></app-error></td>
                </tr>
                <tr *ngIf="!loadingController.isLoading && loadingController.isError && !loadingController.errorMessage && !loadingController.errorMessageTranslateId">
                    <td><app-error [error]="loadingController.error"></app-error></td>
                </tr>

                <tr *ngIf="!loadingController.isLoading && loadingController.isError && isOnRetryEnabled()">
                    <td align="center">
                        <app-action [text]="'retry' | translate" (onExecute)="retry()"></app-action>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
<ng-content *ngIf="!loadingController.isLoading  && !loadingController.isError"></ng-content>
