import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { Util } from 'src/app/general/util/util';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Adds a location header. If the location id is provided the logation is loaded.
// If the location proto is provided the data is taken from such location.

// Usage Example

// Template:

// <app-location-header [locationId]="locationId"></app-location-header>
// <app-location-header [location]="location"></app-location-header>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-location-header',
  standalone: true,
  imports: [
    CommonModule,
    TitleComponent,
    TranslateModule,
    LoadableContentComponent
  ],
  templateUrl: './location-header.component.html',
  styleUrls: ['./location-header.component.css']
})
export class LocationHeaderComponent implements OnInit, OnChanges {
  @Input() locationId?: string;
  @Input() location?: proto.waiternow.common.ILocationProto;

  locationLoadingController: LoadingController;

  headerText: string;

  constructor(
      private sessionService: SessionService,
      private backendService: BackendService) {
    this.locationId = '';
    this.headerText = '';
    this.locationLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.locationId && !this.location) {
      if (this.sessionService.enforceAuthentication()
          == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
        return;
      }
      this.loadLocation();
    }
    if (this.location) {
      this.updateHeaderText(this.location);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('locationId', changes)
       || ComponentUtil.bindingChanged('location', changes)) {
      this.ngOnInit();
    }
  }

  private updateHeaderText(locationProto: proto.waiternow.common.ILocationProto): void {
    this.headerText = Util.safeString(locationProto.redundantData?.businessName) + ' ' + Util.safeString(locationProto.name);
  }

  public loadLocation(): void {
    this.locationLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_location'));
    this.backendService.getLocation(
      Util.safeString(this.locationId),
      /* onSuccess= */ location => {
        if (location) {
          this.updateHeaderText(location);
        }
        this.locationLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.locationLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_location'));
      }
    );
  }
}
