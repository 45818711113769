import { Util } from "src/app/general/util/util";
import { BackendService } from "../services/backend.service";
import { NavigationService } from "../services/navigation.service";
import { DialogService } from "src/app/general/services/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Consumer } from "src/app/general/interfaces/functions";
import { ToastService } from "src/app/general/services/toast.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { ToolbarUtil } from "../util/toolbar-util";
import * as proto from 'src/proto/compiled-protos';

export class CampaignToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;
  navigationService: NavigationService;
  translateService: TranslateService;
  dialogService: DialogService;
  toastService: ToastService;
  compactForMobile: boolean;
  onCampaignDeleted: Consumer<string>;

  public actions: Array<ToolbarAction<proto.waiternow.common.ICampaignProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.ICampaignProto>>;

  /**
   * @param compactForMobile true to show open in new window for mobile devices.
   *                         Useful when the tool bar is part of a data table row.
   */
  constructor(
      sessionService: SessionService,
      backendService: BackendService,
      navigationService: NavigationService,
      translateService: TranslateService,
      dialogService: DialogService,
      toastService: ToastService,
      compactForMobile: boolean,
      onCampaignDeleted: Consumer<string>) {
    this.sessionService = sessionService;
    this.backendService = backendService;
    this.navigationService = navigationService;
    this.translateService = translateService;
    this.dialogService = dialogService;
    this.toastService = toastService;
    this.compactForMobile = compactForMobile;
    this.onCampaignDeleted = onCampaignDeleted;

    this.actions =
    [
      {
        icon: 'business',
        tooltipTranslateId: 'go_to_parent',
        onExecute: campaign => {
          if (campaign?.businessId) {
            this.navigationService.goToBusinessPage(Util.safeString(campaign?.businessId));
          } else if(campaign?.locationId) {
            this.navigationService.goToLocationPage(Util.safeString(campaign?.locationId));
          }
        }
      },
      ToolbarUtil.createToolbarActionEdit(
          campaign => this.navigationService.goToEditCampaignPage(Util.safeString(campaign.id))),
      ToolbarUtil.createToolbarActionDelete(
        campaign => this.deleteCampaign(campaign)),
      ToolbarUtil.createToolbarActionViewProto(ProtoType.CAMPAIGN, campaign => Util.safeString(campaign.id), this.sessionService)
    ];

    if (this.compactForMobile) {
      const openInNewWindowAction: ToolbarAction<proto.waiternow.common.ICampaignProto> =
          ToolbarUtil.createToolbarActionOpenInNewWindow(
            campaign => this.navigationService.goToCampaignPage(Util.safeString(campaign.id)));
      Util.insertIntoArray(openInNewWindowAction, 0, this.actions);
      this.compactActions = [openInNewWindowAction];
    } else {
      this.compactActions = this.actions;
    }
  }

  private deleteCampaign(campaign: proto.waiternow.common.ICampaignProto): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_campaign', { code: campaign.code }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.deleteCampaign(
            Util.safeString(campaign.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('campaign_deleted').subscribe(text => this.toastService.success(text));
              this.onCampaignDeleted(Util.safeString(campaign.id));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_campaign').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }
}
