<app-page>
    <app-loadable-content [loadingController]="menuLoadingController" (onRetry)="loadMenuSpec()">
        <app-business-header [businessId]="businessId" *ngIf="businessId"></app-business-header>
        <app-location-header [locationId]="locationId" *ngIf="locationId"></app-location-header>
        <div>
            <table>
                <tr>
                    <td>
                        <app-action [text]="'go_to_business' | translate" [defaultStyle]=true (onExecute)="navigationService.goToBusinessPage(businessId)" *ngIf="businessId"></app-action>
                        <app-action [text]="'go_to_location' | translate" [defaultStyle]=true (onExecute)="navigationService.goToLocationPage(locationId)" *ngIf="locationId"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'save_draft' | translate" [defaultStyle]=true (onExecute)="saveDraft()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'delete_draft' | translate" [defaultStyle]=true (onExecute)="deleteDraft()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'publish_menu' | translate" [defaultStyle]=true (onExecute)="publishMenu()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'delete_menu' | translate" [defaultStyle]=true (onExecute)="deleteMenu()"></app-action>
                    </td>
                </tr>
            </table>
        </div>
        <app-menu-editor [businessId]="businessId" [locationId]="locationId" [menuSpec]="menuSpec">
        </app-menu-editor>
    </app-loadable-content>
</app-page>
