<div mat-dialog-content>
    <table style="padding:10px">
        <tr>
            <td>{{data.yesNoQuestion}}</td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog()">No</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Yes</button>
</div>
