import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ItemReusedWarningDialogService } from '../item-reused-warning-dialog/item-reused-warning-dialog.service';
import { ItemReusedWarningDialogData } from '../util/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import * as proto from 'src/proto/compiled-protos';
import { Util } from 'src/app/general/util/util';
import { MenuEditorUtil } from '../util/menu-editor-util';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-item-selection-reused-warning [itemId]="item.id" [menuSpec]="menuSpec"></app-item-selection-reused-warning>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-item-reused-warning',
  standalone: true,
  imports: [
    CommonModule,
    ActionComponent,
    TranslateModule
  ],
  templateUrl: './item-reused-warning.component.html',
  styleUrls: ['./item-reused-warning.component.css']
})
export class ItemReusedWarningComponent implements OnInit, OnChanges {

  // We use string | null | undefined insteasd of optional (?) so we can do [itemId]="item.id"
  // proto fields are nullable. Optional fields can only be undefined, not null.
  @Input() itemId!: string | null | undefined;
  @Input() menuSpec!: proto.waiternow.common.StructuredMenuSpecProto;

  otherUsages?: ItemReusedWarningDialogData;
  isWarningApplicable: boolean;

  constructor(
      private itemReusedWarningDialogService: ItemReusedWarningDialogService) {
    this.isWarningApplicable = false;
  }

  ngOnInit(): void {
    this.otherUsages = this.findOtherUsages();
    this.isWarningApplicable = (this.otherUsages.reusedInItemSelections.length + this.otherUsages.reusedInRemovableIngredientsOfMenuItems.length) > 1;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('itemId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('menuSpec', changes)) {
      this.ngOnInit();
    }
  }

  public showWarning(): void {
    if (!this.otherUsages) {
      return;
    }
    this.itemReusedWarningDialogService.openDialog(this.otherUsages);
  }

  private findOtherUsages(): ItemReusedWarningDialogData {
    let itemName: proto.waiternow.common.ITextProto = new proto.waiternow.common.TextProto();
    let reusedInItemSelections: Array<proto.waiternow.common.ITextProto> = [];
    let reusedInRemovableIngredientsOfMenuItems: Array<proto.waiternow.common.ITextProto> = [];

    const itemSpec = MenuEditorUtil.findItemSpec(this.itemId, this.menuSpec);
    if (itemSpec) {
      if (itemSpec.name) {
        itemName = itemSpec.name;
      }
      for (const itemSelectionSpec of this.menuSpec.itemSelections) {
        if (Util.arrayContains(this.itemId, itemSelectionSpec.itemIds)) {
          if (itemSelectionSpec.title) {
            reusedInItemSelections.push(itemSelectionSpec.title);
          }
        }
      }
      for (const menuItemSpec of this.menuSpec.menuItems) {
        if (Util.arrayContains(this.itemId, menuItemSpec.removableIngredientItemIds)) {
          if (menuItemSpec.name) {
            reusedInRemovableIngredientsOfMenuItems.push(menuItemSpec.name);
          }
        }
      }
    }

    return {
      itemName: itemName,
      reusedInItemSelections: reusedInItemSelections,
      reusedInRemovableIngredientsOfMenuItems: reusedInRemovableIngredientsOfMenuItems
    }
  }
}
