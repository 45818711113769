import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { SelectItemDialogComponent } from './select-item-dialog.component';
import { SelectItemDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public selectItemForItemSelection(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): void {
//   const inputDialogData: SelectItemDialogData = {
//     selectedItems: ...,
//     items: ...
//   }
//   this.selectItemDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class SelectItemDialogService {

  constructor(private dialog: MatDialog) { }

  public openEditor(inputDialogData: SelectItemDialogData, onUpdate: Consumer<SelectItemDialogData>) : CloseDialog {
    const dialogRef = this.dialog.open(SelectItemDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData) {
        onUpdate(outputDialogData);
      }
    });

    return () => dialogRef.close();
  }
}
