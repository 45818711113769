import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from 'src/app/general/services/device.service';
import { FormService } from 'src/app/general/services/form.service';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { Address, AddressPickerComponent } from 'src/app/general/components/address-picker/address-picker.component';
import { COUNTRIES_NAMES } from '../../util/constants';
import { ActionObserver } from 'src/app/general/components/action/action';
import { SuccessResult, ErrorResult } from 'src/app/general/util/result';
import { ProtoUtil } from '../../util/proto-util';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-add-location-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    AddressPickerComponent
  ],
  templateUrl: './add-location-page.component.html',
  styleUrls: ['./add-location-page.component.css']
})
export class AddLocationPageComponent implements OnInit  {
  readonly COUNTRIES_NAMES: Array<string> = COUNTRIES_NAMES;

  businessId: string;

  nameField: FormControl = new FormControl('', [Validators.required]);
  phoneNumberField: FormControl = new FormControl('', [Validators.required]);
  address: Address | null | undefined;

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.businessId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.businessId = Util.safeString(routeParams.get('businessId'));
  }

  public onAddressChange(address: Address | null | undefined) {
    this.address = address;
  }

  public addLocation(actionObserver: ActionObserver): void {
    const location = new proto.waiternow.common.LocationProto();
    location.businessId = this.businessId;
    location.name = this.nameField.value;
    location.phoneNumber = ProtoUtil.createSimplePhoneNumberProto(this.phoneNumberField.value);
    if (this.address) {
      location.address = ProtoUtil.pickerAddressToAddressProto(this.address);
    }

    this.backendService.createLocation(
      location,
      /* onSuccess= */ addedLocation => {
        actionObserver.onSuccess(SuccessResult.empty());
        this.navigationService.goToLocationPage(Util.safeString(addedLocation?.id));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_adding_location'));
      }
    );
  }
}
