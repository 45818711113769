<mat-form-field class="form-field">
    <mat-label>{{ label }}</mat-label>
    <input matInput
        [formControl]="textFormatFormControl"
        [required]="isRequired()"
        #text_format_input>
    <button mat-icon-button matSuffix (click)="openEditor()" [disabled]="disabled">
        <mat-icon>edit</mat-icon>
    </button>
    <mat-error *ngIf="textFormatFormControl.invalid">
      {{formService.getFieldErrorMessage(textFormatFormControl)}}
    </mat-error>
</mat-form-field>
