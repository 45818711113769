<table *ngIf="data.length > 0">
    <tr>
        <td>
            <mat-spinner diameter=24 strokeWidth=3 *ngIf="isFetchingData"></mat-spinner>
        </td>
        <td>
            <mat-icon color="warn" matTooltip="{{ 'error_fetching_more_data' | translate }}" *ngIf="errorFetchingData">error_outline</mat-icon>
        </td>
        <td>
            <span class="range-legend">{{ rangeLegend }}</span>
        </td>
        <td>
            <app-action icon="first_page" [disabled]="pageIndex == 0" (onExecute)="firstPage()">
            </app-action>
        </td>
        <td>
            <app-action icon="chevron_left" [disabled]="pageIndex == 0" (onExecute)="previousPage()">
            </app-action>
        </td>
        <td>
            <app-action icon="chevron_right" [disabled]="data.length <= ((pageIndex + 1) * pageSize)" (onExecute)="nextPage()">
            </app-action>
        </td>
        <td>
            <app-action icon="last_page" [disabled]="data.length <= ((pageIndex + 1) * pageSize)" (onExecute)="lastPage()">
            </app-action>
        </td>
        <td>
            <app-action icon="refresh" [disabled]="isFetchingData || !fetchMoreDataEnabled" (onExecute)="fetchData()" [tooltip]="'fetch_more_data' | translate">
            </app-action>
        </td>
    </tr>
</table>
