<table width="100%" class="no-spacing-no-padding-table">
    <tr>
        <td>
            <mat-form-field appField>
                <mat-label>{{ searchElementLabel }}</mat-label>
                <input type="text" matInput [formControl]="autoCompleteControl" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="textProvider" [autoSelectActiveOption]="true" [hideSingleSelectionIndicator]="false" (optionSelected)="onAutocompleteOptionSelected($event)">
                    <mat-option *ngFor="let element of autoCompleteElements" [value]="element">{{ textProvider(element)}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </td>
        <td valign="top">
            <app-action [tooltip]="'create_new' | translate" icon="add" (onExecute)="createElement()" *ngIf="elementCreationHelper"></app-action>
        </td>
    </tr>
    <tr *ngFor="let element of selectedElements">
        <td width="100%">
            {{ textProvider(element) }}
        </td>
        <td>
            <app-action [tooltip]="'remove' | translate" icon="close" (onExecute)="unselect(element)"></app-action>
        </td>
    </tr>
</table>
