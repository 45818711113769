<app-loadable-content [loadingController]="campaignsLoadingController" (onRetry)="loadCampaigns()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'campaigns' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-data-table
                [data]="campaigns"
                [columns]="columns"
                [toolbarActions]="campaignToolbarModel.actions"
                [compactToolbarActions]="campaignToolbarModel.compactActions">
            </app-data-table>
        </mat-card-content>
    </mat-card>
</app-loadable-content>
