<h2 mat-dialog-title>{{ data.titleTranslateId | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td>
                <mat-form-field appField *ngIf="isTypeText(data.field1) || isTypeNumber(data.field1)">
                    <mat-label>{{ data.field1.translateId | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.field1.valueAsText" *ngIf="isTypeText(data.field1)" cdkFocusInitial>
                    <input matInput type="number" [(ngModel)]="data.field1.valueAsNumber" *ngIf="isTypeNumber(data.field1)" cdkFocusInitial>
                </mat-form-field>
                <app-money-picker *ngIf="isTypeMoney(data.field1)"
                    [label]="data.field1.translateId | translate"
                    [currencies]="CURRENCIES"
                    (onValueChange)="onField1MoneyChange($event)">
                </app-money-picker>
            </td>
        </tr>
        <tr *ngIf="data.field2">
            <td>
                <mat-form-field appField *ngIf="isTypeText(data.field2) || isTypeNumber(data.field2)">
                    <mat-label>{{ data.field2.translateId | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.field2.valueAsText" *ngIf="isTypeText(data.field2)">
                    <input matInput type="number" [(ngModel)]="data.field2.valueAsNumber" *ngIf="isTypeNumber(data.field2)">
                </mat-form-field>
                <app-money-picker *ngIf="isTypeMoney(data.field2)"
                    [label]="data.field2.translateId | translate"
                    [currencies]="CURRENCIES"
                    (onValueChange)="onField2MoneyChange($event)">
                </app-money-picker>
            </td>
        </tr>
        <tr *ngIf="data.field3">
            <td>
                <mat-form-field appField *ngIf="isTypeText(data.field3) || isTypeNumber(data.field3)">
                    <mat-label>{{ data.field3.translateId | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.field3.valueAsText" *ngIf="isTypeText(data.field3)">
                    <input matInput type="number" [(ngModel)]="data.field3.valueAsNumber" *ngIf="isTypeNumber(data.field3)">
                </mat-form-field>
                <app-money-picker *ngIf="isTypeMoney(data.field3)"
                    [label]="data.field3.translateId | translate"
                    [currencies]="CURRENCIES"
                    (onValueChange)="onField3MoneyChange($event)">
                </app-money-picker>
            </td>
        </tr>
        <tr *ngIf="data.field4">
            <td>
                <mat-form-field appField *ngIf="isTypeText(data.field4) || isTypeNumber(data.field4)">
                    <mat-label>{{ data.field4.translateId | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.field4.valueAsText" *ngIf="isTypeText(data.field4)">
                    <input matInput type="number" [(ngModel)]="data.field4.valueAsNumber" *ngIf="isTypeNumber(data.field4)">
                </mat-form-field>
                <app-money-picker *ngIf="isTypeMoney(data.field4)"
                    [label]="data.field4.translateId | translate"
                    [currencies]="CURRENCIES"
                    (onValueChange)="onField4MoneyChange($event)">
                </app-money-picker>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidElement()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
