import { Component } from '@angular/core';
import { CommonModule, ImageLoaderConfig } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { LocationHeaderComponent } from '../../components/location-header/location-header.component';
import { BackendService } from '../../services/backend.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { EnvironmentUtil } from '../../util/environment-util';
import { MatRadioModule } from '@angular/material/radio';
import { HeadingComponent } from 'src/app/general/components/heading/heading.component';
import { DeviceService } from 'src/app/general/services/device.service';
import { TableLayoutComponent } from 'src/app/general/components/table-layout/table-layout.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import { NavigationService } from '../../services/navigation.service';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-location-qr-codes-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    LoadableContentComponent,
    LocationHeaderComponent,
    TranslateModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    FieldContainerDirective,
    FieldDirective,
    ReactiveFormsModule,
    ActionComponent,
    MatRadioModule,
    HeadingComponent,
    TableLayoutComponent
  ],
  templateUrl: './location-qr-codes-page.component.html',
  styleUrls: ['./location-qr-codes-page.component.css']
})
export class LocationQrCodesPageComponent {
  locationId: string;
  location: proto.waiternow.common.ILocationProto;

  locationLoadingController: LoadingController;

  downloadQrCodesActionUrl;
  qrCodeSizeField: FormControl;
  languageField: FormControl;
  themeField: FormControl;

  constructor(
      public sessionService: SessionService,
      public deviceService: DeviceService,
      private activatedRoute: ActivatedRoute,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.locationId = '';
    this.location = new proto.waiternow.common.LocationProto();

    this.locationLoadingController = new LoadingController();

    this.downloadQrCodesActionUrl = '';
    this.qrCodeSizeField = new FormControl("small");
    this.languageField = new FormControl("en");
    this.themeField = new FormControl("light");
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.locationId = Util.safeString(pathParams.get('locationId'));
    this.loadLocation();
  }

  public goToLocationPage(): void {
    this.navigationService.goToLocationPage(this.locationId)
  }

  public loadLocation(): void {
    if (!this.locationId) {
      return;
    }
    this.locationLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_location'));
    this.backendService.getLocation(
      this.locationId,
      /* onSuccess= */ location => {
        if (location) {
          this.location = location;
        }
        this.locationLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.locationLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_location'));
      }
    );
  }

  public updateDownloadQrCodesActionUrl(): void {
    this.downloadQrCodesActionUrl = EnvironmentUtil.resolveBackendUrl('/service/user/qrcode/generate/location')
        + '/' + this.qrCodeSizeField.value + '/' + this.languageField.value
        + '/' + this.themeField.value + '/' + this.locationId;
  }
}
