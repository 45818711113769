<app-right-aligned-compact-content>
    <table>
        <tr>
            <td>
                <app-item-selection-reused-warning [itemSelectionId]="data.itemSelection.id" [menuSpec]="data.menuSpec"></app-item-selection-reused-warning>
            </td>
        </tr>
    </table>
</app-right-aligned-compact-content>
<h2 mat-dialog-title>{{ 'item_selection' | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'title_en' | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.itemSelection.titleEn" cdkFocusInitial>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'title_es' | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.itemSelection.titleEs">
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-checkbox [(ngModel)]="data.itemSelection.isRequired">{{ 'required' | translate }}</mat-checkbox>
            </td>
        </tr>
        <tr>
            <td>
                <mat-checkbox [(ngModel)]="data.itemSelection.isMultipleSelection">{{ 'multiple_selection' | translate }}</mat-checkbox>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'max_selections' | translate }}</mat-label>
                    <input matInput type="number" [(ngModel)]="data.itemSelection.maxSelections" min="0" step="1" pattern="[0-9]" [disabled]="!data.itemSelection.isMultipleSelection">
                </mat-form-field>
            </td>
        </tr>
        <tr *ngIf="data.allowChaningItems">
            <td>
                <app-heading>{{ 'items' | translate }}</app-heading>
            </td>
        </tr>
        <tr *ngIf="data.allowChaningItems">
            <td>
                <app-multi-select
                    [searchElementLabel]="'search_item' | translate"
                    [selectedElements]="data.itemSelection.items"
                    [allElements]="data.allItems"
                    [textProvider]="itemMultiselectUtil.getItemTextProvider()"
                    [comparator]="itemMultiselectUtil.getItemComparator()"
                    [elementCreationHelper]="itemMultiselectUtil.getItemCreationHelper()"
                    (onSelectedElementsChange)="onSelectedItemsChange($event)">
                </app-multi-select>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidItemSelection()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
