import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-page>
//     Page content...
// </app-page>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-page',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent],
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css', '../../../../styles.css']
})
export class PageComponent {

}
