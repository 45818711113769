import { BiConsumer, Consumer, Function } from "../../interfaces/functions";

export class DataTableColumn<T> {
  public name: string;
  public nameTranslateId: string;
  // Hides column for mobile devices
  public hideInCompactMode?: boolean;

  constructor(name: string, nameTranslateId: string, hideInCompactMode?: boolean) {
    this.name = name;
    this.nameTranslateId = nameTranslateId;
    this.hideInCompactMode = hideInCompactMode;
  }

  public isStringColumn(): boolean {
    return this instanceof StringColumn
        && !(this instanceof CompactTextColumn)
        && !(this instanceof CompactTextIfMobileColumn);
  }

  public asStringColumn(): StringColumn<T> {
    if (this instanceof StringColumn) {
      return this as StringColumn<T>;
    }
    throw new Error('Invalid column type');
  }

  public isCompactTextColumn(): boolean {
    return this instanceof CompactTextColumn
        && !(this instanceof CompactTextIfMobileColumn);
  }

  public asCompactTextColumn(): CompactTextColumn<T> {
    if (this instanceof CompactTextColumn) {
      return this as CompactTextColumn<T>;
    }
    throw new Error('Invalid column type');
  }

  public isCompactTextIfMobileColumn(): boolean {
    return this instanceof CompactTextIfMobileColumn;
  }

  public asCompactTextIfMobileColumn(): CompactTextIfMobileColumn<T> {
    if (this instanceof CompactTextIfMobileColumn) {
      return this as CompactTextIfMobileColumn<T>;
    }
    throw new Error('Invalid column type');
  }

  public isLinkColumn(): boolean {
    return this instanceof LinkColumn;
  }

  public asLinkColumn(): LinkColumn<T> {
    if (this instanceof LinkColumn) {
      return this as LinkColumn<T>;
    }
    throw new Error('Invalid column type');
  }

  public isCheckboxColumn(): boolean {
    return this instanceof CheckboxColumn;
  }

  public asCheckboxColumn(): CheckboxColumn<T> {
    if (this instanceof CheckboxColumn) {
      return this as CheckboxColumn<T>;
    }
    throw new Error('Invalid column type');
  }
}

export class StringColumn<T> extends DataTableColumn<T> {
  public valueExtractor: Function<T, string>;

  constructor(
      name: string,
      nameTranslateId: string,
      valueExtractor: Function<T, string | null | undefined>,
      hideInCompactMode?: boolean) {
    super(name, nameTranslateId, hideInCompactMode);
    this.valueExtractor = input => valueExtractor(input) || '';
  }
}

export class CompactTextColumn<T> extends StringColumn<T> {
  public compactTextLength: number;

  constructor(
      name: string,
      nameTranslateId: string,
      valueExtractor: Function<T, string | null | undefined>,
      compactTextLength:number,
      hideInCompactMode?: boolean) {
    super(name, nameTranslateId, valueExtractor, hideInCompactMode);
    this.compactTextLength = compactTextLength;
  }
}

export class CompactTextIfMobileColumn<T> extends CompactTextColumn<T> {

  constructor(
      name: string,
      nameTranslateId: string,
      valueExtractor: Function<T, string | null | undefined>,
      compactTextLength:number,
      hideInCompactMode?: boolean) {
    super(name, nameTranslateId, valueExtractor, compactTextLength, hideInCompactMode);
  }
}

export class LinkColumn<T> extends DataTableColumn<T> {
  public urlExtractor: Function<T, string>;
  public textExtractor: Function<T, string>;

  constructor(
      name: string,
      nameTranslateId: string,
      urlExtractor: Function<T, string | null | undefined>,
      textExtractor: Function<T, string | null | undefined>,
      hideInCompactMode?: boolean) {
    super(name, nameTranslateId, hideInCompactMode);
    this.urlExtractor = input => urlExtractor(input) || '';
    this.textExtractor = input => textExtractor(input) || '';
  }
}

export class CheckboxColumn<T> extends DataTableColumn<T> {
  public stateExtractor: Function<T, boolean>;
  public onStateChanged: BiConsumer<T, boolean>;

  constructor(
      name: string,
      nameTranslateId: string,
      stateExtractor: Function<T, boolean>,
      onStateChanged: BiConsumer<T, boolean>,
      hideInCompactMode?: boolean) {
    super(name, nameTranslateId, hideInCompactMode);
    this.stateExtractor = stateExtractor;
    this.onStateChanged = onStateChanged;
  }
}
