import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { LocationHeaderComponent } from '../location-header/location-header.component';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { ToolbarComponent } from 'src/app/general/components/toolbar/toolbar.component';
import { LocationToolbarModel } from '../../model/location-toolbar-model';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { SessionService } from '../../services/session.service';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-location [locationId]="locationId" *ngIf="locationId"></app-location>

// <app-location [location]="location" *ngIf="location"></app-location>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-location',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LoadableContentComponent,
    LocationHeaderComponent,
    ToolbarComponent,
    ActionComponent
  ],
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit, OnChanges {
  @Input() locationId?: string;
  @Input() locationInput?: proto.waiternow.common.ILocationProto;

  location: proto.waiternow.common.ILocationProto;
  locationToolbarModel: LocationToolbarModel;
  locationLoadingController: LoadingController;

  constructor(
      private backendService: BackendService,
      public navigationService: NavigationService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private toastService: ToastService,
      public sessionService: SessionService) {
    this.location = new proto.waiternow.common.LocationProto();
    this.locationToolbarModel = new LocationToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.toastService,
      /* compactForMobile= */ false,
      /* onLocationDeleted= */ locationId => this.navigationService.goToHomePage()
    );
    this.locationLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.locationInput) {
      this.location = this.locationInput;
    } else {
      this.loadLocation();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('locationId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('locationInput', changes)) {
      this.ngOnInit();
    }
  }

  public loadLocation(): void {
    if (!this.locationId) {
      return;
    }
    this.locationLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_location'));
    this.backendService.getLocation(
      this.locationId,
      /* onSuccess= */ location => {
        if (location) {
          this.location = location;
        }
        this.locationLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.locationLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_location'));
      }
    );
  }
}
