<h2 mat-dialog-title>{{ 'item_selection' | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'item_selection' | translate }}</mat-label>
                    <mat-select [(ngModel)]="data.itemSelection">
                        <mat-option *ngFor="let itemSelection of data.allItemSelections" [value]="itemSelection">
                            {{ getText(itemSelection) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td align="center" valign="top">
                <app-action icon="add" [tooltip]="'create_new' | translate" (onExecute)="createItemSelection()"></app-action>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidItemSelection()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
