<app-loadable-content [loadingController]="userBusinessesLoadingController" (onRetry)="loadBusinesses()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'businesses' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-data-table
                [data]="managedBusinesses"
                [columns]="columns"
                [toolbarActions]="managedBusinessToolbarModel.actions"
                [compactToolbarActions]="managedBusinessToolbarModel.compactActions">
            </app-data-table>
        </mat-card-content>
    </mat-card>
</app-loadable-content>
