<app-page>
    <app-user-header [userId]="userId"></app-user-header>
    <div>
        <table>
            <tr>
                <td>
                    <app-action [text]="'add' | translate" [defaultStyle]=true (onExecute)="goToAddBusinessPage()"></app-action>
                </td>
                <td>
                    <app-action [text]="'go_to_user' | translate" [defaultStyle]=true (onExecute)="goToUserPage()"></app-action>
                </td>
            </tr>
        </table>
    </div>
    <app-user-businesses [userId]="userId"></app-user-businesses>
</app-page>
