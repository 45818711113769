<!-- <h1 mat-dialog-title></h1> -->
<div mat-dialog-content>
    <mat-form-field style="width: 150px">
        <mat-label>Value</mat-label>
        <input matInput type="number" [(ngModel)]="data.value" cdkFocusInitial>
    </mat-form-field>
    &nbsp;
    <mat-form-field style="width: 160px">
        <mat-label>Unit</mat-label>
        <mat-select [(ngModel)]="data.unit">
            <mat-option *ngFor="let unit of data.units" [value]="unit">{{unit}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
