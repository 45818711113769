import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppError } from '../../util/error';
import { ComponentUtil } from '../../util/component-util';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-error *ngIf="!isLoading && isError" [message]="loadingErrorMessage" [error]="appError">
// </app-error>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit, OnChanges {
  @Input() message?: string;
  @Input() tooltip?: string;
  @Input() error?: AppError;

  nonNullTooltip: string;
  errorDetails: string;

  constructor() {
    this.errorDetails = '';
    this.nonNullTooltip = '';
  }

  ngOnInit(): void {
    if (this.tooltip) {
      this.nonNullTooltip = this.tooltip;
    }

    if (this.error) {
      if (this.error.isServerError()) {
        this.errorDetails = this.error.getServerErrorCode();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('tooltip', changes)
        || ComponentUtil.bindingChanged('error', changes)) {
      this.ngOnInit();
    }
  }
}
