<app-right-aligned-compact-content>
    <table>
        <tr>
            <td>
                <app-item-reused-warning [itemId]="data.item.id" [menuSpec]="data.menuSpec"></app-item-reused-warning>
            </td>
        </tr>
    </table>
</app-right-aligned-compact-content>

<h2 mat-dialog-title>{{ 'item' | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'name_en' | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.item.nameEn" cdkFocusInitial>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'name_es' | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.item.nameEs">
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <app-money-picker
                    [label]="'price' | translate"
                    [currencies]="CURRENCIES"
                    [value]="initialMoneyValue"
                    (onValueChange)="onPriceChange($event)">
                </app-money-picker>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidItem()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
