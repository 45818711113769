export class AppError extends Error {
  private httpErrorCode?: number;
  private serverErrorCode: string | null | undefined;

  constructor(message: string, cause?: any, httpErrorCode?: number, serverErrorCode?: string | null) {
      super(message, cause);
      this.message = message;
      this.cause = cause;
      this.httpErrorCode = httpErrorCode;
      this.serverErrorCode = serverErrorCode;
  }

  public getMessage(): string {
    return this.message;
  }

  public getCause(): any {
    return this.cause;
  }

  public isAuthenticationError(): boolean {
    return this.httpErrorCode === 401;
  }

  public isServerError(): boolean {
    if (this.serverErrorCode) {
      return true;
    }
    return false;
  }

  public getServerErrorCode(): string {
    return this.serverErrorCode ? this.serverErrorCode : '';
  }

  public addErrorDetails(errorMessage: string): string {
    if (!this.serverErrorCode) {
      return errorMessage;
    }
    return errorMessage + ' \n' + this.serverErrorCode;
  }
}
