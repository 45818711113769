import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { ScheduleEditorDialogData } from '../util/interfaces';
import { MatSelectModule } from '@angular/material/select';
import { ScheduleReusedWarningComponent } from '../schedule-reused-warning/schedule-reused-warning.component';
import { RightAlignedCompactContentComponent } from 'src/app/general/components/right-aligned-compact-content/right-aligned-compact-content.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateSchedule(schedule: proto.waiternow.common.StructuredMenuProto.IScheduleLevel1Proto): void {
//   const inputDialogData: ScheduleEditorDialogData = {
//     schedule: {
//       recurreceType: proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.DAILY,
//     }
//   }
//   this.scheduleEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-schedule-editor-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MatSelectModule,
    MatCheckboxModule,
    ScheduleReusedWarningComponent,
    RightAlignedCompactContentComponent
  ],
  templateUrl: './schedule-editor-dialog.component.html',
  styleUrls: ['./schedule-editor-dialog.component.css']
})
export class ScheduleEditorDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ScheduleEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScheduleEditorDialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public isValidSchedule(): boolean {
    if (this.data.schedule.dailyRecurrence.startsAtMinute && !this.data.schedule.dailyRecurrence.startsAtHour) {
      return false;
    }
    if (this.data.schedule.dailyRecurrence.endsAtMinute && !this.data.schedule.dailyRecurrence.endsAtHour) {
      return false;
    }
    return true;
  }

  // This is a workaround to be able to so a foor loop in Angular insteaf of a forach.
  // new Array() was not recognized in the template.
  public createArray(size: number): Array<number> {
    return new Array(size);
  }
}
