import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from 'src/app/general/services/device.service';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { PageComponent } from '../../components/page/page.component';
import { ProgressComponent } from 'src/app/general/components/progress/progress.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouteUtil } from 'src/app/general/util/route-util';
import { ActivatedRoute } from '@angular/router';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { ChartConfiguration } from 'chart.js';
import { Formatter } from '../../util/formatter';
import { ProtoUtil } from '../../util/proto-util';
import { AuthenticationStatus } from '../../util/util';
import { LocationHeaderComponent } from '../../components/location-header/location-header.component';
import { Util } from 'src/app/general/util/util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { MatIconModule } from '@angular/material/icon';
import { NavigationService } from '../../services/navigation.service';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-location-metrics-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    ProgressComponent,
    TranslateModule,
    MatExpansionModule,
    MatGridListModule,
    NgChartsModule,
    MatTooltipModule,
    LocationHeaderComponent,
    ActionComponent,
    MatIconModule
  ],
  templateUrl: './location-metrics-page.component.html',
  styleUrls: ['./location-metrics-page.component.css']
})
export class LocationMetricsPageComponent implements OnInit {
  // This is set after the view is initialized
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  locationId: string;

  isInProgress: boolean;

  errorLoadingDailyCharts: boolean;
  errorLoadingMonthlyCharts: boolean;
  errorLoadingYearlyCharts: boolean;

  integerChartOptions: ChartConfiguration['options'];
  currencyChartOptions: ChartConfiguration['options'];

  paidOdersChartDataDaily: ChartConfiguration['data'];
  paidOdersChartDataMonthly: ChartConfiguration['data'];
  paidOdersChartDataYearly: ChartConfiguration['data'];
  waiterNowRevenueChartDataDaily: ChartConfiguration['data'];
  waiterNowRevenueChartDataMonthly: ChartConfiguration['data'];
  waiterNowRevenueChartDataYearly: ChartConfiguration['data'];
  paymentsVolumeChartDataDaily: ChartConfiguration['data'];
  paymentsVolumeChartDataMonthly: ChartConfiguration['data'];
  paymentsVolumeChartDataYearly: ChartConfiguration['data'];
  refundsVolumeChartDataDaily: ChartConfiguration['data'];
  refundsVolumeChartDataMonthly: ChartConfiguration['data'];
  refundsVolumeChartDataYearly: ChartConfiguration['data'];
  checkInsChartDataDaily: ChartConfiguration['data'];
  checkInsChartDataMonthly: ChartConfiguration['data'];
  checkInsChartDataYearly: ChartConfiguration['data'];
  ordersChartDataDaily: ChartConfiguration['data'];
  ordersChartDataMonthly: ChartConfiguration['data'];
  ordersChartDataYearly: ChartConfiguration['data'];
  onlinePickupOrdersChartDataDaily: ChartConfiguration['data'];
  onlinePickupOrdersChartDataMonthly: ChartConfiguration['data'];
  onlinePickupOrdersChartDataYearly: ChartConfiguration['data'];
  deliveryOrdersChartDataDaily: ChartConfiguration['data'];
  deliveryOrdersChartDataMonthly: ChartConfiguration['data'];
  deliveryOrdersChartDataYearly: ChartConfiguration['data'];
  incompleteOrdersChartDataDaily: ChartConfiguration['data'];
  incompleteOrdersChartDataMonthly: ChartConfiguration['data'];
  incompleteOrdersChartDataYearly: ChartConfiguration['data'];
  refundedOrdersChartDataDaily: ChartConfiguration['data'];
  refundedOrdersChartDataMonthly: ChartConfiguration['data'];
  refundedOrdersChartDataYearly: ChartConfiguration['data'];
  tipsChartDataDaily: ChartConfiguration['data'];
  tipsChartDataMonthly: ChartConfiguration['data'];
  tipsChartDataYearly: ChartConfiguration['data'];
  thumbsUpChartDataDaily: ChartConfiguration['data'];
  thumbsUpChartDataMonthly: ChartConfiguration['data'];
  thumbsUpChartDataYearly: ChartConfiguration['data'];
  thumbsDownChartDataDaily: ChartConfiguration['data'];
  thumbsDownChartDataMonthly: ChartConfiguration['data'];
  thumbsDownChartDataYearly: ChartConfiguration['data'];
  reviewsCommentsChartDataDaily: ChartConfiguration['data'];
  reviewsCommentsChartDataMonthly: ChartConfiguration['data'];
  reviewsCommentsChartDataYearly: ChartConfiguration['data'];

  // Since #paidOdersChartDailyRef is added to a canvas in the template, we need to specify that we
  // want to read BaseChartDirective, otherwise ElementRef (for the canvas) is read. This happens
  // even though the type is specified as BaseChartDirective.
  // @ViewChild('paidOdersChartDailyRef') paidOdersChartDaily!: BaseChartDirective;
  @ViewChild('paidOdersChartDailyRef', {read: BaseChartDirective} ) paidOdersChartDaily!: BaseChartDirective;
  @ViewChild('paidOdersChartMonthlyRef', {read: BaseChartDirective} ) paidOdersChartMonthly!: BaseChartDirective;
  @ViewChild('paidOdersChartYearlyRef', {read: BaseChartDirective} ) paidOdersChartYearly!: BaseChartDirective;
  @ViewChild('waiterNowRevenueChartDailyRef', {read: BaseChartDirective} ) waiterNowRevenueChartDaily!: BaseChartDirective;
  @ViewChild('waiterNowRevenueChartMonthlyRef', {read: BaseChartDirective} ) waiterNowRevenueChartMonthly!: BaseChartDirective;
  @ViewChild('waiterNowRevenueChartYearlyRef', {read: BaseChartDirective} ) waiterNowRevenueChartYearly!: BaseChartDirective;
  @ViewChild('paymentsVolumeChartDailyRef', {read: BaseChartDirective} ) paymentsVolumeChartDaily!: BaseChartDirective;
  @ViewChild('paymentsVolumeChartMonthlyRef', {read: BaseChartDirective} ) paymentsVolumeChartMonthly!: BaseChartDirective;
  @ViewChild('paymentsVolumeChartYearlyRef', {read: BaseChartDirective} ) paymentsVolumeChartYearly!: BaseChartDirective;
  @ViewChild('refundsVolumeChartDailyRef', {read: BaseChartDirective} ) refundsVolumeChartDaily!: BaseChartDirective;
  @ViewChild('refundsVolumeChartMonthlyRef', {read: BaseChartDirective} ) refundsVolumeChartMonthly!: BaseChartDirective;
  @ViewChild('refundsVolumeChartYearlyRef', {read: BaseChartDirective} ) refundsVolumeChartYearly!: BaseChartDirective;
  @ViewChild('checkInsChartDailyRef', {read: BaseChartDirective} ) checkInsChartDaily!: BaseChartDirective;
  @ViewChild('checkInsChartMonthlyRef', {read: BaseChartDirective} ) checkInsChartMonthly!: BaseChartDirective;
  @ViewChild('checkInsChartYearlyRef', {read: BaseChartDirective} ) checkInsChartYearly!: BaseChartDirective;
  @ViewChild('ordersChartDailyRef', {read: BaseChartDirective} ) ordersChartDaily!: BaseChartDirective;
  @ViewChild('ordersChartMonthlyRef', {read: BaseChartDirective} ) ordersChartMonthly!: BaseChartDirective;
  @ViewChild('ordersChartYearlyRef', {read: BaseChartDirective} ) ordersChartYearly!: BaseChartDirective;
  @ViewChild('onlinePickupOrdersChartDailyRef', {read: BaseChartDirective} ) onlinePickupOrdersChartDaily!: BaseChartDirective;
  @ViewChild('onlinePickupOrdersChartMonthlyRef', {read: BaseChartDirective} ) onlinePickupOrdersChartMonthly!: BaseChartDirective;
  @ViewChild('onlinePickupOrdersChartYearlyRef', {read: BaseChartDirective} ) onlinePickupOrdersChartYearly!: BaseChartDirective;
  @ViewChild('deliveryOrdersChartDailyRef', {read: BaseChartDirective} ) deliveryOrdersChartDaily!: BaseChartDirective;
  @ViewChild('deliveryOrdersChartMonthlyRef', {read: BaseChartDirective} ) deliveryOrdersChartMonthly!: BaseChartDirective;
  @ViewChild('deliveryOrdersChartYearlyRef', {read: BaseChartDirective} ) deliveryOrdersChartYearly!: BaseChartDirective;
  @ViewChild('incompleteOrdersChartDailyRef', {read: BaseChartDirective} ) incompleteOrdersChartDaily!: BaseChartDirective;
  @ViewChild('incompleteOrdersChartMonthlyRef', {read: BaseChartDirective} ) incompleteOrdersChartMonthly!: BaseChartDirective;
  @ViewChild('incompleteOrdersChartYearlyRef', {read: BaseChartDirective} ) incompleteOrdersChartYearly!: BaseChartDirective;
  @ViewChild('refundedOrdersChartDailyRef', {read: BaseChartDirective} ) refundedOrdersChartDaily!: BaseChartDirective;
  @ViewChild('refundedOrdersChartMonthlyRef', {read: BaseChartDirective} ) refundedOrdersChartMonthly!: BaseChartDirective;
  @ViewChild('refundedOrdersChartYearlyRef', {read: BaseChartDirective} ) refundedOrdersChartYearly!: BaseChartDirective;
  @ViewChild('tipsChartDailyRef', {read: BaseChartDirective} ) tipsChartDaily!: BaseChartDirective;
  @ViewChild('tipsChartMonthlyRef', {read: BaseChartDirective} ) tipsChartMonthly!: BaseChartDirective;
  @ViewChild('tipsChartYearlyRef', {read: BaseChartDirective} ) tipsChartYearly!: BaseChartDirective;
  @ViewChild('thumbsUpChartDailyRef', {read: BaseChartDirective} ) thumbsUpChartDaily!: BaseChartDirective;
  @ViewChild('thumbsUpChartMonthlyRef', {read: BaseChartDirective} ) thumbsUpChartMonthly!: BaseChartDirective;
  @ViewChild('thumbsUpChartYearlyRef', {read: BaseChartDirective} ) thumbsUpChartYearly!: BaseChartDirective;
  @ViewChild('thumbsDownChartDailyRef', {read: BaseChartDirective} ) thumbsDownChartDaily!: BaseChartDirective;
  @ViewChild('thumbsDownChartMonthlyRef', {read: BaseChartDirective} ) thumbsDownChartMonthly!: BaseChartDirective;
  @ViewChild('thumbsDownChartYearlyRef', {read: BaseChartDirective} ) thumbsDownChartYearly!: BaseChartDirective;
  @ViewChild('reviewsCommentsChartDailyRef', {read: BaseChartDirective} ) reviewsCommentsChartDaily!: BaseChartDirective;
  @ViewChild('reviewsCommentsChartMonthlyRef', {read: BaseChartDirective} ) reviewsCommentsChartMonthly!: BaseChartDirective;
  @ViewChild('reviewsCommentsChartYearlyRef', {read: BaseChartDirective} ) reviewsCommentsChartYearly!: BaseChartDirective;

  constructor(
      public deviceService: DeviceService,
      private sessionService: SessionService,
      private backendService: BackendService,
      private activatedRoute: ActivatedRoute,
      private translateService: TranslateService,
      private navigationService: NavigationService) {
    this.locationId = '';

    this.isInProgress = false;

    this.errorLoadingDailyCharts = false;
    this.errorLoadingMonthlyCharts = false;
    this.errorLoadingYearlyCharts = false;

    this.integerChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {}
      },
      plugins: {
        legend: { display: true }
      }
    };
    this.currencyChartOptions = {
      responsive: true,
      scales: {
        x: {},
        y: {
          ticks: {
            callback: (value, index, values) => {
              return '$' + value;
            }
          }
        }
      },
      plugins: {
        legend: { display: true }
      }
    };

    this.paidOdersChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.paidOdersChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.paidOdersChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.waiterNowRevenueChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.waiterNowRevenueChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.waiterNowRevenueChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.paymentsVolumeChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.paymentsVolumeChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.paymentsVolumeChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.refundsVolumeChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.refundsVolumeChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.refundsVolumeChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.checkInsChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.checkInsChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.checkInsChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.ordersChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.ordersChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.ordersChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.onlinePickupOrdersChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.onlinePickupOrdersChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.onlinePickupOrdersChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.deliveryOrdersChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.deliveryOrdersChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.deliveryOrdersChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.incompleteOrdersChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.incompleteOrdersChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.incompleteOrdersChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.refundedOrdersChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.refundedOrdersChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.refundedOrdersChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.tipsChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.tipsChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.tipsChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.thumbsUpChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.thumbsUpChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.thumbsUpChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.thumbsDownChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.thumbsDownChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.thumbsDownChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.reviewsCommentsChartDataDaily = { labels: [], datasets: [{ data: [], label: '', fill: 'origin' }] };
    this.reviewsCommentsChartDataMonthly = { labels: [], datasets: [{ data: [], label: ''}] };
    this.reviewsCommentsChartDataYearly = { labels: [], datasets: [{ data: [], label: ''}] };

    this.initLabels();
    this.translateService.onLangChange.subscribe(event => this.initLabels());
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.locationId = Util.safeString(pathParams.get('locationId'));
    this.fetchDailyCharts();
  }

  public goToLocationPage(): void {
    this.navigationService.goToLocationPage(this.locationId)
  }

  private fetchDailyCharts(): void {
    this.isInProgress = true;
    this.backendService.findLocationMetrics(
      this.locationId,
      proto.waiternow.common.PeriodType.DAILY,
      /* onSuccess= */ metrics => {
        this.initDailyMetrics(metrics);
        this.fetchMonthlyCharts();
      },
      /* onError */ error => {
        this.errorLoadingDailyCharts = true;
        this.fetchMonthlyCharts();
      }
    );
  }

  private fetchMonthlyCharts(): void {
    this.backendService.findLocationMetrics(
      this.locationId,
      proto.waiternow.common.PeriodType.MONTHLY,
      /* onSuccess= */ metrics => {
        this.initMonthlyMetrics(metrics);
        this.fetchYearlyCharts();
      },
      /* onError= */ error =>  {
        this.errorLoadingMonthlyCharts = true;
        this.fetchYearlyCharts();
      }
    );
  }

  private fetchYearlyCharts(): void {
    this.backendService.findLocationMetrics(
      this.locationId,
      proto.waiternow.common.PeriodType.YEARLY,
      /* onSuccess= */ metrics => {
        this.initYearlyMetrics(metrics);
        this.isInProgress = false;
        this.updateCharts();
      },
      /* onError= */ error => {
        this.errorLoadingYearlyCharts = true;
        this.isInProgress = false;
        this.updateCharts();
      }
    );
  }

  private initDailyMetrics(metrics: proto.waiternow.common.ILocationMetricsProto | null | undefined): void {
    if (!metrics || !metrics.metrics) {
      return;
    }

    for (let i = metrics.metrics.length - 1; i >= 0; i--) {
      const metric = metrics.metrics[i];
      const label = Formatter.formatDateProto(metric.period?.from);
      this.paidOdersChartDataDaily.labels?.push(label);
      this.waiterNowRevenueChartDataDaily.labels?.push(label);
      this.paymentsVolumeChartDataDaily.labels?.push(label);
      this.refundsVolumeChartDataDaily.labels?.push(label);
      this.checkInsChartDataDaily.labels?.push(label);
      this.ordersChartDataDaily.labels?.push(label);
      this.onlinePickupOrdersChartDataDaily.labels?.push(label);
      this.deliveryOrdersChartDataDaily.labels?.push(label);
      this.incompleteOrdersChartDataDaily.labels?.push(label);
      this.refundedOrdersChartDataDaily.labels?.push(label);
      this.tipsChartDataDaily.labels?.push(label);
      this.thumbsUpChartDataDaily.labels?.push(label);
      this.thumbsDownChartDataDaily.labels?.push(label);
      this.reviewsCommentsChartDataDaily.labels?.push(label);

      this.paidOdersChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.paidOrders));
      this.waiterNowRevenueChartDataDaily.datasets[0].data.push(this.nonNullMoneyNumber(metric.waiternowOrderPaymentsRevenue));
      this.paymentsVolumeChartDataDaily.datasets[0].data.push(this.nonNullMoneyNumber(metric.orderPaymentsVolume));
      this.refundsVolumeChartDataDaily.datasets[0].data.push(this.nonNullMoneyNumber(metric.refundedOrdersVolume));
      this.checkInsChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.checkIns));
      this.ordersChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.orders));
      this.onlinePickupOrdersChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.onlinePickupOrders));
      this.deliveryOrdersChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.deliveryOrders));
      this.incompleteOrdersChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.incompleteOrders));
      this.refundedOrdersChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.refundedOrders));
      this.tipsChartDataDaily.datasets[0].data.push(this.nonNullMoneyNumber(metric.tips));
      this.thumbsUpChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.thumbsUp));
      this.thumbsDownChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.thumbsDown));
      this.reviewsCommentsChartDataDaily.datasets[0].data.push(this.nonNullNumber(metric.reviewsWithComments));
    }
  }

  private initMonthlyMetrics(metrics: proto.waiternow.common.ILocationMetricsProto | null | undefined): void {
    if (!metrics || !metrics.metrics) {
      return;
    }

    for (let i = metrics.metrics.length - 1; i >= 0; i--) {
      const metric = metrics.metrics[i];
      const label = Formatter.formatDateProtoAsYearMonth(metric.period?.from);
      this.paidOdersChartDataMonthly.labels?.push(label);
      this.waiterNowRevenueChartDataMonthly.labels?.push(label);
      this.paymentsVolumeChartDataMonthly.labels?.push(label);
      this.refundsVolumeChartDataMonthly.labels?.push(label);
      this.checkInsChartDataMonthly.labels?.push(label);
      this.ordersChartDataMonthly.labels?.push(label);
      this.onlinePickupOrdersChartDataMonthly.labels?.push(label);
      this.deliveryOrdersChartDataMonthly.labels?.push(label);
      this.incompleteOrdersChartDataMonthly.labels?.push(label);
      this.refundedOrdersChartDataMonthly.labels?.push(label);
      this.tipsChartDataMonthly.labels?.push(label);
      this.thumbsUpChartDataMonthly.labels?.push(label);
      this.thumbsDownChartDataMonthly.labels?.push(label);
      this.reviewsCommentsChartDataMonthly.labels?.push(label);

      this.paidOdersChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.paidOrders));
      this.waiterNowRevenueChartDataMonthly.datasets[0].data.push(this.nonNullMoneyNumber(metric.waiternowOrderPaymentsRevenue));
      this.paymentsVolumeChartDataMonthly.datasets[0].data.push(this.nonNullMoneyNumber(metric.orderPaymentsVolume));
      this.refundsVolumeChartDataMonthly.datasets[0].data.push(this.nonNullMoneyNumber(metric.refundedOrdersVolume));
      this.checkInsChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.checkIns));
      this.ordersChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.orders));
      this.onlinePickupOrdersChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.onlinePickupOrders));
      this.deliveryOrdersChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.deliveryOrders));
      this.incompleteOrdersChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.incompleteOrders));
      this.refundedOrdersChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.refundedOrders));
      this.tipsChartDataMonthly.datasets[0].data.push(this.nonNullMoneyNumber(metric.tips));
      this.thumbsUpChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.thumbsUp));
      this.thumbsDownChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.thumbsDown));
      this.reviewsCommentsChartDataMonthly.datasets[0].data.push(this.nonNullNumber(metric.reviewsWithComments));
    }
  }

  private initYearlyMetrics(metrics: proto.waiternow.common.ILocationMetricsProto | null | undefined): void {
    if (!metrics || !metrics.metrics) {
      return;
    }

    for (let i = metrics.metrics.length - 1; i >= 0; i--) {
      const metric = metrics.metrics[i];
      const label = Formatter.formatDateProtoAsYear(metric.period?.from);
      this.paidOdersChartDataYearly.labels?.push(label);
      this.waiterNowRevenueChartDataYearly.labels?.push(label);
      this.paymentsVolumeChartDataYearly.labels?.push(label);
      this.refundsVolumeChartDataYearly.labels?.push(label);
      this.checkInsChartDataYearly.labels?.push(label);
      this.ordersChartDataYearly.labels?.push(label);
      this.onlinePickupOrdersChartDataYearly.labels?.push(label);
      this.deliveryOrdersChartDataYearly.labels?.push(label);
      this.incompleteOrdersChartDataYearly.labels?.push(label);
      this.refundedOrdersChartDataYearly.labels?.push(label);
      this.tipsChartDataYearly.labels?.push(label);
      this.thumbsUpChartDataYearly.labels?.push(label);
      this.thumbsDownChartDataYearly.labels?.push(label);
      this.reviewsCommentsChartDataYearly.labels?.push(label);

      this.paidOdersChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.paidOrders));
      this.waiterNowRevenueChartDataYearly.datasets[0].data.push(this.nonNullMoneyNumber(metric.waiternowOrderPaymentsRevenue));
      this.paymentsVolumeChartDataYearly.datasets[0].data.push(this.nonNullMoneyNumber(metric.orderPaymentsVolume));
      this.refundsVolumeChartDataYearly.datasets[0].data.push(this.nonNullMoneyNumber(metric.refundedOrdersVolume));
      this.checkInsChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.checkIns));
      this.ordersChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.orders));
      this.onlinePickupOrdersChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.onlinePickupOrders));
      this.deliveryOrdersChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.deliveryOrders));
      this.incompleteOrdersChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.incompleteOrders));
      this.refundedOrdersChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.refundedOrders));
      this.tipsChartDataYearly.datasets[0].data.push(this.nonNullMoneyNumber(metric.tips));
      this.thumbsUpChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.thumbsUp));
      this.thumbsDownChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.thumbsDown));
      this.reviewsCommentsChartDataYearly.datasets[0].data.push(this.nonNullNumber(metric.reviewsWithComments));
    }
  }

  private nonNullNumber(nullableNumber: number | null | undefined): number {
    return Util.safeNumber(nullableNumber);
  }

  private nonNullMoneyNumber(money: proto.waiternow.common.IMoneyProto | null | undefined): number {
    return ProtoUtil.moneyToNumber(money);
  }

  private initLabels(): void {

    this.translateService.get('metric_period_daily').subscribe(periodTypeText => {
      this.translateService.get('metrics_paid_orders', {periodType: periodTypeText}).subscribe(text => {
        this.paidOdersChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_waiternow_revenue', {periodType: periodTypeText}).subscribe(text => {
        this.waiterNowRevenueChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_payments_volume', {periodType: periodTypeText}).subscribe(text => {
        this.paymentsVolumeChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_refunds_volume', {periodType: periodTypeText}).subscribe(text => {
        this.refundsVolumeChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_check_ins', {periodType: periodTypeText}).subscribe(text => {
        this.checkInsChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_orders', {periodType: periodTypeText}).subscribe(text => {
        this.ordersChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_online_pickup_orders', {periodType: periodTypeText}).subscribe(text => {
        this.onlinePickupOrdersChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_delivery_orders', {periodType: periodTypeText}).subscribe(text => {
        this.deliveryOrdersChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_incomplete_orders', {periodType: periodTypeText}).subscribe(text => {
        this.incompleteOrdersChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_refunded_orders', {periodType: periodTypeText}).subscribe(text => {
        this.refundedOrdersChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_tips', {periodType: periodTypeText}).subscribe(text => {
        this.tipsChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_thumbs_up', {periodType: periodTypeText}).subscribe(text => {
        this.thumbsUpChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_thumbs_down', {periodType: periodTypeText}).subscribe(text => {
        this.thumbsDownChartDataDaily.datasets[0].label = text;
      });
      this.translateService.get('metrics_reviews_comments', {periodType: periodTypeText}).subscribe(text => {
        this.reviewsCommentsChartDataDaily.datasets[0].label = text;
      });
    });

    this.translateService.get('metric_period_monthly').subscribe(periodTypeText => {
      this.translateService.get('metrics_paid_orders', {periodType: periodTypeText}).subscribe(text => {
        this.paidOdersChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_waiternow_revenue', {periodType: periodTypeText}).subscribe(text => {
        this.waiterNowRevenueChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_payments_volume', {periodType: periodTypeText}).subscribe(text => {
        this.paymentsVolumeChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_refunds_volume', {periodType: periodTypeText}).subscribe(text => {
        this.refundsVolumeChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_check_ins', {periodType: periodTypeText}).subscribe(text => {
        this.checkInsChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_orders', {periodType: periodTypeText}).subscribe(text => {
        this.ordersChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_online_pickup_orders', {periodType: periodTypeText}).subscribe(text => {
        this.onlinePickupOrdersChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_delivery_orders', {periodType: periodTypeText}).subscribe(text => {
        this.deliveryOrdersChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_incomplete_orders', {periodType: periodTypeText}).subscribe(text => {
        this.incompleteOrdersChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_refunded_orders', {periodType: periodTypeText}).subscribe(text => {
        this.refundedOrdersChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_tips', {periodType: periodTypeText}).subscribe(text => {
        this.tipsChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_thumbs_up', {periodType: periodTypeText}).subscribe(text => {
        this.thumbsUpChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_thumbs_down', {periodType: periodTypeText}).subscribe(text => {
        this.thumbsDownChartDataMonthly.datasets[0].label = text;
      });
      this.translateService.get('metrics_reviews_comments', {periodType: periodTypeText}).subscribe(text => {
        this.reviewsCommentsChartDataMonthly.datasets[0].label = text;
      });
    });
    this.translateService.get('metric_period_yearly').subscribe(periodTypeText => {
      this.translateService.get('metrics_paid_orders', {periodType: periodTypeText}).subscribe(text => {
        this.paidOdersChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_waiternow_revenue', {periodType: periodTypeText}).subscribe(text => {
        this.waiterNowRevenueChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_payments_volume', {periodType: periodTypeText}).subscribe(text => {
        this.paymentsVolumeChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_refunds_volume', {periodType: periodTypeText}).subscribe(text => {
        this.refundsVolumeChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_check_ins', {periodType: periodTypeText}).subscribe(text => {
        this.checkInsChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_orders', {periodType: periodTypeText}).subscribe(text => {
        this.ordersChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_online_pickup_orders', {periodType: periodTypeText}).subscribe(text => {
        this.onlinePickupOrdersChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_delivery_orders', {periodType: periodTypeText}).subscribe(text => {
        this.deliveryOrdersChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_incomplete_orders', {periodType: periodTypeText}).subscribe(text => {
        this.incompleteOrdersChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_refunded_orders', {periodType: periodTypeText}).subscribe(text => {
        this.refundedOrdersChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_tips', {periodType: periodTypeText}).subscribe(text => {
        this.tipsChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_thumbs_up', {periodType: periodTypeText}).subscribe(text => {
        this.thumbsUpChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_thumbs_down', {periodType: periodTypeText}).subscribe(text => {
        this.thumbsDownChartDataYearly.datasets[0].label = text;
      });
      this.translateService.get('metrics_reviews_comments', {periodType: periodTypeText}).subscribe(text => {
        this.reviewsCommentsChartDataYearly.datasets[0].label = text;
      });
    });
  }

  private updateCharts(): void {
    this.paidOdersChartDaily.update();
    this.paidOdersChartDaily.update();
    this.paidOdersChartMonthly.update();
    this.paidOdersChartYearly.update();
    this.waiterNowRevenueChartDaily.update();
    this.waiterNowRevenueChartMonthly.update();
    this.waiterNowRevenueChartYearly.update();
    this.paymentsVolumeChartDaily.update();
    this.paymentsVolumeChartMonthly.update();
    this.paymentsVolumeChartYearly.update();
    this.refundsVolumeChartDaily.update();
    this.refundsVolumeChartMonthly.update();
    this.refundsVolumeChartYearly.update();
    this.checkInsChartDaily.update();
    this.checkInsChartMonthly.update();
    this.checkInsChartYearly.update();
    this.ordersChartDaily.update();
    this.ordersChartMonthly.update();
    this.ordersChartYearly.update();
    this.onlinePickupOrdersChartDaily.update();
    this.onlinePickupOrdersChartMonthly.update();
    this.onlinePickupOrdersChartYearly.update();
    this.deliveryOrdersChartDaily.update();
    this.deliveryOrdersChartMonthly.update();
    this.deliveryOrdersChartYearly.update();
    this.incompleteOrdersChartDaily.update();
    this.incompleteOrdersChartMonthly.update();
    this.incompleteOrdersChartYearly.update();
    this.refundedOrdersChartDaily.update();
    this.refundedOrdersChartMonthly.update();
    this.refundedOrdersChartYearly.update();
    this.tipsChartDaily.update();
    this.tipsChartMonthly.update();
    this.tipsChartYearly.update();
    this.thumbsUpChartDaily.update();
    this.thumbsUpChartMonthly.update();
    this.thumbsUpChartYearly.update();
    this.thumbsDownChartDaily.update();
    this.thumbsDownChartMonthly.update();
    this.thumbsDownChartYearly.update();
    this.reviewsCommentsChartDaily.update();
    this.reviewsCommentsChartMonthly.update();
    this.reviewsCommentsChartYearly.update();
  }
}