<app-page>
    <app-title>{{ 'add_location' | translate }}</app-title>
    <table appFieldContainer align="center">
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'name' | translate }}</mat-label>
                    <input matInput [formControl]="nameField" required>
                    <mat-error *ngIf="nameField.invalid">{{formService.getFieldErrorMessage(nameField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'phone_number' | translate }}</mat-label>
                    <input matInput [formControl]="phoneNumberField" required>
                    <mat-error *ngIf="phoneNumberField.invalid">{{formService.getFieldErrorMessage(phoneNumberField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <app-address-picker
                        [label]="'address' | translate"
                        [required]="true"
                        [disabled]="false"
                        [countries]="COUNTRIES_NAMES"
                        (onValueChange)="onAddressChange($event)">
                </app-address-picker>
            </td>
        </tr>
        <tr>
            <td align="center">
                <app-action [text]="'submit' | translate"
                    [disabled]="nameField.invalid || phoneNumberField.invalid || !address"
                    (onAsyncExecute)="addLocation($event)">
                </app-action>
            </td>
        </tr>
    </table>
</app-page>
