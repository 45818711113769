import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentUtil } from '../../util/component-util';
import { ActionComponent } from '../action/action.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-compact-text [text]="myLongTextVariableBinding" [compactTextLength]="4">
// </app-compact-text>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-compact-text',
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    ActionComponent
  ],
  templateUrl: './compact-text.component.html',
  styleUrls: ['./compact-text.component.css']
})
export class CompactTextComponent implements OnInit, OnChanges {
  @Input() text!: string;
  @Input() compactTextLength!: number;

  compactText: string;
  displayText: string;
  tooltip: string;

  isExpandCollapseEnabled: boolean;
  isExpanded: boolean;

  constructor() {
    this.compactText = '';
    this.displayText = '';
    this.tooltip = '';
    this.isExpandCollapseEnabled = false;
    this.isExpanded = false;
  }

  ngOnInit(): void {
    this.updateCompactText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('text', changes)
        || ComponentUtil.bindingChanged('compactTextLength', changes)) {
      this.ngOnInit();
    }
  }

  private updateCompactText() : void {
    if (this.text && this.text.length > this.compactTextLength) {
      this.isExpandCollapseEnabled = true;
      this.compactText = this.text.substring(0, this.compactTextLength) + '...';
      this.collapse();
    } else {
      this.isExpandCollapseEnabled = false;
      this.compactText = this.text;
    }
  }

  public expand(): void {
    this.isExpanded = true;
    this.displayText = this.text;
    this.tooltip = '';
  }

  public collapse(): void {
    this.isExpanded = false;
    this.displayText = this.compactText;
    this.tooltip = this.text;
  }
}
