import { Injectable } from '@angular/core';

enum LoggingLevel {
  Debug,
  Info,
  Warning,
  Error,
  None
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private loggingLevel: LoggingLevel;

  constructor() {
    this.loggingLevel = LoggingLevel.Debug;
  }

  public logDebug(message?: any, ...optionalParams: any[]): void {
    if (this.loggingLevel > LoggingLevel.Debug) {
      return;
    }
    console.log('[DEBUG] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logInfo(message?: any, ...optionalParams: any[]): void {
    if (this.loggingLevel > LoggingLevel.Info) {
      return;
    }
    console.log('[INFO] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logWarning(message?: any, ...optionalParams: any[]): void {
    if (this.loggingLevel > LoggingLevel.Warning) {
      return;
    }
    console.log('[WARNING] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logError(message?: any, ...optionalParams: any[]): void {
    if (this.loggingLevel > LoggingLevel.Error) {
      return;
    }
    console.error('[ERROR] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logErrorWithCause(cause: any, message?: any, ...optionalParams: any[]): void {
    if (this.loggingLevel < LoggingLevel.Error) {
      return;
    }
    console.error('[ERROR] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
    console.error(cause);
  }

  private logMessageWithParameters(message?: any, ...optionalParams: any[]) {
    if (optionalParams && optionalParams.length > 0) {
      console.log(message, ...optionalParams);
    } else {
      console.log(message);
    }
  }

  private getTime(): string {
    return new Date().toLocaleString();
  }
}
