<h2 mat-dialog-title>{{ 'items' | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td>
                <app-multi-select
                    [searchElementLabel]="'search_item' | translate"
                    [selectedElements]="data.selectedItems"
                    [allElements]="data.allItems"
                    [textProvider]="itemMultiselectUtil.getItemTextProvider()"
                    [comparator]="itemMultiselectUtil.getItemComparator()"
                    [elementCreationHelper]="itemMultiselectUtil.getItemCreationHelper()"
                    (onSelectedElementsChange)="onSelectedItemsChange($event)">
                </app-multi-select>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidItemsSelection()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <!-- cdkFocusInitial used here to avoid the select to open the dropdown list when the dialog is shown -->
    <button mat-button (click)="closeDialog()" cdkFocusInitial>{{ 'cancel' | translate }}</button>
</div>
