<app-page>
    <app-title>{{ 'add_point_of_service' | translate }}</app-title>
    <table appFieldContainer align="center">
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'friendly_name' | translate }}</mat-label>
                    <input matInput [formControl]="friendlyNameField" required>
                    <mat-error *ngIf="friendlyNameField.invalid">{{formService.getFieldErrorMessage(friendlyNameField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'type' | translate }}</mat-label>
                    <mat-select [formControl]="typeField">
                        <mat-option [value]="POINT_OF_SERVICE_TYPE_NONE">{{ 'none' | translate }}</mat-option>
                        <mat-option [value]="POINT_OF_SERVICE_TYPE_TABLE">Table</mat-option>
                        <mat-option [value]="POINT_OF_SERVICE_TYPE_ONLINE">Online</mat-option>
                        <mat-option [value]="POINT_OF_SERVICE_TYPE_ONLINE_PICKUP">Online Pickup</mat-option>
                        <mat-option [value]="POINT_OF_SERVICE_TYPE_ONSITE_PICKUP">Onsite Pickup</mat-option>
                        <mat-option [value]="POINT_OF_SERVICE_TYPE_MENU">Menu</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td align="center">
                <app-action [text]="'submit' | translate"
                    [disabled]="friendlyNameField.invalid || typeField.value == POINT_OF_SERVICE_TYPE_NONE"
                    (onAsyncExecute)="addPointOfService($event)">
                </app-action>
            </td>
        </tr>
    </table>
</app-page>
