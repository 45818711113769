import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BackendService } from '../../services/backend.service';
import { Formatter } from '../../util/formatter';
import { MatCardModule } from '@angular/material/card';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { CompactTextColumn, DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { UserToolbarModel } from '../../model/user-toolbar-model';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-business-users businessId="{{business.id}}"></app-business-users>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-business-users',
  standalone: true,
  imports: [
    CommonModule,
    LoadableContentComponent,
    TranslateModule,
    MatCardModule,
    DataTableComponent
  ],
  templateUrl: './business-users.component.html',
  styleUrls: ['./business-users.component.css']
})
export class BusinessUsersComponent implements OnInit, OnChanges {
  @Input() businessId!: string;

  businessUsersLoadingController: LoadingController;

  managerUsers: Array<proto.waiternow.common.BusinessUsersProto.IManagerUserProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.BusinessUsersProto.IManagerUserProto>>;

  userToolbarModel: UserToolbarModel;

  constructor(
      private backendService: BackendService,
      private navigationService: NavigationService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private toastService: ToastService,
      private sessionService: SessionService) {
    this.businessUsersLoadingController = new LoadingController();

    this.managerUsers = new Array();
    this.columns = [
      new CompactTextColumn(
        /* name= */ 'id',
        /* translateId= */ 'id',
        /* valueExtractor= */  managerUser => managerUser.id,
        /* compactTextLength= */ 6),
      new StringColumn(
        /* name= */ 'name',
        /* translateId= */ 'name',
        /* valueExtractor= */  managerUser => managerUser.name + ' ' + managerUser.lastName),
      new StringColumn(
        /* name= */ 'role',
        /* translateId= */ 'role',
        /* valueExtractor= */  managerUser => Formatter.formatRole(managerUser.role),
        /* hideInCompactMode= */ true)
      ];

      this.userToolbarModel = new UserToolbarModel(
        this.sessionService,
        this.backendService,
        this.navigationService,
        this.translateService,
        this.dialogService,
        this.toastService,
        /* compactForMobile= */ true,
        /* onUserDeleted= */ userId => this.loadUsers()
      );
    }

  ngOnInit(): void {
    this.loadUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('businessId', changes)) {
      this.ngOnInit();
    }
  }

  public loadUsers(): void {
    if (!this.businessId) {
      return;
    }

    this.businessUsersLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_business_users'));
    this.backendService.findBusinessUsers(
      this.businessId,
      /* onSuccess= */ businessUsers => {
        if (businessUsers && businessUsers?.users) {
          this.managerUsers = businessUsers?.users;
        } else {
          this.managerUsers = [];
        }
        this.businessUsersLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.businessUsersLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_business_users'));
      }
    );
  }
}
