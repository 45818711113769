import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { ToolbarComponent } from 'src/app/general/components/toolbar/toolbar.component';
import { UserToolbarModel } from '../../model/user-toolbar-model';
import { SessionService } from '../../services/session.service';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-user [userId]="userId" *ngIf="userId"></app-user>

// <app-user [userEmail]="userEmail" *ngIf="userEmail"></app-user>

// <app-user [user]="user" *ngIf="user"></app-user>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LoadableContentComponent,
    TitleComponent,
    ToolbarComponent
  ],
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit, OnChanges {
  @Input() userId?: string;
  @Input() userEmail?: string;
  @Input() userInput?: proto.waiternow.common.IUserProto;

  user: proto.waiternow.common.IUserProto;

  userLoadingController: LoadingController;

  userToolbarModel: UserToolbarModel;

  constructor(
      private backendService: BackendService,
      private navigationService: NavigationService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private sessionService: SessionService,
      private toastService: ToastService) {
    this.user = new proto.waiternow.common.UserProto();
    this.userToolbarModel = new UserToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.toastService,
      /* compactForMobile= */ false,
      /* onLocationDeleted= */ userId => this.navigationService.goToHomePage()
    );
    this.userLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.userInput) {
      this.user = this.userInput;
    } else {
      this.loadUser();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('userId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('userEmail', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('userInput', changes)) {
      this.ngOnInit();
    }
  }

  public loadUser(): void {
    if (!this.userId && !this.userEmail) {
      return;
    }
    this.userLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_user'));
    if (this.userId) {
      this.backendService.getUser(
        this.userId,
        /* onSuccess= */ user => {
          if (user) {
            this.user = user;
          }
          this.userLoadingController.onSuccess();
        },
        /* onError= */ error => {
          this.userLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_user'));
        }
      );
    } else if (this.userEmail){
      this.backendService.findUserByEmail(
        this.userEmail,
        /* onSuccess= */ user => {
          if (user) {
            this.user = user;
          }
          this.userLoadingController.onSuccess();
        },
        /* onError= */ error => {
          this.userLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_user'));
        }
      );
    }
  }
}
