import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { SelectItemSelectionDialogData } from '../util/interfaces';
import { SelectItemSelectionDialogComponent } from './select-item-selection-dialog.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public selecItemSelection(): void {
//   const inputDialogData: SelecItemSelectionDialogData = {
//   }
//   this.selectItemSelectionDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class SelectItemSelectionDialogService {

  constructor(private dialog: MatDialog) { }

  public openEditor(inputDialogData: SelectItemSelectionDialogData, onUpdate: Consumer<SelectItemSelectionDialogData>) : CloseDialog {
    const dialogRef = this.dialog.open(SelectItemSelectionDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData) {
        onUpdate(outputDialogData);
      }
    });

    return () => dialogRef.close();
  }
}
