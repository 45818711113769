import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { DeviceService } from '../../services/device.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <div appFieldContainer>
//     <mat-form-field appField>
//         <mat-label>{{ 'sales_tax' | translate }}</mat-label>
//         <input matInput type="number" placeholder="0.0785" [formControl]="taxesConfig.salesTax">
//         <mat-error *ngIf="taxesConfig.salesTax.invalid">{{formService.getFieldErrorMessage(taxesConfig.salesTax)}}</mat-error>
//     </mat-form-field>
// </div>
// ----------------------------------------------------------------------------

@Directive({
  selector: '[appFieldContainer]',
  standalone: true
})
export class FieldContainerDirective {

  // Example taken from:
  // https://angular.io/guide/attribute-directives
  // and
  // https://github.com/angular/angular/blob/main/packages/common/src/directives/ng_class.ts

  constructor(
      private element: ElementRef,
      private renderer: Renderer2,
      private deviceService: DeviceService) {
    this.updateDirective();
  }

  private updateDirective(): void {
    if (this.deviceService.isMobile()) {
      this.renderer.removeClass(this.element.nativeElement, 'form');
      this.renderer.addClass(this.element.nativeElement, 'form-mobile');
      // this.element.nativeElement.style.width = '100%';
    } else {
      this.renderer.removeClass(this.element.nativeElement, 'form-mobile');
      this.renderer.addClass(this.element.nativeElement, 'form');
      // this.element.nativeElement.style.width = '400px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateDirective();
  }
}
