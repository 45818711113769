<app-loadable-content [loadingController]="businessLoadingController" (onRetry)="loadBusiness()">
    <table width="100%">
        <tr>
            <td align="center">
                <app-business-header [business]="business"></app-business-header>
            </td>
        </tr>
        <tr>
            <td align="center">
                <app-toolbar [subject]="business" [actions]="businessToolbarModel.actions" [compactActions]="businessToolbarModel.compactActions"></app-toolbar>
            </td>
        </tr>
        <tr>
            <td  align="center">
                <div class="container-with-overlay">
                    <img [src]="logoUrl" *ngIf="business.logo"
                        [ngClass]="deviceService.ifMobile('logo-mobile').orElse('logo')">
                    <app-no-image-placeholder matTooltip="Logo" *ngIf="!business.logo"></app-no-image-placeholder>
                    <div class="container-overlay-top-right-12px-outside">
                        <app-file-selector [isMiniFab]="true" icon="edit" [tooltip]="'edit' | translate" fileChooserAcceptFilter="image/*" (onFileSelected)="updateBusinessLogo($event)"></app-file-selector>
                    </div>
                </div>
            </td>
        </tr>
        <tr><td>&nbsp;</td></tr>
        <tr>
            <td  align="center">
                <h3> {{ 'header_landscape' | translate}} </h3>
                <div class="container-with-overlay">
                    <img [src]="headerForLandscapeScreenUrl" *ngIf="business.headerForLandscapeScreen"
                        [ngClass]="deviceService.ifMobile('header-for-landscape-screen-mobile').orElse('header-for-landscape-screen')">
                    <app-no-image-placeholder matTooltip="Header" *ngIf="!business.headerForLandscapeScreen"></app-no-image-placeholder>
                    <div class="container-overlay-top-right-12px-outside">
                        <app-file-selector [isMiniFab]="true" icon="edit" [tooltip]="'edit' | translate" fileChooserAcceptFilter="image/*" (onFileSelected)="updateBusinessHeaderForLandscapeScreen($event)"></app-file-selector>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td  align="center">
                <h3> {{ 'header_portrait' | translate}} </h3>
                <div class="container-with-overlay">
                    <img [src]="headerForPortraitScreenUrl" *ngIf="business.headerForPortraitScreen"
                    [ngClass]="deviceService.ifMobile('header-for-portrait-screen-mobile').orElse('header-for-portrait-screen')">
                    <app-no-image-placeholder matTooltip="Header" *ngIf="!business.headerForPortraitScreen"></app-no-image-placeholder>
                    <div class="container-overlay-top-right-12px-outside">
                        <app-file-selector [isMiniFab]="true" icon="edit" [tooltip]="'edit' | translate" fileChooserAcceptFilter="image/*" (onFileSelected)="updateBusinessHeaderForPortraitScreen($event)"></app-file-selector>
                    </div>
                </div>
            </td>
        </tr>
    </table>
</app-loadable-content>
