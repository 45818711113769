import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorComponent } from '../error/error.component';
import { ProgressComponent } from '../progress/progress.component';
import { ActionComponent } from '../action/action.component';
import { LoadingController } from './loading-controler';

// ----------------------------------------------------------------------------
// Usage Example

// Component that displays a progress indicator while the content is being loaded.
// Once the content is loaded the progress indicator is hidden and the content is displayed.

// If onRetry is provided, a rety button will appear if the content fails to load (when isError is true).

// Template:

// <app-loadable-content
//         [loadingController]="loadingController"
//         (onRetry)="loadData()">
//     Content
// </app-loadable-content>

// TS:

// loadingController = new LoadingController();
// ...
// loadingController.onLoadingStarted(LoadingMessage.withMessage('Loading...'));
// ...
// // loadingController.onSuccess();
// // or
// const error = new AppError('App error');
// loadingController.onError(ErrorResult.withErrorAndMessage(error, 'Error message'));

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-loadable-content',
  standalone: true,
  imports: [
    CommonModule,
    ProgressComponent,
    ActionComponent,
    TranslateModule,
    ErrorComponent
  ],
  templateUrl: './loadable-content.component.html',
  styleUrls: ['./loadable-content.component.css']
})
export class LoadableContentComponent {
  @Input() loadingController!: LoadingController;
  @Output() onRetry: EventEmitter<void> = new EventEmitter<void>();

  public retry(): void {
    this.onRetry.emit();
  }

  public isOnRetryEnabled() {
    return this.onRetry.observed;
  }
}
