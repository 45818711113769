<app-page>
    <app-loadable-content [loadingController]="locationLoadingController" (onRetry)="loadLocation()">
        <app-location-header [location]="location"></app-location-header>
        <div>
            <table>
                <tr>
                    <td>
                        <app-action [text]="'go_to_location' | translate" [defaultStyle]=true (onExecute)="goToLocationPage()"></app-action>
                    </td>
                </tr>
            </table>
        </div>
        <form #formRef method="POST" [action]="downloadQrCodesActionUrl">
            <input type="hidden" name="Auth-Token" [value]="sessionService.getAuthToken()"/>
            <table appFieldContainer align="center">
               <tr>
                    <td align="center">
                         <app-heading>{{'qr_code_style' | translate}}</app-heading>
                    </td>
               </tr>
               <tr>
                    <td>
                        <app-table-layout [columns]="deviceService.ifMobile(1).orElse(3)" [items]="3">
                             <div #layout>
                                 {{ 'size' | translate }}
                                 <br>
                                 <mat-radio-group [formControl]="qrCodeSizeField">
                                     <mat-radio-button value="small">small</mat-radio-button>
                                     <br>
                                     <mat-radio-button value="small-raw">Small Raw</mat-radio-button>
                                     <br>
                                     <mat-radio-button value="4x3">4x3 Inch</mat-radio-button>
                                     <br>
                                     <mat-radio-button value="4x4">4x4 Inch</mat-radio-button>
                                     <br>
                                     <mat-radio-button value="4x6">4x6 Inch</mat-radio-button>
                                 </mat-radio-group>
                             </div>
                             <div #layout>
                                 {{ 'theme' | translate }}
                                 <br>
                                 <mat-radio-group [formControl]="themeField">
                                     <mat-radio-button value="light">Light</mat-radio-button>
                                     <br>
                                     <mat-radio-button value="dark">Dark</mat-radio-button>
                                 </mat-radio-group>
                             </div>
                             <div #layout>
                                 {{ 'language' | translate }}
                                 <br>
                                 <mat-radio-group [formControl]="languageField">
                                     <mat-radio-button value="en">English</mat-radio-button>
                                     <br>
                                     <mat-radio-button value="es">Español</mat-radio-button>
                                 </mat-radio-group>
                             </div>
                        </app-table-layout>
                    </td>
               </tr>
                <tr>
                    <td align="center">
                        <app-action [text]="'download' | translate"
                            (onExecute)="updateDownloadQrCodesActionUrl(); formRef.action = downloadQrCodesActionUrl; formRef.submit()">
                        </app-action>
                    </td>
                </tr>
            </table>
        </form>
    </app-loadable-content>
</app-page>
