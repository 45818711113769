import { Util } from "src/app/general/util/util";
import { BackendService } from "../services/backend.service";
import { NavigationService } from "../services/navigation.service";
import { DialogService } from "src/app/general/services/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Consumer } from "src/app/general/interfaces/functions";
import { ToastService } from "src/app/general/services/toast.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { ToolbarUtil } from "../util/toolbar-util";
import * as proto from 'src/proto/compiled-protos';

export class UserToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;
  navigationService: NavigationService;
  translateService: TranslateService;
  dialogService: DialogService;
  toastService: ToastService;
  compactForMobile: boolean;
  onUserDeleted: Consumer<string>;

  public actions: Array<ToolbarAction<proto.waiternow.common.IUserProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.IUserProto>>;

  /**
   * @param compactForMobile true to show open in new window for mobile devices.
   *                         Useful when the tool bar is part of a data table row.
   */
  constructor(
      sessionService: SessionService,
      backendService: BackendService,
      navigationService: NavigationService,
      translateService: TranslateService,
      dialogService: DialogService,
      toastService: ToastService,
      compactForMobile: boolean,
      onUserDeleted: Consumer<string>) {
    this.sessionService = sessionService;
    this.backendService = backendService;
    this.navigationService = navigationService;
    this.translateService = translateService;
    this.dialogService = dialogService;
    this.toastService = toastService;
    this.compactForMobile = compactForMobile;
    this.onUserDeleted = onUserDeleted;

    this.actions =
    [
      {
        icon: 'business',
        tooltipTranslateId: 'businesses',
        onExecute: user => this.navigationService.goToUserBusinessesPage(Util.safeString(user?.id))
      },
      ToolbarUtil.createToolbarActionEdit(
          user => this.navigationService.goToEditUserPage(Util.safeString(user.id))),
      ToolbarUtil.createToolbarActionDelete(
          user => this.deleteUser(user)),
      ToolbarUtil.createToolbarActionViewProto(ProtoType.USER, user => Util.safeString(user.id), this.sessionService)
    ];

    if (this.compactForMobile) {
      const openInNewWindowAction: ToolbarAction<proto.waiternow.common.IUserProto> =
          ToolbarUtil.createToolbarActionOpenInNewWindow(
            user => this.navigationService.goToUserPage(Util.safeString(user.id)));
      Util.insertIntoArray(openInNewWindowAction, 0, this.actions);
      this.compactActions = [openInNewWindowAction];
    } else {
      this.compactActions = this.actions;
    }
  }

  private deleteUser(user: proto.waiternow.common.IUserProto): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_user', { name: user.name + ' ' + user.lastName }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.deleteUser(
            Util.safeString(user.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('user_deleted').subscribe(text => this.toastService.success(text));
              this.onUserDeleted(Util.safeString(user.id));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_user').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }
}
