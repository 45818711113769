import { Util } from "src/app/general/util/util";
import { BackendService } from "../services/backend.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { ToolbarUtil } from "../util/toolbar-util";
import { ActionObserver } from "src/app/general/components/action/action";
import { ErrorResult, SuccessResult } from "src/app/general/util/result";
import { NavigationService } from "../services/navigation.service";
import * as proto from 'src/proto/compiled-protos';

export class DeviceToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;

  public actions: Array<ToolbarAction<proto.waiternow.common.IDeviceProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.IDeviceProto>>;

  constructor(
      sessionService: SessionService,
      backendService: BackendService) {
    this.sessionService = sessionService;
    this.backendService = backendService;

    this.actions =
    [
      {
        icon: 'signal_wifi_off',
        tooltipTranslateId: 'terminate_session',
        onAsyncExecute: (device, actionObserver) => this.terminateDeviceSession(device, actionObserver)
      },
      ToolbarUtil.createToolbarActionViewProto(ProtoType.DEVICE, device => Util.safeString(device.id), this.sessionService)
    ];

    this.compactActions = this.actions;
  }

  private terminateDeviceSession(device: proto.waiternow.common.IDeviceProto | undefined, actionObserver: ActionObserver) {
    if (!device) {
      actionObserver.onError(ErrorResult.empty());
    }
    this.backendService.terminateDeviceSessionRequest(
      Util.safeString(device?.id),
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('terminate_session_request_sent'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_terminating_device_session'));
      }
    );
  }
}
