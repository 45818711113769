import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BackendService } from '../../services/backend.service';
import { FileSelectorComponent, FileSelectorOutput } from 'src/app/general/components/file-selector/file-selector.component';
import { ToastService } from 'src/app/general/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { NoImagePlaceholderComponent } from 'src/app/general/components/no-image-placeholder/no-image-placeholder.component';
import { DeviceService } from 'src/app/general/services/device.service';
import { NavigationService } from '../../services/navigation.service';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { DialogService } from 'src/app/general/services/dialog.service';
import { Util } from 'src/app/general/util/util';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { BusinessToolbarModel } from '../../model/business-toolbar-model';
import { SessionService } from '../../services/session.service';
import { ToolbarComponent } from 'src/app/general/components/toolbar/toolbar.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import { BusinessHeaderComponent } from '../business-header/business-header.component';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-business [businessId]="businessId" *ngIf="businessId"></app-business>

// <app-business [business]="business" *ngIf="business"></app-business>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-business',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    FileSelectorComponent,
    NoImagePlaceholderComponent,
    LoadableContentComponent,
    BusinessHeaderComponent,
    ToolbarComponent
  ],
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit, OnChanges {
  @Input() businessId?: string;
  @Input() businessInput?: proto.waiternow.common.IBusinessProto;

  business: proto.waiternow.common.IBusinessProto;

  businessLoadingController: LoadingController;

  businessToolbarModel: BusinessToolbarModel;

  logoUrl: string | null | undefined;
  headerForLandscapeScreenUrl: string | null | undefined;
  headerForPortraitScreenUrl: string | null | undefined;

  constructor(
      public deviceService: DeviceService,
      private backendService: BackendService,
      private sessionService: SessionService,
      private toastService: ToastService,
      private translateService: TranslateService,
      private navigationService: NavigationService,
      private dialogService: DialogService) {
    this.business = new proto.waiternow.common.BusinessProto();
    this.businessToolbarModel = new BusinessToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.toastService,
      /* compactForMobile= */ false,
      /* onLocationDeleted= */ businessId => this.navigationService.goToHomePage()
    );
    this.businessLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.businessInput) {
      this.business = this.businessInput;
      this.businessLoaded();
    } else {
      this.loadBusiness();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('businessId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('businessInput', changes)) {
      this.ngOnInit();
    }
  }

  public loadBusiness(): void {
    if (!this.businessId) {
      return;
    }
    this.businessLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_business'));
    this.backendService.getBusiness(
      this.businessId,
      /* onSuccess= */ business => {
        if (business) {
          this.business = business;
          this.businessLoaded();
        }
        this.businessLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.businessLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_business'));
      }
    );
  }

  private businessLoaded(): void {
    this.logoUrl = this.business.logo?.imageUrl;
    this.headerForLandscapeScreenUrl = this.business.headerForLandscapeScreen?.imageUrl;
    this.headerForPortraitScreenUrl = this.business.headerForPortraitScreen?.imageUrl;
  }

  public updateBusinessLogo(fileSelectorOutput: FileSelectorOutput<any>): void {
    if (fileSelectorOutput.file) {
      const closeProgressDialog = this.dialogService.openProgressDialog();
      this.backendService.updateBusinessLogo(
        Util.safeString(this.business.id),
        fileSelectorOutput.file,
        /* onSuccess= */ () => {
            closeProgressDialog();
            // The logo image id is the business id, so no need to refresh the business, we just need to
            // refresh the image view. TODO: However, the first time an image is set we will need to refresh the page
            // to fetch the business again, because business.logo will be empty.
            this.logoUrl = this.business.logo?.imageUrl + "?timestamp=" + new Date().getTime();
        },
        /* onError */ error => {
          closeProgressDialog();
          this.translateService.get('error_internal').subscribe(this.toastService.error);
        }
      );
    }
  }

  public updateBusinessHeaderForLandscapeScreen(fileSelectorOutput: FileSelectorOutput<any>): void {
    if (fileSelectorOutput.file) {
      const closeProgressDialog = this.dialogService.openProgressDialog();
      this.backendService.updateBusinessHeaderForLandscapeScreen(
        Util.safeString(this.business.id),
        fileSelectorOutput.file,
        /* onSuccess= */ () => {
            closeProgressDialog();
            // The header image id is the business id, so no need to refresh the business, we just need to
            // refresh the image view. TODO: However, the first time an image is set we will need to refresh the page
            // to fetch the business again, because business.headerForLandscapeScreen will be empty.
            this.headerForLandscapeScreenUrl = this.business.headerForLandscapeScreen?.imageUrl + "?timestamp=" + new Date().getTime();
        },
        /* onError */ error => {
          closeProgressDialog();
          this.translateService.get('error_internal').subscribe(this.toastService.error);
        }
      );
    }
  }

  public updateBusinessHeaderForPortraitScreen(fileSelectorOutput: FileSelectorOutput<any>): void {
    if (fileSelectorOutput.file) {
      const closeProgressDialog = this.dialogService.openProgressDialog();
      this.backendService.updateBusinessHeaderForPortraitScreen(
        Util.safeString(this.business.id),
        fileSelectorOutput.file,
        /* onSuccess= */ () => {
            closeProgressDialog();
            // The header image id is the business id, so no need to refresh the business, we just need to
            // refresh the image view. TODO: However, the first time an image is set we will need to refresh the page
            // to fetch the business again, because business.headerForPortraitScreen will be empty.
            this.headerForPortraitScreenUrl = this.business.headerForPortraitScreen?.imageUrl + "?timestamp=" + new Date().getTime();
        },
        /* onError */ error => {
          closeProgressDialog();
          this.translateService.get('error_internal').subscribe(this.toastService.error);
        }
      );
    }
  }
}
