<app-page>
    <app-title>{{ 'create_user' | translate }}</app-title>
    <table appFieldContainer align="center">
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'email' | translate }}</mat-label>
                    <input matInput [formControl]="emailField" required>
                    <mat-error *ngIf="emailField.invalid">{{formService.getFieldErrorMessage(emailField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <table align="center">
                    <tr>
                        <td style="vertical-align: bottom;">
                            <app-action [text]="'send_email_verification_code' | translate"
                                [disabled]="emailField.invalid || isEmailVerificationCodeValid"
                                [resetSignal]="emailVerificationResetSignal"
                                (onAsyncExecute)="sendEmailVerificationCode($event)">
                            </app-action>
                        </td>
                        <td style="vertical-align: bottom;">
                            <app-action [text]="'verify_code' | translate"
                                [disabled]="emailField.invalid || emailVerificationCodeField.invalid || isEmailVerificationCodeValid"
                                [resetSignal]="emailVerificationResetSignal"
                                (onAsyncExecute)="validateEmailCode($event)">
                            </app-action>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr *ngIf="sessionService.getIsAdminUser()">
            <td align="center">
                <app-action [text]="'skip_email_verification_process' | translate"
                    [disabled]="emailField.invalid || isEmailVerificationCodeValid"
                    [resetSignal]="emailVerificationResetSignal"
                    (onAsyncExecute)="getEmailVerificationCode($event)">
                </app-action>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'email_verification_code' | translate }}</mat-label>
                    <input matInput [formControl]="emailVerificationCodeField" required>
                    <mat-error *ngIf="emailVerificationCodeField.invalid">{{formService.getFieldErrorMessage(emailVerificationCodeField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'password' | translate }}</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="passwordField" required>
                    <app-action matSuffix [icon]="hidePassword ? 'visibility_off' : 'visibility'" (onExecute)="hidePassword = !hidePassword">
                    </app-action>
                    <mat-error *ngIf="passwordField.invalid">{{formService.getFieldErrorMessage(passwordField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'name' | translate }}</mat-label>
                    <input matInput [formControl]="nameField" required>
                    <mat-error *ngIf="nameField.invalid">{{formService.getFieldErrorMessage(nameField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'last_name' | translate }}</mat-label>
                    <input matInput [formControl]="lastNameField" required>
                    <mat-error *ngIf="lastNameField.invalid">{{formService.getFieldErrorMessage(lastNameField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'phone_number' | translate }}</mat-label>
                    <input matInput [formControl]="phoneNumberField">
                    <mat-error *ngIf="phoneNumberField.invalid">{{formService.getFieldErrorMessage(phoneNumberField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <table align="center">
                    <tr>
                        <td style="vertical-align: bottom;">
                            <app-action [text]="'send_phone_number_verification_code' | translate"
                                [disabled]="phoneNumberField.invalid || isPhoneNumberVerificationCodeValid"
                                [resetSignal]="phoneNumberVerificationResetSignal"
                                (onAsyncExecute)="sendPhoneNumberVerificationCode($event)">
                            </app-action>
                        </td>
                        <td style="vertical-align: bottom;">
                            <app-action [text]="'verify_code' | translate"
                                [disabled]="phoneNumberField.invalid || phoneNumberVerificationCodeField.invalid || isPhoneNumberVerificationCodeValid"
                                [resetSignal]="phoneNumberVerificationResetSignal"
                                (onAsyncExecute)="validatePhoneNumberCode($event)">
                            </app-action>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr *ngIf="sessionService.getIsAdminUser()">
            <td align="center">
                <app-action [text]="'skip_phone_number_verification_process' | translate"
                    [disabled]="phoneNumberField.invalid || isPhoneNumberVerificationCodeValid"
                    [resetSignal]="phoneNumberVerificationResetSignal"
                    (onAsyncExecute)="getPhoneNumberVerificationCode($event)">
                </app-action>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'phone_number_verification_code' | translate }}</mat-label>
                    <input matInput [formControl]="phoneNumberVerificationCodeField" required>
                    <mat-error *ngIf="phoneNumberVerificationCodeField.invalid">{{formService.getFieldErrorMessage(phoneNumberVerificationCodeField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td align="center">
                <app-action [text]="'submit' | translate"
                    [disabled]="nameField.invalid || emailVerificationCodeField.invalid || !isEmailVerificationCodeValid || passwordField.invalid || nameField.invalid || lastNameField.invalid || phoneNumberField.invalid || phoneNumberVerificationCodeField.invalid || !isPhoneNumberVerificationCodeValid"
                    (onAsyncExecute)="createUser($event)">
                </app-action>
            </td>
        </tr>
    </table>
</app-page>
