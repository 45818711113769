import * as proto from 'src/proto/compiled-protos';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PageComponent } from '../../components/page/page.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DeviceService } from '../../../general/services/device.service';
import { FormService } from '../../../general/services/form.service';
import { EditLocationSettingsComponent } from '../../components/edit-location-settings/edit-location-settings.component';
import { SessionService } from '../../services/session.service';
import { BackendService } from '../../services/backend.service';
import { Consumer, Runnable } from 'src/app/general/interfaces/functions';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { AuthenticationStatus } from '../../util/util';
import { AppError } from 'src/app/general/util/error';
import { Util } from 'src/app/general/util/util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, LoadingMessage, SuccessResult } from "src/app/general/util/result";
import { NavigationService } from '../../services/navigation.service';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { Formatter } from '../../util/formatter';
import { ProtoUtil } from '../../util/proto-util';

@Component({
  selector: 'app-edit-business-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    EditLocationSettingsComponent,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    LoadableContentComponent
  ],
  templateUrl: './edit-business-page.component.html',
  styleUrls: ['./edit-business-page.component.css']
})
export class EditBusinessPageComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild(EditLocationSettingsComponent) editLocationSettingsComponent!: EditLocationSettingsComponent;

  businessId: string;
  locationSettings: proto.waiternow.common.LocationProto.ISettingsProto | null | undefined;
  pageTitle: string;
  businessLoadingController: LoadingController;

  generalConfigFields = new (class {
    name: FormControl = new FormControl('', [Validators.required]);
    descriptionEn: FormControl = new FormControl('', [Validators.required]);
    descriptionEs: FormControl = new FormControl('', [Validators.required]);
  })();

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.businessId = '';

    this.pageTitle = '';

    this.businessLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.businessId = Util.safeString(routeParams.get('businessId'));
    this.loadBusiness();
  }

  public editLocationSettingsComponentAccordionOpenAll(): void {
    this.editLocationSettingsComponent.accordionOpenAll();
  }

  public editLocationSettingsComponentAccordionCloseAll(): void {
    this.editLocationSettingsComponent.accordionCloseAll();
  }

  public goToBusinessPage(): void {
    this.navigationService.goToBusinessPage(this.businessId)
  }

  public loadBusiness(): void {
    this.businessLoadingController.onLoadingStarted(LoadingMessage.empty());
    this.backendService.getBusiness(
      this.businessId,
      /* onSuccess= */ business => {
        this.initFormControls(business);
        this.businessLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.businessLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_business'));
      }
    );
  }

  private initFormControls(business: proto.waiternow.common.IBusinessProto | null | undefined): void {
    if (!business) {
      return;
    }

    this.pageTitle = Util.safeString(business.businessName);

    this.locationSettings = business?.locationSettings;

    // General
    this.generalConfigFields.name.setValue(business?.businessName);
    if (business.description) {
      this.generalConfigFields.descriptionEn.setValue(Formatter.getText(business.description, proto.waiternow.common.Language.ENGLISH));
      this.generalConfigFields.descriptionEs.setValue(Formatter.getText(business.description, proto.waiternow.common.Language.SPANISH));
    } else if (business.legacyDescription) {
      this.generalConfigFields.descriptionEn.setValue(business.legacyDescription);
    }
  }

  private createBusiness(): proto.waiternow.common.BusinessProto {
    const business = new proto.waiternow.common.BusinessProto();
    business.id = this.businessId;
    return business;
  }

  private saveBusiness(
      business: proto.waiternow.common.BusinessProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateBusinessActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    this.backendService.updateBusiness(
      business,
      fieldsToRemove,
      /* settingsFieldsToRemove= */ [],
      /* onSuccess= */ () => onSuccess(),
      /* onError= */ error => onError(error)
    );
  }

  public saveGeneral(actionObserver: ActionObserver): void {
    const business = this.createBusiness();
    const fieldsToRemove: Array<proto.waiternow.common.UpdateBusinessActionProto.Request.RemovableField> = [];

    business.businessName = this.generalConfigFields.name.value;
    if (this.generalConfigFields.descriptionEn.value || this.generalConfigFields.descriptionEs.value) {
      business.description =  ProtoUtil.createTextProto(this.generalConfigFields.descriptionEn.value, this.generalConfigFields.descriptionEs.value);
    } else {
      fieldsToRemove.push(proto.waiternow.common.UpdateBusinessActionProto.Request.RemovableField.DESCRIPTION);
    }

    this.saveBusiness(
      business,
      fieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_business'));
      }
    );
  }
}
