import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogData } from './confirmation-dialog-data';
import { MatButtonModule } from '@angular/material/button';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <button mat-raised-button (click)="openConfirmationDialog('My yes no question?')">Open Confirmation Dialog</button>

// TS:

// public openConfirmationDialog(yesNoQuestion: string) : void {
//   const closeDialog = this.dialogService.open(
//       yesNoQuestion,
//       /* onYes= */ () => {},
//       /* onNo= */ () => {});
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
