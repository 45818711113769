import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndeterminatePaginatorData, IndeterminatePaginatorModel } from './indeterminate-paginator-model';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentUtil } from '../../util/component-util';
import { Util } from '../../util/util';
import { ActionComponent } from '../action/action.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// If initialData changes the paginator starts over. If initial data is empty, fetching more data is disabled.
// <app-indeterminate-paginator
//     [model]="indeterminatePaginatorModel"
//     [pageSize]="10"
//     [initialData]="myInitialData">
// </app-indeterminate-paginator>

// TS:

// export class BusinessSearchComponent implements IndeterminatePaginatorModel<protos.waiternow.common.IBusinessProto> {
//   ...
//   indeterminatePaginatorModel: IndeterminatePaginatorModel<protos.waiternow.common.IBusinessProto>;

//   constructor(...) {
//     ...
//     this.indeterminatePaginatorModel = this;
//   }

//   updatePage(page: protos.waiternow.common.IBusinessProto[]): void {
//     Update UI with current page
//     ...
//   }

//   fetchData(continuationToken: string | null | undefined): Observable<IndeterminatePaginatorData<protos.waiternow.common.IBusinessProto>> {
//     // Query backend and return an observable, return of() from 'rxjs' for no data
//     ...
//   }

//}
// ----------------------------------------------------------------------------

interface PageRange {
  startIndex: number;
  size: number;
}

@Component({
  selector: 'app-indeterminate-paginator',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    ActionComponent,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    TranslateModule],
  templateUrl: './indeterminate-paginator.component.html',
  styleUrls: ['./indeterminate-paginator.component.css']
})
export class IndeterminatePaginatorComponent<T> implements OnInit, OnChanges {
  @Input() model!: IndeterminatePaginatorModel<T>;
  @Input() pageSize!: number;
  @Input() initialData!: IndeterminatePaginatorData<T>;

  continuationToken: string | null | undefined;

  data: Array<T>;
  pageIndex: number;
  rangeLegend: string;
  fetchMoreDataEnabled: boolean;
  isFetchingData: boolean;
  errorFetchingData: boolean;

  constructor() {
    this.data = new Array();
    this.pageIndex = 0;
    this.rangeLegend = '';
    this.fetchMoreDataEnabled = true;
    this.isFetchingData = false;
    this.errorFetchingData = false;
  }

  ngOnInit(): void {
    this.pageIndex = 0;
    this.rangeLegend = '';
    this.fetchMoreDataEnabled = true;
    this.isFetchingData = false;
    this.errorFetchingData = false;

    if (this.initialData.data && this.initialData.data.length > 0) {
      Util.replaceArrayItems(this.data, this.initialData.data);
      this.continuationToken = this.initialData.continuationToken;
      this.updatePage();
      this.fetchMoreDataEnabled = true;
    } else {
      Util.clearArray(this.data);
      this.updatePage();
      this.fetchMoreDataEnabled = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('initialData', changes)
        || ComponentUtil.bindingChanged('pageSize', changes)
        || ComponentUtil.bindingChanged('model', changes)) {
          this.ngOnInit();
    }
  }

  public fetchData(): void {
    this.isFetchingData = true;
    this.errorFetchingData = false;
    let dataFetched = false;
    this.model.fetchData(this.continuationToken).subscribe(
      {
        next: moreData => {
          this.isFetchingData = false;
          this.continuationToken = moreData.continuationToken;
          const currentPageRange = this.cualculatePageRange();
          if (moreData.data && moreData.data.length > 0) {
            dataFetched = true;
            for(let item of moreData.data) {
              this.data.push(item);
            }
            const newPageRange = this.cualculatePageRange();
            this.updateRangeLegend(newPageRange);
            if (currentPageRange.size != newPageRange.size) {
              this.updatePage();
            }
          } else {
            this.fetchMoreDataEnabled = false;
          }
        },
        complete: () => {
          this.isFetchingData = false;
          if (!dataFetched) {
            this.fetchMoreDataEnabled = false;
          }
        },
        error: exception => {
          this.isFetchingData = false;
          this.errorFetchingData = true;
        }
      }
    );
  }

  public firstPage(): void {
    this.pageIndex = 0;
    this.updatePage();
  }

  public previousPage(): void {
    this.pageIndex--;
    this.updatePage();
  }

  public nextPage(): void {
    this.pageIndex++;
    this.updatePage();
  }

  public lastPage(): void {
    let numberOfPages = Math.ceil(this.data.length / this.pageSize);
    this.pageIndex = numberOfPages - 1;
    this.updatePage();
  }

  private updatePage(): void {
    const pageRange = this.cualculatePageRange();
    this.updateRangeLegend(pageRange);
    this.model.updatePage(this.data.slice(pageRange.startIndex, pageRange.startIndex + pageRange.size));
  }

  private updateRangeLegend(range: PageRange): void {
    if (range.size > 0) {
      const start = range.startIndex + 1;
      const end = start + range.size - 1;
      this.rangeLegend = start + ' - ' + end + ' of ' + this.data.length;
    } else {
      this.rangeLegend = '';
    }
  }

  private cualculatePageRange(): PageRange {
    if (this.data.length == 0) {
      return {startIndex: 0, size: 0};
    }
    return {
      startIndex: this.pageIndex * this.pageSize,
      size: Math.min(this.pageSize, this.data.length - ((this.pageIndex * this.pageSize)))
    };
  }
}
