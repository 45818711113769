import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Adds a business header. If the business id is provided the business is loaded.
// If the business proto is provided the data is taken from such business.

// Usage Example

// Template:

// <app-business-header [businessId]="businessId"></app-business-header>
// <app-business-header [business]="business"></app-business-header>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-business-header',
  standalone: true,
  imports: [
    CommonModule,
    TitleComponent,
    TranslateModule,
    LoadableContentComponent

  ],
  templateUrl: './business-header.component.html',
  styleUrls: ['./business-header.component.css']
})
export class BusinessHeaderComponent {
  @Input() businessId?: string;
  @Input() business?: proto.waiternow.common.IBusinessProto;

  businessLoadingController: LoadingController;

  headerText: string;

  constructor(
      private sessionService: SessionService,
      private backendService: BackendService) {
    this.businessId = '';
    this.headerText = '';
    this.businessLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.businessId && !this.business) {
      if (this.sessionService.enforceAuthentication()
          == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
        return;
      }
      this.loadBusiness();
    }
    if (this.business) {
      this.updateHeaderText(this.business);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('businessId', changes)
       || ComponentUtil.bindingChanged('business', changes)) {
      this.ngOnInit();
    }
  }

  private updateHeaderText(businessProto: proto.waiternow.common.IBusinessProto): void {
    this.headerText = Util.safeString(businessProto.businessName);
  }

  public loadBusiness(): void {
    this.businessLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_business'));
    this.backendService.getBusiness(
      Util.safeString(this.businessId),
      /* onSuccess= */ business => {
        if (business) {
          this.updateHeaderText(business);
        }
        this.businessLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.businessLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_business'));
      }
    );
  }
}
