import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ErrorComponent } from '../error/error.component';
import { ProgressComponent } from '../progress/progress.component';
import { ComponentUtil } from '../../util/component-util';
import { AppError } from '../../util/error';
import { ActionObserver } from './action';
import { Param } from '../../interfaces/param';

// ----------------------------------------------------------------------------
// Usage Example

// Component that displays a button. If the action is async when clicked a
// progress indicator is shown.
// Once the action completes, it shows a successful message or an error message
// if provided.
// The button is hidden while the progress indicator is visible. The progress
// indicator takes the place of the button.

// Template:
// See icons: https://fonts.google.com/icons?icon.set=Material+Icons

// Non-async action.
// <app-action
//     text="Button text"
//     tooltip="Button tooltip"
//     icon="edit"
//     [highlightIcon]="false"
//     [defaultStyle]=true
//     [disabled]="false"
//     (onExecute)="executeAction()">
// </app-action>

// Async action
// <app-action
//     text="Button text"
//     tooltip="Button tooltip"
//     icon="edit"
//     [disabled]="formControl1.invalid || formControl2.invalid"
//     [resetSignal]="resetSignal"
//     (onAsyncExecute)="executeAsyncAction($event)">
// </app-action>

// Form submit
// <app-action
//     text="Button text"
//     tooltip="Button tooltip"
//     icon="edit"
//     [disabled]="formControl1.invalid || formControl2.invalid"
//     [isSubmitType]="true">
// </app-action>

// Open in new window post form action (Default formMethod is POST)
// <app-action
//     text="Button text"
//     tooltip="Button tooltip"
//     icon="edit"
//     [disabled]="false"
//     [urlToOpenInNewWindowWithFormRequest]="EnvironmentUtil.resolveUrl('/service/debug/proto/read/location')"
//     formMethod="GET"
//     [formParams]="[sessionService.getAuthTokenFormParam(), { name: 'ProtoId', value: location ? location.id : '' }]">
// </app-action>
// ----------------------------------------------------------------------------

// TS:

// public executeAction(): void {
//   ...
// }

// public executeAsyncAction(actionObserver: ActionObserver): void {
//   this.executeAfterDelay(3000, () => {
//       // actionObserver.onSuccess(SuccessResult.withMessage('Success message'));
//       // or
//       const error = new AppError('App error');
//       actionObserver.onError(ErrorResult.withErrorAndMessage(error, 'Error message'));
//   });
// }
// async executeAfterDelay(delayMilis: number, task: () => void) {
//   await new Promise(resolve => setTimeout(resolve, delayMilis)).then(task);
// }

// The reset signal is optional, it is used to reset the success/error messages for async actions.
// resetSignal: number;

// constructor() {
//   this.resetSignal = 0;
// }

// // Change the value of ths resetSignal when you want the action component to reset.
// this.resetSignal++;

// import { EnvironmentUtil } from '../../util/environment-util';
// ...
// readonly EnvironmentUtil = EnvironmentUtil;


@Component({
  selector: 'app-action',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ProgressComponent,
    ErrorComponent
  ],
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit, OnChanges {
  @Input() text?: string;
  @Input() tooltip?: string;
  @Input() icon?: string;
  @Input() highlightIcon?: boolean;
  @Input() disabled?: boolean;
  @Input() defaultStyle?: boolean;

  // Actions with execution behavior
  @Input() resetSignal?: number;
  @Output() onExecute: EventEmitter<void> = new EventEmitter<void>();
  @Output() onAsyncExecute: EventEmitter<ActionObserver> = new EventEmitter<ActionObserver>();

  // Submit type action
  @Input() isSubmitType?: boolean;

  // Open in new window post form action
  @Input() urlToOpenInNewWindowWithFormRequest?: string;
  @Input() formMethod?: string = 'POST';
  @Input() formParams?: Array<Param>;

  isExecuting: boolean;
  successMessage: string;
  successMessageTranslateId: string;
  isError: boolean;
  error?: AppError;
  errorMessage: string;
  errorMessageTranslateId: string;

  iconColor: string;

  constructor() {
    this.isExecuting = false;
    this.successMessage = '';
    this.successMessageTranslateId = '';
    this.isError = false;
    this.errorMessage = '';
    this.errorMessageTranslateId = '';
    this.iconColor = "";
  }

  ngOnInit(): void {
    this.clearState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('resetSignal', changes)) {
      this.ngOnInit();
    }
  }

  private clearState() {
    this.isExecuting = false;
    this.successMessage = '';
    this.successMessageTranslateId = '';
    this.isError = false;
    this.errorMessage = '';
    this.errorMessageTranslateId = '';
    this.error = undefined;
    // primary, accent, or warn
    this.iconColor = this.highlightIcon ? "warn" : "";
  }

  public execute():void {
    this.clearState();
    if (this.onExecute.observed) {
      this.onExecute.emit();
    } else if (this.onAsyncExecute.observed) {
      this.isExecuting = true;
      this.onAsyncExecute.emit(
        {
          onSuccess: successResult => {
            this.isExecuting = false;
            if (successResult.successMessage) {
              this.successMessage = successResult.successMessage;
            } else if (successResult.successMessageTranslateId) {
              this.successMessageTranslateId = successResult.successMessageTranslateId;
            }
          },
          onError: errorResult => {
            this.isExecuting = false;
            this.isError = true;
            if (errorResult.error) {
              this.error = errorResult.error;
            }
            if (errorResult.errorMessage) {
              this.errorMessage = errorResult.errorMessage;
            } else if (errorResult.errorMessageTranslateId) {
              this.errorMessageTranslateId = errorResult.errorMessageTranslateId;
            } else if(this.error) {
              this.errorMessage = this.error.getMessage();
            }
          }
        }
      );
    }
  }
}
