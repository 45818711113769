import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { ScheduleReusedWarningDialogData } from '../util/interfaces';
import { MenuEditorUtil } from '../util/menu-editor-util';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public showWarning(): void {
//   const inputDialogData: ScheduleReusedWarningDialogData = {
//     ...
//   }
//   this.ScheduleReusedWarningDialogData.openDialog(inputDialogData);
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-schedule-reused-warning-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    DataTableComponent
  ],
  templateUrl: './schedule-reused-warning-dialog.component.html',
  styleUrls: ['./schedule-reused-warning-dialog.component.css']
})
export class ScheduleReusedWarningDialogComponent {

  columns: Array<DataTableColumn<proto.waiternow.common.ITextProto>> = [
    new StringColumn(
        /* name= */ 'menuItem',
        /* translateId= */ 'menu_item',
        /* valueExtractor= */  textProto => this.getNonEmptyText(textProto)),
  ];

  constructor(
      public dialogRef: MatDialogRef<ScheduleReusedWarningDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ScheduleReusedWarningDialogData,
      private localizationService: LocalizationService) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public getNonEmptyText(textProto: proto.waiternow.common.ITextProto): string {
    return MenuEditorUtil.getNonEmptyText(textProto, this.localizationService);
  }
}
