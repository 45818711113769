import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '../../services/device.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-title>{{ 'find_business' | translate }}</app-title>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-title',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent {

  constructor(public deviceService: DeviceService) {
  }
}
