import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { MatExpansionModule, MatAccordion } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { Runnable, Consumer } from 'src/app/general/interfaces/functions';
import { DeviceService } from 'src/app/general/services/device.service';
import { FormService } from 'src/app/general/services/form.service';
import { AppError } from 'src/app/general/util/error';
import { LoadingMessage, ErrorResult, SuccessResult } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { PageComponent } from '../../components/page/page.component';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { Formatter } from '../../util/formatter';
import { ProtoUtil } from '../../util/proto-util';
import { Money, MoneyPickerComponent } from 'src/app/general/components/money-picker/money-picker.component';
import { CURRENCIES } from '../../util/constants';
import { MatCheckboxModule } from '@angular/material/checkbox';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-edit-campaign-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    MatSelectModule,
    LoadableContentComponent,
    MoneyPickerComponent,
    MatCheckboxModule
  ],
  templateUrl: './edit-campaign-page.component.html',
  styleUrls: ['./edit-campaign-page.component.css']
})
export class EditCampaignPageComponent implements OnInit {
  readonly CURRENCIES: Array<string> = CURRENCIES;

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  campaignId: string;
  pageTitle: string;
  campaignLoadingController: LoadingController;

  generalConfigFields = new (class {
    code: FormControl = new FormControl('', [Validators.required]);
    descriptionEn: FormControl = new FormControl('');
    descriptionEs: FormControl = new FormControl('');
    discountFixedAmountInitialValue: Money | null | undefined;
    discountFixedAmountCurrentValue: Money | null | undefined;
    discountPercentage: FormControl = new FormControl('');
    conditionRuleFirstPurchase: FormControl = new FormControl(false);
    conditionRuleExcludeOnline: FormControl = new FormControl(false);
    isActive: FormControl = new FormControl(false);
  })();

  constructor(
      public formService: FormService,
      public deviceService: DeviceService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.campaignId = '';
    this.pageTitle = '';
    this.campaignLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.campaignId = Util.safeString(routeParams.get('campaignId'));
    this.loadCampaign();
  }

  public goToCampaignPage(): void {
    this.navigationService.goToCampaignPage(this.campaignId)
  }

  public loadCampaign(): void {
    this.campaignLoadingController.onLoadingStarted(LoadingMessage.empty());
    this.backendService.getCampaign(
      this.campaignId,
      /* onSuccess= */ campaign => {
        this.initFormControls(campaign);
        this.campaignLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.campaignLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_point_of_service'));
      }
    );
  }

  private initFormControls(campaign: proto.waiternow.common.ICampaignProto | null | undefined): void {
    if (!campaign) {
      return;
    }

    this.pageTitle = Util.safeString(campaign.code);

    // General
    this.generalConfigFields.code.setValue(campaign?.code);
    this.generalConfigFields.descriptionEn.setValue(Formatter.getText(campaign.description, proto.waiternow.common.Language.ENGLISH));
    this.generalConfigFields.descriptionEs.setValue(Formatter.getText(campaign.description, proto.waiternow.common.Language.SPANISH));

    if (campaign.discount && campaign.discount.fixedAmount) {
      this.generalConfigFields.discountFixedAmountInitialValue
          = ProtoUtil.moneyProtoToPickerMoney(campaign.discount.fixedAmount);
      this.generalConfigFields.discountFixedAmountCurrentValue = this.generalConfigFields.discountFixedAmountInitialValue;
    }
    if (campaign.discount && campaign.discount.percentage && campaign.discount.percentage.value) {
      this.generalConfigFields.discountPercentage.setValue(campaign.discount.percentage.value);
    }
    if (campaign.conditions) {
      for (let i = 0; i < campaign.conditions.length; i++) {
        const condition = campaign.conditions[0];
        if (condition.rule == proto.waiternow.common.CampaignProto.ConditionProto.Rule.FIRST_PURCHASE) {
          this.generalConfigFields.conditionRuleFirstPurchase.setValue(true);
        }
        else if (condition.rule == proto.waiternow.common.CampaignProto.ConditionProto.Rule.EXCLUDE_ONLINE_ORDERS) {
          this.generalConfigFields.conditionRuleExcludeOnline.setValue(true);
        }
      }
    }
    if (campaign.isActive) {
      this.generalConfigFields.isActive.setValue(true);
    }
  }

  public onDiscountFixedAmountChange(money: Money | null | undefined) {
    this.generalConfigFields.discountFixedAmountCurrentValue = money;
  }

  private createCampaign(): proto.waiternow.common.CampaignProto {
    const campaign = new proto.waiternow.common.CampaignProto();
    campaign.id = this.campaignId;
    return campaign;
  }

  private saveCampaign(
      campaign: proto.waiternow.common.CampaignProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateCampaignActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    this.backendService.updateCampaign(
      campaign,
      fieldsToRemove,
      /* onSuccess= */ () => onSuccess(),
      /* onError= */ error => onError(error)
    );
  }

  public saveGeneral(actionObserver: ActionObserver): void {
    const campaign = this.createCampaign();
    const fieldsToRemove: Array<proto.waiternow.common.UpdateCampaignActionProto.Request.RemovableField> = [];

    campaign.code = this.generalConfigFields.code.value;
    if (this.generalConfigFields.descriptionEn.value || this.generalConfigFields.descriptionEs.value) {
      campaign.description = ProtoUtil.createTextProto(this.generalConfigFields.descriptionEn.value, this.generalConfigFields.descriptionEs.value);
    } else {
      fieldsToRemove.push(proto.waiternow.common.UpdateCampaignActionProto.Request.RemovableField.DESCRIPTION);
    }

    let isDiscountEmpty = true;
    if (this.generalConfigFields.discountFixedAmountCurrentValue
        && (this.generalConfigFields.discountFixedAmountCurrentValue.units > 0 || this.generalConfigFields.discountFixedAmountCurrentValue.cents > 0)) {
      campaign.discount = new proto.waiternow.common.DerivedQuantityConfigProto();
      campaign.discount.fixedAmount = ProtoUtil.pickerMoneyToMoneyProto(this.generalConfigFields.discountFixedAmountCurrentValue)
      isDiscountEmpty = false;
    }
    if(this.generalConfigFields.discountPercentage.value) {
      if (!campaign.discount) {
        campaign.discount = new proto.waiternow.common.DerivedQuantityConfigProto();
      }
      campaign.discount.percentage = new proto.waiternow.common.DecimalProto();
      campaign.discount.percentage.value = this.generalConfigFields.discountPercentage.value.toString();
      isDiscountEmpty = false;
    }
    if (isDiscountEmpty) {
      fieldsToRemove.push(proto.waiternow.common.UpdateCampaignActionProto.Request.RemovableField.DISCOUNT);
    }

    const conditions: Array<proto.waiternow.common.CampaignProto.ConditionProto> = [];
    if (this.generalConfigFields.conditionRuleFirstPurchase.value) {
      const condition = new proto.waiternow.common.CampaignProto.ConditionProto();
      condition.rule = proto.waiternow.common.CampaignProto.ConditionProto.Rule.FIRST_PURCHASE;
      conditions.push(condition);
    }
    if (this.generalConfigFields.conditionRuleExcludeOnline.value) {
      const condition = new proto.waiternow.common.CampaignProto.ConditionProto();
      condition.rule = proto.waiternow.common.CampaignProto.ConditionProto.Rule.EXCLUDE_ONLINE_ORDERS;
      conditions.push(condition);
    }
    if (conditions.length > 0) {
      campaign.conditions = conditions;
    } else {
      fieldsToRemove.push(proto.waiternow.common.UpdateCampaignActionProto.Request.RemovableField.CONDITIONS);
    }

    if (this.generalConfigFields.isActive.value) {
      campaign.isActive = true;
    } else {
      fieldsToRemove.push(proto.waiternow.common.UpdateCampaignActionProto.Request.RemovableField.IS_ACTIVE);
    }

    this.saveCampaign(
      campaign,
      fieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_campaign'));
      }
    );
  }
}
