<table>
    <tr>
        <td>
            <table width="100%">
                <tr>
                    <td class="vertical-align-middle">
                        <img class="logo" src="../../assets/img/logo.png"/>
                    </td>
                    <td align="right" class="vertical-align-middle">
                        <button mat-icon-button (click)="toggleSidebar()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <table>
            <tr>
                <td>
                    <button mat-flat-button (click)="goToHomePage()">
                        <mat-icon>home</mat-icon>
                        {{ 'home' | translate }}
                    </button>
                </td>
            </tr>
            <tr>
                <td>
                    <button mat-flat-button (click)="goToCreateUserPage()">
                        <mat-icon>person</mat-icon>
                        {{ 'create_user' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getIsAdminUser()">
                <td>
                    <button mat-flat-button (click)="goToMetricsPage()">
                        <mat-icon>bar_chart</mat-icon>
                        {{ 'metrics' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getIsAdminUser()">
                <td>
                    <button mat-flat-button (click)="goToAdminUserPage()">
                        <mat-icon>admin_panel_settings</mat-icon>
                        {{ 'admin_user' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getIsAdminUser()">
                <td>
                    <button mat-flat-button (click)="goToDebugPage()">
                        <mat-icon>code</mat-icon>
                        {{ 'debug' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getIsAdminUser()">
                <td>
                    <button mat-flat-button (click)="goToUnackedAndPaidOrdersPage()">
                        <mat-icon>list</mat-icon>
                        {{ 'unacked_and_paid_orders' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getIsAdminUser()">
                <td>
                    <button mat-flat-button (click)="goToDisputedOrdersPage()">
                        <mat-icon>playlist_remove</mat-icon>
                        {{ 'disputed_orders' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getIsAdminUser()">
                <td>
                    <button mat-flat-button (click)="goToDeviceOrdersPage()">
                        <mat-icon>smartphone</mat-icon>
                        {{ 'device_orders' | translate }}
                    </button>
                </td>
            </tr>
            <tr>
                <td>
                    <button mat-flat-button (click)="goToMenuEditor()">
                        <mat-icon>menu</mat-icon>
                        {{ 'menu_editor' | translate }}
                    </button>
                </td>
            </tr>
            <tr>
                <td>
                    <button mat-flat-button (click)="logout()">
                        <mat-icon>logout</mat-icon>
                        {{ 'sign_out' | translate }}
                    </button>
                </td>
            </tr>
        </table>
    </tr>
</table>
