<table appFieldContainer>
    <tr>
        <td>
            <app-title>
                {{ 'sign_in' | translate }}
            </app-title>
        </td>
    </tr>
    <tr>
        <td class="error-color" *ngIf="errorMessage" align="center">
            {{ errorMessage }}
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field appField>
                <mat-label>{{ 'email' | translate }}</mat-label>
                <input matInput placeholder="email@example.com" [formControl]="emailFormControl" required>
                <mat-error *ngIf="emailFormControl.invalid">{{formService.getFieldErrorMessage(emailFormControl)}}</mat-error>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            <mat-form-field appField>
                <mat-label>{{ 'password' | translate }}</mat-label>
                <input matInput [type]="hidePassword ? 'password' : 'text'" [formControl]="passwordFormControl" required (keyup.enter)="signIn()">
                <app-action matSuffix [icon]="hidePassword ? 'visibility_off' : 'visibility'" (onExecute)="hidePassword = !hidePassword">
                </app-action>
                <mat-error *ngIf="passwordFormControl.invalid">{{formService.getFieldErrorMessage(passwordFormControl)}}</mat-error>
            </mat-form-field>
        </td>
    </tr>
    <tr>
        <td>
            <mat-checkbox [formControl]="rememberMeFormControl">{{ 'remember_me' | translate }}</mat-checkbox>
        </td>
    </tr>
    <tr>
        <td align="center">
            <app-action [text]="'submit' | translate" [disabled]="emailFormControl.invalid || passwordFormControl.invalid" (onExecute)="signIn()">
            </app-action>
        </td>
    </tr>
</table>
