import { Util } from 'src/app/general/util/util';
import { Formatter } from 'src/app/specific/util/formatter';
import { ProtoUtil } from 'src/app/specific/util/proto-util';
import * as proto from 'src/proto/compiled-protos';
import { EditorCategory, EditorItem, EditorItemSelection, EditorSchedule } from './interfaces';
import { LocalizationService } from 'src/app/general/services/localization.service';

export class MenuEditorUtil {

  /**
   * Returns the text in the selected language. If the text is not defined returns the default text.
   * @param textProto
   * @returns
   */
  static getNonEmptyText(textProto: proto.waiternow.common.ITextProto | null | undefined, localizationService: LocalizationService): string {
    let str: string | null | undefined;
    if (localizationService.isSpanish()) {
      str = Formatter.getText(textProto, proto.waiternow.common.Language.SPANISH);
    }
    else {
      str = Formatter.getText(textProto, proto.waiternow.common.Language.ENGLISH);
    }
    if (!str) {
      str = textProto?.text;
    }
    return Util.safeString(str);
  }

  static getMenuEntryId(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto) {
    if (menuEntry?.menuItem) {
      return Util.safeString(menuEntry?.menuItem.id);
    } else if (menuEntry?.combo) {
      return Util.safeString(menuEntry?.combo.id);
    }
    return '';
  }

  static getMenuEntryCategoryIds(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto
    ): Array<string> {
    const menuEntryId = MenuEditorUtil.getMenuEntryId(menuEntry)
    if (menuEntry.menuItem) {
      const menuItemSpec = MenuEditorUtil.findMenuItemSpec(menuEntryId, menuSpec);
      if (!menuItemSpec) {
        return [];
      }
      return menuItemSpec.categoryIds || [];
    } else {
      const comboSpec = MenuEditorUtil.findComboSpec(menuEntryId, menuSpec);
      if (!comboSpec) {
        return [];
      }
      return comboSpec.categoryIds || [];
    }
  }

  static getMenuEntryRemovableIngredients(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto
    ): Array<proto.waiternow.common.StructuredMenuProto.IItemProto> {
    if (menuEntry?.menuItem?.removableIngredients) {
      return menuEntry?.menuItem?.removableIngredients;
    }
    return [];
  }

  static findCategorySpec(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ) : proto.waiternow.common.StructuredMenuSpecProto.IMenuItemProto | undefined {
    for (const categorySpec of menuSpec.categories || []) {
      if (categorySpec.id == id) {
        return categorySpec;
      }
    }
    return undefined;
  }

  static findMenuItemSpec(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ) : proto.waiternow.common.StructuredMenuSpecProto.IMenuItemProto | undefined {
    for (const menuItemSpec of menuSpec.menuItems || []) {
      if (menuItemSpec.id == id) {
        return menuItemSpec;
      }
    }
    return undefined;
  }

  static findComboSpec(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ) : proto.waiternow.common.StructuredMenuSpecProto.IComboProto | undefined {
    for (const comboSpec of menuSpec.combos || []) {
      if (comboSpec.id == id) {
        return comboSpec;
      }
    }
    return undefined;
  }

  static findItemSelectionSpec(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ): proto.waiternow.common.StructuredMenuSpecProto.IItemSelectionProto | undefined {
    for (const itemSelectionSpec of menuSpec.itemSelections || []) {
      if (itemSelectionSpec.id == id) {
        return itemSelectionSpec;
      }
    }
    return undefined;
  }

  static findItemSpec(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ): proto.waiternow.common.StructuredMenuSpecProto.IMenuItemProto | undefined {
    for (const itemSpec of menuSpec.items || []) {
      if (itemSpec.id == id) {
        return itemSpec;
      }
    }
    return undefined;
  }

  static findAvailabilityScheduleSpec(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ) : proto.waiternow.common.IAvailabilityScheduleProto | undefined {
    for (const availabilitySchedule of menuSpec.schedules || []) {
      if (availabilitySchedule.id == id) {
        return availabilitySchedule;
      }
    }
    return undefined;
  }

  static findCategorySpecIndex(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ): number {
    let index = 0;
    for (const categorySpec of menuSpec.categories || []) {
      if (categorySpec.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findMenuItemSpecIndex(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ): number {
    let index = 0;
    for (const menuItemSpec of menuSpec.menuItems || []) {
      if (menuItemSpec.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findComboSpecIndex(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ): number {
    let index = 0;
    for (const comboSpec of menuSpec.combos || []) {
      if (comboSpec.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findItemSelectionSpecIndex(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ): number {
    let index = 0;
    for (const itemSelectionSpec of menuSpec.itemSelections || []) {
      if (itemSelectionSpec.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findItemSelectionSpecIndexInMenuItemSpec(
      id: string | null | undefined,
      menuItemSpec: proto.waiternow.common.StructuredMenuSpecProto.IMenuItemProto,
    ): number {
    let index = 0;
    for (const itemSelectionSpecId of menuItemSpec.itemSelectionIds || []) {
      if (itemSelectionSpecId == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findItemSpecIndex(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
    ): number {
    let index = 0;
    for (const itemSpec of menuSpec.items || []) {
      if (itemSpec.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findItemSpecIndexInItemSelectionSpec(
      id: string | null | undefined,
      itemSelectionSpec: proto.waiternow.common.StructuredMenuSpecProto.IItemSelectionProto
    ): number {
    let index = 0;
    for (const itemSpecId of itemSelectionSpec.itemIds || []) {
      if (itemSpecId == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findItemSpecIndexInRemovableIngredients(
      id: string | null | undefined,
      menuItemSpec: proto.waiternow.common.StructuredMenuSpecProto.MenuItemProto
    ): number {
    let index = 0;
    for (const itemSpecId of menuItemSpec.removableIngredientItemIds || []) {
      if (itemSpecId == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findAvailabilityScheduleSpecIndex(
      id: string | null | undefined,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
      ) : number {
    let index = 0;
    for (const availabilitySchedule of menuSpec.schedules || []) {
      if (availabilitySchedule.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findCategoryIndex(
      id: string | null | undefined,
      menu: proto.waiternow.common.IStructuredMenuProto): number {
    let index = 0;
    for (const category of menu.categories || []) {
      if (category.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findMenuEntryIndex(
      id: string | null | undefined,
      category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto
    ): number {
    let index = 0;
    for (const menuEntry of category.menuEntries || []) {
      if (MenuEditorUtil.getMenuEntryId(menuEntry) == id) {
          return index;
      }
      index++;
    }
    return -1;
  }

  static findItemSelectionIndex(
      id: string | null | undefined,
      menuItem: proto.waiternow.common.StructuredMenuProto.IMenuItemProto
    ): number {
    let index = 0;
    for (const itemSelection of menuItem.itemSelections || []) {
      if (itemSelection.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findItemIndexInItemSelection(
      id: string | null | undefined,
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto
    ): number {
    let index = 0;
    for (const item of itemSelection.items || []) {
      if (item.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static findItemIndexInRemovableIngredients(
      id: string | null | undefined,
      menuItem: proto.waiternow.common.StructuredMenuProto.IMenuItemProto
    ): number {
    let index = 0;
    for (const item of menuItem.removableIngredients || []) {
      if (item.id == id) {
        return index;
      }
      index++;
    }
    return -1;
  }

  static categorySpecToEditorCategory(categorySpec: proto.waiternow.common.StructuredMenuSpecProto.ICategoryProto): EditorCategory {
    return {
      id: Util.safeString(categorySpec.id),
      nameEn: Formatter.getText(categorySpec.name, proto.waiternow.common.Language.ENGLISH),
      nameEs: Formatter.getText(categorySpec.name, proto.waiternow.common.Language.SPANISH),
    };
  }

  static categoryToEditorCategory(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): EditorCategory {
    return {
      id: Util.safeString(category.id),
      nameEn: Formatter.getText(category.name, proto.waiternow.common.Language.ENGLISH),
      nameEs: Formatter.getText(category.name, proto.waiternow.common.Language.SPANISH),
    };
  }

  static itemSelectionSpecToEditorItemSelection(
      itemSelectionSpec: proto.waiternow.common.StructuredMenuSpecProto.IItemSelectionProto,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto): EditorItemSelection {
    return {
      id: Util.safeString(itemSelectionSpec.id),
      titleEn: Formatter.getText(itemSelectionSpec.title, proto.waiternow.common.Language.ENGLISH),
      titleEs: Formatter.getText(itemSelectionSpec.title, proto.waiternow.common.Language.SPANISH),
      isRequired: itemSelectionSpec.isRequired ? true : false,
      isMultipleSelection: itemSelectionSpec.selectionType == proto.waiternow.common.SelectionType.MULTIPLE ? true : false,
      maxSelections: itemSelectionSpec.maxSelections ? itemSelectionSpec.maxSelections : undefined,
      items: MenuEditorUtil.getItemSelectionSpecItemsAsEditorItems(itemSelectionSpec, menuSpec),
    };
  }

  static itemSelectionToEditorItemSelection(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): EditorItemSelection {
    return {
      id: Util.safeString(itemSelection.id),
      titleEn: Formatter.getText(itemSelection.title, proto.waiternow.common.Language.ENGLISH),
      titleEs: Formatter.getText(itemSelection.title, proto.waiternow.common.Language.SPANISH),
      isRequired: itemSelection.isRequired ? true : false,
      isMultipleSelection: itemSelection.selectionType == proto.waiternow.common.SelectionType.MULTIPLE ? true : false,
      maxSelections: itemSelection.maxSelections ? itemSelection.maxSelections : undefined,
      items: MenuEditorUtil.getItemSelectionItemsAsEditorItems(itemSelection),
    };
  }

  static editorItemSelectionToItemSelectionSpec(editorItemSelection: EditorItemSelection): proto.waiternow.common.StructuredMenuSpecProto.IItemSelectionProto {
    const itemSelectonSpec = new proto.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto();
    itemSelectonSpec.id = Util.safeString(editorItemSelection.id);
    itemSelectonSpec.title = ProtoUtil.createTextProto(editorItemSelection.titleEn, editorItemSelection.titleEs);
    itemSelectonSpec.isRequired = editorItemSelection.isRequired ? editorItemSelection.isRequired : false;
    itemSelectonSpec.selectionType = editorItemSelection.isMultipleSelection ? proto.waiternow.common.SelectionType.MULTIPLE : proto.waiternow.common.SelectionType.SINGLE;
    itemSelectonSpec.maxSelections = editorItemSelection.isMultipleSelection && editorItemSelection.maxSelections ? editorItemSelection.maxSelections : 0;
    // Items ids are not copied because they may be new items wothout id. This is done by the menu editor.
    itemSelectonSpec.itemIds = [];
    return itemSelectonSpec;
  }

  static editorItemSelectionToItemSelection(editorItemSelection: EditorItemSelection): proto.waiternow.common.StructuredMenuProto.IItemSelectionProto {
    const itemSelecton = new proto.waiternow.common.StructuredMenuProto.ItemSelectionProto();
    itemSelecton.id = Util.safeString(editorItemSelection.id);
    itemSelecton.title = ProtoUtil.createTextProto(editorItemSelection.titleEn, editorItemSelection.titleEs);
    itemSelecton.isRequired = editorItemSelection.isRequired ? editorItemSelection.isRequired : false;
    itemSelecton.selectionType = editorItemSelection.isMultipleSelection ? proto.waiternow.common.SelectionType.MULTIPLE : proto.waiternow.common.SelectionType.SINGLE;
    itemSelecton.maxSelections = editorItemSelection.isMultipleSelection && editorItemSelection.maxSelections ? editorItemSelection.maxSelections : 0;
    // Items ids are not copied because they may be new items wothout id. This is done by the menu editor.
    itemSelecton.items = [];
    return itemSelecton;
  }

  static itemSpecToEditorItem(itemSpec: proto.waiternow.common.StructuredMenuSpecProto.IItemProto): EditorItem {
    return {
      id: Util.safeString(itemSpec.id),
      nameEn: Formatter.getText(itemSpec.name, proto.waiternow.common.Language.ENGLISH),
      nameEs: Formatter.getText(itemSpec.name, proto.waiternow.common.Language.SPANISH),
      price: itemSpec.price ? ProtoUtil.moneyProtoToPickerMoney(itemSpec.price) : undefined,
    };
  }

  static itemToEditorItem(item: proto.waiternow.common.StructuredMenuProto.IItemProto): EditorItem {
    return {
      id: Util.safeString(item.id),
      nameEn: Formatter.getText(item.name, proto.waiternow.common.Language.ENGLISH),
      nameEs: Formatter.getText(item.name, proto.waiternow.common.Language.SPANISH),
      price: item.price ? ProtoUtil.moneyProtoToPickerMoney(item.price) : undefined,
    }
  }

  static editorItemToItem(dialogItem: EditorItem): proto.waiternow.common.StructuredMenuProto.IItemProto {
    const item = new proto.waiternow.common.StructuredMenuProto.ItemProto();
    item.id = Util.safeString(dialogItem.id);
    item.name = ProtoUtil.createTextProto(dialogItem.nameEn, dialogItem.nameEs);
    item.price = dialogItem.price ? ProtoUtil.pickerMoneyToMoneyProto(dialogItem.price) : undefined;
    return item;
  }

  static getAllCategorySpecsAsEditorCategories(menuSpec: proto.waiternow.common.IStructuredMenuSpecProto): Array<EditorCategory> {
    const categories: Array<EditorCategory> = [];
    for (const categorySpec of menuSpec.categories || []) {
      categories.push(MenuEditorUtil.categorySpecToEditorCategory(categorySpec));
    }
    return categories;
  }

  static getAllItemSelectionSpecsAsEditorItemSelections(
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto,
      itemSelectionIdsToExclude: Array<string>): Array<EditorItemSelection> {
    const itemSelections: Array<EditorItemSelection> = [];
    for (const itemSelectionSpec of menuSpec.itemSelections || []) {
      if (!Util.arrayContains(itemSelectionSpec.id, itemSelectionIdsToExclude)) {
        itemSelections.push(MenuEditorUtil.itemSelectionSpecToEditorItemSelection(itemSelectionSpec, menuSpec));
      }
    }
    return itemSelections;
  }

  static getAllItemSpecsAsEditorItems(menuSpec: proto.waiternow.common.IStructuredMenuSpecProto): Array<EditorItem> {
    const items: Array<EditorItem> = [];
    for (const itemSpec of menuSpec.items || []) {
      items.push(MenuEditorUtil.itemSpecToEditorItem(itemSpec));
    }
    return items;
  }

  static getMenuEntryCategoriesAsEditorCategories(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto
    ): Array<EditorCategory> {
    const categories: Array<EditorCategory> = [];
    let categoryIds: Array<string> = MenuEditorUtil.getMenuEntryCategoryIds(menuEntry, menuSpec);
    for (const categoryId of categoryIds) {
      const category = MenuEditorUtil.findCategorySpec(categoryId, menuSpec);
      if (category) {
        categories.push(
          {
            id: Util.safeString(category.id),
            nameEn: Formatter.getText(category.name, proto.waiternow.common.Language.ENGLISH),
            nameEs: Formatter.getText(category.name, proto.waiternow.common.Language.SPANISH),
          }
        );
      }
    }
    return categories;
  }

  static getMenuEntryRemovableIngredientsAsEditorItems(
      menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto
    ): Array<EditorItem> {
    const dialogItems: Array<EditorItem> = [];
    const items = this.getMenuEntryRemovableIngredients(menuEntry);
    for (const item of items) {
      dialogItems.push(this.itemToEditorItem(item));
    }
    return dialogItems;
  }

  static getItemSelectionSpecItemsAsEditorItems(
      itemSelectionSpec: proto.waiternow.common.StructuredMenuSpecProto.IItemSelectionProto,
      menuSpec: proto.waiternow.common.IStructuredMenuSpecProto): Array<EditorItem> {
    const editorItems: Array<EditorItem> = [];
    for (const itemId of itemSelectionSpec.itemIds || []) {
      const itemSpec = MenuEditorUtil.findItemSpec(itemId, menuSpec);
      if (itemSpec) {
        editorItems.push(this.itemSpecToEditorItem(itemSpec));
      }
    }
    return editorItems;
  }

  static getItemSelectionItemsAsEditorItems(
      itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): Array<EditorItem> {
    const editorItems: Array<EditorItem> = [];
    for (const item of itemSelection.items || []) {
      editorItems.push(this.itemToEditorItem(item));
    }
    return editorItems;
  }

  static editorScheduleToAvailabilityScheduleProto(editorSchedule: EditorSchedule): proto.waiternow.common.IAvailabilityScheduleProto {
    const availabilityShceduleProto = new proto.waiternow.common.AvailabilityScheduleProto();
    availabilityShceduleProto.id = Util.safeString(editorSchedule.id);
    availabilityShceduleProto.schedule = new proto.waiternow.common.ScheduleProto();
    availabilityShceduleProto.schedule.dailyRecurrences = [];
    if (editorSchedule.dailyRecurrence && editorSchedule.dailyRecurrence.recurrenceType) {
      const dailyRecurrenceProto = new proto.waiternow.common.DailyRecurrenceProto();
      dailyRecurrenceProto.recurrenceType = editorSchedule.dailyRecurrence.recurrenceType;
      if (editorSchedule.dailyRecurrence.startsAtHour || editorSchedule.dailyRecurrence.endsAtHour) {
        dailyRecurrenceProto.timeRange = new proto.waiternow.common.TimeRangeProto();
        if (editorSchedule.dailyRecurrence.startsAtHour) {
          dailyRecurrenceProto.timeRange.from = new proto.waiternow.common.TimeProto();
          dailyRecurrenceProto.timeRange.from.hour = editorSchedule.dailyRecurrence.startsAtHour;
          dailyRecurrenceProto.timeRange.from.minute = editorSchedule.dailyRecurrence.startsAtMinute || 0;
        }
        if (editorSchedule.dailyRecurrence.endsAtHour) {
          dailyRecurrenceProto.timeRange.to = new proto.waiternow.common.TimeProto();
          dailyRecurrenceProto.timeRange.to.hour = editorSchedule.dailyRecurrence.endsAtHour;
          dailyRecurrenceProto.timeRange.to.minute = editorSchedule.dailyRecurrence.endsAtMinute || 0;
        }
        if (editorSchedule.isInverted) {
          availabilityShceduleProto.schedule.isInverted = true;
        }
      }
      availabilityShceduleProto.schedule.dailyRecurrences.push(dailyRecurrenceProto);
    }
    return availabilityShceduleProto;
  }

  static availabilityScheduleProtoToEditorSchedule(availabilityScheduleProto: proto.waiternow.common.IAvailabilityScheduleProto): EditorSchedule {
    let dailyRecurrenceProto: proto.waiternow.common.IDailyRecurrenceProto | undefined = undefined;
    if (availabilityScheduleProto.schedule
        && availabilityScheduleProto.schedule.dailyRecurrences
        && availabilityScheduleProto.schedule.dailyRecurrences?.length > 0) {
      dailyRecurrenceProto = availabilityScheduleProto.schedule?.dailyRecurrences[0];
    }
    const editorSchedule: EditorSchedule = {
      id: Util.safeString(availabilityScheduleProto.id),
      dailyRecurrence: {
        recurrenceType: dailyRecurrenceProto
          && dailyRecurrenceProto.recurrenceType
          ? dailyRecurrenceProto.recurrenceType
          : proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.DAILY
      }
    };
    if (dailyRecurrenceProto?.timeRange) {
      if (dailyRecurrenceProto?.timeRange.from) {
        editorSchedule.dailyRecurrence.startsAtHour = dailyRecurrenceProto?.timeRange.from.hour || 0;
        editorSchedule.dailyRecurrence.startsAtMinute = dailyRecurrenceProto?.timeRange.from.minute || 0;
      }
      if (dailyRecurrenceProto?.timeRange.to) {
        editorSchedule.dailyRecurrence.endsAtHour = dailyRecurrenceProto?.timeRange.to.hour || 0;
        editorSchedule.dailyRecurrence.endsAtMinute = dailyRecurrenceProto?.timeRange.to.minute || 0;
      }
      if (availabilityScheduleProto.schedule?.isInverted) {
        editorSchedule.isInverted = true;
      }
  }
    return editorSchedule;
  }

  static getAllSchedulesAsEditorSchedules(menuSpec: proto.waiternow.common.IStructuredMenuSpecProto): Array<EditorSchedule> {
    const schedules: Array<EditorSchedule> = [];
    for (const availabilityScheduleProto of menuSpec.schedules || []) {
      schedules.push(MenuEditorUtil.availabilityScheduleProtoToEditorSchedule(availabilityScheduleProto));
    }
    return schedules;
  }
}
