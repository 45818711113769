// To replace environment.ts with this file use (defined in angular.json):
// ng serve --configuration=development
// ng build --configuration=development
export const environment = {
  isDev: true,
  isProd: false,
  backend: {
    url: 'https://backend.waiternow.dev'
  },
};
