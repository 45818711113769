import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { ScheduleEditorDialogComponent } from './schedule-editor-dialog.component';
import { ScheduleEditorDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateSchedule(schedule: proto.waiternow.common.StructuredMenuProto.IScheduleLevel1Proto): void {
//   const inputDialogData: ScheduleEditorDialogData = {
//     schedule: {
//       recurreceType: proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.DAILY,
//     }
//   }
//   this.scheduleEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class ScheduleEditorDialogService {

  constructor(private dialog: MatDialog) { }

  public openEditor(inputDialogData: ScheduleEditorDialogData, onUpdate: Consumer<ScheduleEditorDialogData>) : CloseDialog {
    const dialogRef = this.dialog.open(ScheduleEditorDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData) {
        onUpdate(outputDialogData);
      }
    });

    return () => dialogRef.close();
  }
}
