<div class="centered-content-container">
    <div class="centered-content-header">
        <app-header></app-header>
    </div>
    <div class="centered-content">
        <ng-content></ng-content>
    </div>
    <div class="centered-content-footer">
        <app-footer></app-footer>
    </div>
</div>
