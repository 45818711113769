import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { OrderToolbarModel } from '../../model/order-toolbar-model';
import { SessionService } from '../../services/session.service';
import { ToolbarComponent } from 'src/app/general/components/toolbar/toolbar.component';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import { DisputeDialogService } from '../dispute-dialog/dispute-dialog.service';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-order [orderId]="orderId" *ngIf="orderId"></app-order>

// <app-order [order]="order" *ngIf="order"></app-order>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LoadableContentComponent,
    TitleComponent,
    ToolbarComponent
  ],
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent {
  @Input() orderId?: string;
  @Input() orderInput?: proto.waiternow.common.IOrderProto;

  order: proto.waiternow.common.IOrderProto;

  orderLoadingController: LoadingController;

  orderToolbarModel: OrderToolbarModel;

  constructor(
      private backendService: BackendService,
      private navigationService: NavigationService,
      private sessionService: SessionService,
      private dialogService: DialogService,
      private disputeDialogService: DisputeDialogService,
      private translateService: TranslateService,
      private toastService: ToastService) {
    this.order = new proto.waiternow.common.OrderProto();
    this.orderToolbarModel = new OrderToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.disputeDialogService,
      this.toastService,
      /* compactForMobile= */ false
    );
    this.orderLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.orderInput) {
      this.order = this.orderInput;
    } else {
      this.loadOrder();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('orderId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('orderInput', changes)) {
      this.ngOnInit();
    }
  }

  public loadOrder(): void {
    if (!this.orderId) {
      return;
    }
    this.orderLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_order'));
    this.backendService.getOrder(
      this.orderId,
      /* onSuccess= */ order => {
        if (order) {
          this.order = order;
        }
        this.orderLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.orderLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_order'));
      }
    );
  }
}
