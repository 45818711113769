<!-- <h2 mat-dialog-title>{{ 'category' | translate }}</h2> -->
<div mat-dialog-content>
    <div>
        {{ 'item_resused' | translate: {item: getNonEmptyText(data.itemName)} }}
    </div>
    <app-data-table [data]="data.reusedInItemSelections" [columns]="itemSelectionsColumns">
    </app-data-table>
    <app-data-table [data]="data.reusedInRemovableIngredientsOfMenuItems" [columns]="menuItemsColumns">
    </app-data-table>
</div>
<div mat-dialog-actions align="end">
    <!-- <button mat-button [mat-dialog-close]="data">{{ 'ok' | translate }}</button> -->
    <button mat-button (click)="closeDialog()">{{ 'close' | translate }}</button>
</div>
