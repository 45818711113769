import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ToolbarComponent } from 'src/app/general/components/toolbar/toolbar.component';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { PointOfServiceToolbarModel } from '../../model/point-of-service-toolbar-model';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-point-of-service [pointOfServiceId]="pointOfServiceId" *ngIf="pointOfServiceId"></app-point-of-service>

// <app-point-of-service [pointOfService]="pointOfService" *ngIf="pointOfService"></app-point-of-service>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-point-of-service',
  standalone: true,
  imports: [
    CommonModule,
    CommonModule,
    TranslateModule,
    LoadableContentComponent,
    TitleComponent,
    ToolbarComponent,
    ActionComponent
  ],
  templateUrl: './point-of-service.component.html',
  styleUrls: ['./point-of-service.component.css']
})
export class PointOfServiceComponent implements OnInit, OnChanges {
  @Input() pointOfServiceId?: string;
  @Input() pointOfServiceInput?: proto.waiternow.common.IPointOfServiceProto;

  pointOfService: proto.waiternow.common.IPointOfServiceProto;
  pointOfServiceToolbarModel: PointOfServiceToolbarModel;
  pointOfServiceLoadingController: LoadingController;

  constructor(
      private backendService: BackendService,
      public navigationService: NavigationService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private toastService: ToastService,
      public sessionService: SessionService) {
    this.pointOfService = new proto.waiternow.common.PointOfServiceProto();
    this.pointOfServiceToolbarModel = new PointOfServiceToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.toastService,
      /* compactForMobile= */ false,
      /* onLocationDeleted= */ pointOfServiceId => this.navigationService.goToHomePage()
    );
    this.pointOfServiceLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.pointOfServiceInput) {
      this.pointOfService = this.pointOfServiceInput;
    } else {
      this.loadPointOfService();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('pointOfServiceId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('pointOfServiceInput', changes)) {
      this.ngOnInit();
    }
  }

  public loadPointOfService(): void {
    if (!this.pointOfServiceId) {
      return;
    }
    this.pointOfServiceLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_point_of_service'));
    this.backendService.getPointOfService(
      this.pointOfServiceId,
      /* onSuccess= */ pointOfService => {
        if (pointOfService) {
          this.pointOfService = pointOfService;
        }
        this.pointOfServiceLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.pointOfServiceLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_point_of_service'));
      }
    );
  }
}
