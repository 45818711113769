import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableColumn, CompactTextColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { Formatter } from '../../util/formatter';
import { CampaignToolbarModel } from '../../model/campaign-toolbar-model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { NavigationService } from '../../services/navigation.service';
import { MatCardModule } from '@angular/material/card';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import * as proto from 'src/proto/compiled-protos';
import { BusinessOrLocationId } from '../../util/util';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-campaigns [businessId]="businessId"></app-campaigns>

// <app-campaigns [locationId]="locationId"></app-campaigns>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-campaigns',
  standalone: true,
  imports: [
    CommonModule,
    LoadableContentComponent,
    TranslateModule,
    MatCardModule,
    DataTableComponent
  ],
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.css']
})
export class CampaignsComponent {
  @Input() businessId!: string;
  @Input() locationId!: string;

  campaignsLoadingController: LoadingController;

  campaigns: Array<proto.waiternow.common.ICampaignProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.ICampaignProto>>;

  campaignToolbarModel: CampaignToolbarModel;

  constructor(
      private backendService: BackendService,
      private sessionService: SessionService,
      private toastService: ToastService,
      private translateService: TranslateService,
      private navigationService: NavigationService,
      private dialogService: DialogService) {
    this.campaignsLoadingController = new LoadingController();

    this.campaigns = new Array();
    this.columns = [
      new CompactTextColumn(
        /* name= */ 'id',
        /* translateId= */ 'id',
        /* valueExtractor= */  campaign => campaign.id,
        /* compactTextLength= */ 6),
      new StringColumn(
        /* name= */ 'code',
        /* translateId= */ 'code',
        /* valueExtractor= */  campaign => campaign.code),
      new StringColumn(
        /* name= */ 'description_en',
        /* translateId= */ 'description_en',
        /* valueExtractor= */  campaign => Formatter.getText(campaign.description, proto.waiternow.common.Language.ENGLISH),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'description_es',
        /* translateId= */ 'description_es',
        /* valueExtractor= */  campaign => Formatter.getText(campaign.description, proto.waiternow.common.Language.SPANISH),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'discount',
        /* translateId= */ 'discount',
        /* valueExtractor= */  campaign => Formatter.formatDerivedQuantityConfig(campaign.discount),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'conditions',
        /* translateId= */ 'conditions',
        /* valueExtractor= */  campaign => Formatter.formatCampaignConditions(campaign.conditions),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'is_active',
        /* translateId= */ 'is_active',
        /* valueExtractor= */  campaign => campaign.isActive ? 'True' : 'False',
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'lastUpdateTime',
        /* translateId= */ 'last_update',
        /* valueExtractor= */  campaign => Formatter.formatTimestampProto(campaign.lastUpdateTime), // TODO: add other languages
        /* hideInCompactMode= */ true)
      ];

      this.campaignToolbarModel = new CampaignToolbarModel(
        this.sessionService,
        this.backendService,
        this.navigationService,
        this.translateService,
        this.dialogService,
        this.toastService,
        /* compactForMobile= */ true,
        /* onLocationDeleted= */ campaignId => this.loadCampaigns()
      );
    }

  ngOnInit(): void {
    this.loadCampaigns();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('businessId', changes)
        || ComponentUtil.bindingChanged('locationId', changes)) {
      this.ngOnInit();
    }
  }

  public loadCampaigns(): void {
    this.campaignsLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_campaigns'));
    this.backendService.findCampaigns(
      // businessId and locationId are mutually exclusive, only one is set
      new BusinessOrLocationId(this.businessId, this.locationId),
      /* onSuccess= */ campaignsProto => {
        this.campaignsLoadingController.onSuccess();
        if (campaignsProto && campaignsProto?.campaigns) {
          this.campaigns = campaignsProto?.campaigns;
        } else {
          this.campaigns = [];
        }
      },
      /* onError= */ error => {
        this.campaignsLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_campaigns'));
      }
    );
  }
}
