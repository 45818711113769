import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { ToolbarComponent } from 'src/app/general/components/toolbar/toolbar.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { CampaignToolbarModel } from '../../model/campaign-toolbar-model';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-campaign [campaignId]="campaignId" *ngIf="campaignId"></app-campaign>

// <app-campaign [campaign]="campaign" *ngIf="campaign"></app-campaign>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-campaign',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LoadableContentComponent,
    TitleComponent,
    ToolbarComponent,
    ActionComponent
  ],
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.css']
})
export class CampaignComponent {
  @Input() campaignId?: string;
  @Input() campaignInput?: proto.waiternow.common.ICampaignProto;

  campaign: proto.waiternow.common.ICampaignProto;
  campaignToolbarModel: CampaignToolbarModel;
  campaignLoadingController: LoadingController;

  constructor(
      private backendService: BackendService,
      public navigationService: NavigationService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private toastService: ToastService,
      public sessionService: SessionService) {
    this.campaign = new proto.waiternow.common.CampaignProto();
    this.campaignToolbarModel = new CampaignToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.toastService,
      /* compactForMobile= */ false,
      /* onLocationDeleted= */ campaignId => this.navigationService.goToHomePage()
    );
    this.campaignLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.campaignInput) {
      this.campaign = this.campaignInput;
    } else {
      this.loadCampaign();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('campaignId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('campaignInput', changes)) {
      this.ngOnInit();
    }
  }

  public loadCampaign(): void {
    if (!this.campaignId) {
      return;
    }
    this.campaignLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_campaign'));
    this.backendService.getCampaign(
      this.campaignId,
      /* onSuccess= */ campaign => {
        if (campaign) {
          this.campaign = campaign;
        }
        this.campaignLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.campaignLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_campaign'));
      }
    );
  }
}
