<div mat-dialog-content>
    <table width="400px">
        <tr>
            <td colspan="3">
                <mat-form-field style="width: 100%">
                    <mat-label>Street</mat-label>
                    <input matInput [(ngModel)]="data.street" cdkFocusInitial>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field>
                    <mat-label>City</mat-label>
                    <input matInput [(ngModel)]="data.city">
                </mat-form-field>
            </td>
            <td>
                <mat-form-field>
                    <mat-label>State</mat-label>
                    <input matInput [(ngModel)]="data.state">
                </mat-form-field>
            </td>
            <td>
                <mat-form-field>
                    <mat-label>Postal Code</mat-label>
                    <input matInput [(ngModel)]="data.postalCode">
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <mat-label>Country</mat-label>
                <mat-select [(ngModel)]="data.country">
                    <mat-option *ngFor="let country of data.countries" [value]="country">{{country}}</mat-option>
                </mat-select>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
