import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormPostAction, ToolbarAction } from './toolbar-action';
import { DeviceService } from '../../services/device.service';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from '../action/action.component';
import { ActionObserver } from '../action/action';
import { Util } from '../../util/util';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-toolbar [actions]="actions"></app-toolbar>

// <app-toolbar [subject]="mySubject" [actions]="actions"></app-toolbar>

// Compact actions, if provided, is displayed for mobile devices.

// <app-toolbar [subject]="mySubject" [actions]="actions" [compactActions]="compactActions" [resetSignal]="resetSignal"></app-toolbar>

// TS:

// // No subject
// this.actions: Array<ToolbarAction<void>> = [
//   { icon: 'home', tooltipTranslateId: 'home', onExecute: irrelevant => { console.log('Home clicked'); } },
//   {
//     textTranslateId: 'my_text',
//     isHidden: subject => isHidden(subject),
//     isDisabled: subject => isDisabled(subject),
//     onExecute: irrelevant => { console.log('Edit clicked'); }
//   },
//   {
//     icon: 'delete',
//     tooltipTranslateId: 'delete',
//     onAsyncExecute: (irrelevant: void, actionObserver: ActionObserver) => {
//       this.executeAfterDelay(3000, () => {
//         // actionObserver.onSuccess(SuccessResult.withMessage('Success message'));
//         // or
//         const error = new AppError('App error');
//         actionObserver.onError(ErrorResult.withErrorAndMessage(error, 'Error message'));
//       });
//     }
//   },
//   {
//     icon: 'plagiarism',
//     tooltipTranslateId: 'view_proto',
//     formPostActionToOpenInNewWindowProvider: location => {
//       return {
//         url: environment.backend.url + '/service/debug/proto/read/' + ProtoType.LOCATION,
//         formParams: [
//           { name: 'AuthToken', value: this.sessionService.getAuthToken() },
//           { name: 'ProtoId', value: Util.safeString(location?.id) }
//         ]
//       };
//     }
//   }
// ];
// this.compactActions: Array<ToolbarAction<void>> = [this.actions[0]];

// // With subject
// this.actions: Array<ToolbarAction<SubjectType>> = [
//   { icon: 'home', tooltipTranslateId: 'home', onExecute: subject => { console.log('Home clicked for ' + subject.id); } },
//   { icon: 'edit', tooltipTranslateId: 'edit', onExecute: subject => { console.log('Edit clicked for ' + subject.id); } },
//   {
//     icon: 'delete',
//     tooltipTranslateId: 'delete',
//     onAsyncExecute: (subject: SubjectType | undefined, actionObserver: ActionObserver) => {
//       this.executeAfterDelay(3000, () => {
//         // actionObserver.onSuccess(SuccessResult.withMessage('Success message'));
//         // or
//         const error = new AppError('App error');
//         actionObserver.onError(ErrorResult.withErrorAndMessage(error, 'Error message'));
//       });
//     }
//   }
// ];
// this.compactActions: Array<ToolbarAction<SubjectType>> = [this.actions[0]];

// async executeAfterDelay(delayMilis: number, task: () => void): void {
//   await new Promise(resolve => setTimeout(resolve, delayMilis)).then(task);
// }

// The reset signal is optional, it is used to reset the success/error messages for async actions.
// resetSignal: number;

// constructor() {
//   this.resetSignal = 0;
// }

// // Change the value of ths resetSignal when you want the action component to reset.
// this.resetSignal++;

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ActionComponent
  ],
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent<T> {
  // The subject is part of the toolbar for cases where the tool bar is par of an *ngFor
  // (tables with a tool bar for each row). *ngFor won't create instances of interfaces
  // (like Runnable), it is better to call toolbarAction.execute(subject) than
  // toolbarAction.execute() (and encapsulate the subject in the Runnable).
  @Input() subject?: T;
  @Input() actions!: Array<ToolbarAction<T>>;
  @Input() compactActions?: Array<ToolbarAction<T>>;
  @Input() resetSignal?: number;

  constructor(
      public deviceService: DeviceService) {
  }

  public isHiddenAction(action: ToolbarAction<T>): boolean {
    if (action.isHidden) {
      action.isHidden(this.subject);
    }
    return false;
  }

  public isDisabledAction(action: ToolbarAction<T>): boolean {
    if (action.isDisabled) {
      action.isDisabled(this.subject);
    }
    return false;
  }

  public isExecuteAction(action: ToolbarAction<T>): boolean {
    if (action.onExecute) {
      return true;
    }
    return false;
  }

  public isAsyncExecuteAction(action: ToolbarAction<T>): boolean {
    if (action.onAsyncExecute) {
      return true;
    }
    return false;
  }

  public isFormPostActionToOpenInNewWindow(action: ToolbarAction<T>): boolean {
    if (action.formPostActionToOpenInNewWindowProvider) {
      return true;
    }
    return false;
  }

  public executeAction(action: ToolbarAction<T>): void {
    if (action.onExecute) {
      action.onExecute(this.subject);
    }
  }

  public executeAsyncAction(action: ToolbarAction<T>, observer: ActionObserver): void {
    if (action.onAsyncExecute) {
      action.onAsyncExecute(this.subject, observer);
    }
  }

  public getFormPostAction(action: ToolbarAction<T>): FormPostAction {
    if (action.formPostActionToOpenInNewWindowProvider) {
      return action.formPostActionToOpenInNewWindowProvider(this.subject);
    }
    return {url: '', formParams: []};
  }

  public toSafeString(str: string | null | undefined): string {
    return Util.safeString(str);
  }
 }
