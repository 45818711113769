<!-- <h1 mat-dialog-title></h1> -->
<div mat-dialog-content>
    $
    <mat-form-field style="width: 100px">
        <mat-label>Units</mat-label>
        <input matInput type="number" [(ngModel)]="data.units" cdkFocusInitial min="0" step="1" pattern="[0-9]">
    </mat-form-field>
    .
    <mat-form-field style="width: 80px">
        <mat-label>Cents</mat-label>
        <input matInput type="number" [(ngModel)]="data.cents" min="0" max="99" step="1" pattern="[0-9]">
    </mat-form-field>
    &nbsp;
    <mat-form-field style="width: 120px">
        <mat-label>Currency</mat-label>
        <mat-select [(ngModel)]="data.currencyCode">
            <mat-option *ngFor="let currency of data.currencies" [value]="currency">{{currency}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
