import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { OrderComponent } from '../../components/order/order.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { Util } from 'src/app/general/util/util';

@Component({
  selector: 'app-order-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    OrderComponent
  ],
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css']
})
export class OrderPageComponent {
  orderId: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute) {
    this.orderId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.orderId = Util.safeString(pathParams.get('orderId'));
  }
}
