<!-- https://material.angular.io/components/sidenav/examples -->
<mat-drawer-container autosize class="app-container">
  <!-- modes: side, over, push -->
  <mat-drawer #drawerRef class="sidebar" [mode]="deviceService.ifMobile('over').orElse('side')">
      <app-sidebar-content></app-sidebar-content>
  </mat-drawer>
  <div class="app-content">
      <!-- Components defined at routes.ts are placed here -->
      <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
