import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { SelectItemSelectionDialogData, EditorSchedule, ScheduleEditorDialogData, ItemSelectionEditorDialogData, EditorItemSelection } from '../util/interfaces';
import { MenuEditorUtil } from '../util/menu-editor-util';
import { ItemSelectionEditorDialogService } from '../item-selection-editor-dialog/item-selection-editor-dialog.service';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public selecItemSelection(): void {
//   const inputDialogData: SelecItemSelectionDialogData = {
//   }
//   this.selectItemSelectionDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-select-item-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MatSelectModule,
    ActionComponent
  ],
  templateUrl: './select-item-selection-dialog.component.html',
  styleUrls: ['./select-item-selection-dialog.component.css']
})
export class SelectItemSelectionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SelectItemSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectItemSelectionDialogData,
    private localizationService: LocalizationService,
    private itemSelectionEditorDialogService: ItemSelectionEditorDialogService) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public getText(itemSelection: EditorItemSelection): string {
    let text = '';
    if (this.localizationService.isSpanish() && itemSelection.titleEs) {
      text = itemSelection.titleEs;
    }
    if (!text && itemSelection.titleEn) {
      text = itemSelection.titleEn;
    }
    return text;
  }

  public isValidItemSelection(): boolean {
    return true;
  }

  public createItemSelection(): void {
    const inputDialogData: ItemSelectionEditorDialogData = {
      itemSelection: { },
      allowChaningItems: true,
      allItems: MenuEditorUtil.getAllItemSpecsAsEditorItems(this.data.menuSpec),
      menuSpec: this.data.menuSpec
    }
    this.itemSelectionEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        this.data.itemSelection = outputDialogData.itemSelection;
        this.data.allItemSelections?.push(outputDialogData.itemSelection);
      }
    );
  }
}
