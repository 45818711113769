export enum AuthenticationStatus {
    USER_ALREADY_AUTHENTICATED = 1,
    USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE = 2
}

export class BusinessOrLocationId {
  businessId?: string;
  locationId?: string;

  constructor(businessId?: string, locationId?: string) {
      this.businessId = businessId;
      this.locationId = locationId;
  }

  static forBusiness(businessId: string): BusinessOrLocationId {
      return new BusinessOrLocationId(businessId, undefined);
  }

  static forLocation(locationId: string): BusinessOrLocationId {
    return new BusinessOrLocationId(undefined, locationId);
  }
}
