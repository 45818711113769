import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarService } from '../../services/sidebar.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';
import { SessionService } from '../../services/session.service';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { VersionService } from '../../services/version.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-header></app-header>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ActionComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../../../styles.css']
})
export class HeaderComponent {

  public isVersionStale?: boolean;

  constructor(
      public deviceService: DeviceService,
      public navigationService: NavigationService,
      public localizationService: LocalizationService,
      public sessionService: SessionService,
      private sidebarService: SidebarService,
      private versionService: VersionService) {
    this.versionService.getVersion(
      /* onSuccess= */ version => this.isVersionStale = version.isVersionStale,
      /* onError= */ error => {});
  }

  public toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  logout() {
    this.sessionService.logout();
    this.navigationService.goToSignInPage()
  }
}
