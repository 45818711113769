import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { ItemSelectionEditorDialogComponent } from './item-selection-editor-dialog.component';
import { ItemSelectionEditorDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateItemSelection(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): void {
//   const inputDialogData: ItemSelectionEditorDialogData = {
//     itemSelection: {
//       titleEn: Formatter.getText(itemSelection.title, proto.waiternow.common.Language.ENGLISH),
//       titleEs: Formatter.getText(itemSelection.title, proto.waiternow.common.Language.SPANISH)
//     },
//     allowChaningItems: false
//   }
//   this.itemSelectionEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class ItemSelectionEditorDialogService {

  constructor(private dialog: MatDialog) { }

  public openEditor(inputDialogData: ItemSelectionEditorDialogData, onUpdate: Consumer<ItemSelectionEditorDialogData>) : CloseDialog {
    const dialogRef = this.dialog.open(ItemSelectionEditorDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData) {
        onUpdate(outputDialogData);
      }
    });

    return () => dialogRef.close();
  }
}
