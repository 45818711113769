import { Observable, map, of } from "rxjs";
import { IndeterminatePaginatorData } from "src/app/general/components/indeterminate-paginator/indeterminate-paginator-model";
import { OrdersModel } from "./orders-model";
import { BackendService } from "../../services/backend.service";
import { DataTableColumn, StringColumn } from "src/app/general/components/data-table/data-table-column";
import { Util } from "src/app/general/util/util";
import * as proto from 'src/proto/compiled-protos';

export class DeviceOrdersModel implements OrdersModel {
  deviceId: string;
  backendService: BackendService;

  constructor(deviceId: string, backendService: BackendService) {
    this.deviceId = deviceId;
    this.backendService = backendService;
  }

  fetchData(continuationToken: string | null | undefined): Observable<IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>> {
    if (!this.deviceId) {
      return of({data: new Array(), continuationToken: ''});
    }
    return this.backendService.findDeviceOrdersReturnObservable(this.deviceId, continuationToken)
      .pipe(
        map(deviceOrders => {
          const paginatorData: IndeterminatePaginatorData<proto.waiternow.common.IOrderProto> = {data: [], continuationToken: continuationToken};
          if (deviceOrders) {
            paginatorData.data = deviceOrders.orders;
            paginatorData.continuationToken = deviceOrders.continuationToken;
          }
          return paginatorData;
        })
      );
  }

  getAdditionalColumns(): Array<DataTableColumn<proto.waiternow.common.IOrderProto>> {
    return [
      new StringColumn(
        /* name= */ 'businessName',
        /* translateId= */ 'business_name',
        /* valueExtractor= */ order => Util.safeString(order.redundantData?.businessName),
        /* hideInCompactMode= */ true
      ),
      new StringColumn(
        /* name= */ 'locationName',
        /* translateId= */ 'location_name',
        /* valueExtractor= */ order => Util.safeString(order.redundantData?.locationName),
        /* hideInCompactMode= */ true
      )
    ];
  }
}
