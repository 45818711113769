<app-page>
    <app-loadable-content [loadingController]="campaignLoadingController" (onRetry)="loadUser()">
        <app-title>{{ pageTitle }}</app-title>
        <div>
            <table>
                <tr>
                    <td>
                        <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'go_to_user' | translate" [defaultStyle]=true (onExecute)="goToUserPage()"></app-action>
                    </td>
                </tr>
            </table>
        </div>
        <mat-accordion multi>
            <!-- ------------------------------------------------------------------ -->
            <!-- General config -->
            <!-- ------------------------------------------------------------------ -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'general' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div appFieldContainer>
                    <mat-form-field appField>
                        <mat-label>{{ 'name' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.name" required>
                        <mat-error *ngIf="generalConfigFields.name.invalid">{{formService.getFieldErrorMessage(generalConfigFields.name)}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appField>
                        <mat-label>{{ 'last_name' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.lastName" required>
                        <mat-error *ngIf="generalConfigFields.lastName.invalid">{{formService.getFieldErrorMessage(generalConfigFields.lastName)}}</mat-error>
                    </mat-form-field>
                    <mat-form-field appField>
                        <mat-label>{{ 'phone_number' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.phoneNumber">
                        <mat-error *ngIf="generalConfigFields.phoneNumber.invalid">{{formService.getFieldErrorMessage(generalConfigFields.phoneNumber)}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <app-action [text]="'save' | translate"
                        [disabled]="generalConfigFields.name.invalid || generalConfigFields.lastName.invalid"
                        (onAsyncExecute)="saveGeneral($event)">
                    </app-action>
                </div>
            </mat-expansion-panel>
            <!-- ------------------------------------------------------------------ -->
            <!-- Email config -->
            <!-- ------------------------------------------------------------------ -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'email' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div appFieldContainer>
                    {{ 'requires_superuser_privileges' | translate }}
                    <mat-form-field appField>
                        <mat-label>{{ 'email' | translate }}</mat-label>
                        <input matInput [formControl]="emailConfigFields.email" required>
                        <mat-error *ngIf="emailConfigFields.email.invalid">{{formService.getFieldErrorMessage(emailConfigFields.email)}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <app-action [text]="'save' | translate"
                        [disabled]="emailConfigFields.email.invalid"
                        (onAsyncExecute)="saveEmail($event)">
                    </app-action>
                </div>
            </mat-expansion-panel>
            <!-- ------------------------------------------------------------------ -->
            <!-- Password config -->
            <!-- ------------------------------------------------------------------ -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'password' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div appFieldContainer>
                    {{ 'requires_superuser_privileges' | translate }}
                    <mat-form-field appField>
                        <mat-label>{{ 'password' | translate }}</mat-label>
                        <input matInput [type]="passwordConfigFields.hidePassword ? 'password' : 'text'" [formControl]="passwordConfigFields.password" required>
                        <app-action matSuffix [icon]="passwordConfigFields.hidePassword ? 'visibility_off' : 'visibility'" (onExecute)="passwordConfigFields.hidePassword = !passwordConfigFields.hidePassword">
                        </app-action>
                        <mat-error *ngIf="passwordConfigFields.password.invalid">{{formService.getFieldErrorMessage(passwordConfigFields.password)}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <app-action [text]="'save' | translate"
                        [disabled]="passwordConfigFields.password.invalid"
                        (onAsyncExecute)="savePassword($event)">
                    </app-action>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </app-loadable-content>
</app-page>
