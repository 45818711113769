import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { ItemSelectionReusedWarningDialogData } from '../util/interfaces';
import { ItemSelectionReusedWarningDialogComponent } from './item-selection-reused-warning-dialog.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public showWarning(): void {
//   const inputDialogData: ItemSelectionReusedWarningDialogData = {
//     ...
//   }
//   this.itemSelectionReusedWarningDialogService.openDialog(inputDialogData);
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class ItemSelectionReusedWarningDialogService {

  constructor(private dialog: MatDialog) { }

  public openDialog(inputDialogData: ItemSelectionReusedWarningDialogData) : CloseDialog {
    const dialogRef = this.dialog.open(ItemSelectionReusedWarningDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    return () => dialogRef.close();
  }
}
