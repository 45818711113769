import { Function } from '../interfaces/functions';

export class Util {

  static safeString(str: string | null | undefined): string {
    if (!str) {
      return '';
    }
    return str;
  }

  static safeNumber(nullableNumber: number | null | undefined): number {
    if (!nullableNumber) {
      return 0;
    }
    return nullableNumber;
  }

  static safeArray<T>(array: Array<T> | null | undefined): Array<T> {
    if (!array) {
      return new Array();
    }
    return array;
  }

  static arrayContains<T>(element: T | null | undefined, array: Array<T> | null | undefined): boolean {
    if (!array || !element) {
      return false;
    }
    return array.indexOf(element) >= 0;
  }

  static insertIntoArray<T>(element: T, index: number, array: Array<T>) {
    array.splice(index, 0, element);
  }

  /**
   * This method can be used to replace the content of an array without losing the array instance.
   *
   * @param source array to replace items for
   * @param replaceWith new items
   */
  static replaceArrayItems<T>(source: Array<T>, replaceWith: Array<T> | null | undefined): void {
    if (!replaceWith) {
      Util.clearArray(source);
      return;
    }

    const originalSourceLength = source.length;
    for (let i = 0; i < originalSourceLength && i < replaceWith.length; i++) {
      source[i] = replaceWith[i];
    }
    // In case source was smaller than replaceWith
    for (let i = originalSourceLength; i < replaceWith.length; i++) {
      source.push(replaceWith[i]);
    }
    // In case source was greater than replaceWith
    for (let i = replaceWith.length; i < originalSourceLength; i++) {
      source.pop();
    }
  }

  /**
   * This method can be used to replace the content of an array that is part of a binding, where
   * replacing the array's instance may cause unexpected behaviors.
   *
   * @param source array to replace items for
   * @param replaceWith new items
   * @param converter converts elements of the type 'replaceWith' to elements of the type 'source'
   */
  static replaceArrayItemsWithConverter<T, E>(
      source: Array<T>, replaceWith: Array<E> | null | undefined,
      convert: Function<E, T>): void {
    if (!replaceWith) {
      Util.clearArray(source);
      return;
    }

    const originalSourceLength = source.length;
    for (let i = 0; i < originalSourceLength && i < replaceWith.length; i++) {
      source[i] = convert(replaceWith[i]);
    }
    // In case source was smaller than replaceWith
    for (let i = originalSourceLength; i < replaceWith.length; i++) {
      source.push(convert(replaceWith[i]));
    }
    // In case source was greater than replaceWith
    for (let i = replaceWith.length; i < originalSourceLength; i++) {
      source.pop();
    }
  }

  /**
   * Deletes all elements in the given array.
   *
   * @param array array to clear
   */
  static clearArray<T>(array: Array<T>): void {
    while(array.length > 0) {
      array.pop();
    }
  }

  static cloneArray<T>(array: Array<T>): Array<T> {
    const newArray: Array<T> = [];
    for (const element of array) {
      newArray.push(element);
    }
    return newArray;
  }

  /**
   * Convets an array from one item type to another.
   *
   * @param array array
   * @param convert converter
   */
  static convertArray<T, E>(
      array: Array<T> | null | undefined,
      convert: Function<T, E | null | undefined>): Array<E> {
    if (!array) {
      return new Array();
    }
    const convertedArray: Array<E> = new Array();
    for (let item of array) {
      const convertedItem = convert(item);
      if (convertedItem) {
        convertedArray.push(convertedItem);
      }
    }
    return convertedArray;
  }

  static swap<T>(index1: number, index2: number, array: Array<T>): void {
    const tmp = array[index1];
    array[index1] = array[index2];
    array[index2] = tmp;
  }
}
