<div mat-dialog-content>
    <table style="padding:10px">
        <tr *ngFor="let messageLine of data">
            <!-- &nbsp; is to have a blank line if the message contains \n\n -->
            <td>{{ messageLine }} &nbsp;</td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="closeDialog()">OK</button>
</div>
