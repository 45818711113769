import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { DeviceService } from 'src/app/general/services/device.service';
import { FormService } from 'src/app/general/services/form.service';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { ActionObserver } from 'src/app/general/components/action/action';
import { Runnable, Consumer } from 'src/app/general/interfaces/functions';
import { AppError } from 'src/app/general/util/error';
import { LoadingMessage, ErrorResult, SuccessResult } from 'src/app/general/util/result';
import { NavigationService } from '../../services/navigation.service';
import { Formatter } from '../../util/formatter';
import { ProtoUtil } from '../../util/proto-util';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-edit-user-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    LoadableContentComponent,
  ],
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.css']
})
export class EditUserPageComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  userId: string;
  pageTitle: string;
  campaignLoadingController: LoadingController;

  generalConfigFields = new (class {
    name: FormControl = new FormControl('', [Validators.required]);
    lastName: FormControl = new FormControl('', [Validators.required]);
    phoneNumber: FormControl = new FormControl('');
  })();

  emailConfigFields = new (class {
    email: FormControl = new FormControl('', [Validators.required]);
  })();

  passwordConfigFields = new (class {
    password: FormControl = new FormControl('', [Validators.required]);
    hidePassword: boolean = true;
  })();

  constructor(
      public formService: FormService,
      public deviceService: DeviceService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.userId = '';
    this.pageTitle = '';
    this.campaignLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.userId = Util.safeString(routeParams.get('userId'));
    this.loadUser();
  }

  public goToUserPage(): void {
    this.navigationService.goToUserPage(this.userId)
  }

  public loadUser(): void {
    this.campaignLoadingController.onLoadingStarted(LoadingMessage.empty());
    this.backendService.getUser(
      this.userId,
      /* onSuccess= */ user => {
        this.initFormControls(user);
        this.campaignLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.campaignLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_user'));
      }
    );
  }

  private initFormControls(user: proto.waiternow.common.IUserProto | null | undefined): void {
    if (!user) {
      return;
    }

    this.pageTitle = Util.safeString(user.name) + " " + Util.safeString(user.lastName);

    // General
    this.generalConfigFields.name.setValue(user?.name);
    this.generalConfigFields.lastName.setValue(user?.lastName);
    this.generalConfigFields.phoneNumber.setValue(Formatter.formatPhoneNumber(user?.phoneNumber));

    // Email
    this.emailConfigFields.email.setValue(user?.email);
  }

  private createUser(): proto.waiternow.common.UserProto {
    const user = new proto.waiternow.common.UserProto();
    user.id = this.userId;
    return user;
  }

  private saveUser(
      user: proto.waiternow.common.UserProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdateUserActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    this.backendService.updateUser(
      user,
      fieldsToRemove,
      /* onSuccess= */ () => onSuccess(),
      /* onError= */ error => onError(error)
    );
  }

  public saveGeneral(actionObserver: ActionObserver): void {
    const user = this.createUser();
    const fieldsToRemove: Array<proto.waiternow.common.UpdateUserActionProto.Request.RemovableField> = [];

    user.name = this.generalConfigFields.name.value;
    user.lastName = this.generalConfigFields.lastName.value;
    if (this.generalConfigFields.phoneNumber.value) {
      user.phoneNumber = ProtoUtil.createSimplePhoneNumberProto(this.generalConfigFields.phoneNumber.value);
    } else {
      fieldsToRemove.push(proto.waiternow.common.UpdateUserActionProto.Request.RemovableField.PHONE_NUMBER);
    }

    this.saveUser(
      user,
      fieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_user'));
      }
    );
  }

  public saveEmail(actionObserver: ActionObserver): void {
    this.backendService.updateUserEmail(
      this.userId,
      this.emailConfigFields.email.value,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_user'))
      }
    );
  }

  public savePassword(actionObserver: ActionObserver): void {
    this.backendService.updateUserPassword(
      this.userId,
      this.passwordConfigFields.password.value,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_user'))
      }
    );
  }
}
