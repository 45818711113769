import { HostListener, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

const MOBILE_MAX_WIDTH: number = 600;

export class OrElse {
  private mobileValue: any;
  private deviceService: DeviceService;

  constructor(mobileValue: any, deviceService: DeviceService) {
    this.mobileValue = mobileValue;
    this.deviceService = deviceService;
  }

  public orElse(notMobileValue: any) {
    if (this.deviceService.isMobile()) {
      return this.mobileValue;
    }
    return notMobileValue;
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private isMobileDevice: boolean;

  constructor(
      private deviceDetectorService: DeviceDetectorService) {
    this.isMobileDevice = this.deviceDetectorService.isMobile();
  }

  public isMobile(): boolean {
    return this.isMobileDevice;
  }

  /**
   * DeviceDetectorService from 'ngx-device-detector' does not update when the browser changes
   * from mobile to desktop mode. The page has to be refreshed. So we use the page width to
   * determine of we switch to mobile.
   *
   * However, @HostListener only works in components. If we added this implementation to a
   * BaseComponent and we make all components to extend from it, many components will be listening
   * for such event. Instead, top level components (the AppComponent for example which is run for
   * all pages) could listen for onResize events and delegate to this method to update whether the
   * device is mobile or not.
   *
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobileDevice = event.target.innerWidth <= MOBILE_MAX_WIDTH;
  }

  /**
   * Usage exampe:
   * deviceService.ifMobile('mobile-class').orElse('desktop-class');
   *
   * @param mobileValue
   * @returns
   */
  public ifMobile(mobileValue: any) : OrElse {
    return new OrElse(mobileValue, this);
  }
}
