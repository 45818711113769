import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageComponent } from '../../components/page/page.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/general/services/device.service';
import { FormService } from 'src/app/general/services/form.service';
import { BackendService } from '../../services/backend.service';
import { ProtoUtil } from '../../util/proto-util';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, SuccessResult } from "src/app/general/util/result";
import { AuthenticationStatus } from '../../util/util';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-create-user-page',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PageComponent,
    TitleComponent,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    FieldContainerDirective,
    FieldDirective,
    ReactiveFormsModule,
    ActionComponent
  ],
  templateUrl: './create-user-page.component.html',
  styleUrls: ['./create-user-page.component.css', '../../../../styles.css']
})
export class CreateUserPageComponent implements OnInit {
  hidePassword: boolean;

  emailField: FormControl = new FormControl('', [Validators.required, Validators.email]);
  emailVerificationCodeField: FormControl = new FormControl('', [Validators.required]);
  isEmailVerificationCodeValid: boolean = false;
  passwordField: FormControl = new FormControl('', [Validators.required]);
  nameField: FormControl = new FormControl('', [Validators.required]);
  lastNameField: FormControl = new FormControl('', [Validators.required]);
  phoneNumberField: FormControl = new FormControl('');
  phoneNumberVerificationCodeField: FormControl = new FormControl('');
  isPhoneNumberVerificationCodeValid: boolean = true;

  emailVerificationResetSignal: number;
  phoneNumberVerificationResetSignal: number;

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      public sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.hidePassword = true;
    this.emailVerificationResetSignal = 0;
    this.phoneNumberVerificationResetSignal = 0;
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    this.emailField.valueChanges.subscribe(() => {
      this.isEmailVerificationCodeValid = false;
      this.emailVerificationResetSignal++;
    });
    this.phoneNumberField.valueChanges.subscribe(() => {
      // Since phone number is not required, if it is empty a verification code is not required.
      this.isPhoneNumberVerificationCodeValid = this.phoneNumberField.value ? false : true;
      this.phoneNumberVerificationResetSignal++;
    });
  }

  public sendEmailVerificationCode(actionObserver: ActionObserver): void {
    this.backendService.sendEmailVerificationCode(
      this.emailField.value,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('email_verification_code_sent'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_sending_code'));
      }
    );
  }

  public validateEmailCode(actionObserver: ActionObserver): void {
    this.backendService.validateEmailCode(
      this.emailField.value,
      this.emailVerificationCodeField.value,
      /* onSuccess= */ response => {
        if (response.isVerificationCodeValid) {
          this.isEmailVerificationCodeValid = true;
          actionObserver.onSuccess(SuccessResult.withMessageTranslateId('code_validated'));
        } else {
          actionObserver.onError(ErrorResult.withMessageTranslateId('invalid_code'));
        }
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'invalid_code'));
      }
    );
  }

  public getEmailVerificationCode(actionObserver: ActionObserver): void {
    this.backendService.getEmailVerificationCode(
      this.emailField.value,
      /* onSuccess= */ verificationCode => {
        this.emailVerificationCodeField.setValue(verificationCode);
        this.isEmailVerificationCodeValid = true;
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('code_validated'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_validating_code'));
      }
    );
  }

  public sendPhoneNumberVerificationCode(actionObserver: ActionObserver): void {
    this.backendService.sendPhoneNumberVerificationCode(
      ProtoUtil.createSimplePhoneNumberProto(this.phoneNumberField.value),
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('phone_number_verification_code_sent'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_sending_code'));
      }
    );
  }

  public validatePhoneNumberCode(actionObserver: ActionObserver): void {
    this.backendService.validatePhoneNumberCode(
      ProtoUtil.createSimplePhoneNumberProto(this.phoneNumberField.value),
      this.phoneNumberVerificationCodeField.value,
      /* onSuccess= */ response => {
        if (response.isVerificationCodeValid) {
          this.isPhoneNumberVerificationCodeValid = true;
          actionObserver.onSuccess(SuccessResult.withMessageTranslateId('code_validated'));
        } else {
          actionObserver.onError(ErrorResult.withMessageTranslateId('invalid_code'));
        }
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'invalid_code'));
      }
    );
  }

  public getPhoneNumberVerificationCode(actionObserver: ActionObserver): void {
    this.backendService.getPhoneNumberVerificationCode(
      ProtoUtil.createSimplePhoneNumberProto(this.phoneNumberField.value),
      /* onSuccess= */ verificationCode => {
        this.phoneNumberVerificationCodeField.setValue(verificationCode);
        this.isPhoneNumberVerificationCodeValid = true;
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('code_validated'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_validating_code'));
      }
    );
  }

  public createUser(actionObserver: ActionObserver): void {
    const user = new proto.waiternow.common.UserProto();
    user.email = this.emailField.value;
    user.name = this.nameField.value;
    user.lastName = this.lastNameField.value;
    if (this.phoneNumberField.value) {
      user.phoneNumber = ProtoUtil.createSimplePhoneNumberProto(this.phoneNumberField.value);
    }

    this.backendService.createUser(
      user,
      this.emailVerificationCodeField.value,
      this.passwordField.value,
      this.phoneNumberVerificationCodeField.value,
      /* onSuccess= */ response => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('user_created'));
        if (response.userId) {
          this.navigationService.goToUserPage(response.userId);
        }
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_creating_user'));
      }
    );
  }
}
