import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { TranslateModule } from '@ngx-translate/core';
import { OrdersModel } from '../../components/orders/orders-model';
import { OrdersComponent } from '../../components/orders/orders.component';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { UnackedAndPaidOrdersModel } from '../../components/orders/unacked-paid-orders-model';
import { TitleComponent } from 'src/app/general/components/title/title.component';

@Component({
  selector: 'app-unacked-and-paid-orders-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    OrdersComponent,
    TitleComponent
  ],
  templateUrl: './unacked-and-paid-orders-page.component.html',
  styleUrls: ['./unacked-and-paid-orders-page.component.css']
})
export class UnackedAndPaidOrdersPageComponent {
  unackedAndPaidOrdersModel: OrdersModel;

  constructor(
      private sessionService: SessionService,
      private backendService: BackendService) {
    this.unackedAndPaidOrdersModel = new UnackedAndPaidOrdersModel(this.backendService);
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }
  }
}
