import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormService } from '../../services/form.service';
import { ComponentUtil } from '../../util/component-util';
import { TranslateModule } from '@ngx-translate/core';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <div appFieldContainer>
//   <app-address-picker
//       label="Address picker"
//       [required]="true"
//       [disabled]="false"
//       [countries]="['USA', 'MEXICO']"
//       [value]="initiaAddressValue"
//       (onValueChange)="onAddressChange($event)">
//   </app-address-picker>
// </div>

// TS:

// initiaAddressValue: Address = {street: '1600 Amphitheatre Parkway', city: 'Mountain View', state: 'CA', postalCode: '94043', country: 'USA'};
// public onAddressChange(address: Address | null | undefined) {
//   // address could be null or undefined if the user manually deleted the text from the input fields
//   console.log('Address changed: ' + address.street + ' ' + address.city + ....);
// }
// ----------------------------------------------------------------------------

export interface Address {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

interface DialogData {
  street: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  countries: Array<string>;
}

@Component({
  selector: 'app-address-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule
  ],
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.css']
})
export class AddressPickerComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() required?: boolean;
  @Input() disabled?: boolean;
  @Input() countries!: Array<string>;
  @Input() value?: Address | null | undefined;
  @Output() onValueChange: EventEmitter<Address | null | undefined> = new EventEmitter<Address | null | undefined>();

  @ViewChild('text_format_input', {read: ElementRef}) textFormatInput!: ElementRef;

  currentAddress: Address | null | undefined;

  textFormatFormControl: FormControl;

  constructor(
      public formService: FormService,
      private dialog: MatDialog) {
    this.textFormatFormControl = new FormControl('');
  }

  ngOnInit(): void {
    if (this.required) {
      this.textFormatFormControl.addValidators([Validators.required]);
    }
    if (this.disabled) {
      this.textFormatFormControl.disable();
    } else {
      this.textFormatFormControl.enable();
    }
    if (this.value) {
      this.currentAddress = this.value;
      this.textFormatFormControl.setValue(this.addressToString(this.currentAddress));
    }
    this.textFormatFormControl.valueChanges.subscribe(() => {
      this.currentAddress = this.stringToAddress(this.textFormatFormControl.value);
      this.onValueChange.emit(this.currentAddress);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('value', changes)) {
      if (this.value) {
        this.currentAddress = this.value;
        this.textFormatFormControl.setValue(this.addressToString(this.currentAddress));
      } else {
        this.currentAddress = null;
        this.textFormatFormControl.setValue('');
      }
    }
    if (ComponentUtil.bindingChanged('disabled', changes)) {
      if (this.disabled) {
        this.textFormatFormControl.disable();
      } else {
        this.textFormatFormControl.enable();
      }
    }
  }

  // Since 'required' is an optional input, it could be undefined. So, in order to use it in the template, we need this method that returns a boolean.
  public isRequired(): boolean {
    if (this.required) {
      return true;
    }
    return false;
  }

  public openEditor(): void {
    const dialogData: DialogData = {street: '', city: '', state: '', postalCode: '', country: '', countries: this.countries};
    if (this.currentAddress) {
      dialogData.street = this.currentAddress.street;
      dialogData.city = this.currentAddress.city;
      dialogData.state = this.currentAddress.state;
      dialogData.postalCode = this.currentAddress.postalCode;
      dialogData.country = this.currentAddress.country;
    }

    const textFormatInputRect = this.textFormatInput.nativeElement.getBoundingClientRect();

    const dialogRef = this.dialog.open(AddressEditorDialogComponent, {
      // This field must be named 'data'
      data: dialogData,
      autoFocus: true,
      // Thi si required to allow closing the dialog by clicking at any place outside the dialog
      hasBackdrop: true,
      // If this is false clicking at any place outside the dialog closes the dialog
      disableClose: false,
      position: {
        left: (textFormatInputRect.left - 10) + 'px',
        top: (textFormatInputRect.top + textFormatInputRect.height + 10) + 'px'
      }
    });

    dialogRef.afterClosed().subscribe(dialogData => {
      if (dialogData) {
        this.currentAddress = {street: dialogData.street, city: dialogData.city, state: dialogData.state, postalCode: dialogData.postalCode, country: dialogData.country};
        this.textFormatFormControl.setValue(this.addressToString(this.currentAddress));
        // textFormatFormControl emits the onValueChange
        // this.onValueChange.emit(this.currentAddress);
      }
    });
  }

  private addressToString(address: Address | null | undefined): string {
    if (!address) {
      return '';
    }
    let strStreet = 'street: ';
    let strCity = 'city: ';
    let strState = 'state: ';
    let strPostalCode = 'postalCode: ';
    let strCountry = 'country: ';
    if (address.street) {
      strStreet += address.street;
    }
    if (address.city) {
      strCity += address.city;
    }
    if (address.state) {
      strState += address.state;
    }
    if (address.postalCode) {
      strPostalCode += address.postalCode;
    }
    if (address.country) {
      strCountry += address.country;
    }
    return strStreet + ', ' + strCity + ', ' + strState + ', ' + strPostalCode + ', ' + strCountry;
  }

  private stringToAddress(strAddress: string | null | undefined): Address | null | undefined {
    if (!strAddress) {
      return null;
    }
    let street = '';
    let city = '';
    let state = '';
    let postalCode = '';
    let country = '';

    for (let addressComponent of strAddress.split(',')) {
      addressComponent = addressComponent.trim();
      if (addressComponent.startsWith('street:')) {
        street = this.extractAddressComponentValue('street:', addressComponent);
      }
      if (addressComponent.startsWith('city:')) {
        city = this.extractAddressComponentValue('city:', addressComponent);
      }
      if (addressComponent.startsWith('state:')) {
        state = this.extractAddressComponentValue('state:', addressComponent);
      }
      if (addressComponent.startsWith('postalCode:')) {
        postalCode = this.extractAddressComponentValue('postalCode:', addressComponent);
      }
      if (addressComponent.startsWith('country:')) {
        country = this.extractAddressComponentValue('country:', addressComponent);
      }
    }

    return {street: street, city: city, state: state, postalCode: postalCode, country: country};
  }

  private extractAddressComponentValue(prefix: string, addressComponent: string): string {
    if (addressComponent.length > prefix.length) {
      return addressComponent.substring(prefix.length).trim();
    }
    return '';
  }
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    TranslateModule
  ],
  templateUrl: './address-picker-editor.component.html',
})
export class AddressEditorDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<AddressEditorDialogComponent>,
      // This field must be named 'data'
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
