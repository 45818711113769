import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <button mat-raised-button (click)="openMessageDialog('My message')">Open Message Dialog</button>

// TS:

// public openMessageDialog(message: string) : void {
//   const closeDialog = this.dialogService.open(message);
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-message-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<string>) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
