import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { NavigationService } from '../../services/navigation.service';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PageComponent,
    ActionComponent
  ],
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css', '../../../../styles.css']
})
export class PageNotFoundComponent {

  constructor(
      private navigationService: NavigationService){
  }

  goToHomePage() {
    this.navigationService.goToHomePage();
  }
}
