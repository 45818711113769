import { ActivatedRoute, ParamMap, Params } from '@angular/router';
import { Consumer } from 'src/app/general/interfaces/functions';

export class RouteUtil {

  /**
   * Returns the path parameters.
   * @param activatedRoute activated route needs to be injected in each page so it has information of the actual route.
   * @returns path parameters
   */
  static getPathParams(activatedRoute: ActivatedRoute): ParamMap {
    return activatedRoute.snapshot.paramMap;
  }

  /**
   * Returns the path parameters.
   * @param activatedRoute activated route needs to be injected in each page so it has information of the actual route.
   * @returns path parameters
   */
  static getQueryParams(activatedRoute: ActivatedRoute, paramsConsumer: Consumer<Params>) {
    activatedRoute.queryParams.subscribe(params => {
      paramsConsumer(params);
    });
  }
}
