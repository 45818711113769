<app-loadable-content [loadingController]="ordersLoadingController" (onRetry)="loadInitialData()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'orders' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table width="100%">
                <tr>
                    <td>
                        <app-data-table
                            [data]="orders"
                            [columns]="columns"
                            [toolbarActions]="orderToolbarModel.actions"
                            [compactToolbarActions]="orderToolbarModel.compactActions">
                        </app-data-table>
                    </td>
                </tr>
                <tr>
                    <td align="right">
                        <app-indeterminate-paginator [model]="paginatorModel" [pageSize]="10" [initialData]="initialPaginatorData"></app-indeterminate-paginator>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
</app-loadable-content>
