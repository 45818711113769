import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationHeaderComponent } from '../../components/location-header/location-header.component';
import { LocationPointsOfServiceComponent } from '../../components/location-points-of-service/location-points-of-service.component';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { NavigationService } from '../../services/navigation.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-location-points-of-service-page',
  standalone: true,
  imports: [
    CommonModule,
    LocationPointsOfServiceComponent,
    PageComponent,
    LocationHeaderComponent,
    ActionComponent,
    TranslateModule
  ],
  templateUrl: './location-points-of-service-page.component.html',
  styleUrls: ['./location-points-of-service-page.component.css']
})
export class LocationPointsOfServicePageComponent  implements OnInit {

  locationId: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute,
      private navigationService: NavigationService) {
    this.locationId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.locationId = Util.safeString(pathParams.get('locationId'));
  }

  public goToAddPointOfServicePage(): void {
    this.navigationService.goToAddPointOfServicePage(this.locationId)
  }

  public goToLocationPage(): void {
    this.navigationService.goToLocationPage(this.locationId)
  }
}
