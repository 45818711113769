import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ItemSelectionReusedWarningDialogData } from '../util/interfaces';
import { ItemSelectionReusedWarningDialogService } from '../item-selection-reused-warning-dialog/item-selection-reused-warning-dialog.service';
import { TranslateModule } from '@ngx-translate/core';
import { MenuEditorUtil } from '../util/menu-editor-util';
import { Util } from 'src/app/general/util/util';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-item-selection-reused-warning [itemSelectionId]="itemSelection.id" [menuSpec]="menuSpec"></app-item-selection-reused-warning>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-item-selection-reused-warning',
  standalone: true,
  imports: [
    CommonModule,
    ActionComponent,
    TranslateModule
  ],
  templateUrl: './item-selection-reused-warning.component.html',
  styleUrls: ['./item-selection-reused-warning.component.css']
})
export class ItemSelectionReusedWarningComponent implements OnInit, OnChanges {

  // We use string | null | undefined instead of optional (?) so we can do [itemSelectionId]="itemSelection.id"
  // proto fields are nullable. Optional fields can only be undefined, not null.
  @Input() itemSelectionId!: string | null | undefined;
  @Input() menuSpec!: proto.waiternow.common.StructuredMenuSpecProto;

  otherUsages?: ItemSelectionReusedWarningDialogData;
  isWarningApplicable: boolean;

  constructor(
      private itemSelectionReusedWarningDialogService: ItemSelectionReusedWarningDialogService) {
    this.isWarningApplicable = false;
  }

  ngOnInit(): void {
    this.otherUsages = this.findOtherUsages();
    this.isWarningApplicable = this.otherUsages.reusedInMenuItems.length > 1
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('itemSelectionId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('menuSpec', changes)) {
      this.ngOnInit();
    }
  }

  public showWarning(): void {
    if (!this.otherUsages) {
      return;
    }
    this.itemSelectionReusedWarningDialogService.openDialog(this.otherUsages);
  }

  private findOtherUsages(): ItemSelectionReusedWarningDialogData {
    let itemSelectionTitle: proto.waiternow.common.ITextProto = new proto.waiternow.common.TextProto();
    let reusedInMenuItems: Array<proto.waiternow.common.ITextProto> = [];

    const itemSelectionSpec = MenuEditorUtil.findItemSelectionSpec(this.itemSelectionId, this.menuSpec);
    if (itemSelectionSpec) {
      if (itemSelectionSpec.title) {
        itemSelectionTitle = itemSelectionSpec.title;
      }
      for (const menuItemSpec of this.menuSpec.menuItems) {
        if (Util.arrayContains(this.itemSelectionId, menuItemSpec.itemSelectionIds)) {
          if (menuItemSpec.name) {
            reusedInMenuItems.push(menuItemSpec.name);
          }
        }
      }
    }

    return {
      itemSelectionTitle: itemSelectionTitle,
      reusedInMenuItems: reusedInMenuItems
    }
  }
}
