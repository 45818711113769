import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { DataTableColumn, CompactTextColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { Formatter } from '../../util/formatter';
import { NavigationService } from '../../services/navigation.service';
import { PointOfServiceToolbarModel } from '../../model/point-of-service-toolbar-model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { MatCardModule } from '@angular/material/card';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-location-points-of-service [locationId]="locationId"></app-location-points-of-service>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-location-points-of-service',
  standalone: true,
  imports: [
    CommonModule,
    LoadableContentComponent,
    TranslateModule,
    DataTableComponent,
    MatCardModule
  ],
  templateUrl: './location-points-of-service.component.html',
  styleUrls: ['./location-points-of-service.component.css']
})
export class LocationPointsOfServiceComponent implements OnInit, OnChanges {
  @Input() locationId!: string;

  locationPointsOfServiceLoadingController: LoadingController;

  pointsOfService: Array<proto.waiternow.common.IPointOfServiceProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.IPointOfServiceProto>>;

  pointOfServiceToolbarModel: PointOfServiceToolbarModel;

  constructor(
      private backendService: BackendService,
      private sessionService: SessionService,
      private navigationService: NavigationService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      private toastService: ToastService) {
    this.locationPointsOfServiceLoadingController = new LoadingController();

    this.pointsOfService = new Array();
    this.columns = [
      new CompactTextColumn(
        /* name= */ 'id',
        /* translateId= */ 'id',
        /* valueExtractor= */  pointOfService => pointOfService.id,
        /* compactTextLength= */ 6),
      new StringColumn(
        /* name= */ 'friendlyName',
        /* translateId= */ 'friendly_name',
        /* valueExtractor= */  pointOfService => pointOfService.friendlyName),
      new StringColumn(
        /* name= */ 'type',
        /* translateId= */ 'type',
        /* valueExtractor= */  pointOfService => Formatter.formatPointOfServiceType(pointOfService.type),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'vanityUrl',
        /* translateId= */ 'vanity_url',
        /* valueExtractor= */  pointOfService => pointOfService.vanityUrl,
        /* hideInCompactMode= */ true),
        new StringColumn(
        /* name= */ 'lastUpdateTime',
        /* translateId= */ 'last_update',
        /* valueExtractor= */  device => Formatter.formatTimestampProto(device.lastUpdateTime),
        /* hideInCompactMode= */ true)
      ];

      this.pointOfServiceToolbarModel = new PointOfServiceToolbarModel(
        this.sessionService,
        this.backendService,
        this.navigationService,
        this.translateService,
        this.dialogService,
        this.toastService,
        /* compactForMobile= */ true,
        /* onPointOfServiceDeleted= */ pointOfServiceId => this.loadPointsOfService()
      );
    }

  ngOnInit(): void {
    this.loadPointsOfService();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('locationId', changes)) {
      this.ngOnInit();
    }
  }

  public loadPointsOfService(): void {
    this.locationPointsOfServiceLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_location_points_of_service'));
    this.backendService.findLocationPointsOfService(
      this.locationId,
      /* onSuccess= */ pointsOfServiceProto => {
        this.locationPointsOfServiceLoadingController.onSuccess();
        if (pointsOfServiceProto && pointsOfServiceProto?.pointsOfService) {
          this.pointsOfService = pointsOfServiceProto?.pointsOfService;
        } else {
          this.pointsOfService = [];
        }
      },
      /* onError= */ error => {
        this.locationPointsOfServiceLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_location_points_of_service'));
      }
    );
  }
}
