import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { CategoryEditorDialogComponent } from './category-editor-dialog.component';
import { CategoryEditorDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateCategory(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): void {
//   const inputDialogData: CategoryEditorDialogData = {
//     category: {
//       nameEn: Formatter.getText(category.name, proto.waiternow.common.Language.ENGLISH),
//       nameEs: Formatter.getText(category.name, proto.waiternow.common.Language.SPANISH)
//     }
//   }
//   this.categoryEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class CategoryEditorDialogService {

  constructor(private dialog: MatDialog) { }

  public openEditor(inputDialogData: CategoryEditorDialogData, onUpdate: Consumer<CategoryEditorDialogData>) : CloseDialog {
    const dialogRef = this.dialog.open(CategoryEditorDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData) {
        onUpdate(outputDialogData);
      }
    });

    return () => dialogRef.close();
  }
}
