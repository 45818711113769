import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sidebarToggler: () => void;

  constructor() {
    this.sidebarToggler = () => { };
  }

  /**
   * This method should only be used by the app component to initialize tht toggler.
   *
   * @param toggler toggler.
   */
  public setSidebarToggler(toggler: () => void) {
    this.sidebarToggler = toggler;
  }

  public toggleSidebar(): void {
    this.sidebarToggler();
  }
}
