import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressDialogComponent } from '../components/progress-dialog/progress-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { Runnable } from '../interfaces/functions';
import { MessageDialogComponent } from '../components/message-dialog/message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  /**
   * Opens a progress dialog.
   *
   * @param message dialog message
   * @param title dialog title (optional)
   * @returns a method reference used to close the dialog
   */
  public openProgressDialog(message?: string, title?: string) : CloseDialog {
    const dialogRef = this.dialog.open(ProgressDialogComponent, {
      data: {message: message, title: title},
      autoFocus: true,
      hasBackdrop: true,
      disableClose: true,
    });
    return () => dialogRef.close();
  }

  /**
   * Opens a progress dialog.
   *
   * @param yesNoQuestion yes/no question
   * @param onYes callback method if the 'yes' button is clicked
   * @param onNo callback method if the 'no' button is clicked
   * @returns a method reference used to close the dialog
   */
  public openConfirmationDialog(yesNoQuestion: string, onYes: Runnable, onNo: Runnable) : CloseDialog {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {yesNoQuestion: yesNoQuestion},
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData) {
        onYes();
      } else {
        onNo();
      }
    });

    return () => dialogRef.close();
  }

  /**
   * Opens a message dialog.
   *
   * @param message message
   */
  public openMessageDialog(message: string) : void {
    this.dialog.open(MessageDialogComponent, {
      data: message.split('\n'),
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });
  }
}

export interface CloseDialog {
  (): void;
}
