<app-page>
    <app-loadable-content [loadingController]="campaignLoadingController" (onRetry)="loadCampaign()">
        <app-title>{{ pageTitle }}</app-title>
        <div>
            <table>
                <tr>
                    <td>
                        <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'go_to_campaign' | translate" [defaultStyle]=true (onExecute)="goToCampaignPage()"></app-action>
                    </td>
                </tr>
            </table>
        </div>
        <mat-accordion multi>
            <!-- ------------------------------------------------------------------ -->
            <!-- General config -->
            <!-- ------------------------------------------------------------------ -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'general' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div appFieldContainer>
                    <mat-form-field appField>
                        <mat-label>{{ 'code' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.code" required>
                        <mat-error *ngIf="generalConfigFields.code.invalid">{{formService.getFieldErrorMessage(generalConfigFields.code)}}</mat-error>
                    </mat-form-field>
                </div>
                <div appFieldContainer>
                    <mat-form-field appField>
                        <mat-label>{{ 'description_en' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.descriptionEn">
                        <mat-error *ngIf="generalConfigFields.descriptionEn.invalid">{{formService.getFieldErrorMessage(generalConfigFields.descriptionEn)}}</mat-error>
                    </mat-form-field>
                </div>
                <div appFieldContainer>
                    <mat-form-field appField>
                        <mat-label>{{ 'description_es' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.descriptionEs">
                        <mat-error *ngIf="generalConfigFields.descriptionEs.invalid">{{formService.getFieldErrorMessage(generalConfigFields.descriptionEs)}}</mat-error>
                    </mat-form-field>
                </div>
                <div appFieldContainer>
                    <app-money-picker
                        label="{{ 'discount_fixed_amount' | translate }}"
                        [required]="false"
                        [disabled]="false"
                        [currencies]="CURRENCIES"
                        [value]="generalConfigFields.discountFixedAmountInitialValue"
                        (onValueChange)="onDiscountFixedAmountChange($event)">
                    </app-money-picker>
                </div>
                <div appFieldContainer>
                    <mat-form-field appField>
                        <mat-label>{{ 'discount_percentage' | translate }}</mat-label>
                        <input matInput type="number" placeholder="0.5 for 50%" [formControl]="generalConfigFields.discountPercentage">
                        <mat-error *ngIf="generalConfigFields.discountPercentage.invalid">{{formService.getFieldErrorMessage(generalConfigFields.discountPercentage)}}</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-checkbox [formControl]="generalConfigFields.conditionRuleFirstPurchase">
                        {{ 'first_purchase' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="generalConfigFields.conditionRuleExcludeOnline">
                        {{ 'exclude_online_orders' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="generalConfigFields.isActive">
                        {{ 'is_active' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <app-action [text]="'save' | translate"
                        [disabled]="generalConfigFields.code.invalid"
                        (onAsyncExecute)="saveGeneral($event)">
                    </app-action>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </app-loadable-content>
</app-page>
