<app-page>
    <app-loadable-content [loadingController]="pointOfServiceLoadingController" (onRetry)="loadPointOfService()">
        <app-title>{{ pageTitle }}</app-title>
        <div>
            <table>
                <tr>
                    <td>
                        <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll()"></app-action>
                    </td>
                    <td>
                        <app-action [text]="'go_to_point_of_service' | translate" [defaultStyle]=true (onExecute)="goToPointOfServicePage()"></app-action>
                    </td>
                </tr>
            </table>
        </div>
        <mat-accordion multi>
            <!-- ------------------------------------------------------------------ -->
            <!-- General config -->
            <!-- ------------------------------------------------------------------ -->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'general' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div appFieldContainer>
                    <mat-form-field appField>
                        <mat-label>{{ 'friendly_name' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.friendlyName" required>
                        <mat-error *ngIf="generalConfigFields.friendlyName.invalid">{{formService.getFieldErrorMessage(generalConfigFields.friendlyName)}}</mat-error>
                    </mat-form-field>
                    waiternow.com/business/[VANITY_URL]
                    <mat-form-field appField>
                        <mat-label>{{ 'vanity_url' | translate }}</mat-label>
                        <input matInput [formControl]="generalConfigFields.vanityUrl" placeholder="oldtemplebar">
                        <mat-error *ngIf="generalConfigFields.vanityUrl.invalid">{{formService.getFieldErrorMessage(generalConfigFields.vanityUrl)}}</mat-error>
                    </mat-form-field>
                    <div appFieldContainer>
                        <mat-form-field appField>
                            <mat-label>{{ 'type' | translate }}</mat-label>
                            <mat-select [formControl]="generalConfigFields.type">
                                <mat-option [value]="POINT_OF_SERVICE_TYPE_NONE">{{ 'none' | translate }}</mat-option>
                                <mat-option [value]="POINT_OF_SERVICE_TYPE_TABLE">Table</mat-option>
                                <mat-option [value]="POINT_OF_SERVICE_TYPE_ONLINE">Online</mat-option>
                                <mat-option [value]="POINT_OF_SERVICE_TYPE_ONLINE_PICKUP">Online Pickup</mat-option>
                                <mat-option [value]="POINT_OF_SERVICE_TYPE_ONSITE_PICKUP">Onsite Pickup</mat-option>
                                <mat-option [value]="POINT_OF_SERVICE_TYPE_MENU">Menu</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div>
                    <app-action [text]="'save' | translate"
                        [disabled]="generalConfigFields.friendlyName.invalid || generalConfigFields.type.invalid"
                        (onAsyncExecute)="saveGeneral($event)">
                    </app-action>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </app-loadable-content>
</app-page>
