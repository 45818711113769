import { Directive, ElementRef, Renderer2 } from '@angular/core';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <div appFieldContainer>
//     <mat-form-field appField>
//         <mat-label>{{ 'sales_tax' | translate }}</mat-label>
//         <input matInput type="number" placeholder="0.0785" [formControl]="taxesConfig.salesTax">
//         <mat-error *ngIf="taxesConfig.salesTax.invalid">{{formService.getFieldErrorMessage(taxesConfig.salesTax)}}</mat-error>
//     </mat-form-field>
// </div>
// ----------------------------------------------------------------------------

@Directive({
  selector: '[appField]',
  standalone: true
})
export class FieldDirective {

  // Example taken from:
  // https://angular.io/guide/attribute-directives
  // and
  // https://github.com/angular/angular/blob/main/packages/common/src/directives/ng_class.ts

  constructor(
      private element: ElementRef,
      private renderer: Renderer2) {
    this.renderer.addClass(this.element.nativeElement, 'form-field');
  }
}
