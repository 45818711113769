export class TimeUtil {

  // https://howtodoinjava.com/typescript/typescript-date-object/

  constructor() { }

  static now(): Date {
    return new Date();
  }

  static dateToMillis(date: Date): number {
    return date.getTime();
  }

  static createDateFromMillis(millis: number): Date {
    return new Date(millis);
  }

  static plusMillis(date: Date, millis: number): Date {
    return new Date(date.getTime() + millis);
  }
}
