<table width="100%">
    <tr>
        <td width="100%">
            <table width="100%" class="no-spacing-no-padding-table">
                <tr>
                    <td class="rounded-corner-background-left">
                        <div class="rounded-corner-div"></div>
                    </td>
                    <td class="palette-background-color-less-black" width="100%">
                        <table align="center">
                            <!-- Top content goes here (content inside the rounded part of the footer) -->
                            <tr>
                                <td align="center" class="palette-color-light-grey powered-by">
                                    Powered by Soft Evolution LLC
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="rounded-corner-background-right">
                        <div class="rounded-corner-div"></div>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <td width="100%">
            <table class="palette-background-color-less-black" width="100%">
                <!-- Bottom content goes here (content below the rounded part of the footer) -->
            </table>
        </td>
    </tr>
</table>
