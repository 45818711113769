import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from 'src/app/general/components/multi-select/multi-select.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { EditorItem, SelectItemDialogData } from '../util/interfaces';
import { ItemMultiSelectUtil } from '../util/item-multi-select-util';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public selectItemForItemSelection(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): void {
//   const inputDialogData: SelectItemDialogData = {
//     selectedItems: ...,
//     items: ...
//   }
//   this.selectItemDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-select-item-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MultiSelectComponent
  ],
  templateUrl: './select-item-dialog.component.html',
  styleUrls: ['./select-item-dialog.component.css']
})
export class SelectItemDialogComponent {

  itemMultiselectUtil: ItemMultiSelectUtil;

  constructor(
      public dialogRef: MatDialogRef<SelectItemDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: SelectItemDialogData,
      private localizationService: LocalizationService) {
    this.itemMultiselectUtil = new ItemMultiSelectUtil(this.localizationService);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public onSelectedItemsChange(selectedItems: Array<EditorItem>): void {
    this.data.selectedItems = selectedItems;
  }

  public isValidItemsSelection(): boolean {
    if (!this.data.selectedItems || this.data.selectedItems.length == 0) {
      return false;
    }
    return true;
  }
}
