<table>
    <tr>
        <td>
            <span [matTooltip]="tooltip">{{displayText}}</span>
        </td>
        <td>
            <app-action icon="add" (onExecute)="expand()" *ngIf="isExpandCollapseEnabled && !isExpanded">
            </app-action>
        </td>
        <td>
            <app-action icon="remove" (onExecute)="collapse()" *ngIf="isExpandCollapseEnabled && isExpanded">
            </app-action>
        </td>
    </tr>
</table>
