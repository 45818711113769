import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CloseDialog } from 'src/app/general/services/dialog.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { ItemEditorDialogComponent } from './item-editor-dialog.component';
import { ItemEditorDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateItem(item: proto.waiternow.common.StructuredMenuProto.IItemProto): void {
//   const inputDialogData: ItemEditorDialogData = {
//     item: {
//       nameEn: Formatter.getText(item.name, proto.waiternow.common.Language.ENGLISH),
//       nameEs: Formatter.getText(item.name, proto.waiternow.common.Language.SPANISH)
//     }
//   }
//   this.itemEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ itemEditorDialogData => {
//       console.log(itemEditorDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Injectable({
  providedIn: 'root',
})
export class ItemEditorDialogService {

  constructor(private dialog: MatDialog) { }

  public openEditor(inputDialogData: ItemEditorDialogData, onUpdate: Consumer<ItemEditorDialogData>) : CloseDialog {
    const dialogRef = this.dialog.open(ItemEditorDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData) {
        onUpdate(outputDialogData);
      }
    });

    return () => dialogRef.close();
  }
}
