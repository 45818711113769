import { SimpleChanges } from "@angular/core";

export class ComponentUtil {

  constructor() { }

  /**
   * Checks if the given binding name changed.
   *
   * This is an utility method for components that implement OnChanges from @angular/core.
   *
   * @param bindingName binfing name
   * @param changes changes
   */
  static bindingChanged(bindingName: string, changes: SimpleChanges): boolean {
    // firstChange is true the first time the input is set. It is false if the input changes.
    return changes[bindingName] && !changes[bindingName].firstChange;
  }
}
