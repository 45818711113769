import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-page-centered-content>
//     Page content...
// </app-page-centered-content>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-page-centered-content',
  standalone: true,
  imports: [CommonModule, HeaderComponent, FooterComponent],
  templateUrl: './page-centered-content.component.html',
  styleUrls: ['./page-centered-content.component.css']
})
export class PageCenteredContentComponent {

}
