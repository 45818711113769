import { Observable, map, of } from "rxjs";
import { IndeterminatePaginatorData } from "src/app/general/components/indeterminate-paginator/indeterminate-paginator-model";
import { OrdersModel } from "./orders-model";
import { BackendService } from "../../services/backend.service";
import { DataTableColumn } from "src/app/general/components/data-table/data-table-column";
import * as proto from 'src/proto/compiled-protos';

export class LocationDisputedOrdersModel implements OrdersModel {
  locationId: string;
  backendService: BackendService;

  constructor(locationId: string, backendService: BackendService) {
    this.locationId = locationId;
    this.backendService = backendService;
  }

  fetchData(continuationToken: string | null | undefined): Observable<IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>> {
    if (!this.locationId) {
      return of({data: [], continuationToken: ''});
    }
    return this.backendService.findLocationDisputedOrdersReturnObservable(this.locationId, continuationToken)
      .pipe(
        map(locationOrders => {
          const paginatorData: IndeterminatePaginatorData<proto.waiternow.common.IOrderProto> = {data: [], continuationToken: continuationToken};
          if (locationOrders) {
            paginatorData.data = locationOrders.orders;
            paginatorData.continuationToken = locationOrders.continuationToken;
          }
          return paginatorData;
        })
      );
  }

  getAdditionalColumns(): Array<DataTableColumn<proto.waiternow.common.IOrderProto>> {
    return [];
  }
}
