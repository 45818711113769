import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressComponent } from '../progress/progress.component';
import { ProgressDialogData } from './progress-dialog-data';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <button mat-raised-button (click)="openProgressDialog()">Open Progress Dialog</button>
// <button mat-raised-button (click)="openProgressDialogWithoutMessage()">Open Progress Dialog Without Message</button>

// TS:

// public openProgressDialog() : void {
//   const closeDialog = this.dialogService.openProgressDialog('Progress message', 'Progress Title');
//   this.executeAfterDelay(3000, closeDialog);
//   // or
//   // this.executeAfterDelay(3000, () => closeDialog());
// }

// public openProgressDialogWithoutMessage() : void {
//   const closeDialog = this.dialogService.openProgressDialog();
//   this.executeAfterDelay(3000, closeDialog);
//   // or
//   // this.executeAfterDelay(3000, () => closeDialog());
// }

// async executeAfterDelay(delayMilis: number, task: () => void) {
//   await new Promise(resolve => setTimeout(resolve, delayMilis)).then(task);
// }
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-progress-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ProgressComponent
  ],
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.css']
})
export class ProgressDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProgressDialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
