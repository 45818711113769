<input type="file" [accept]="dialogData.fileChooserAcceptFilter" style="display:none;" (change)="onFileSelected($event)" #file_input_ref_name>

<div mat-dialog-title>
    {{ 'selected_file' | translate }}
</div>
<div mat-dialog-content>
    <span *ngIf="selectedFile">{{selectedFile.name}}</span>
    <span *ngIf="!selectedFile">{{ 'none' | translate}}</span>
</div>
<div mat-dialog-actions align="center">
    <button mat-button class="button" (click)="file_input_ref_name.click()">
        {{'select_file' | translate}}
    </button>
    <button mat-button class="button" (click)="ok()" [disabled]="!selectedFile">
        {{'ok' | translate}}
    </button>
    <button mat-button class="button" (click)="onNoClick()" cdkFocusInitial>
        {{'cancel' | translate}}
    </button>
</div>
