import { Util } from "src/app/general/util/util";
import { BackendService } from "../services/backend.service";
import { NavigationService } from "../services/navigation.service";
import { DialogService } from "src/app/general/services/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Consumer } from "src/app/general/interfaces/functions";
import { ToastService } from "src/app/general/services/toast.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { ToolbarUtil } from "../util/toolbar-util";
import * as proto from 'src/proto/compiled-protos';

// This tool bar has the same actions as BusinessToolbarModel.

export class ManagedBusinessToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;
  navigationService: NavigationService;
  translateService: TranslateService;
  dialogService: DialogService;
  toastService: ToastService;
  compactForMobile: boolean;
  onBusinessDeleted: Consumer<string>;

  public actions: Array<ToolbarAction<proto.waiternow.common.UserBusinessesProto.IManagedBusinessProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.UserBusinessesProto.IManagedBusinessProto>>;

  /**
   * @param compactForMobile true to show open in new window for mobile devices.
   *                         Useful when the tool bar is part of a data table row.
   */
  constructor(
      sessionService: SessionService,
      backendService: BackendService,
      navigationService: NavigationService,
      translateService: TranslateService,
      dialogService: DialogService,
      toastService: ToastService,
      compactForMobile: boolean,
      onBusinessDeleted: Consumer<string>) {
    this.sessionService = sessionService;
    this.backendService = backendService;
    this.navigationService = navigationService;
    this.translateService = translateService;
    this.dialogService = dialogService;
    this.toastService = toastService;
    this.compactForMobile = compactForMobile;
    this.onBusinessDeleted = onBusinessDeleted;

    this.actions =
    [
      ToolbarUtil.createToolbarActionEdit(
          managedBusiness => this.navigationService.goToEditBusinessPage(Util.safeString(managedBusiness?.business?.id))),
      {
        icon: 'person',
        tooltipTranslateId: 'users',
        onExecute: managedBusiness => this.navigationService.goToBusinessUsersPage(Util.safeString(managedBusiness?.business?.id))
      },
      {
        icon: 'house',
        tooltipTranslateId: 'locations',
        onExecute: managedBusiness => this.navigationService.goToBusinessLocationsPage(Util.safeString(managedBusiness?.business?.id))
      },
      {
        icon: 'campaign',
        tooltipTranslateId: 'campaigns',
        onExecute: managedBusiness => this.navigationService.goToBusinessCampaignsPage(Util.safeString(managedBusiness?.business?.id))
      },
      ToolbarUtil.createToolbarActionDelete(
        managedBusiness => this.deleteBusiness(managedBusiness.business)),
      ToolbarUtil.createToolbarActionViewProto(ProtoType.BUSINESS, managedBusiness => Util.safeString(managedBusiness.business?.id), this.sessionService)
    ];

    if (this.compactForMobile) {
      const openInNewWindowAction: ToolbarAction<proto.waiternow.common.UserBusinessesProto.IManagedBusinessProto> =
          ToolbarUtil.createToolbarActionOpenInNewWindow(
            managedBusiness => this.navigationService.goToBusinessPage(Util.safeString(managedBusiness?.business?.id)));
      Util.insertIntoArray(openInNewWindowAction, 0, this.actions);
      this.compactActions = [openInNewWindowAction];
    } else {
      this.compactActions = this.actions;
    }
  }

  private deleteBusiness(business: proto.waiternow.common.IBusinessProto | null | undefined): void {
    if (!business) {
      return;
    }
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_business', { name: business.businessName }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.deleteBusiness(
            Util.safeString(business.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('business_deleted').subscribe(text => this.toastService.success(text));
              this.onBusinessDeleted(Util.safeString(business.id));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_business').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }
}
