<!-- ---------------------------------------------------------------------- -->
<!-- This component displays content for both, the menu items page and the  -->
<!-- menu item detail page. Lots of editions will happen to the MenuSpec    -->
<!-- that is easier to keep them all in the same template, otherwise many   -->
<!-- interactions will be required between components                       -->
<!-- ---------------------------------------------------------------------- -->

<!-- ---------------------------------------------------------------------- -->
<!-- Menu Entries Page -->
<!-- ---------------------------------------------------------------------- -->
<div [ngClass]="deviceService.ifMobile('menu-container-mobile').orElse('menu-container')" *ngIf="!selectedMenuEntry">
    <table align="center" appFieldContainer>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'search' | translate }}</mat-label>
                    <input matInput type="text" [formControl]="searchFormControl">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </td>
        </tr>
    </table>
    <table width="100%">
        <tr>
            <td [attr.colspan]="menuItemsPerRow">
                <app-right-aligned-compact-content>
                    <table>
                        <tr>
                            <td>
                                <button mat-fab [matTooltip]="'add' | translate" (click)="addMenuEntry()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                </app-right-aligned-compact-content>
            </td>
        </tr>
        <ng-template let-category ngFor [ngForOf]="menu.categories" [ngForTrackBy]="trackCategoryChanges">
            <tr>
                <td [attr.colspan]="menuItemsPerRow">
                    <table class="no-spacing-no-padding-table">
                        <tr>
                            <td width="100%">
                                <app-subtitle>{{getNonEmptyText(category.name)}}</app-subtitle>
                            </td>
                            <td>
                                <app-action icon="edit" [tooltip]="'edit' | translate" (onExecute)="updateCategory(category)">
                                </app-action>
                            </td>
                            <td>
                                <app-action icon="north" [tooltip]="'move_up' | translate" [disabled]="isFirstCategory(category) || isMenuFiltered()" (onExecute)="moveCategoryUp(category)">
                                </app-action>
                            </td>
                            <td>
                                <app-action icon="south" [tooltip]="'move_down' | translate" [disabled]="isLastCategory(category) || isMenuFiltered()" (onExecute)="moveCategoryDown(category)">
                                </app-action>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <ng-template let-menuEntryGroup ngFor [ngForOf]="splitMenuItemsInGroups(category, menuItemsPerRow)" [ngForTrackBy]="trackMenuEntryGroupChanges">
                <tr>
                    <td *ngFor="let menuEntry of menuEntryGroup; trackBy: trackMenuEntryChanges"
                            [ngClass]="deviceService.ifMobile('menu-entry-container-mobile').orElse('menu-entry-container')"
                            [width]="menuItemColumnWidth">
                        <!--
                            For desktop mode we use a specific size for the card when we only have one menu item,
                            otherwise the card will take the entire space of the td, which has width as menuItemColumnWidth.
                            This to avoid the menu item to cover the entire screen.
                         -->
                        <mat-card [ngClass]="{'single-menu-entry-card-desktop-mode': !deviceService.isMobile() && menuSpec.menuItems.length == 1}">
                            <app-right-aligned-compact-content>
                                <table>
                                    <tr>
                                        <td>
                                            <app-action icon="visibility" [tooltip]="'open' | translate" (onExecute)="openMenuEntryDetails(menuEntry)">
                                            </app-action>
                                        </td>
                                        <td>
                                            <app-action icon="edit" [tooltip]="'edit' | translate" (onExecute)="updateMenuEntry(menuEntry)">
                                            </app-action>
                                        </td>
                                        <td>
                                            <app-file-selector
                                                icon="image"
                                                [tooltip]="'update_image' | translate"
                                                fileChooserAcceptFilter="image/*"
                                                [userData]="menuEntry"
                                                (onFileSelected)="updateMenuEntryImage($event)">
                                            </app-file-selector>
                                        </td>
                                        <td>
                                            <app-action icon="hide_image" [tooltip]="'delete_image' | translate" [disabled]="!hasMenuEntryImage(menuEntry)" (onExecute)="deleteMenuEntryImage(menuEntry)">
                                            </app-action>
                                        </td>
                                        <td>
                                            <app-action icon="schedule" [tooltip]="'availability_schedule' | translate" (onExecute)="updateMenuEntrySchedule(menuEntry)">
                                            </app-action>
                                        </td>
                                        <td>
                                            <app-action icon="delete" [tooltip]="'delete' | translate" (onExecute)="deleteMenuEntry(menuEntry)">
                                            </app-action>
                                        </td>
                                        <td>
                                            <app-action icon="north" [tooltip]="'move_up' | translate" [disabled]="isFirstMenuEntryInCategory(menuEntry, category) || isMenuFiltered()" (onExecute)="moveMenuEntryUp(menuEntry, category)">
                                            </app-action>
                                        </td>
                                        <td>
                                            <app-action icon="south" [tooltip]="'move_down' | translate" [disabled]="isLastMenuEntryInCategory(menuEntry, category) || isMenuFiltered()" (onExecute)="moveMenuEntryDown(menuEntry, category)">
                                            </app-action>
                                        </td>
                                    </tr>
                                </table>
                            </app-right-aligned-compact-content>
                            <img mat-card-image [src]="getMenuEntryImageUrl(menuEntry)" width="100%" *ngIf="getMenuEntryImageUrl(menuEntry) && this.deviceService.isMobile()">
                            <img mat-card-image [src]="getMenuEntryImageUrl(menuEntry)" width="100%" height="200px" *ngIf="getMenuEntryImageUrl(menuEntry) && !this.deviceService.isMobile()">
                            <mat-card-content>
                                <table width="100%">
                                    <tr>
                                        <td align="center" [ngClass]="deviceService.ifMobile('menu-entry-name-mobile').orElse('menu-entry-name')">
                                            {{ getMenuEntryName(menuEntry) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <span [ngClass]="deviceService.ifMobile('menu-entry-price-mobile').orElse('menu-entry-price')">
                                                {{ getMenuEntryPrice(menuEntry) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" [ngClass]="deviceService.ifMobile('menu-entry-description-mobile').orElse('menu-entry-description')">
                                            {{ getMenuEntryDescription(menuEntry) }}&nbsp;
                                        </td>
                                    </tr>
                                    <tr *ngIf="containsAlcohol(menuEntry) || hasAvailabilitySchedule(menuEntry)">
                                        <td>
                                            <app-right-aligned-compact-content>
                                                <table>
                                                    <tr>
                                                        <td align="right" *ngIf="containsAlcohol(menuEntry)">
                                                            <mat-icon [matTooltip]="'contains_alcohol' | translate">wine_bar</mat-icon>
                                                        </td>
                                                        <td align="right" *ngIf="hasAvailabilitySchedule(menuEntry)">
                                                            <mat-icon [matTooltip]="getAvailabilityScheduleText(menuEntry)">schedule</mat-icon>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </app-right-aligned-compact-content>
                                        </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </td>
                </tr>
            </ng-template>
        </ng-template>
    </table>
    </div>

<!-- ---------------------------------------------------------------------- -->
<!-- Menu Entry Detail Page -->
<!-- ---------------------------------------------------------------------- -->
<div [ngClass]="deviceService.ifMobile('menu-container-mobile').orElse('menu-container')" *ngIf="selectedMenuEntry">
    <table align="center" [ngClass]="deviceService.ifMobile('menu-entry-detail-container-mobile').orElse('menu-entry-detail-container')">
        <tr>
            <td class="menu-entry-detail-card-container">
                <!-- NOTE!!! This same code is shown in the menu entry overview above (Except the toolbar) -->
                <mat-card>
                    <app-right-aligned-compact-content>
                        <table>
                            <tr>
                                <td>
                                    <app-action icon="playlist_add" [tooltip]="'add_item_selection' | translate" (onExecute)="addItemSelection(selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="edit" [tooltip]="'edit' | translate" (onExecute)="updateMenuEntry(selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-file-selector
                                        icon="image"
                                        [tooltip]="'update_image' | translate"
                                        fileChooserAcceptFilter="image/*"
                                        [userData]="selectedMenuEntry"
                                       (onFileSelected)="updateMenuEntryImage($event)">
                                    </app-file-selector>
                                </td>
                                <td>
                                    <app-action icon="hide_image" [tooltip]="'delete_image' | translate" [disabled]="!hasMenuEntryImage(selectedMenuEntry)" (onExecute)="deleteMenuEntryImage(selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="schedule" [tooltip]="'availability_schedule' | translate" (onExecute)="updateMenuEntrySchedule(selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="delete" [tooltip]="'delete' | translate" (onExecute)="deleteMenuEntry(selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="close" [tooltip]="'close' | translate" (onExecute)="closeMenuEntryDetails()">
                                    </app-action>
                                </td>
                            </tr>
                        </table>
                    </app-right-aligned-compact-content>
                    <img mat-card-image [src]="getMenuEntryImageUrl(selectedMenuEntry)" width="100%">
                    <mat-card-content>
                        <table width="100%">
                            <tr>
                                <td align="center" [ngClass]="deviceService.ifMobile('menu-entry-name-mobile').orElse('menu-entry-name')">
                                    {{ getMenuEntryName(selectedMenuEntry) }}
                                </td>
                            </tr>
                            <tr>
                                <td align="center">
                                    <span [ngClass]="deviceService.ifMobile('menu-entry-price-mobile').orElse('menu-entry-price')">
                                        {{ getMenuEntryPrice(selectedMenuEntry) }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" [ngClass]="deviceService.ifMobile('menu-entry-description-mobile').orElse('menu-entry-description')">
                                    {{ getMenuEntryDescription(selectedMenuEntry) }}&nbsp;
                                </td>
                            </tr>
                            <tr *ngIf="containsAlcohol(selectedMenuEntry) || hasAvailabilitySchedule(selectedMenuEntry)">
                                <td>
                                    <app-right-aligned-compact-content>
                                        <table>
                                            <tr>
                                                <td align="right" *ngIf="containsAlcohol(selectedMenuEntry)">
                                                    <mat-icon [matTooltip]="'contains_alcohol' | translate">wine_bar</mat-icon>
                                                </td>
                                                <td align="right" *ngIf="hasAvailabilitySchedule(selectedMenuEntry)">
                                                    <mat-icon [matTooltip]="getAvailabilityScheduleText(selectedMenuEntry)">schedule</mat-icon>
                                                </td>
                                            </tr>
                                        </table>
                                    </app-right-aligned-compact-content>
                                </td>
                            </tr>
                </table>
                    </mat-card-content>
                </mat-card>
            </td>
        </tr>
        <tr *ngFor="let itemSelection of getMenuEntryItemSelections(selectedMenuEntry)">
            <td class="menu-entry-detail-card-container">
                <mat-card>
                    <table width="100%">
                        <tr>
                            <td align="right">
                                <table>
                                    <tr>
                                        <td *ngIf="hasMaxSelections(itemSelection)" class="item-selection-header-atribute">
                                            {{ 'max_selections' | translate }} {{ itemSelection.maxSelections }}
                                        </td>
                                        <td *ngIf="itemSelection.isRequired" class="item-selection-header-atribute">
                                            <strong>{{ 'required' | translate }}</strong>&nbsp;
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <mat-card-header>
                        <table width="100%" class="no-spacing-no-padding-table">
                            <tr>
                                <td width="100%">
                                    <mat-card-title>{{ getNonEmptyText(itemSelection.title) }}</mat-card-title>
                                </td>
                                <td>
                                    <app-item-selection-reused-warning [itemSelectionId]="itemSelection.id" [menuSpec]="menuSpec">
                                    </app-item-selection-reused-warning>
                                </td>
                                <td>
                                    <app-action icon="add" [tooltip]="'add_item' | translate" (onExecute)="selectItemForItemSelection(itemSelection, selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="edit" [tooltip]="'edit' | translate" (onExecute)="updateItemSelection(itemSelection)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="remove_circle_outline" [tooltip]="'remove' | translate" (onExecute)="removeItemSelection(itemSelection, selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="north" [tooltip]="'move_up' | translate" [disabled]="isFirstItemSelectionInMenuEntry(itemSelection, selectedMenuEntry)" (onExecute)="moveItemSelectionInMenuEntryUp(itemSelection, selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="south" [tooltip]="'move_down' | translate" [disabled]="isLastItemSelectionInMenuEntry(itemSelection, selectedMenuEntry)" (onExecute)="moveItemSelectionInMenuEntryDown(itemSelection, selectedMenuEntry)">
                                    </app-action>
                                </td>
                            </tr>
                        </table>
                    </mat-card-header>
                    <mat-card-content>
                        <table width="100%">
                            <tr *ngFor="let item of itemSelection.items">
                                <td width="100%">
                                    {{ getNonEmptyText(item.name) }}
                                </td>
                                <td>
                                    {{ geItemPrice(item) }}
                                </td>
                                <td>
                                    <mat-checkbox *ngIf="!isSingleSelection(itemSelection)"></mat-checkbox>
                                    <mat-radio-button *ngIf="isSingleSelection(itemSelection)"></mat-radio-button>
                                </td>
                                <!--
                                We do not use app-item-reused-warning here because it would be too expensive to alculate it for each item.
                                <td>
                                    <app-item-reused-warning [itemId]="item.id" [menuSpec]="menuSpec">
                                    </app-item-reused-warning>
                                </td>
                                -->
                                <td>
                                    <app-action icon="edit" [tooltip]="'edit' | translate" (onExecute)="updateItem(item)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="remove_circle_outline" [tooltip]="'remove' | translate" (onExecute)="removeItemForItemSelection(item, itemSelection, selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="north" [tooltip]="'move_up' | translate" [disabled]="isFirstItemInItemSelection(item, itemSelection)" (onExecute)="moveItemInItemSelectionUp(item, itemSelection, selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="south" [tooltip]="'move_down' | translate" [disabled]="isLastItemInItemSelection(item, itemSelection)" (onExecute)="moveItemInItemSelectionDown(item, itemSelection, selectedMenuEntry)">
                                    </app-action>
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </td>
        </tr>
        <tr>
            <td class="menu-entry-detail-card-container">
                <mat-card>
                    <mat-card-header>
                        <table width="100%" class="no-spacing-no-padding-table">
                            <tr>
                                <td width="100%">
                                    <mat-card-title>{{ 'removable_ingredients' | translate }}</mat-card-title>
                                </td>
                                <td>
                                    <app-action icon="add" [tooltip]="'add_item' | translate" (onExecute)="selectItemForRemovableIngredients(selectedMenuEntry)">
                                    </app-action>
                                </td>
                            </tr>
                        </table>
                    </mat-card-header>
                    <mat-card-content>
                        <table width="100%">
                            <tr *ngFor="let item of getMenuEntryRemovableIngredients(selectedMenuEntry)">
                                <td width="100%">
                                    {{ 'without' | translate: { item: getNonEmptyText(item.name) } }}
                                </td>
                                <td><mat-checkbox></mat-checkbox></td>
                                <!--
                                We do not use app-item-reused-warning here because it would be too expensive to alculate it for each item.
                                <td>
                                    <app-item-reused-warning [itemId]="item.id" [menuSpec]="menuSpec">
                                    </app-item-reused-warning>
                                </td>
                                -->
                                <td>
                                    <app-action icon="edit" [tooltip]="'edit' | translate" (onExecute)="updateItem(item)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="remove_circle_outline" [tooltip]="'remove' | translate" (onExecute)="removeItemForRemovableIngredients(item, selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="north" [tooltip]="'move_up' | translate" [disabled]="isFirstItemInRemovableIngredients(item, selectedMenuEntry)" (onExecute)="moveItemInRemovableIngredientsUp(item, selectedMenuEntry)">
                                    </app-action>
                                </td>
                                <td>
                                    <app-action icon="south" [tooltip]="'move_down' | translate" [disabled]="isLastItemInRemovableIngredients(item, selectedMenuEntry)" (onExecute)="moveItemInRemovableIngredientsDown(item, selectedMenuEntry)">
                                    </app-action>
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </td>
        </tr>
    </table>
</div>
