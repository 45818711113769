<h2 mat-dialog-title>{{ 'category' | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'name_en' | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.category.nameEn" cdkFocusInitial>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'name_es' | translate }}</mat-label>
                    <input matInput type="text" [(ngModel)]="data.category.nameEs">
                </mat-form-field>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidCategory()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
