import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { EditorSchedule, SelectScheduleDialogData, ScheduleEditorDialogData } from '../util/interfaces';
import { MatSelectModule } from '@angular/material/select';
import { Formatter } from 'src/app/specific/util/formatter';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ScheduleEditorDialogService } from '../schedule-editor-dialog/schedule-editor-dialog.service';
import { MenuEditorUtil } from '../util/menu-editor-util';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateMenuEntrySchedule(): void {
//   const inputDialogData: SelectScheduleDialogData = {
//   }
//   this.selectScheduleDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-select-schedule-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MatSelectModule,
    ActionComponent
  ],
  templateUrl: './select-schedule-dialog.component.html',
  styleUrls: ['./select-schedule-dialog.component.css']
})
export class SelectScheduleDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SelectScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectScheduleDialogData,
    private localizationService: LocalizationService,
    private scheduleEditorDialogService: ScheduleEditorDialogService) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public getText(schedule: EditorSchedule): string {
    return Formatter.formatSchedule(
      MenuEditorUtil.editorScheduleToAvailabilityScheduleProto(schedule).schedule,
      this.localizationService.isSpanish() ? proto.waiternow.common.Language.SPANISH : proto.waiternow.common.Language.ENGLISH);
  }

  public isValidMenuEntrySchedule(): boolean {
    return true;
  }

  public createSchedule(): void {
    const inputDialogData: ScheduleEditorDialogData = {
      schedule: {
        dailyRecurrence: {
          recurrenceType: proto.waiternow.common.DailyRecurrenceProto.RecurrenceType.DAILY
        }
      },
      menuSpec: this.data.menuSpec
    }
    this.scheduleEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        this.data.schedule = outputDialogData.schedule;
        this.data.allSchedules?.push(outputDialogData.schedule);
      }
    );
  }

  public editSelectedSchedule(): void {
    if (!this.data.schedule) {
      return;
    }
    const inputDialogData: ScheduleEditorDialogData = {
      schedule: this.data.schedule,
      menuSpec: this.data.menuSpec
    }
    this.scheduleEditorDialogService.openEditor(
      inputDialogData,
      /* onUpdate */ outputDialogData => {
        this.data.schedule = outputDialogData.schedule;
        this.data.allSchedules?.push(outputDialogData.schedule);
      }
    );
  }
}
