<app-loadable-content [loadingController]="businessLocationsLoadingController" (onRetry)="loadLocations()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'locations' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-data-table
                [data]="locations"
                [columns]="columns"
                [toolbarActions]="locationToolbarModel.actions"
                [compactToolbarActions]="locationToolbarModel.compactActions">
            </app-data-table>
        </mat-card-content>
    </mat-card>
</app-loadable-content>
