<app-page>
    <app-title>{{ 'add_business' | translate }}</app-title>
    <table appFieldContainer align="center">
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'business_name' | translate }}</mat-label>
                    <input matInput [formControl]="businessNameField" required>
                    <mat-error *ngIf="businessNameField.invalid">{{formService.getFieldErrorMessage(businessNameField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'description' | translate }}</mat-label>
                    <input matInput [formControl]="descriptionField" required>
                    <mat-error *ngIf="descriptionField.invalid">{{formService.getFieldErrorMessage(descriptionField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td align="center">
                <app-action [text]="'submit' | translate"
                    [disabled]="businessNameField.invalid || descriptionField.invalid"
                    (onAsyncExecute)="addBusiness($event)">
                </app-action>
            </td>
        </tr>
    </table>
</app-page>
