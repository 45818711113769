<app-right-aligned-compact-content>
    <table>
        <tr>
            <td>
                <app-schedule-reused-warning [scheduleId]="data.schedule.id" [menuSpec]="data.menuSpec"></app-schedule-reused-warning>
            </td>
        </tr>
    </table>
</app-right-aligned-compact-content>
<h2 mat-dialog-title>{{ 'schedule' | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td colspan="3">
                <mat-form-field appField>
                    <mat-label>{{ 'recurrence_type' | translate }}</mat-label>
                    <mat-select [(ngModel)]="data.schedule.dailyRecurrence.recurrenceType" cdkFocusInitial>
                        <mat-option [value]="1">DAILY</mat-option>
                        <mat-option [value]="2">WEEKDAYS</mat-option>
                        <mat-option [value]="3">WEEKENDS</mat-option>
                        <mat-option [value]="4">WEEKLY_ON_SUNDAY</mat-option>
                        <mat-option [value]="5">WEEKLY_ON_MONDAY</mat-option>
                        <mat-option [value]="6">WEEKLY_ON_TUESDAY</mat-option>
                        <mat-option [value]="7">WEEKLY_ON_WEDNESDAY</mat-option>
                        <mat-option [value]="8">WEEKLY_ON_THURSDAY</mat-option>
                        <mat-option [value]="9">WEEKLY_ON_FRIDAY</mat-option>
                        <mat-option [value]="10">WEEKLY_ON_SATURDAY</mat-option>
                        <mat-option [value]="11">MONTHLY_ON_FIRST_SUNDAY</mat-option>
                        <mat-option [value]="12">MONTHLY_ON_FIRST_MONDAY</mat-option>
                        <mat-option [value]="13">MONTHLY_ON_FIRST_TUESDAY</mat-option>
                        <mat-option [value]="14">MONTHLY_ON_FIRST_WEDNESDAY</mat-option>
                        <mat-option [value]="15">MONTHLY_ON_FIRST_THURSDAY</mat-option>
                        <mat-option [value]="16">MONTHLY_ON_FIRST_FRIDAY</mat-option>
                        <mat-option [value]="17">MONTHLY_ON_FIRST_SATURDAY</mat-option>
                        <mat-option [value]="18">MONTHLY_ON_SECOND_SUNDAY</mat-option>
                        <mat-option [value]="19">MONTHLY_ON_SECOND_MONDAY</mat-option>
                        <mat-option [value]="20">MONTHLY_ON_SECOND_TUESDAY</mat-option>
                        <mat-option [value]="21">MONTHLY_ON_SECOND_WEDNESDAY</mat-option>
                        <mat-option [value]="22">MONTHLY_ON_SECOND_THURSDAY</mat-option>
                        <mat-option [value]="23">MONTHLY_ON_SECOND_FRIDAY</mat-option>
                        <mat-option [value]="24">MONTHLY_ON_SECOND_SATURDAY</mat-option>
                        <mat-option [value]="25">MONTHLY_ON_THIRD_SUNDAY</mat-option>
                        <mat-option [value]="26">MONTHLY_ON_THIRD_MONDAY</mat-option>
                        <mat-option [value]="27">MONTHLY_ON_THIRD_TUESDAY</mat-option>
                        <mat-option [value]="28">MONTHLY_ON_THIRD_WEDNESDAY</mat-option>
                        <mat-option [value]="29">MONTHLY_ON_THIRD_THURSDAY</mat-option>
                        <mat-option [value]="30">MONTHLY_ON_THIRD_FRIDAY</mat-option>
                        <mat-option [value]="31">MONTHLY_ON_THIRD_SATURDAY</mat-option>
                        <mat-option [value]="32">MONTHLY_ON_LAST_SUNDAY</mat-option>
                        <mat-option [value]="33">MONTHLY_ON_LAST_MONDAY</mat-option>
                        <mat-option [value]="34">MONTHLY_ON_LAST_TUESDAY</mat-option>
                        <mat-option [value]="35">MONTHLY_ON_LAST_WEDNESDAY</mat-option>
                        <mat-option [value]="36">MONTHLY_ON_LAST_THURSDAY</mat-option>
                        <mat-option [value]="37">MONTHLY_ON_LAST_FRIDAY</mat-option>
                        <mat-option [value]="38">MONTHLY_ON_LAST_SATURDAY</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                {{ 'starts_at' | translate }}
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="data.schedule.dailyRecurrence.startsAtHour">
                        <mat-option  *ngFor="let irrelevant of createArray(24); let i = index" [value]="i">
                            {{ i }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                :
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="data.schedule.dailyRecurrence.startsAtMinute">
                        <mat-option  *ngFor="let irrelevant of createArray(60); let i = index" [value]="i">
                            {{ i }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                {{ 'ends_at' | translate }}
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="data.schedule.dailyRecurrence.endsAtHour">
                        <mat-option  *ngFor="let irrelevant of createArray(24); let i = index" [value]="i">
                            {{ i }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td>
                :
            </td>
            <td>
                <mat-form-field>
                    <mat-select [(ngModel)]="data.schedule.dailyRecurrence.endsAtMinute">
                        <mat-option  *ngFor="let irrelevant of createArray(60); let i = index" [value]="i">
                            {{ i }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-checkbox [(ngModel)]="data.schedule.isInverted">{{ 'inverted' | translate }}</mat-checkbox>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidSchedule()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
