import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '../../services/device.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-heading>{{ 'find_business' | translate }}</app-heading>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-heading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.css']
})
export class HeadingComponent {

  constructor(public deviceService: DeviceService) {
  }
}
