import { Routes } from '@angular/router';
import { HomePageComponent } from './specific/pages/home-page/home-page.component';
import { PageNotFoundComponent } from './specific/pages/page-not-found/page-not-found.component';
import { SignInPageComponent } from './specific/pages/sign-in-page/sign-in-page.component';
import { MetricsPageComponent } from './specific/pages/metrics-page/metrics-page.component';
import { AdminUserPageComponent } from './specific/pages/admin-user-page/admin-user-page.component';
import { DebugPageComponent } from './specific/pages/debug-page/debug-page.component';
import { BusinessPageComponent } from './specific/pages/business-page/business-page.component';
import { EditBusinessPageComponent } from './specific/pages/edit-business-page/edit-business-page.component';
import { LocationPageComponent } from './specific/pages/location-page/location-page.component';
import { EditLocationPageComponent } from './specific/pages/edit-location-page/edit-location-page.component';
import { LocationDevicesPageComponent } from './specific/pages/location-devices-page/location-devices-page.component';
import { LocationMetricsPageComponent } from './specific/pages/location-metrics-page/location-metrics-page.component';
import { LocationOrdersPageComponent } from './specific/pages/location-orders-page/location-orders-page.component';
import { LocationRefundedOrdersPageComponent } from './specific/pages/location-refunded-orders-page/location-refunded-orders-page.component';
import { LocationQrCodesPageComponent } from './specific/pages/location-qr-codes-page/location-qr-codes-page.component';
import { LocationPointsOfServicePageComponent } from './specific/pages/location-points-of-service-page/location-points-of-service-page.component';
import { UnackedAndPaidOrdersPageComponent } from './specific/pages/unacked-and-paid-orders-page/unacked-and-paid-orders-page.component';
import { CreateUserPageComponent } from './specific/pages/create-user-page/create-user-page.component';
import { UserPageComponent } from './specific/pages/user-page/user-page.component';
import { EditUserPageComponent } from './specific/pages/edit-user-page/edit-user-page.component';
import { PointOfServicePageComponent } from './specific/pages/point-of-service-page/point-of-service-page.component';
import { EditPointOfServicePageComponent } from './specific/pages/edit-point-of-service-page/edit-point-of-service-page.component';
import { CampaignPageComponent } from './specific/pages/campaign-page/campaign-page.component';
import { EditCampaignPageComponent } from './specific/pages/edit-campaign-page/edit-campaign-page.component';
import { OrderPageComponent } from './specific/pages/order-page/order-page.component';
import { EditOrderPageComponent } from './specific/pages/edit-order-page/edit-order-page.component';
import { CampaignsPageComponent } from './specific/pages/campaigns-page/campaigns-page.component';
import { UserBusinessesPageComponent } from './specific/pages/user-businesses-page/user-businesses-page.component';
import { BusinessUsersPageComponent } from './specific/pages/business-users-page/business-users-page.component';
import { BusinessLocationsPageComponent } from './specific/pages/business-locations-page/business-locations-page.component';
import { AddBusinessPageComponent } from './specific/pages/add-business-page/add-business-page.component';
import { AddCampaignPageComponent } from './specific/pages/add-campaign-page/add-campaign-page.component';
import { AddLocationPageComponent } from './specific/pages/add-location-page/add-location-page.component';
import { AddPointOfServicePageComponent } from './specific/pages/add-point-of-service-page/add-point-of-service-page.component';
import { DeviceOrdersPageComponent } from './specific/pages/device-orders-page/device-orders-page.component';
import { EditMenuPageComponent } from './specific/pages/edit-menu-page/edit-menu-page.component';
import { DisputedOrdersPageComponent } from './specific/pages/disputed-orders-page/disputed-orders-page.component';
import { LocationDisputedOrdersPageComponent } from './specific/pages/location-disputed-orders-page/location-disputed-orders-page.component';

// https://angular.io/guide/router
// https://angular.io/start/start-routing

const routeConfig: Routes = [
  {
    path: '',
    component: HomePageComponent,
    title: 'Home page'
  },
  {
    path: 'signin',
    component: SignInPageComponent,
    title: 'Sign-in'
  },
  {
    path: 'metrics',
    component: MetricsPageComponent,
    title: 'Metrics'
  },
  {
    path: 'admin_user',
    component: AdminUserPageComponent,
    title: 'admin_user'
  },
  {
    path: 'debug',
    component: DebugPageComponent,
    title: 'Debug'
  },
  {
    path: 'unacked_and_paid_orders',
    component: UnackedAndPaidOrdersPageComponent,
    title: 'Unacked and Paid Orders'
  },
  {
    path: 'disputed_orders',
    component: DisputedOrdersPageComponent,
    title: 'Disputed Orders'
  },
  {
    path: 'create_user',
    component: CreateUserPageComponent,
    title: 'Create User'
  },
  {
    path: 'user/:userIdOrEmail',
    component: UserPageComponent,
    title: 'User'
  },
  {
    path: 'user/:userId/edit',
    component: EditUserPageComponent,
    title: 'User'
  },
  {
    path: 'user/:userId/businesses',
    component: UserBusinessesPageComponent,
    title: 'User Businesses'
  },
  {
    path: 'user/:userId/businesses/add',
    component: AddBusinessPageComponent,
    title: 'Add Businesss'
  },
  {
    path: 'business/:businessId',
    component: BusinessPageComponent,
    title: 'Business'
  },
  {
    path: 'business/:businessId/edit',
    component: EditBusinessPageComponent,
    title: 'Business'
  },
  {
    path: 'business/:businessId/users',
    component: BusinessUsersPageComponent,
    title: 'Business Users'
  },
  {
    path: 'business/:businessId/locations',
    component: BusinessLocationsPageComponent,
    title: 'Business Locations'
  },
  {
    path: 'business/:businessId/locations/add',
    component: AddLocationPageComponent,
    title: 'Add Location'
  },
  {
    path: 'location/:locationId',
    component: LocationPageComponent,
    title: 'Location'
  },
  {
    path: 'location/:locationId/edit',
    component: EditLocationPageComponent,
    title: 'Location'
  },
  {
    path: 'location/:locationId/devices',
    component: LocationDevicesPageComponent,
    title: 'Location Devices'
  },
  {
    path: 'location/:locationId/metrics',
    component: LocationMetricsPageComponent,
    title: 'Location Metrics'
  },
  {
    path: 'location/:locationId/orders',
    component: LocationOrdersPageComponent,
    title: 'Location Orders'
  },
  {
    path: 'location/:locationId/points_of_service',
    component: LocationPointsOfServicePageComponent,
    title: 'Location Points of Service'
  },
  {
    path: 'location/:locationId/points_of_service/add',
    component: AddPointOfServicePageComponent,
    title: 'Add Point of Service'
  },
  {
    path: 'location/:locationId/refunded_orders',
    component: LocationRefundedOrdersPageComponent,
    title: 'Location Refunded Orders'
  },
  {
    path: 'location/:locationId/disputed_orders',
    component: LocationDisputedOrdersPageComponent,
    title: 'Location Disputed Orders'
  },
  {
    path: 'location/:locationId/qr_codes',
  component: LocationQrCodesPageComponent,
    title: 'Location QR Codes'
  },
  {
    path: 'point_of_service/:pointOfServiceId',
    component: PointOfServicePageComponent,
    title: 'Point of Service'
  },
  {
    path: 'point_of_service/:pointOfServiceId/edit',
    component: EditPointOfServicePageComponent,
    title: 'Point of Service'
  },
  {
    path: 'campaign/:campaignId',
    component: CampaignPageComponent,
    title: 'Campaign'
  },
  {
    path: 'campaign/:campaignId/edit',
    component: EditCampaignPageComponent,
    title: 'Campaign'
  },
  {
    path: 'business/:businessId/campaigns',
    component: CampaignsPageComponent,
    title: 'Business Campaigns'
  },
  {
    path: 'business/:businessId/campaigns/add',
    component: AddCampaignPageComponent,
    title: 'Add Business Campaign'
  },
  {
    path: 'location/:locationId/campaigns',
    component: CampaignsPageComponent,
    title: 'Location Campaigns'
  },
  {
    path: 'location/:locationId/campaigns/add',
    component: AddCampaignPageComponent,
    title: 'Add Location Campaign'
  },
  {
    path: 'order/:orderId',
    component: OrderPageComponent,
    title: 'Order'
  },
  {
    path: 'order/:orderId/edit',
    component: EditOrderPageComponent,
    title: 'Order'
  },
  {
    path: 'device_orders',
    component: DeviceOrdersPageComponent,
    title: 'Device Orders'
  },
  {
    path: 'business/:businessId/menu/edit',
    component: EditMenuPageComponent,
    title: 'Menu Editor'
  },
  {
    path: 'location/:locationId/menu/edit',
    component: EditMenuPageComponent,
    title: 'Menu Editor'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'Page not found'
  },
];

export default routeConfig;
