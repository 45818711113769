import { Util } from "src/app/general/util/util";
import { BackendService } from "../services/backend.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { ToolbarUtil } from "../util/toolbar-util";
import { NavigationService } from "../services/navigation.service";
import { DialogService } from "src/app/general/services/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Consumer } from "src/app/general/interfaces/functions";
import { ToastService } from "src/app/general/services/toast.service";
import { ActionObserver } from "src/app/general/components/action/action";
import { ErrorResult, SuccessResult } from "src/app/general/util/result";
import * as proto from 'src/proto/compiled-protos';

export class PointOfServiceToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;
  navigationService: NavigationService;
  translateService: TranslateService;
  dialogService: DialogService;
  toastService: ToastService;
  compactForMobile: boolean;
  onPointOfServiceDeleted: Consumer<string>

  public actions: Array<ToolbarAction<proto.waiternow.common.IPointOfServiceProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.IPointOfServiceProto>>;

  /**
   * @param compactForMobile true to show open in new window for mobile devices.
   *                         Useful when the tool bar is part of a data table row.
   */
  constructor(
      sessionService: SessionService,
      backendService: BackendService,
      navigationService: NavigationService,
      translateService: TranslateService,
      dialogService: DialogService,
      toastService: ToastService,
      compactForMobile: boolean,
      onPointOfServiceDeleted: Consumer<string>) {
    this.sessionService = sessionService;
    this.backendService = backendService;
    this.navigationService = navigationService;
    this.translateService = translateService
    this.dialogService = dialogService
    this.toastService = toastService
    this.compactForMobile = compactForMobile;
    this.onPointOfServiceDeleted = onPointOfServiceDeleted;

    this.actions =
    [
      {
        icon: 'business',
        tooltipTranslateId: 'go_to_location',
        onExecute: pointOfService => this.navigationService.goToLocationPage(Util.safeString(pointOfService?.locationId))
      },
      ToolbarUtil.createToolbarActionEdit(
        pointOfService => this.navigationService.goToEditPointOfServicePage(Util.safeString(pointOfService.id))),
      ToolbarUtil.createToolbarActionDelete(
        pointOfService => this.deletePointOfService(pointOfService)),
      ToolbarUtil.createToolbarActionViewProto(ProtoType.POINT_OF_SERVICE, pointOfService => Util.safeString(pointOfService.id), this.sessionService),
      {
        icon: 'on_device_training',
        tooltipTranslateId: 'go_to_consumer',
        onExecute: pointOfService => this.navigationService.goToConsumer(Util.safeString(pointOfService?.id))
      },
      {
        icon: 'app_registration',
        tooltipTranslateId: 'check_in',
        onAsyncExecute: (pointOfService, actionObserver) => this.checkIn(pointOfService, actionObserver)
      },
    ];

    if (this.compactForMobile) {
      const openInNewWindowAction: ToolbarAction<proto.waiternow.common.IPointOfServiceProto> =
          ToolbarUtil.createToolbarActionOpenInNewWindow(
              pointOfService => this.navigationService.goToPointOfServicePage(Util.safeString(pointOfService.id)));
      Util.insertIntoArray(openInNewWindowAction, 0, this.actions);
      this.compactActions = [openInNewWindowAction];
    } else {
      this.compactActions = this.actions;
    }
  }

  private checkIn(pointOfService: proto.waiternow.common.IPointOfServiceProto | undefined, actionObserver: ActionObserver) {
    if (!pointOfService) {
      actionObserver.onError(ErrorResult.empty());
    }
    this.backendService.checkIn(
      Util.safeString(pointOfService?.id),
      /* onSuccess= */ checkInData => {
        console.log(checkInData);
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('check_console'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_checking_in'));
      }
    );
  }


  private deletePointOfService(pointOfService: proto.waiternow.common.IPointOfServiceProto): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_point_of_service', { friendly_name: pointOfService.friendlyName }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.deletePointOfService(
            Util.safeString(pointOfService.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('point_of_service_deleted').subscribe(text => this.toastService.success(text));
              this.onPointOfServiceDeleted(Util.safeString(pointOfService.id));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_point_of_service').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }
}
