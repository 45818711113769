import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BackendService } from '../../services/backend.service';
import { Formatter } from '../../util/formatter';
import { MatCardModule } from '@angular/material/card';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { CompactTextColumn, DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LocationToolbarModel } from '../../model/location-toolbar-model';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-business-locations businessId="{{business.id}}"></app-business-locations>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-business-locations',
  standalone: true,
  imports: [
    CommonModule,
    LoadableContentComponent,
    TranslateModule,
    MatCardModule,
    DataTableComponent
  ],
  templateUrl: './business-locations.component.html',
  styleUrls: ['./business-locations.component.css']
})
export class BusinessLocationsComponent implements OnInit, OnChanges {
  @Input() businessId!: string;

  businessLocationsLoadingController: LoadingController;

  locations: Array<proto.waiternow.common.ILocationProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.ILocationProto>>;

  locationToolbarModel: LocationToolbarModel;

  constructor(
      private backendService: BackendService,
      public navigationService: NavigationService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private toastService: ToastService,
      public sessionService: SessionService) {
    this.businessLocationsLoadingController = new LoadingController();

    this.locations = new Array();
    this.columns = [
      new CompactTextColumn(
        /* name= */ 'id',
        /* translateId= */ 'id',
        /* valueExtractor= */  location => location.id,
        /* compactTextLength= */ 6),
      new StringColumn(
        /* name= */ 'name',
        /* translateId= */ 'name',
        /* valueExtractor= */  location => location.name),
      new StringColumn(
        /* name= */ 'address',
        /* translateId= */ 'address',
        /* valueExtractor= */  location => Formatter.formatAddress(location.address),
        /* hideInCompactMode= */ true)
      ];

    this.locationToolbarModel = new LocationToolbarModel(
      this.sessionService,
      this.backendService,
      this.navigationService,
      this.translateService,
      this.dialogService,
      this.toastService,
      /* compactForMobile= */ true,
      /* onLocationDeleted= */ locationId => this.loadLocations()
    );
  }

  ngOnInit(): void {
    this.loadLocations();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('businessId', changes)) {
      this.ngOnInit();
    }
  }

  public loadLocations(): void {
    if (!this.businessId) {
      return;
    }
    this.businessLocationsLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_business_locations'));
    this.backendService.findBusinessLocations(
      this.businessId,
      /* onSuccess= */ locationsProto => {
        if (locationsProto && locationsProto?.locations) {
          this.locations = locationsProto?.locations;
        } else {
          this.locations = [];
        }
        this.businessLocationsLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.businessLocationsLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_business_locations'));
      }
    );
  }
}
