import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormService } from '../../services/form.service';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ComponentUtil } from '../../util/component-util';
import { TranslateModule } from '@ngx-translate/core';
import { LoggingService } from '../../services/logging.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <div>
//   <app-quantity-picker
//       label="Quantity picker"
//       [required]="true"
//       [disabled]="false"
//       [units]="['METER', 'FOOT', 'KILOMETER', 'MILE']"
//       [value]="initialQuantityValue"
//       (onValueChange)="onQuantityChange($event)">
//   </app-quantity-picker>
// </div>

// TS:

// initialQuantityValue: Quantity = {value: 1.5, unit: 'FOOT'};
// public onQuantityChange(quantity: Quantity | null | undefined) {
//   // quantity could be null or undefined if the user manually deleted the text from the input field
//   console.log('Quantity changed: ' + quantity.value + ' ' + quantity.unit);
// }
// ----------------------------------------------------------------------------

export interface Quantity {
  value: number;
  unit: string;
}

interface DialogData {
  value: number;
  unit: string;
  units: Array<string>;
}

@Component({
  selector: 'app-quantity-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './quantity-picker.component.html',
  styleUrls: ['./quantity-picker.component.css']
})
export class QuantityPickerComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() required?: boolean;
  @Input() disabled?: boolean;
  @Input() units!: Array<string>;
  @Input() value?: Quantity | null | undefined;
  @Output() onValueChange: EventEmitter<Quantity | null | undefined> = new EventEmitter<Quantity | null | undefined>();

  @ViewChild('text_format_input', {read: ElementRef}) textFormatInput!: ElementRef;

  currentQuantity: Quantity | null | undefined;

  textFormatFormControl: FormControl;

  constructor(
      public formService: FormService,
      private loggingService: LoggingService,
      private dialog: MatDialog) {
    this.textFormatFormControl = new FormControl('');
  }

  ngOnInit(): void {
    if (this.required) {
      this.textFormatFormControl.addValidators([Validators.required]);
    }
    if (this.disabled) {
      this.textFormatFormControl.disable();
    } else {
      this.textFormatFormControl.enable();
    }
    if (this.value) {
      this.currentQuantity = this.value;
      this.textFormatFormControl.setValue(this.quantityToString(this.currentQuantity));
    }
    this.textFormatFormControl.valueChanges.subscribe(() => {
      this.currentQuantity = this.stringToQuantity(this.textFormatFormControl.value);
      this.onValueChange.emit(this.currentQuantity);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('value', changes)) {
      if (this.value) {
        this.currentQuantity = this.value;
        this.textFormatFormControl.setValue(this.quantityToString(this.currentQuantity));
      } else {
        this.currentQuantity = null;
        this.textFormatFormControl.setValue('');
      }
    }
    if (ComponentUtil.bindingChanged('disabled', changes)) {
      if (this.disabled) {
        this.textFormatFormControl.disable();
      } else {
        this.textFormatFormControl.enable();
      }
    }
  }

  // Since 'required' is an optional input, it could be undefined. So, in order to use it in the template, we need this method that returns a boolean.
  public isRequired(): boolean {
    if (this.required) {
      return true;
    }
    return false;
  }

  public openEditor(): void {
    const dialogData: DialogData = {value: 0, unit: this.units[0], units: this.units};
    if (this.currentQuantity) {
      dialogData.value = this.currentQuantity.value;
      dialogData.unit = this.currentQuantity.unit;
    }

    const textFormatInputRect = this.textFormatInput.nativeElement.getBoundingClientRect();

    const dialogRef = this.dialog.open(QuantityEditorDialogComponent, {
      // This field must be named 'data'
      data: dialogData,
      autoFocus: true,
      // Thi si required to allow closing the dialog by clicking at any place outside the dialog
      hasBackdrop: true,
      // If this is false clicking at any place outside the dialog closes the dialog
      disableClose: false,
      position: {
        left: (textFormatInputRect.left - 10) + 'px',
        top: (textFormatInputRect.top + textFormatInputRect.height + 10) + 'px'
      }
    });

    dialogRef.afterClosed().subscribe(dialogData => {
      if (dialogData) {
        this.currentQuantity = { value: dialogData.value, unit: dialogData.unit };
        this.textFormatFormControl.setValue(this.quantityToString(this.currentQuantity));
        // textFormatFormControl emits the onValueChange
        // this.onValueChange.emit(this.currentQuantity);
      }
    });
  }

  private quantityToString(quantity: Quantity | null | undefined): string {
    if (!quantity) {
      return '';
    }
    return quantity.value + ' ' + quantity.unit;
  }

  private stringToQuantity(strQuantity: string | null | undefined): Quantity | null | undefined {
    if (!strQuantity) {
      return null;
    }

    const quantityComponents = strQuantity.trim().split(' ');
    if (quantityComponents.length != 2) {
      this.loggingService.logError('Invalid string quantity: ' + strQuantity);
      return null;
    }

    let value: number = 0;
    let unit: string = '';

    value = Number(quantityComponents[0]);
    unit = quantityComponents[1];

    if (isNaN(value)) {
      this.loggingService.logError('Invalid string quantity: ' + strQuantity);
      return null;
    }

    if (unit.trim().length == 0) {
      this.loggingService.logError('Invalid string quantity: ' + strQuantity);
      return null;
    }

    return {value: value, unit:unit};
  }
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    TranslateModule
  ],
  templateUrl: './quantity-picker-editor.component.html',
})
export class QuantityEditorDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<QuantityEditorDialogComponent>,
      // This field must be named 'data'
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
