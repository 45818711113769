import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { CategoryEditorDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateCategory(category: proto.waiternow.common.StructuredMenuProto.ICategoryLevel1Proto): void {
//   const inputDialogData: CategoryEditorDialogData = {
//     category: {
//       nameEn: Formatter.getText(category.name, proto.waiternow.common.Language.ENGLISH),
//       nameEs: Formatter.getText(category.name, proto.waiternow.common.Language.SPANISH)
//     }
//   }
//   this.categoryEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-category-editor-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective
  ],
  templateUrl: './category-editor-dialog.component.html',
  styleUrls: ['./category-editor-dialog.component.css']
})
export class CategoryEditorDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CategoryEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CategoryEditorDialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public isValidCategory(): boolean {
    if (this.data.category.nameEn) {
      return true;
    }
    if (this.data.category.nameEs) {
      return true;
    }
    return false;
  }
}
