import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-no-image-placeholder matTooltip="Logo" *ngIf="!business.logo"></app-no-image-placeholder>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-no-image-placeholder',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatIconModule],
  templateUrl: './no-image-placeholder.component.html',
  styleUrls: ['./no-image-placeholder.component.css']
})
export class NoImagePlaceholderComponent {
  @Input() tooltip!: string;

  constructor() {    
  }
}
