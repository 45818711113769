import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '../../services/device.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-subtitle>{{ 'find_business' | translate }}</app-subtitle>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-subtitle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './subtitle.component.html',
  styleUrls: ['./subtitle.component.css']
})
export class SubtitleComponent {

  constructor(public deviceService: DeviceService) {
  }
}
