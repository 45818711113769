import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { Money, MoneyPickerComponent } from 'src/app/general/components/money-picker/money-picker.component';
import { CURRENCIES } from 'src/app/specific/util/constants';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateElementField, CreateElementFieldType, ElementCreationHelper, MultiSelectComponent } from 'src/app/general/components/multi-select/multi-select.component';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { Util } from 'src/app/general/util/util';
import { BiFunction, Function } from 'src/app/general/interfaces/functions';
import { HeadingComponent } from 'src/app/general/components/heading/heading.component';
import { EditorCategory, EditorItem, MenuItemEditorDialogData as MenuItemEditorDialogData } from '../util/interfaces';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateMenuEntry(menuEntry: proto.waiternow.common.StructuredMenuProto.IMenuEntryProto): void {
//   const inputDialogData: MenuEntryEditorDialogData = {
//     menuItem: {
//       nameEn: Formatter.getText(menuEntry.menuItem.name, proto.waiternow.common.Language.ENGLISH),
//       nameEs: Formatter.getText(menuEntry.menuItem.name, proto.waiternow.common.Language.SPANISH)
//     },
//     allCategories: ...
//   }
//   this.menuEntryEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-menu-item-editor-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MoneyPickerComponent,
    MatCheckboxModule,
    MultiSelectComponent,
    HeadingComponent,
  ],
  templateUrl: './menu-item-editor-dialog.component.html',
  styleUrls: ['./menu-item-editor-dialog.component.css']
})
export class MenuItemEditorDialogComponent {
  readonly CURRENCIES: Array<string> = CURRENCIES;

  initialMoneyValue?: Money;

  categoryTextProvider: Function<EditorCategory, string> = category => this.getNonEmptyName(category);
  categoryComparator: BiFunction<EditorCategory, EditorCategory, boolean> = (a, b) => a == b;

  categoryCreationHelper = new (class implements ElementCreationHelper<EditorCategory> {
    public getCreateElementDialogTitleTranslateId(): string {
      return 'create_category';
    }
    public getFields(): Array<CreateElementField> {
      return [
        {
          translateId: 'name_en',
          type: CreateElementFieldType.TEXT,
          isRequired: true
        },
        {
          translateId: 'name_es',
          type: CreateElementFieldType.TEXT
        }
      ];
    }
    public createElement(data: Array<CreateElementField>): EditorItem {
      return {
        nameEn: data[0].valueAsText,
        nameEs: data[1].valueAsText
      };
    }
  })();

  constructor(
      public dialogRef: MatDialogRef<MenuItemEditorDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: MenuItemEditorDialogData,
      private localizationService: LocalizationService) {
    if (data && data.menuItem.price) {
      this.initialMoneyValue = {
        units: data.menuItem.price.units,
        cents: data.menuItem.price.cents,
        currencyCode: data.menuItem.price.currencyCode
      }
    }
  }

  public onPriceChange(money: Money | null | undefined) {
    if (money ) {
      this.data.menuItem.price = money;
    } else {
      this.data.menuItem.price = undefined;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public getNonEmptyName(category: EditorCategory): string {
    if (!category) {
      return '';
    }
    let str: string | null | undefined;
    if (this.localizationService.isSpanish() || !category.nameEn) {
      str = category.nameEs;
    }
    if (!str) {
      str = category.nameEn;
    }
    return Util.safeString(str);
  }

  public onSelectedCategoriesChange(selectedCategories: Array<EditorCategory>): void {
    this.data.menuItem.categories = selectedCategories;
  }

  public isValidMenuEntry(): boolean {
    if (!this.data.menuItem.nameEn && !this.data.menuItem.nameEs) {
      return false;
    }
    if (!this.data.menuItem.categories || this.data.menuItem.categories.length == 0) {
      return false;
    }
    return true;
  }
}
