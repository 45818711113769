<table *ngIf="!compactActions || compactActions.length == 0 || !deviceService.isMobile()" class="toolbar-table">
    <tr>
        <td *ngFor="let action of actions">
            <app-action
                *ngIf="isExecuteAction(action) && !isHiddenAction(action)"
                [text]="toSafeString(action.textTranslateId) | translate"
                [icon]="action.icon"
                [tooltip]="toSafeString(action.tooltipTranslateId) | translate"
                [disabled]="isDisabledAction(action)"
                (onExecute)="executeAction(action)">
            </app-action>
            <app-action
                *ngIf="isAsyncExecuteAction(action) && !isHiddenAction(action)"
                [text]="toSafeString(action.textTranslateId) | translate"
                [icon]="action.icon"
                [tooltip]="toSafeString(action.tooltipTranslateId) | translate"
                [disabled]="isDisabledAction(action)"
                [resetSignal]="resetSignal"
                (onAsyncExecute)="executeAsyncAction(action, $event)">
            </app-action>
            <app-action
                *ngIf="isFormPostActionToOpenInNewWindow(action) && !isHiddenAction(action)"
                [text]="toSafeString(action.textTranslateId) | translate"
                [icon]="action.icon"
                [tooltip]="toSafeString(action.tooltipTranslateId) | translate"
                [disabled]="isDisabledAction(action)"
                [urlToOpenInNewWindowWithFormRequest]="getFormPostAction(action).url"
                [formParams]="getFormPostAction(action).formParams">
            </app-action>
        </td>
    </tr>
</table>
<table *ngIf="compactActions && compactActions.length > 0 && deviceService.isMobile()" class="toolbar-table">
    <tr>
        <td *ngFor="let action of compactActions">
            <app-action
                *ngIf="isExecuteAction(action) && !isHiddenAction(action)"
                [text]="toSafeString(action.textTranslateId) | translate"
                [icon]="action.icon"
                [tooltip]="toSafeString(action.tooltipTranslateId) | translate"
                [disabled]="isDisabledAction(action)"
                (onExecute)="executeAction(action)">
            </app-action>
            <app-action
                *ngIf="isAsyncExecuteAction(action) && !isHiddenAction(action)"
                [text]="toSafeString(action.textTranslateId) | translate"
                [icon]="action.icon"
                [tooltip]="toSafeString(action.tooltipTranslateId) | translate"
                [disabled]="isDisabledAction(action)"
                [resetSignal]="resetSignal"
                (onAsyncExecute)="executeAsyncAction(action, $event)">
            </app-action>
            <app-action
                *ngIf="isFormPostActionToOpenInNewWindow(action) && !isHiddenAction(action)"
                [text]="toSafeString(action.textTranslateId) | translate"
                [icon]="action.icon"
                [tooltip]="toSafeString(action.tooltipTranslateId) | translate"
                [disabled]="isDisabledAction(action)"
                [urlToOpenInNewWindowWithFormRequest]="getFormPostAction(action).url"
                [formParams]="getFormPostAction(action).formParams">
            </app-action>
        </td>
    </tr>
</table>
