import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { TableLayoutComponent } from 'src/app/general/components/table-layout/table-layout.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { ActivatedRoute } from '@angular/router';
import { ActionObserver } from 'src/app/general/components/action/action';
import { Runnable, Consumer } from 'src/app/general/interfaces/functions';
import { FormService } from 'src/app/general/services/form.service';
import { AppError } from 'src/app/general/util/error';
import { SuccessResult, ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { MatSelectModule } from '@angular/material/select';
import { DeviceService } from 'src/app/general/services/device.service';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-edit-point-of-service-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TableLayoutComponent,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    MatSelectModule,
    LoadableContentComponent
  ],
  templateUrl: './edit-point-of-service-page.component.html',
  styleUrls: ['./edit-point-of-service-page.component.css']
})
export class EditPointOfServicePageComponent implements OnInit {
  readonly POINT_OF_SERVICE_TYPE_NONE: string = "none";
  readonly POINT_OF_SERVICE_TYPE_TABLE: string = "table";
  readonly POINT_OF_SERVICE_TYPE_ONLINE: string = "online";
  readonly POINT_OF_SERVICE_TYPE_ONLINE_PICKUP: string = "online-pickup";
  readonly POINT_OF_SERVICE_TYPE_ONSITE_PICKUP: string = "onsite-pickup";
  readonly POINT_OF_SERVICE_TYPE_MENU: string = "menu";

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  pointOfServiceId: string;
  pageTitle: string;
  pointOfServiceLoadingController: LoadingController;

  generalConfigFields = new (class {
    friendlyName: FormControl = new FormControl('', [Validators.required]);
    type: FormControl = new FormControl('');
    vanityUrl: FormControl = new FormControl('');
  })();

  constructor(
      public formService: FormService,
      public deviceService: DeviceService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.pointOfServiceId = '';
    this.pageTitle = '';
    this.pointOfServiceLoadingController = new LoadingController();
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.pointOfServiceId = Util.safeString(routeParams.get('pointOfServiceId'));
    this.loadPointOfService();
  }

  public goToPointOfServicePage(): void {
    this.navigationService.goToPointOfServicePage(this.pointOfServiceId)
  }

  public loadPointOfService(): void {
    this.pointOfServiceLoadingController.onLoadingStarted(LoadingMessage.empty());
    this.backendService.getPointOfService(
      this.pointOfServiceId,
      /* onSuccess= */ pointOfService => {
        this.initFormControls(pointOfService);
        this.pointOfServiceLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.pointOfServiceLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_fetching_point_of_service'));
      }
    );
  }

  private initFormControls(pointOfService: proto.waiternow.common.IPointOfServiceProto | null | undefined): void {
    if (!pointOfService) {
      return;
    }

    this.pageTitle = Util.safeString(pointOfService.friendlyName);

    // General
    this.generalConfigFields.friendlyName.setValue(pointOfService?.friendlyName);
    this.generalConfigFields.vanityUrl.setValue(pointOfService?.vanityUrl);
    if (pointOfService.type == proto.waiternow.common.PointOfServiceProto.PointOfServiceType.TABLE) {
      this.generalConfigFields.type.setValue(this.POINT_OF_SERVICE_TYPE_TABLE);
    } else if(pointOfService.type == proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE) {
      this.generalConfigFields.type.setValue(this.POINT_OF_SERVICE_TYPE_ONLINE);
    } else if(pointOfService.type == proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE_PICKUP) {
      this.generalConfigFields.type.setValue(this.POINT_OF_SERVICE_TYPE_ONLINE_PICKUP);
    } else if(pointOfService.type == proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONSITE_PICKUP) {
      this.generalConfigFields.type.setValue(this.POINT_OF_SERVICE_TYPE_ONSITE_PICKUP);
    } else if(pointOfService.type == proto.waiternow.common.PointOfServiceProto.PointOfServiceType.MENU) {
      this.generalConfigFields.type.setValue(this.POINT_OF_SERVICE_TYPE_MENU);
    } else {
      this.generalConfigFields.type.setValue(this.POINT_OF_SERVICE_TYPE_NONE);
    }
  }

  private createPointOfService(): proto.waiternow.common.PointOfServiceProto {
    const pointOfService = new proto.waiternow.common.PointOfServiceProto();
    pointOfService.id = this.pointOfServiceId;
    return pointOfService;
  }

  private savePointOfService(
      pointOfService: proto.waiternow.common.PointOfServiceProto,
      fieldsToRemove: Array<proto.waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField>,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    this.backendService.updatePointOfService(
      pointOfService,
      fieldsToRemove,
      /* onSuccess= */ () => onSuccess(),
      /* onError= */ error => onError(error)
    );
  }

  public saveGeneral(actionObserver: ActionObserver): void {
    const pointOfService = this.createPointOfService();
    const fieldsToRemove: Array<proto.waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField> = [];

    pointOfService.friendlyName = this.generalConfigFields.friendlyName.value;
    if (this.generalConfigFields.vanityUrl.value) {
      pointOfService.vanityUrl = this.generalConfigFields.vanityUrl.value;
    } else {
      fieldsToRemove.push(proto.waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField.VANITY_URL);
    }
    if (this.generalConfigFields.type.value === this.POINT_OF_SERVICE_TYPE_TABLE) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.TABLE;
    } else if(this.generalConfigFields.type.value === this.POINT_OF_SERVICE_TYPE_ONLINE) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE;
    } else if(this.generalConfigFields.type.value === this.POINT_OF_SERVICE_TYPE_ONLINE_PICKUP) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE_PICKUP;
    } else if(this.generalConfigFields.type.value === this.POINT_OF_SERVICE_TYPE_ONSITE_PICKUP) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONSITE_PICKUP;
    } else if(this.generalConfigFields.type.value === this.POINT_OF_SERVICE_TYPE_MENU) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.MENU;
    } else {
      fieldsToRemove.push(proto.waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField.TYPE);
    }

    this.savePointOfService(
      pointOfService,
      fieldsToRemove,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('saved'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_updating_point_of_service'));
      }
    );
  }
}
