import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-file-selector
//     [isMiniFab]="true"
//     icon="attach_file"
//     iconColor="primary"
//     tooltip="Upload image"
//     fileChooserAcceptFilter="image/*"
//     (onFileSelected)="onFileSelected($event)">
// </app-file-selector>

// <app-file-selector
//     icon="attach_file"
//     tooltip="Upload image"
//     fileChooserAcceptFilter="image/*"
//     (onFileSelected)="onFileSelected($event)">
// </app-file-selector>

// <app-file-selector
//     icon="attach_file"
//     tooltip="Upload image"
//     fileChooserAcceptFilter="image/*"
//     [userData]="dataToGetBackOnFileSelected"
//     (onFileSelected)="onFileSelected($event)">
// </app-file-selector>

// TS:

// public onFileSelected(fileSelectorOutput: FileSelectorOutput<any>) {
//   console.log("File selected..");
//   console.log(fileSelectorOutput.file);
// }

// public onFileSelected(fileSelectorOutput: FileSelectorOutput<DataToGetBackOnFileSelected>) {
//   console.log("File selected..");
//   console.log(fileSelectorOutput.file);
//   console.log(fileSelectorOutput.userData);
// }
// ----------------------------------------------------------------------------

export interface FileSelectorOutput<T> {
  file: File;
  // The user may pass data to the file selector that is passed back on file selected event.
  userData: T | null | undefined;
}

@Component({
  selector: 'app-file-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule
  ],
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.css']
})
export class FileSelectorComponent<T> {
  @Input() isMiniFab!: boolean;
  @Input() icon!: string;
  @Input() iconColor?: string;
  @Input() tooltip?: string;
  // For images: image/*
  @Input() fileChooserAcceptFilter?: string;
  // Data passed to the file selector to be passed back on file selected event.
  @Input() userData? : T;
  @Output() onFileSelected: EventEmitter<FileSelectorOutput<T>> = new EventEmitter<FileSelectorOutput<T>>();

  constructor(
    public dialog: MatDialog) {
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(FileSelectorDialogContent,
      {
        data: {fileChooserAcceptFilter: this.fileChooserAcceptFilter},
      });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.onFileSelected.emit({file: dialogResult, userData: this.userData})
    });
  }
}

interface DialogData {
  fileChooserAcceptFilter?: string;
}

@Component({
  selector: 'file-selector-dialog-content',
  templateUrl: 'file-selector-dialog-content.html',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    NgIf
  ],
})
export class FileSelectorDialogContent implements  AfterViewInit {
  // This is set after the view is initialized
  @ViewChild('file_input_ref_name') fileInput!: ElementRef;

  selectedFile: File | undefined;

  constructor(
    public dialogRef: MatDialogRef<FileSelectorDialogContent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {
  }

  ngAfterViewInit(): void {
    // This will open the file chooser dialog right after the FileSelectorDialogContent is initialized
    this.fileInput.nativeElement.click();
  }

  public onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
    }
  }

  public ok() {
    this.dialogRef.close(/* dialogResult= */ this.selectedFile);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
