import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public getDisputeData(): void {
//   this.disputeDialogService.openDialog(
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

export interface DisputeDialogData {
  disputeDate: Date,
  reason?: string,
}

@Component({
  selector: 'app-dispute-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MatDatepickerModule,
  ],
  // These providers are needed because this is a dialog component: https://github.com/angular/components/issues/28186
  // For regular components see example in https://material.angular.io/components/datepicker/overview
  providers: [ {provide: DateAdapter, useClass: NativeDateAdapter}, {provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS} ],
  templateUrl: './dispute-dialog.component.html',
  styleUrls: ['./dispute-dialog.component.css']
})
export class DisputeDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DisputeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DisputeDialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public isValidDispute(): boolean {
    // Note that since a date picker is used on the input, if the user types 1 it gets translated to 01/01/2001.
    // If the user types 7/1 it gets translated to 07/01/2001. So we set a minimum for the year to force a complete date.
    if (!this.data.disputeDate || this.data.disputeDate.getFullYear() < 2020) {
      return false;
    }
    return true;
  }
}
