import { environment } from 'src/environments/environment';

export class EnvironmentUtil {

  static resolveBackendUrl(relativeUrl: string): string {
    let absoluteUrl = environment.backend.url;
    if (!relativeUrl.startsWith('/')) {
      absoluteUrl += '/';
    }
    absoluteUrl += relativeUrl;
    return absoluteUrl;
  }

  static isDev() : boolean {
    return environment.isDev;
  }

  static isProd() : boolean {
    return environment.isProd;
  }
}
