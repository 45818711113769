import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { LocationComponent } from '../../components/location/location.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { Util } from 'src/app/general/util/util';

@Component({
  selector: 'app-location-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    LocationComponent
  ],
  templateUrl: './location-page.component.html',
  styleUrls: ['./location-page.component.css']
})
export class LocationPageComponent {
  locationId: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute) {
    this.locationId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.locationId = Util.safeString(pathParams.get('locationId'));
  }
}
