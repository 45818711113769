import { ElementCreationHelper, CreateElementField, CreateElementFieldType } from "src/app/general/components/multi-select/multi-select.component";
import { BiFunction, Function } from "src/app/general/interfaces/functions";
import { EditorItem } from "./interfaces";
import { Util } from "src/app/general/util/util";
import { Formatter } from "src/app/specific/util/formatter";
import { ProtoUtil } from "src/app/specific/util/proto-util";
import { LocalizationService } from "src/app/general/services/localization.service";

export class ItemMultiSelectUtil {

    private localizationService: LocalizationService;
    private itemTextProvider: Function<EditorItem, string> = item => this.getText(item);
    private itemComparator: BiFunction<EditorItem, EditorItem, boolean> = (a, b) => {
        if (a.id) {
          return a.id == b.id
        }
        return this.getText(a) == this.getText(b);
    };
    private itemCreationHelper = new (class implements ElementCreationHelper<EditorItem> {
        public getCreateElementDialogTitleTranslateId(): string {
          return 'create_item';
        }
        public getFields(): Array<CreateElementField> {
          return [
            {
              translateId: 'name_en',
              type: CreateElementFieldType.TEXT,
              isRequired: true
            },
            {
              translateId: 'name_es',
              type: CreateElementFieldType.TEXT
            },
            {
              translateId: 'price',
              type: CreateElementFieldType.MONEY,
            }
          ];
        }
        public createElement(data: Array<CreateElementField>): EditorItem {
          return {
            nameEn: data[0].valueAsText,
            nameEs: data[1].valueAsText,
            price: data[2].valueAsMoney
          };
        }
    })();

    constructor(localizationService: LocalizationService) {
        this.localizationService = localizationService;
    }

    public getItemTextProvider(): Function<EditorItem, string> {
        return this.itemTextProvider;
    }

    public getItemComparator(): BiFunction<EditorItem, EditorItem, boolean> {
        return this.itemComparator;
    }

    public getItemCreationHelper(): ElementCreationHelper<EditorItem> {
        return this.itemCreationHelper;
    }

    private getText(item: EditorItem): string {
        let text = this.getNonEmptyName(item, this.localizationService);
        const priceText = this.getPriceIfNotZero(item);
        if (priceText) {
          text += ' (' + priceText + ')';
        }
        return text;
    }

    private getNonEmptyName(item: EditorItem, localizationService: LocalizationService): string {
        if (!item) {
          return '';
        }
        let str: string | null | undefined;
        if (localizationService.isSpanish() || !item.nameEn) {
          str = item.nameEs;
        }
        if (!str) {
          str = item.nameEn;
        }
        return Util.safeString(str);
    }

    private getPriceIfNotZero(item: EditorItem): string {
        if (!item || !item.price || (item.price.units <= 0 && item.price.cents <= 0)) {
          return '';
        }
        return Formatter.formatMoney(ProtoUtil.pickerMoneyToMoneyProto(item.price));
    }
}