<h2 mat-dialog-title>{{ 'menu_entry_schedule' | translate }}</h2>
<div mat-dialog-content>
    <table appFieldContainer>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'schedule' | translate }}</mat-label>
                    <mat-select [(ngModel)]="data.schedule">
                        <mat-option [value]="undefined">{{ 'none' | translate }}</mat-option>
                        <mat-option *ngFor="let schedule of data.allSchedules" [value]="schedule">
                            {{ getText(schedule) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
            <td align="center" valign="top">
                <app-action icon="edit" [tooltip]="'edit_selected' | translate" [disabled]="!this.data.schedule" (onExecute)="editSelectedSchedule()"></app-action>
            </td>
            <td align="center" valign="top">
                <app-action icon="add" [tooltip]="'create_new' | translate" (onExecute)="createSchedule()"></app-action>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [disabled]="!isValidMenuEntrySchedule()" [mat-dialog-close]="data">{{ 'ok' | translate }}</button>
    <button mat-button (click)="closeDialog()">{{ 'cancel' | translate }}</button>
</div>
