import { Injectable } from '@angular/core';
import { BooleanFieldLocalStore, DateFieldLocalStore, StringFieldLocalStore } from 'src/app/general/util/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  authTokenStore: StringFieldLocalStore;
  authTokenValidUntilStore: DateFieldLocalStore;
  isAdminUserStore: BooleanFieldLocalStore;
  isSuperuserStore: BooleanFieldLocalStore;
  lastUserEmailStore: StringFieldLocalStore;

  constructor() {
      this.authTokenStore = new StringFieldLocalStore('auth-token');
      this.authTokenValidUntilStore = new DateFieldLocalStore('auth-token-valid-until');
      this.isAdminUserStore = new BooleanFieldLocalStore('is-admin-user');
      this.isSuperuserStore = new BooleanFieldLocalStore('is-superuser');
      this.lastUserEmailStore = new StringFieldLocalStore('last-user-email');
  }

  public getAuthTokenStore(): StringFieldLocalStore {
    return this.authTokenStore;
  }

  public getAuthTokenValidUntilStore(): DateFieldLocalStore {
    return this.authTokenValidUntilStore;
  }

  public getIsAdminUserStore(): BooleanFieldLocalStore {
    return this.isAdminUserStore;
  }

  public getIsSuperuserStore(): BooleanFieldLocalStore {
    return this.isSuperuserStore;
  }

  public getLastUserEmailStore(): StringFieldLocalStore {
    return this.lastUserEmailStore;
  }
}
