import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FormService } from '../../services/form.service';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ComponentUtil } from '../../util/component-util';
import { TranslateModule } from '@ngx-translate/core';
import { LoggingService } from '../../services/logging.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <div>
//   <app-money-picker
//       label="Money picker"
//       [required]="true"
//       [disabled]="false"
//       [currencies]="['USD', 'MXN']"
//       [value]="initialMoneyValue"
//       (onValueChange)="onMoneyChange($event)">
//   </app-money-picker>
// </div>

// TS:

// initialMoneyValue: Money = {units: 10, cents: 50, currencyCode: 'USD'};
// public onMoneyChange(money: Money | null | undefined) {
//   // money could be null or undefined if the user manually deleted the text from the input field
//   console.log('Money changed: $' + money.units + '.' + money.cents + ' ' + money.currencyCode);
// }
// ----------------------------------------------------------------------------

export interface Money {
  units: number;
  cents: number;
  currencyCode: string;
}

interface DialogData {
  units: number;
  cents: number;
  currencyCode: string;
  currencies: Array<string>;
}

@Component({
  selector: 'app-money-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule
  ],
  templateUrl: './money-picker.component.html',
  styleUrls: ['./money-picker.component.css']
})
export class MoneyPickerComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() required?: boolean;
  @Input() disabled?: boolean;
  @Input() currencies!: Array<string>;
  @Input() value?: Money | null | undefined;
  @Output() onValueChange: EventEmitter<Money | null | undefined> = new EventEmitter<Money | null | undefined>();

  @ViewChild('text_format_input', {read: ElementRef}) textFormatInput!: ElementRef;

  currentMoney: Money | null | undefined;

  textFormatFormControl: FormControl;

  constructor(
      public formService: FormService,
      private loggingService: LoggingService,
      private dialog: MatDialog) {
    this.textFormatFormControl = new FormControl('');
  }

  ngOnInit(): void {
    if (this.required) {
      this.textFormatFormControl.addValidators([Validators.required]);
    }
    if (this.disabled) {
      this.textFormatFormControl.disable();
    } else {
      this.textFormatFormControl.enable();
    }
    if (this.value) {
      this.currentMoney = this.value;
      this.textFormatFormControl.setValue(this.moneyToString(this.currentMoney));
    }
    this.textFormatFormControl.valueChanges.subscribe(() => {
      this.currentMoney = this.stringToMoney(this.textFormatFormControl.value);
      this.onValueChange.emit(this.currentMoney);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('value', changes)) {
      if (this.value) {
        this.currentMoney = this.value;
        this.textFormatFormControl.setValue(this.moneyToString(this.currentMoney));
      } else {
        this.currentMoney = null;
        this.textFormatFormControl.setValue('');
      }
    }
    if (ComponentUtil.bindingChanged('disabled', changes)) {
      if (this.disabled) {
        this.textFormatFormControl.disable();
      } else {
        this.textFormatFormControl.enable();
      }
    }
  }

  // Since 'required' is an optional input, it could be undefined. So, in order to use it in the template, we need this method that returns a boolean.
  public isRequired(): boolean {
    if (this.required) {
      return true;
    }
    return false;
  }

  public openEditor(): void {
    const dialogData: DialogData = {units: 0, cents: 0, currencyCode: this.currencies[0], currencies: this.currencies};
    if (this.currentMoney) {
      dialogData.units = this.currentMoney.units;
      dialogData.cents = this.currentMoney.cents;
      dialogData.currencyCode = this.currentMoney.currencyCode;
    }

    const textFormatInputRect = this.textFormatInput.nativeElement.getBoundingClientRect();

    const dialogRef = this.dialog.open(MoneyEditorDialogComponent, {
      // This field must be named 'data'
      data: dialogData,
      autoFocus: true,
      // Thi si required to allow closing the dialog by clicking at any place outside the dialog
      hasBackdrop: true,
      // If this is false clicking at any place outside the dialog closes the dialog
      disableClose: false,
      position: {
        left: (textFormatInputRect.left - 10) + 'px',
        top: (textFormatInputRect.top + textFormatInputRect.height + 10) + 'px'
      }
    });

    dialogRef.afterClosed().subscribe(dialogData => {
      if (dialogData) {
        this.currentMoney = { units: dialogData.units, cents: dialogData.cents, currencyCode: dialogData.currencyCode };
        this.textFormatFormControl.setValue(this.moneyToString(this.currentMoney));
        // textFormatFormControl emits the onValueChange
        // this.onValueChange.emit(this.currentMoney);
      }
    });
  }

  private moneyToString(money: Money | null | undefined): string {
    if (!money) {
      return '';
    }
    let str = '$' + money.units + '.';
    if (!money.cents || money.cents == 0) {
      str += '00';
    } else if(money.cents < 10) {
      str += '0' + money.cents;
    } else {
      str += money.cents;
    }
    return str + ' ' + money.currencyCode;
  }

  private stringToMoney(strMoney: string | null | undefined): Money | null | undefined {
    if (!strMoney) {
      return null;
    }

    const moneyComponents = strMoney.trim().replace('$', '').split(' ');
    if (moneyComponents.length != 2) {
      this.loggingService.logError('Invalid string money: ' + strMoney);
      return null;
    }

    const amountComponents = moneyComponents[0].split('.');
    if (amountComponents.length != 2) {
      this.loggingService.logError('Invalid string money: ' + strMoney);
      return null;
    }

    let units: number = 0;
    let cents: number = 0;
    let currencyCode: string = '';

    let strCents = amountComponents[1];
    if (strCents.length == 1) {
      strCents += '0';
    }

    units = Number(amountComponents[0]);
    cents = Number(strCents);
    currencyCode = moneyComponents[1];

    if (isNaN(units) || isNaN(cents)) {
      this.loggingService.logError('Invalid string money: ' + strMoney);
      return null;
    }

    if (currencyCode.trim().length == 0) {
      this.loggingService.logError('Invalid string money: ' + strMoney);
      return null;
    }

    return {units: units, cents:cents, currencyCode: currencyCode};
  }
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    TranslateModule,
  ],
  templateUrl: './money-picker-editor.component.html',
})
export class MoneyEditorDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<MoneyEditorDialogComponent>,
      // This field must be named 'data'
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
