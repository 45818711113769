import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { LocationHeaderComponent } from '../../components/location-header/location-header.component';
import { PageComponent } from '../../components/page/page.component';
import { UserBusinessesComponent } from '../../components/user-businesses/user-businesses.component';
import { UserHeaderComponent } from '../../components/user-header/user-header.component';

@Component({
  selector: 'app-user-businesses-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    ActionComponent,
    TranslateModule,
    UserHeaderComponent,
    UserBusinessesComponent
  ],
  templateUrl: './user-businesses-page.component.html',
  styleUrls: ['./user-businesses-page.component.css']
})
export class UserBusinessesPageComponent implements OnInit {

  userId: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute,
      private navigationService: NavigationService) {
    this.userId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.userId = Util.safeString(pathParams.get('userId'));
  }

  public goToAddBusinessPage(): void {
    this.navigationService.goToUserAddBusinessesPage(this.userId)
  }

  public goToUserPage(): void {
    this.navigationService.goToUserPage(this.userId)
  }
}
