<!-- ---------------------------------------------------------------------- -->
<!-- Action with execute behavior -->
<!-- ---------------------------------------------------------------------- -->

<table *ngIf="!isSubmitType && !urlToOpenInNewWindowWithFormRequest">
    <tr *ngIf="isExecuting">
        <td class="centered-content">
            <app-progress></app-progress>
        </td>
    </tr>
    <tr *ngIf="!isExecuting && successMessage" class="success-color">
        <td class="centered-content">{{ successMessage }}</td>
    </tr>
    <tr *ngIf="!isExecuting && successMessageTranslateId && !successMessage" class="success-color">
        <td class="centered-content">{{ successMessageTranslateId | translate }}</td>
    </tr>
    <tr *ngIf="!isExecuting && isError">
        <td class="centered-content">
            <app-error *ngIf="!errorMessageTranslateId" [message]="errorMessage" [error]="error">
            </app-error>
            <app-error *ngIf="errorMessageTranslateId" [message]="errorMessageTranslateId | translate" [error]="error">
            </app-error>
        </td>
    </tr>
    <tr *ngIf="!isExecuting">
        <td class="centered-content">
            <button mat-icon-button
                    *ngIf="!text"
                    matTooltip="{{tooltip}}"
                    [disabled]="disabled"
                    (click)="execute()">
                <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
            </button>
            <button mat-flat-button [ngClass]="{'button': !defaultStyle}"
                    *ngIf="text"
                    matTooltip="{{tooltip}}"
                    [disabled]="disabled"
                    (click)="execute()">
                <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
                {{ text }}
            </button>
        </td>
    </tr>
</table>

<!-- ---------------------------------------------------------------------- -->
<!-- Submit type action -->
<!-- ---------------------------------------------------------------------- -->

<button mat-icon-button
        *ngIf="isSubmitType && !text"
        type="submit"
        matTooltip="{{tooltip}}"
        [disabled]="disabled">
    <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
</button>
<button mat-flat-button [ngClass]="{'button': !defaultStyle}"
        *ngIf="isSubmitType && text"
        type="submit"
        matTooltip="{{tooltip}}"
        [disabled]="disabled">
    <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
    {{ text }}
</button>

<!-- ---------------------------------------------------------------------- -->
<!-- Open in new window post form action -->
<!-- ---------------------------------------------------------------------- -->

<form [method]="formMethod" [action]="urlToOpenInNewWindowWithFormRequest" target="_blank" *ngIf="urlToOpenInNewWindowWithFormRequest">
    <input *ngFor="let param of formParams" type="hidden" [name]="param.name" [value]="param.value"/>
    <button mat-icon-button
            *ngIf="!text"
            type="submit"
            matTooltip="{{tooltip}}"
            [disabled]="disabled">
        <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
    </button>
    <button mat-flat-button [ngClass]="{'button': !defaultStyle}"
            *ngIf="text"
            type="submit"
            matTooltip="{{tooltip}}"
            [disabled]="disabled">
        <mat-icon *ngIf="icon" [color]="iconColor">{{ icon }}</mat-icon>
        {{ text }}
    </button>
</form>
