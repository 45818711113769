import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiFunction, Function } from 'src/app/general/interfaces/functions';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FieldDirective } from '../../directives/field/field.directive';
import { ActionComponent } from '../action/action.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateElementDialogComponent, CreateElementDialogData } from './create-element-dialog.component';
import { Money } from '../money-picker/money-picker.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-multi-select
//     searchElementLabel="Search Category"
//     [selectedElements]="['Element 2']"
//     [allElements]="['Element 1', 'Element 2', 'Element 3']"
//     [textProvider]="textProvider"
//     [comparator]="comparator"
//     (onSelectedElementsChange)="onSelectedElementsChange($event)">
// </app-multi-select>

// If ElementCreationHelper is set, creating elements is allowed

// <app-multi-select
//     searchElementLabel="Search Category"
//     [selectedElements]="['Element 2']"
//     [allElements]="['Element 1', 'Element 2', 'Element 3']"
//     [textProvider]="textProvider"
//     [comparator]="comparator"
//     [elementCreationHelper]="elementCreationHelper"
//     (onSelectedElementsChange)="onSelectedElementsChange($event)">
// </app-multi-select>

// TS:

// textProvider: Function<string, string> = element => element;
// comparator: BiFunction<string, string, boolean> = (a, b) => a == b;

// public onSelectedElementsChange(selectedElements: Array<string>): void {
//   console.log('Selected elements:');
//   console.log(selectedElements);
// }

// elementCreationHelper = new (class implements ElementCreationHelper<string> {
//   public getCreateElementDialogTitleTranslateId(): string {
//     return 'create_category';
//   }
//   public getFieldLabelTranslateIds() {
//     return ['name'];
//   }
//   public createElement(data: Array<string>): string {
//     return data[0];
//   }
// })();


// ----------------------------------------------------------------------------

export enum CreateElementFieldType {
  TEXT,
  NUMBER,
  MONEY
}

export interface CreateElementField {
  translateId: string;
  isRequired?: boolean;
  type: CreateElementFieldType;
  valueAsText?: string;
  valueAsNumber?: number;
  valueAsMoney?: Money;
}

export interface ElementCreationHelper<T> {

  getCreateElementDialogTitleTranslateId(): string;

  /**
   * Returns an array of the labels for the minimum fields required to create an element.
   * For each label a text field will be shown on the create element dialog. The OK button
   * in the dialog will be enbaled only if all fields are not empty.
   */
  getFields(): Array<CreateElementField>;

  /**
   * Creates a new element. The data argument has the fields values for the element to create.
   * The array wil have the same size as the array returned by getFields(),
   * and data will be at the same order.
   */
  createElement(data: Array<CreateElementField>): T;
}

@Component({
  selector: 'app-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FieldDirective,
    ActionComponent,
    TranslateModule
  ],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent<T> implements OnInit {
  // We don't use a single array with a wrapper of T with an isSelected attribute to keep the order elements are added, spo they can be reordered
  @Input() searchElementLabel!: string;
  @Input() selectedElements?: Array<T>;
  @Input() allElements?: Array<T>;
  @Input() textProvider!: Function<T, string>;
  @Input() comparator!: BiFunction<T, T, boolean>;
  @Input() elementCreationHelper?: ElementCreationHelper<T>;

  nonSelectedElements: Array<T> = [];

  @Output() onSelectedElementsChange: EventEmitter<Array<T>> = new EventEmitter<Array<T>>();

  autoCompleteControl = new FormControl<string>('');
  autoCompleteElements?: Array<T> = [];

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
    if (!this.selectedElements) {
      this.selectedElements = [];
    }
    if (!this.allElements) {
      this.allElements = [];
    }

    this.updateNonSelectedElements();

    this.autoCompleteControl.valueChanges.subscribe(() => {
      this.filterAutoCompleteElements();
    });
  }

  private updateNonSelectedElements(): void  {
    const newNonSelectedElements: Array<T> = [];
    for (const element of this.allElements || []) {
      let isSelected = false;
      for (const selectedElement of this.selectedElements || []) {
        if (this.comparator(element, selectedElement)) {
          isSelected = true;
          break;
        }
      }
      if (!isSelected) {
        newNonSelectedElements.push(element);
      }
    }
    this.nonSelectedElements = newNonSelectedElements;
    this.autoCompleteElements = this.nonSelectedElements;
  }

  private filterAutoCompleteElements(): void {
    if (!this.autoCompleteControl.value) {
      this.autoCompleteElements = this.nonSelectedElements;
      return;
    }

    let searchText = '';
    if (this.autoCompleteControl.value) {
      // If an element is selected, the type is T, if something is written, the type is string
      if (typeof this.autoCompleteControl.value === 'string') {
        searchText = this.autoCompleteControl.value;
      } else {
        searchText = this.textProvider(this.autoCompleteControl.value as T);
      }
    }
    searchText = searchText.toLowerCase();

    if (!searchText) {
      this.autoCompleteElements = this.nonSelectedElements;
    }

    this.autoCompleteElements = [];
    for (const element of this.nonSelectedElements) {
      if (this.textProvider(element).toLowerCase().indexOf(searchText) >= 0) {
        this.autoCompleteElements.push(element);
      }
    }
  }

  public onAutocompleteOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.select(event.option.value);
  }

  public select(elementToSelect: T): void {
    if (!this.allElements || !this.selectedElements) {
      return;
    }

    if (elementToSelect) {
      this.selectedElements.push(elementToSelect);
      const newNonSelectedElements: Array<T> = [];
      for (const element of this.nonSelectedElements) {
        if (!this.comparator(element, elementToSelect)) {
          newNonSelectedElements.push(element);
        }
      }
      this.nonSelectedElements = newNonSelectedElements;
      this.autoCompleteElements = this.nonSelectedElements;
      this.autoCompleteControl.setValue(null);
      this.onSelectedElementsChange.emit(this.selectedElements);
    }
  }

  public unselect(elementToRemove: T) {
    const newSelectedElements: Array<T> = [];
    for (const element of this.selectedElements || []) {
      if (!this.comparator(element, elementToRemove)) {
        newSelectedElements.push(element);
      }
    }
    this.selectedElements = newSelectedElements;

    this.updateNonSelectedElements();
    this.onSelectedElementsChange.emit(this.selectedElements);
  }

  public createElement(): void {
    if (!this.elementCreationHelper) {
      return;
    }

    const fields = this.elementCreationHelper.getFields();

    if (!fields || fields.length == 0) {
      return;
    }

    const inputDialogData: CreateElementDialogData = {
      titleTranslateId: this.elementCreationHelper.getCreateElementDialogTitleTranslateId(),
      field1: fields[0]
    }
    if (fields.length > 1) {
      inputDialogData.field2 = fields[1];
    }
    if (fields.length > 2) {
      inputDialogData.field3 = fields[2];
    }
    if (fields.length > 3) {
      inputDialogData.field4 = fields[3];
    }

    const dialogRef = this.dialog.open(CreateElementDialogComponent, {
      data: inputDialogData,
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(outputDialogData => {
      if (outputDialogData && this.elementCreationHelper) {
        // allElements and selectedElements are never undefined, this condition is to prevent a
        // compilation error because in theory allElements could be undefined since it is optional.
        if (!this.allElements || !this.selectedElements) {
          return;
        }
        const newElement = this.elementCreationHelper.createElement(fields);
        this.selectedElements.push(newElement);
        this.allElements.push(newElement);
        this.onSelectedElementsChange.emit(this.selectedElements);
      }
    });
  }
}
