import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { MenuEditorUtil } from '../util/menu-editor-util';
import { ScheduleReusedWarningDialogService } from '../schedule-reused-warning-dialog/schedule-reused-warning-dialog.service';
import { ScheduleReusedWarningDialogData } from '../util/interfaces';
import { Formatter } from 'src/app/specific/util/formatter';
import { ProtoUtil } from 'src/app/specific/util/proto-util';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-schedule-reused-warning [scheduleId]="schedule.id" [menuSpec]="menuSpec"></app-schedule-reused-warning>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-schedule-reused-warning',
  standalone: true,
  imports: [
    CommonModule,
    ActionComponent,
    TranslateModule
  ],
  templateUrl: './schedule-reused-warning.component.html',
  styleUrls: ['./schedule-reused-warning.component.css']
})
export class ScheduleReusedWarningComponent {
  // We use string | null | undefined instead of optional (?) so we can do [scheduleId]="schedule.id"
  // proto fields are nullable. Optional fields can only be undefined, not null.
  @Input() scheduleId!: string | null | undefined;
  @Input() menuSpec!: proto.waiternow.common.StructuredMenuSpecProto;

  otherUsages?: ScheduleReusedWarningDialogData;
  isWarningApplicable: boolean;

  constructor(
      private scheduleReusedWarningDialogService: ScheduleReusedWarningDialogService) {
    this.isWarningApplicable = false;
  }

  ngOnInit(): void {
    this.otherUsages = this.findOtherUsages();
    this.isWarningApplicable = this.otherUsages.reusedInMenuItems.length > 1
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('scheduleId', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('menuSpec', changes)) {
      this.ngOnInit();
    }
  }

  public showWarning(): void {
    if (!this.otherUsages) {
      return;
    }
    this.scheduleReusedWarningDialogService.openDialog(this.otherUsages);
  }

  private findOtherUsages(): ScheduleReusedWarningDialogData {
    let scheduleText: proto.waiternow.common.ITextProto = new proto.waiternow.common.TextProto();
    let reusedInMenuItems: Array<proto.waiternow.common.ITextProto> = [];

    const scheduleSpec = MenuEditorUtil.findAvailabilityScheduleSpec(this.scheduleId, this.menuSpec);
    if (scheduleSpec) {
      scheduleText = ProtoUtil.createTextProto(
        Formatter.formatSchedule(scheduleSpec.schedule, proto.waiternow.common.Language.ENGLISH),
        Formatter.formatSchedule(scheduleSpec.schedule, proto.waiternow.common.Language.SPANISH)
      );
      for (const menuItemSpec of this.menuSpec.menuItems) {
        if (this.scheduleId == menuItemSpec.availabilityScheduleId) {
          if (menuItemSpec.name) {
            reusedInMenuItems.push(menuItemSpec.name);
          }
        }
      }
    }

    return {
      scheduleText: scheduleText,
      reusedInMenuItems: reusedInMenuItems
    }
  }
}
