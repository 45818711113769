import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { EditorItem, ItemSelectionEditorDialogData } from '../util/interfaces';
import { MultiSelectComponent } from 'src/app/general/components/multi-select/multi-select.component';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { HeadingComponent } from 'src/app/general/components/heading/heading.component';
import { ItemMultiSelectUtil } from '../util/item-multi-select-util';
import { ItemSelectionReusedWarningComponent } from '../item-selection-reused-warning/item-selection-reused-warning.component';
import { RightAlignedCompactContentComponent } from 'src/app/general/components/right-aligned-compact-content/right-aligned-compact-content.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateItemSelection(itemSelection: proto.waiternow.common.StructuredMenuProto.IItemSelectionProto): void {
//   const inputDialogData: ItemSelectionEditorDialogData = {
//     itemSelection: {
//       titleEn: Formatter.getText(itemSelection.title, proto.waiternow.common.Language.ENGLISH),
//       titleEs: Formatter.getText(itemSelection.title, proto.waiternow.common.Language.SPANISH)
//     },
//     allowChaningItems: false
//   }
//   this.itemSelectionEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ outputDialogData => {
//       console.log(outputDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-item-selection-editor-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MatCheckboxModule,
    HeadingComponent,
    MultiSelectComponent,
    ItemSelectionReusedWarningComponent,
    RightAlignedCompactContentComponent
  ],
  templateUrl: './item-selection-editor-dialog.component.html',
  styleUrls: ['./item-selection-editor-dialog.component.css']
})
export class ItemSelectionEditorDialogComponent {

  itemMultiselectUtil: ItemMultiSelectUtil;

  constructor(
      public dialogRef: MatDialogRef<ItemSelectionEditorDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ItemSelectionEditorDialogData,
      private localizationService: LocalizationService) {
    this.itemMultiselectUtil = new ItemMultiSelectUtil(this.localizationService);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public onSelectedItemsChange(selectedItems: Array<EditorItem>): void {
    this.data.itemSelection.items = selectedItems;
  }

  public isValidItemSelection(): boolean {
    if (!this.data.itemSelection.titleEn && !this.data.itemSelection.titleEs) {
      return false;
    }
    if (this.data.itemSelection.maxSelections && this.data.itemSelection.maxSelections < 0) {
      return false;
    }
    if (this.data.allowChaningItems && (!this.data.itemSelection.items || this.data.itemSelection.items.length == 0)) {
      return false;
    }
    return true;
  }
}
