import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { Money, MoneyPickerComponent } from '../money-picker/money-picker.component';
import { CreateElementField, CreateElementFieldType } from './multi-select.component';
import { CURRENCIES } from 'src/app/specific/util/constants';

export interface CreateElementDialogData {
  titleTranslateId: string;

  // To create an element at least one field is required
  field1: CreateElementField;
  field2?: CreateElementField;
  field3?: CreateElementField;
  field4?: CreateElementField;
}

@Component({
  selector: 'app-create-element-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MoneyPickerComponent
  ],
  templateUrl: './create-element-dialog.component.html',
  styleUrls: ['./create-element-dialog.component.css']
})
export class CreateElementDialogComponent {
  readonly CURRENCIES: Array<string> = CURRENCIES;

  constructor(
    public dialogRef: MatDialogRef<CreateElementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateElementDialogData) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public isTypeText(field: CreateElementField): boolean {
    if (!field) {
      return false;
    }
    return field.type == CreateElementFieldType.TEXT;
  }

  public isTypeNumber(field: CreateElementField): boolean {
    if (!field) {
      return false;
    }
    return field.type == CreateElementFieldType.NUMBER;
  }

  public isTypeMoney(field: CreateElementField): boolean {
    if (!field) {
      return false;
    }
    return field.type == CreateElementFieldType.MONEY;
  }

  private updateMoney(money: Money | null | undefined, field: CreateElementField | undefined) {
    if (!field) {
      return;
    }
    if (money) {
      field.valueAsMoney = money;
    } else {
      field.valueAsMoney = undefined;
    }
  }

  public onField1MoneyChange(money: Money | null | undefined) {
    this.updateMoney(money, this.data.field1);
  }

  public onField2MoneyChange(money: Money | null | undefined) {
    this.updateMoney(money, this.data.field2);
  }

  public onField3MoneyChange(money: Money | null | undefined) {
    this.updateMoney(money, this.data.field3);
  }

  public onField4MoneyChange(money: Money | null | undefined) {
    this.updateMoney(money, this.data.field4);
  }

  public isValidElement(): boolean {
    if (!this.isValidField(this.data.field1)) {
      return false;
    }
    if (this.data.field2 && !this.isValidField(this.data.field2)) {
      return false;
    }
    if (this.data.field3 && !this.isValidField(this.data.field3)) {
      return false;
    }
    if (this.data.field4 && !this.isValidField(this.data.field4)) {
      return false;
    }
    return true;
  }

  private isValidField(field: CreateElementField): boolean {
    if (!field.isRequired) {
      return true;
    }
    if (field.valueAsText || field.valueAsNumber || field.valueAsMoney) {
      return true;
    }
    return false;
  }
}
