<table class="error-color">
    <tr>
        <td>
            <mat-icon [matTooltip]="nonNullTooltip">error</mat-icon>
        </td>
        <td *ngIf="message">
            {{ message }}
        </td>
    </tr>
    <tr *ngIf="errorDetails">
        <td>
        </td>
        <td>
            {{ errorDetails }}
        </td>
    </tr>
</table>
