<table mat-table [dataSource]="tableDataSource" *ngIf="data.length > 0">
    <ng-container *ngFor="let column of getColumns()" [matColumnDef]="column.name">
        <th mat-header-cell *matHeaderCellDef>{{ column.nameTranslateId | translate }}</th>
        <td mat-cell *matCellDef="let dataItem" (click)="emitRowClick(dataItem, column)">
            <span *ngIf="column.isStringColumn()">
                {{ column.asStringColumn().valueExtractor(dataItem) }}
            </span>
            <app-compact-text *ngIf="column.isCompactTextColumn()"
                [text]="column.asCompactTextColumn().valueExtractor(dataItem)"
                [compactTextLength]="column.asCompactTextColumn().compactTextLength">
            </app-compact-text>
            <div *ngIf="column.isCompactTextIfMobileColumn()">
                <span *ngIf="!deviceService.isMobile()">
                    {{ column.asCompactTextIfMobileColumn().valueExtractor(dataItem) }}
                </span>
                <app-compact-text *ngIf="deviceService.isMobile()"
                    [text]="column.asCompactTextIfMobileColumn().valueExtractor(dataItem)"
                    [compactTextLength]="column.asCompactTextIfMobileColumn().compactTextLength">
                </app-compact-text>
            </div>
            <a *ngIf="column.isLinkColumn() && column.asLinkColumn().urlExtractor(dataItem)"
                    href="{{column.asLinkColumn().urlExtractor(dataItem)}}"
                    target="_blank">
                {{ column.asLinkColumn().textExtractor(dataItem) }}
            </a>
            <mat-slide-toggle *ngIf="column.isCheckboxColumn()"
                [checked]="column.asCheckboxColumn().stateExtractor(dataItem)"
                (change)="emitCheckboxStateChange($event, dataItem, column)">
            </mat-slide-toggle>
            <app-toolbar
                *ngIf="column.name == 'column_toolbar'"
                [subject]="dataItem"
                [actions]="toolbarActions"
                [compactActions]="compactToolbarActions">
            </app-toolbar>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="getColumnNames()"></tr>
    <tr mat-row *matRowDef="let row; columns: getColumnNames();" class="data-table-row"></tr>
</table>
