import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { BackendService } from '../../services/backend.service';
import { Formatter } from '../../util/formatter';
import { CompactTextColumn, DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { DeviceToolbarModel } from '../../model/device-toolbar-model';
import { SessionService } from '../../services/session.service';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { ErrorResult, LoadingMessage } from 'src/app/general/util/result';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-business-devices businessId="{{business.id}}"></app-business-devices>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-location-devices',
  standalone: true,
  imports: [
    CommonModule,
    LoadableContentComponent,
    TranslateModule,
    MatCardModule,
    DataTableComponent

  ],
  templateUrl: './location-devices.component.html',
  styleUrls: ['./location-devices.component.css']
})
export class LocationDevicesComponent implements OnInit, OnChanges {
  @Input() locationId!: string;

  locationDevicesLoadingController: LoadingController;

  devices: Array<proto.waiternow.common.IDeviceProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.IDeviceProto>>;

  deviceToolbarModel: DeviceToolbarModel;

  constructor(
      private backendService: BackendService,
      private sessionService: SessionService) {
    this.locationDevicesLoadingController = new LoadingController();

    this.devices = new Array();
    this.columns = [
      new CompactTextColumn(
        /* name= */ 'id',
        /* translateId= */ 'id',
        /* valueExtractor= */  device => device.id,
        /* compactTextLength= */ 6),
      new StringColumn(
        /* name= */ 'clientType',
        /* translateId= */ 'client_type',
        /* valueExtractor= */  device => Formatter.formatClientType(device.clientType)),
      new StringColumn(
        /* name= */ 'deviceType',
        /* translateId= */ 'device_type',
        /* valueExtractor= */  device => Formatter.formatDeviceType(device.deviceType),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'version',
        /* translateId= */ 'version',
        /* valueExtractor= */  device => device.version,
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'lastUpdateTime',
        /* translateId= */ 'last_update',
        /* valueExtractor= */  device => Formatter.formatTimestampProto(device.lastUpdateTime),
        /* hideInCompactMode= */ true)
      ];

      this.deviceToolbarModel = new DeviceToolbarModel(
        this.sessionService,
        this.backendService
      );
    }

  ngOnInit(): void {
    this.loadDevices();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('locationId', changes)) {
      this.ngOnInit();
    }
  }

  public loadDevices(): void {
    this.locationDevicesLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_location_devices'));
    this.backendService.findLocationDevices(
      this.locationId,
      /* onSuccess= */ devicesProto => {
        this.locationDevicesLoadingController.onSuccess();
        if (devicesProto && devicesProto?.devices) {
          this.devices = devicesProto?.devices;
        } else {
          this.devices = [];
        }
      },
      /* onError= */ error => {
        this.locationDevicesLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_location_devices'));
      }
    );
  }
}
