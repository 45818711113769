import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { DeviceService } from 'src/app/general/services/device.service';
import { FormService } from 'src/app/general/services/form.service';
import { Util } from 'src/app/general/util/util';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, SuccessResult } from 'src/app/general/util/result';
import { MatSelectModule } from '@angular/material/select';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-add-point-of-service-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent,
    MatSelectModule
  ],
  templateUrl: './add-point-of-service-page.component.html',
  styleUrls: ['./add-point-of-service-page.component.css']
})
export class AddPointOfServicePageComponent implements OnInit {
  readonly POINT_OF_SERVICE_TYPE_NONE: string = "none";
  readonly POINT_OF_SERVICE_TYPE_TABLE: string = "table";
  readonly POINT_OF_SERVICE_TYPE_ONLINE: string = "online";
  readonly POINT_OF_SERVICE_TYPE_ONLINE_PICKUP: string = "online-pickup";
  readonly POINT_OF_SERVICE_TYPE_ONSITE_PICKUP: string = "onsite-pickup";
  readonly POINT_OF_SERVICE_TYPE_MENU: string = "menu";

  locationId: string;

  friendlyNameField: FormControl = new FormControl('', [Validators.required]);
  typeField: FormControl = new FormControl(this.POINT_OF_SERVICE_TYPE_NONE);

  constructor(
      public deviceService: DeviceService,
      public formService: FormService,
      private activatedRoute: ActivatedRoute,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService) {
    this.locationId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.locationId = Util.safeString(routeParams.get('locationId'));
  }

  public addPointOfService(actionObserver: ActionObserver): void {
    const pointOfService = new proto.waiternow.common.PointOfServiceProto();
    pointOfService.locationId = this.locationId;
    pointOfService.friendlyName = this.friendlyNameField.value;
    if (this.typeField.value === this.POINT_OF_SERVICE_TYPE_TABLE) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.TABLE;
    } else if(this.typeField.value === this.POINT_OF_SERVICE_TYPE_ONLINE) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE;
    } else if(this.typeField.value === this.POINT_OF_SERVICE_TYPE_ONLINE_PICKUP) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONLINE_PICKUP;
    } else if(this.typeField.value === this.POINT_OF_SERVICE_TYPE_ONSITE_PICKUP) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.ONSITE_PICKUP;
    } else if(this.typeField.value === this.POINT_OF_SERVICE_TYPE_MENU) {
      pointOfService.type = proto.waiternow.common.PointOfServiceProto.PointOfServiceType.MENU;
    }

    this.backendService.createPointOfService(
      pointOfService,
      /* onSuccess= */ addedPointOfService => {
        actionObserver.onSuccess(SuccessResult.empty());
        this.navigationService.goToPointOfServicePage(Util.safeString(addedPointOfService?.id));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_adding_point_of_service'));
      }
    );
  }
}
