import { AppError } from "../../util/error";
import { ErrorResult, LoadingMessage } from "../../util/result";

export class LoadingController {
  public isLoading: boolean = false;
  public isError: boolean = false;
  public error?: AppError;

  public loadingMessage: string = '';
  public loadingMessageTranslateId: string = '';
  public errorMessage: string = '';
  public errorMessageTranslateId: string = '';

  private clearState() {
    this.isLoading = false;
    this.loadingMessage = '';
    this.loadingMessageTranslateId = '';
    this.isError = false;
    this.errorMessage = '';
    this.errorMessageTranslateId = '';
    this.error = undefined;
  }

  public onLoadingStarted(loadingMessage: LoadingMessage) {
    this.clearState();
    this.isLoading = true;
    if (loadingMessage.message) {
        this.loadingMessage = loadingMessage.message;
      } else if (loadingMessage.messageTranslateId) {
        this.loadingMessageTranslateId = loadingMessage.messageTranslateId;
      }
    }

  public onSuccess() {
    this.isLoading = false;
  }

  public onError(errorResult:ErrorResult) {
    this.isLoading = false;
    this.isError = true;
    if (errorResult.error) {
      this.error = errorResult.error;
    }
    if (errorResult.errorMessage) {
      this.errorMessage = errorResult.errorMessage;
    } else if (errorResult.errorMessageTranslateId) {
      this.errorMessageTranslateId = errorResult.errorMessageTranslateId;
    } else if(this.error) {
      this.errorMessage = this.error.getMessage();
    }
  }
}
