import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { DataTableColumn, CompactTextColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';
import { ComponentUtil } from 'src/app/general/util/component-util';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { NavigationService } from '../../services/navigation.service';
import { SessionService } from '../../services/session.service';
import { Formatter } from '../../util/formatter';
import { ManagedBusinessToolbarModel } from '../../model/managed-business-toolbar';
import * as proto from 'src/proto/compiled-protos';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-user-businesses userId="{{user.id}}"></app-user-businesses>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-user-businesses',
  standalone: true,
  imports: [
    CommonModule,
    LoadableContentComponent,
    TranslateModule,
    MatCardModule,
    DataTableComponent
  ],
  templateUrl: './user-businesses.component.html',
  styleUrls: ['./user-businesses.component.css']
})
export class UserBusinessesComponent {
  @Input() userId!: string;

  userBusinessesLoadingController: LoadingController;

  managedBusinesses: Array<proto.waiternow.common.UserBusinessesProto.IManagedBusinessProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.UserBusinessesProto.IManagedBusinessProto>>;

  managedBusinessToolbarModel: ManagedBusinessToolbarModel;

  constructor(
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      private toastService: ToastService) {
    this.userBusinessesLoadingController = new LoadingController();

    this.managedBusinesses = new Array();
    this.columns = [
      new CompactTextColumn(
        /* name= */ 'id',
        /* translateId= */ 'id',
        /* valueExtractor= */  managedBusiness => managedBusiness.business?.id,
        /* compactTextLength= */ 6),
      new StringColumn(
        /* name= */ 'name',
        /* translateId= */ 'name',
        /* valueExtractor= */  managedBusiness => managedBusiness.business?.businessName),
      new StringColumn(
        /* name= */ 'role',
        /* translateId= */ 'role',
        /* valueExtractor= */  managedBusiness => Formatter.formatRole(managedBusiness.userRole),
        /* hideInCompactMode= */ true)
      ];

      this.managedBusinessToolbarModel = new ManagedBusinessToolbarModel(
        this.sessionService,
        this.backendService,
        this.navigationService,
        this.translateService,
        this.dialogService,
        this.toastService,
        /* compactForMobile= */ true,
        /* onBusinessDeleted= */ businessId => this.loadBusinesses()
      );
    }

  ngOnInit(): void {
    this.loadBusinesses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('userId', changes)) {
      this.ngOnInit();
    }
  }

  public loadBusinesses(): void {
    if (!this.userId) {
      return;
    }

    this.userBusinessesLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_user_businesses'));
    this.backendService.findBusinessesByUserId(
      this.userId,
      /* onSuccess= */ userBusinesses => {
        if (userBusinesses && userBusinesses?.businesses) {
          this.managedBusinesses = userBusinesses?.businesses;
        } else {
          this.managedBusinesses = [];
        }
        this.userBusinessesLoadingController.onSuccess();
      },
      /* onError= */ error => {
        this.userBusinessesLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_user_businesses'));
      }
    );
  }
}
