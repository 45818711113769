<app-page>
    <app-business-header [businessId]="businessId"></app-business-header>
    <div>
        <table>
            <tr>
                <td>
                    <app-action [text]="'add' | translate" [defaultStyle]=true (onExecute)="goToAddLocationPage()"></app-action>
                </td>
                <td>
                    <app-action [text]="'go_to_business' | translate" [defaultStyle]=true (onExecute)="goToBusinessPage()"></app-action>
                </td>
            </tr>
        </table>
    </div>
    <app-business-locations [businessId]="businessId"></app-business-locations>
</app-page>
