<app-loadable-content [loadingController]="businessUsersLoadingController" (onRetry)="loadUsers()">
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'users' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <app-data-table
                [data]="managerUsers"
                [columns]="columns"
                [toolbarActions]="userToolbarModel.actions"
                [compactToolbarActions]="userToolbarModel.compactActions">
            </app-data-table>
        </mat-card-content>
    </mat-card>
</app-loadable-content>
