import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { Money, MoneyPickerComponent } from 'src/app/general/components/money-picker/money-picker.component';
import { CURRENCIES } from 'src/app/specific/util/constants';
import { ItemEditorDialogData } from '../util/interfaces';
import { ItemReusedWarningComponent } from '../item-reused-warning/item-reused-warning.component';
import { RightAlignedCompactContentComponent } from 'src/app/general/components/right-aligned-compact-content/right-aligned-compact-content.component';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// TS:

// public updateItem(item: proto.waiternow.common.StructuredMenuProto.IItemProto): void {
//   const inputDialogData: ItemEditorDialogData = {
//     item: {
//       nameEn: Formatter.getText(item.name, proto.waiternow.common.Language.ENGLISH),
//       nameEs: Formatter.getText(item.name, proto.waiternow.common.Language.SPANISH)
//     }
//   }
//   this.itemEditorDialogService.openEditor(
//     inputDialogData,
//     /* onUpdate */ itemEditorDialogData => {
//       console.log(itemEditorDialogData);
//     }
//   );
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-item-editor-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    FieldContainerDirective,
    FieldDirective,
    MoneyPickerComponent,
    ItemReusedWarningComponent,
    RightAlignedCompactContentComponent
  ],
  templateUrl: './item-editor-dialog.component.html',
  styleUrls: ['./item-editor-dialog.component.css']
})
export class ItemEditorDialogComponent {
  readonly CURRENCIES: Array<string> = CURRENCIES;

  initialMoneyValue?: Money;

  constructor(
      public dialogRef: MatDialogRef<ItemEditorDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ItemEditorDialogData) {
    if (data && data.item.price) {
      this.initialMoneyValue = {
        units: data.item.price.units,
        cents: data.item.price.cents,
        currencyCode: data.item.price.currencyCode
      }
    }
  }

  public onPriceChange(money: Money | null | undefined) {
    if (money ) {
      this.data.item.price = money;
    } else {
      this.data.item.price = undefined;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public isValidItem(): boolean {
    if (this.data.item.nameEn) {
      return true;
    }
    if (this.data.item.nameEs) {
      return true;
    }
    return false;
  }
}
