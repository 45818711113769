<app-page>
    <app-title>{{ 'add_business' | translate }}</app-title>
    <table appFieldContainer align="center">
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'business_name' | translate }}</mat-label>
                    <input matInput [formControl]="businessNameField" required>
                    <mat-error *ngIf="businessNameField.invalid">{{formService.getFieldErrorMessage(businessNameField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'description_en' | translate }}</mat-label>
                    <textarea matInput [formControl]="descriptionEnField" required></textarea>
                    <mat-error *ngIf="descriptionEnField.invalid">{{formService.getFieldErrorMessage(descriptionEnField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appField>
                    <mat-label>{{ 'description_es' | translate }}</mat-label>
                    <textarea matInput [formControl]="descriptionEsField" required></textarea>
                    <mat-error *ngIf="descriptionEsField.invalid">{{formService.getFieldErrorMessage(descriptionEsField)}}</mat-error>
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td align="center">
                <app-action [text]="'submit' | translate"
                    [disabled]="businessNameField.invalid || descriptionEnField.invalid || descriptionEsField.invalid"
                    (onAsyncExecute)="addBusiness($event)">
                </app-action>
            </td>
        </tr>
    </table>
</app-page>
