<app-page>
    <app-location-header [locationId]="locationId"></app-location-header>
    <div>
        <table>
            <tr>
                <td>
                    <app-action [text]="'go_to_location' | translate" [defaultStyle]=true (onExecute)="goToLocationPage()"></app-action>
                </td>
            </tr>
        </table>
    </div>
    <app-location-devices [locationId]="locationId"></app-location-devices>
</app-page>
