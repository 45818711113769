<table appFieldContainer align="center">
    <app-title>{{ 'find_business' | translate }}</app-title>
    <tr>
        <td *ngIf="isError" align="center" colspan="2">
            <app-error [message]="'error_searching_businesses' | translate" [error]="error"></app-error>
        </td>
    </tr>
    <tr>
        <td>
            <app-table-layout [columns]="deviceService.ifMobile(1).orElse(4)" [items]="5">
                <div #layout>
                    <mat-form-field appField>
                        <mat-label>{{ 'email' | translate }}</mat-label>
                        <input matInput placeholder="email@example.com" [formControl]="emailFormControl" (keyup.enter)="findBusinesses()">
                        <mat-error *ngIf="emailFormControl.invalid">{{formService.getFieldErrorMessage(emailFormControl)}}</mat-error>
                    </mat-form-field>
                </div>
                <div #layout align="center">
                    {{ 'or' | translate }}
                </div>
                <div #layout>
                    <mat-form-field appField>
                        <mat-label>{{ 'business_name' | translate }}</mat-label>
                        <input matInput placeholder="" [formControl]="businessNameFormControl"  (keyup.enter)="findBusinesses()">
                        <mat-error *ngIf="businessNameFormControl.invalid">{{formService.getFieldErrorMessage(businessNameFormControl)}}</mat-error>
                    </mat-form-field>
                </div>
                <div #layout align="center">
                    <app-action icon="search" (onExecute)="findBusinesses()"
                            [disabled]="emailFormControl.invalid || businessNameFormControl.invalid || (!emailFormControl.value && !businessNameFormControl.value)">
                    </app-action>
                </div>
            </app-table-layout>
        </td>
    </tr>
    <tr *ngIf="isInProgress" align="center">
        <td>
            <app-progress></app-progress>
        </td>
    </tr>
    <tr>
        <td>
            <app-user [userEmail]="businessesQuery.email" *ngIf="businessesQuery.email"></app-user>
        </td>
    </tr>
    <tr>
        <td>
            <table [ngClass]="{'mat-elevation-z8': businesses.length > 0}" width="100%">
                <!--
                    *ngIf="businesses.length > 0" cannot encapsulate app-indeterminate-paginator, otherwise the paginator
                     is not initialized, causing the model not to be setup either, and thus no data is displayed.
                -->
                <tr *ngIf="businesses.length > 0">
                    <td align="right">
                        <app-action icon="close" (onExecute)="clearBusinesses()">
                        </app-action>
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-data-table [data]="businesses" [columns]="columns" [toolbarActions]="actions"></app-data-table>
                    </td>
                </tr>
                <tr>
                    <td align="right">
                        <app-indeterminate-paginator [model]="paginatorModel" [pageSize]="10" [initialData]="initialPaginatorData"></app-indeterminate-paginator>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
