import * as proto from 'src/proto/compiled-protos';

export const CURRENCIES: Array<string> = ['USD', 'MXN'];

export const DISTANCE_UNITS: Array<proto.waiternow.common.DistanceQuantityProto.Unit> = [
    proto.waiternow.common.DistanceQuantityProto.Unit.METER,
    proto.waiternow.common.DistanceQuantityProto.Unit.KILOMETER,
    proto.waiternow.common.DistanceQuantityProto.Unit.MILE
];
// This generated enum numbers
// export const DISTANCE_UNITS_NAMES: Array<string> = DISTANCE_UNITS.map(unit => unit.toString());
export const DISTANCE_UNITS_NAMES: Array<string> = ['METER', 'KILOMETER', 'MILE'];

export const COUNTRIES: Array<proto.waiternow.common.Country> = [
    proto.waiternow.common.Country.UNITED_STATES,
    proto.waiternow.common.Country.MEXICO
];
export const COUNTRIES_NAMES: Array<string> = ['United States', 'Mexico'];

export enum ProtoType {
  USER = 'user',
  BUSINESS = 'business',
  LOCATION = 'location',
  LOCATION_BUSINESS_HOURS = 'location-business-hours',
  POINT_OF_SERVICE = 'pointofservice',
  ORDER = 'order',
  REVIEW = 'review',
  DEVICE = 'device',
  CAMPAIGN = 'campaign',
  DELIVERY = 'delivery'
}
